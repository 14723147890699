import { ListItemCurrency }  from "@relcu/ui";
import { ListItemDate }      from "@relcu/ui";
import { ListItemChips }     from "@relcu/ui";
import { ListItemText }      from "@relcu/ui";
import { ListItemTitle }     from "@relcu/ui";
import { ListItemImage }     from "@relcu/ui";
import { ListRow }           from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { SectionClasses }    from "@relcu/ui";
import { JsonViewProps }     from "@relcu/ui";
import { ListRowClasses }    from "@relcu/ui";
import React                 from "react";
import { FC }                from "react";
import { useNavigate }       from "@relcu/react-router";
import { EmptyList }         from "@relcu/ui";
import { getPurelizedLead }  from "../../../../utils/helpers";
import { getHumanized }      from "../../../../utils/schemaUtils";
import { LeadViewClasses }   from "./LeadViewClasses";
import { useLeads }          from "./useLeads";
import './lead-view.css'
export interface LeadContainerProps extends BoxComponentProps {
  title: string
  data: any[]
}

export const LeadContainer: FC<LeadContainerProps> = React.memo(function LeadContainer(props) {
  const { title, children, data, ...p } = props;
  const navigate = useNavigate();
  return <Box container direction={"column"} className={SectionClasses.Section} gap={"XS"} {...p}>
    <Box className={SectionClasses.SectionHeader}>{title}</Box>
    <Box container direction={"column"} gap={"XXXS"}>
      {
        data.map((lead, index) => {
          lead = getPurelizedLead(lead);
          return <ListRow
            key={index}
            justify={"space-between"}
            onClick={() => {navigate(`/lead/${lead.objectId}`)}}
            className={ListRowClasses.Clickable}
          >
            <ListItemImage text={lead.name} flexBasis={"32px"} flexShrink={0}/>
            <ListItemTitle title={lead.name} flexBasis={"130px"} flexGrow={0} flexShrink={1}/>
            <ListItemText text={getHumanized("Lead", "loanPurpose", lead.loanPurpose)}
                          tiny={true} flexBasis={"130px"} flexGrow={0} flexShrink={0}/>
            <ListItemCurrency currency={lead.loanAmount} label="Loan Amount" flexBasis={"130px"} flexShrink={0}
                              flexGrow={0}/>
            <ListItemText text={lead.leadSource} tiny={true} flexBasis={"130px"} flexGrow={0}/>
            <ListItemChips value={getHumanized("Lead", "leadStatus.status", lead.leadStatus.status)}
                           flexBasis={"130px"} flexGrow={1}/>
            <ListItemDate date={lead.createdAt} format="withTime" style={{ textAlign: "right" }} flexBasis={"80px"} flexShrink={1}/>
          </ListRow>;
        })
      }
    </Box>
  </Box>;
});

export const LeadView: FC<JsonViewProps> = React.memo(function LeadView(props) {
  const api = useLeads();
  return <Box container direction={"column"} gap={"XS"}>
    {
      api.edges.length == 0 ?
        <Box flex={"1 1 auto"} justify={"center"} className={LeadViewClasses.LeadEmptyView}>
          <EmptyList icon={"rc_lead"} content={"When you have lead, you'll see them here."}
                     title={"No leads found"}
                     alignSelf={"center"}/>
        </Box>
        :
        <>
          {api.options.map((option) => {
            const title = option.label != "Custom" ? `${option.label} of` : option.label;
            return api.data[ option.value ]?.length > 0 &&
              <LeadContainer key={option.value} title={title} data={api.data[ option.value ]} gap={"XS"}/>;
          })
          }
        </>
    }
  </Box>;
});

export default LeadView;
