import React                        from "react";
import { Sidebar }                  from "../index";
import { SidebarProps }             from "../Sidebar";
import { useClassNames }            from "../utils";
import { RsRefForwardingComponent } from "../@types/common";

export interface PageSidebarProps extends SidebarProps {

}

export const PageSidebar: RsRefForwardingComponent<"aside", PageSidebarProps> = React.forwardRef(
  (props: SidebarProps, ref) => {
    const {
      classPrefix = "page-sidebar",
      className,
      children,
      style,
      collapsible = true,
      width,
      ...rest
    } = props;
    const { withClassPrefix, merge } = useClassNames(classPrefix);
    const classes = merge(className, withClassPrefix());

    return <Sidebar
      className={classes}
      collapsible={collapsible}
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: width === 0 ? 0 : "4px",
        ...style
      }}
      width={width}
      {...rest}
    >
      {children}
    </Sidebar>;
  }
);

PageSidebar.displayName = "PageSidebar";

export default PageSidebar;
