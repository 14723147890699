import { useReactiveVar }            from "@apollo/client";
import { useQuery }                  from "@apollo/client";
import { gql }                       from "@apollo/client";
import { IQueryBuilderOptions }      from "@relcu/gql-query-builder";
import { CircularLoader }            from "@relcu/ui";
import { throttle }                  from "@relcu/ui";
import { useThrottle }               from "@relcu/ui";
import { Alignment }                 from "@relcu/ui";
import { Tooltip }                   from "@relcu/ui";
import { JsonFieldProps }            from "@relcu/ui";
import { TypographySize }            from "@relcu/ui";
import { IconDirection }             from "@relcu/ui";
import { ButtonVariants }            from "@relcu/ui";
import { Button }                    from "@relcu/ui";
import { TypographyWeight }          from "@relcu/ui";
import { Typography }                from "@relcu/ui";
import { CommonClasses }             from "@relcu/ui";
import { FontIcon }                  from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { useSource }                 from "@relcu/ui";
import { EmptyList }                 from "@relcu/ui";
import { useEffect }                 from "react";
import { useCallback }               from "react";
import { useMemo }                   from "react";
import React                         from "react";
import { PRIORITY_QUEUE_FRAGMENT }   from "../../../../../graph/operations.graphql";
import { visibleQueuesVar }          from "../../../../../reactiveVars";
import { getValidTimezones }         from "../../../../../utils/timezone";
import { useReactiveStorage }        from "../../../../useReactiveStorage";
import { PriorityFilter }            from "../PriorityFilter";
import { usePriorityFilter }         from "../PriorityFilter/usePriorityFilter";
import { PriorityQueue }             from "../PriorityQueue";
import { PriorityViewClasses }       from "../PriorityViewClasses";
import { GetPriorityQueues }         from "./__types__/GetPriorityQueues";
import { Query }                     from "@apollo/client/react/components";
import { GetQueuedLeadsCount }       from "./__types__/GetQueuedLeadsCount";
import { PriorityQueueSubscription } from "./__types__/PriorityQueueSubscription";

export interface PriorityQueuesProps {
  view: string,
  expanded: boolean,
  onExpand: (visible: boolean) => void
  fields: JsonFieldProps[];
  jql: IQueryBuilderOptions
  actions: any[]
}
const GET_PRIORITY_QUEUES = gql`
  query GetPriorityQueues($view:ID!) {
    priorityQueues(where: {priorityView:{have: {objectId: {equalTo: $view}}},enabled: {equalTo: true} },order: [priority_ASC]){
      edges {
        node {
          ...PriorityQueue
        }
      }
    }
  }
  ${PRIORITY_QUEUE_FRAGMENT}
`;

const PRIORITY_QUEUE_SUBSCRIPTION = gql`
  subscription PriorityQueueSubscription{
    priorityQueues(where: {enabled: {equalTo: true}}){
      event
      node {
        ...PriorityQueue
      }
    }
  }
  ${PRIORITY_QUEUE_FRAGMENT}
`;

const GET_PRIORITY_LEADS_COUNT = gql`
  query GetPriorityLeadsCount($members: [ID]!, $queues: [ID]!, $view: ID!){
    priorityLeadsCount(members:$members,queues: $queues,view: $view){
      objectId
      count
    }
  }
`;

export const PriorityQueues = React.memo<PriorityQueuesProps>(function PriorityQueues(props) {
  const { view, expanded, onExpand } = props;
  const { data: { priorityQueues: { edges = [] } = {} } = {}, loading, subscribeToMore, refetch } = useQuery<GetPriorityQueues>(GET_PRIORITY_QUEUES, {
    variables: { view }
  });
  const queues = useMemo(() => edges.map(e => e.node.objectId), [edges]);
  useEffect(() => subscribeToMore<PriorityQueueSubscription>({
    document: PRIORITY_QUEUE_SUBSCRIPTION,
    updateQuery(prev, { subscriptionData: { data: { priorityQueues: { event, node } } } }) {
      refetch();
      return prev;
    }
  }), []);
  const filter = usePriorityFilter();
  const [allCollapsed, setAllCollapsed] = useReactiveStorage(`@priority-collapsed`, {});
  const visibleQueues = useReactiveVar(visibleQueuesVar);
  const isCollapsed = useMemo(() => Object.keys(allCollapsed)
  .filter(k => visibleQueues.includes(k))
  .some(k => allCollapsed[ k ]), [allCollapsed, visibleQueues]);

  const onExpandAll = useCallback(() => {
    const all = visibleQueuesVar().reduce((res, id) => ({ ...res, [ id ]: false }), {});
    setAllCollapsed(value => ({ ...value, ...all }));
  }, []);

  const onCollapseAll = useCallback(() => {
    const all = visibleQueuesVar().reduce((res, id) => ({ ...res, [ id ]: true }), {});
    setAllCollapsed(value => ({ ...value, ...all }));
  }, []);

  //const timezones = useMemo(() => getValidTimezones($settings.callableStartHour, $settings.callableEndHour), [$settings]);
  if (loading) {
    return null;
  }

  return (
    <Box container direction={"column"} flex={1}>
      <Box container alignItems={"center"} className={PriorityViewClasses.PriorityTitle}>
        <Tooltip title={"Focus views"} alignment={Alignment.Top}>
          <FontIcon className={CommonClasses.ClickableIcon}
                    onClick={() => onExpand(!expanded)}
                    type={expanded ? "rc_expand_left" : "rc_expand_right"}
          />
        </Tooltip>
        <Typography size={TypographySize.Subtitle}>Focus views</Typography>
        <PriorityFilter {...filter}/>
        <Box flex={1}/>
        <Button variant={ButtonVariants.Ghost} iconDirection={IconDirection.Right}
                icon={!isCollapsed ? "keyboard_arrow_up1" : "keyboard_arrow_down1"}
                onClick={!isCollapsed ? onCollapseAll : onExpandAll}
        >
          <Typography weight={TypographyWeight.Regular} size={TypographySize.TextSmall}>
            {!isCollapsed ? "Collapse" : "Expand"} all
          </Typography>
        </Button>
      </Box>
      <Query
        query={GET_PRIORITY_LEADS_COUNT}
        fetchPolicy={"cache-and-network"}
        nextFetchPolicy={"cache-first"}
        skip={!queues.length}
        variables={{
          members: filter.value,
          queues: queues,
          view
        }}
      >
        {({ loading, data: { priorityLeadsCount = [] } = {}, refetch }) => {
          const queues = priorityLeadsCount.reduce((acc, currentValue) => {
            acc[ currentValue.objectId ] = currentValue.count;
            return acc;
          }, {});
          const total = Object.values(queues).reduce((a: number, b: number) => (a + b), 0);
          if (!loading && !total) {
            return <EmptyList
              className={PriorityViewClasses.PriorityEmpty}
              title={"No priorities found"}
              content={"Your priorities will be listed here."}
              icon={"rc_focus"}
            />;
          }
          const data = edges.filter(e => !!queues[ e.node.objectId ]);
          const onUpdate = throttle(() => refetch(), 500);
          return (
            <div style={{ overflowY: "auto" }}>
              {data.map(({ node }) => {
                return <PriorityQueue
                  jql={props.jql}
                  fields={props.fields}
                  actions={props.actions}
                  count={queues[ node.objectId ] || 0}
                  key={node.id}
                  filter={filter}
                  queue={node}
                  onUpdate={onUpdate}
                />;
              })}
            </div>
          );
        }}
      </Query>
    </Box>
  );
});
