import React            from "react";
import { FC }           from "react";
import { useState }     from "react";
import { useModal }     from "@relcu/ui";
import ComposeModal     from "../../ComposeModal/ComposeModal";
import { ComposeProps } from "./Compose";
import { Compose }      from "./Compose";

export interface ComposeContainerProps extends ComposeProps {
}
export const ComposeContainer: FC<ComposeContainerProps> = React.memo(function ComposeContainer(props) {
  const { draftId, expand, draftReplyToMessage, onToggle, initialValues, ...rest } = props;
  const [composeModal, composeModalContext] = useModal(ComposeModal);
  const [initialData, setInitialData] = useState(null);

  const handleExtend = (data) => {
    composeModal({
      initialData: data,
      draftId: props.draftId,
      draftReplyToMessage,
      onShrink(data: any) {
        //todo check maybe can refuse this and use apollo cache
        setInitialData(data);
      }
    });
  };

  return <>
    {composeModalContext}
    <Compose
      draftId={draftId}
      initialValues={initialData ?? initialValues}
      expand={expand}
      onToggle={onToggle}
      onExtend={handleExtend}
      draftReplyToMessage={draftReplyToMessage}
      {...rest}
    />
  </>;
});
