import React              from "react";
import { Box }            from "@relcu/ui";
import { BadgeColors }    from "@relcu/ui";
import { BadgeVariants }  from "@relcu/ui";
import { Badge }          from "@relcu/ui";
import { ChipsColors }    from "@relcu/ui";
import { Chips }          from "@relcu/ui";
import { SearchableMenu } from "@relcu/ui";
import { Alignment }      from "@relcu/ui";
import { Popper }         from "@relcu/ui";
import { ButtonSizes }    from "@relcu/ui";
import { ButtonVariants } from "@relcu/ui";
import { Button }         from "@relcu/ui";
import { EmptyList }      from "@relcu/ui";
import { Widget }         from "@relcu/ui";
import { WidgetClasses }  from "@relcu/ui";
import { useTagWidget }   from "./useTagWidget";
import "./tag-widget.css";

export const enum TagWidgetClasses {
  TagWidget = "tag-widget"
}

export const TagWidget = React.memo(function TagWidget() {
  const api = useTagWidget();

  return (
    <Widget className={TagWidgetClasses.TagWidget}>
      <Box container alignItems={"center"} justify={"space-between"} className={WidgetClasses.WidgetHeader}>
        Tag
        <Button type={"button"} disabled={api.disabled} variant={ButtonVariants.Ghost}
                size={ButtonSizes.Medium} icon={"add"}
                onClick={(e) => api.togglePopper(e)}>
          ADD
        </Button>
      </Box>
      <Box container wrap={"wrap"} justify={api.data.length == 0 ? "center" : null} gap={"XXS"}>
        {
          api.data.length == 0 ?
            <EmptyList icon={"local_offer"} content={"Click to \"Add\" button to get started"} title={"No tags"}
                       alignSelf={"center"} small/> :
            api.data.map((tag, index) => (
              <Chips key={index}
                     color={ChipsColors.Grey}
                     label={tag}
                     disabled={api.disabled}
                     onDelete={() => api.handleDelete(tag)}
                     thumbnail={<Badge variant={BadgeVariants.Dot} color={BadgeColors.Primary}/>}/>
            ))
        }
      </Box>
      <Popper alignment={Alignment.BottomLeft} open={!!api.searchBounding} onClickAway={api.handleClose}
              anchorBounding={api.searchBounding}>
        <SearchableMenu
          style={{ width: 200 }}
          options={api.options}
          onSelect={api.handleSelect}
          placeholder={"Search or type here"}
          searchText={api.q}
          onCreateNew={api.handleCreateNew}
          loading={false}
          onType={api.onType}
          searchIcon={"local_offer"}
          showCreateButton={false}
        />
      </Popper>
    </Widget>
  );
});
