import { useContext }       from "react";
import React                from "react";
import { ErrorBoundary }    from "@relcu/ui";
import { Route }            from "@relcu/react-router";
import { Routes }           from "@relcu/react-router";
import { BrowserRouter }    from "@relcu/react-router";
import { AlertProvider }    from "@relcu/ui";
import { DndProvider }      from "react-dnd";
import { HTML5Backend }     from "react-dnd-html5-backend";
import { ClientContext }   from "./Client";
import { App }             from "./components";
import { Login }           from "./components/Guest";
import { Guest }            from "./components/Guest";
import { LoadingIndicator } from "./components/LoadingIndicator";
import { Viewer }          from "./components/Viewer";
import { EmailVisualizer } from "./components/EmailVisualizer";

window.customElements.define("email-visualizer", EmailVisualizer);
export default function Web() {
  const context = useContext(ClientContext);

  return (
    <AlertProvider limit={5}>
      <ErrorBoundary ref={(e) => context.setErrorBoundary(e)}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <LoadingIndicator/>
            <Routes>
              <Route path="/login" element={<Guest/>}>
                <Login/>
              </Route>
              <Route path={"*"} element={<Viewer/>}>
                <Route path={"*"} element={
                  <App/>
                }/>
              </Route>
            </Routes>
          </BrowserRouter>
        </DndProvider>
      </ErrorBoundary>
    </AlertProvider>
  );
}
