import { useContext }           from "react";
import { useCallback }          from "react";
import { FORM_ERROR }           from "@relcu/form";
import { setIn }                from "@relcu/form";
import { FormApi }              from "@relcu/form";
import { useAlert }             from "@relcu/ui";
import { GraphQLError }         from "graphql";
import { ContactDialogContext } from "../../ContactDialog";

export function useContactForm() {
  const { error } = useAlert();
  const { afterSubmit, setCreateContactData, setNeedResolve, initialValues } = useContext(ContactDialogContext);
  function getDuplicateError(graphQLErrors: ReadonlyArray<GraphQLError>) {
    return graphQLErrors?.map((error) => error?.extensions?.validationErrors?.find(vError => vError?.type == "duplicationError")).filter((t) => !!t);
  }
  const onError = useCallback((e, form: FormApi, variables) => {
    const duplicateError = getDuplicateError(e?.graphQLErrors);
    if (duplicateError.length > 0) {
      setNeedResolve(true);
      const data = { ...variables.fields };

      if (initialValues.id) {
        data.id = initialValues.id;
        data.objectId = initialValues.objectId;
      }

      setCreateContactData(data);
      duplicateError.forEach((error) => {
        const name = toFieldName([...error.path, error.field]);
        form.mutators.setFieldData(name, { warning: error.message });
      });
    } else {
      error(e.message);
      if (Array.isArray(e.graphQLErrors)) {
        let errors: Object = { [ FORM_ERROR ]: e.message };
        for (let error of e?.graphQLErrors) {
          error?.extensions?.validationErrors?.forEach((validationError) => {
            errors = setIn(errors, toFieldName([...validationError.path, validationError.field]), validationError.message);
          });
        }
        return errors;
      }
    }
  }, []);
  const beforeSubmit = useCallback((values) => {
    values.phones = values.phones.filter(item => item.number && item);
    values.emails = values.emails.filter(item => item.address && item);

    return values;
  }, []);

  return {
    onError,
    afterSubmit,
    beforeSubmit,
  };
}

function toFieldName(path: Array<string | number>): string {
  let name = "";
  for (let p of path) {
    if (!isFinite(p as number)) {
      name += (name ? `.${p}` : p);
    } else {
      name += `[${p}]`;
    }
  }
  return name;
};
