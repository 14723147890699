import { DateTime }                  from "luxon";
import { useMemo }                   from "react";
import { ReactElement }              from "react";
import { RefAttributes }             from "react";
import { ForwardRefExoticComponent } from "react";
import { HTMLAttributes }            from "react";
import { FC }                        from "react";
import React                         from "react";
import { WithAsProps }               from "../@types/common";
import { RsRefForwardingComponent }  from "../@types/common";
import Badge                         from "../Badge";
import { IconType }                  from "../Icon";
import Icon                          from "../Icon";
import { ListProps }                 from "../index";
import { Typography }                from "../index";
import { Avatar }                    from "../index";
import List                          from "../List";
import { SortConfig }                from "../List/helper/useSortHelper";
import { useClassNames }             from "../utils";

export interface ListCardProps extends HTMLAttributes<HTMLDivElement>, RefAttributes<HTMLDivElement> {
  objectName: string,
  objectIcon?: string,
  date: string,
  unread?: boolean,
  subject?: string | ReactElement,
  icon?: IconType,
  text?: string,
  recipientsCounter?: number
  count?: number,
  behalfOf?: boolean
  selected?: boolean
}
const defaultListCardProps: ListCardProps = {
  date: new Date().toISOString(),
  objectName: ""
};

interface ListCardComponent extends ForwardRefExoticComponent<ListCardProps> {
  ProgressIndicator?: typeof ListCardProgressIndicator;
}

export const ListCard: ListCardComponent = React.forwardRef((props: ListCardProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    objectName,
    objectIcon,
    className,
    selected,
    date,
    count,
    icon,
    subject,
    text,
    unread,
    behalfOf,
    children,
    recipientsCounter,
    ...args
  } = props;
  const { withClassPrefix: listCardWithPrefix, merge: mergeCardClasses } = useClassNames("list-card", "rc");
  const cardClasses = mergeCardClasses(className, listCardWithPrefix({ selected }));
  const { withClassPrefix, prefix, merge } = useClassNames("header-right", "rc");
  const rightClasses = merge(
    withClassPrefix({ top: behalfOf || recipientsCounter })
  );

  const { withClassPrefix: withDatePrefix, merge: dateMerge } = useClassNames("date", "rc");
  const dateClasses = dateMerge(
    withDatePrefix({ behalf: behalfOf, recipient: recipientsCounter })
  );
  const isToday = useMemo(() => {
    const today = DateTime.now();
    return DateTime.fromISO(date).month === today.month && DateTime.fromISO(date).year === today.year && DateTime.fromISO(date).day === today.day;
  }, [date]);

  return <div className={cardClasses} {...args} ref={ref}>
    <div className={"rc-header"}>
      <div className={"rc-header-left"}>
        <Avatar circle={true} size={"sm"} showIndicator={false} src={objectIcon}
                className={"rc-list-card-avatar"}>{objectName}</Avatar>
        <div className={"rc-list-card-name-container"}>
          {
            behalfOf &&
            <Typography weights={"regular"} color={"secondary"} variant={"small12"}
                        className={"rc-name"}>On behalf of</Typography>
          }
          <div className={"rc-list-card-name-section"}>
            <Typography weights={"medium"} color={"primary"} variant={"base14"}
                        className={"rc-name"}>{objectName}</Typography>
            {
              count && <Typography className={"rc-count"}>({count})</Typography>
            }
            <Icon className={"rc-card-icon"} type={icon}/>
          </div>
          {
            recipientsCounter &&
            <Badge content={`+${recipientsCounter}`} size={"md"} className={"rc-recipients-counter"}/>
          }
        </div>
      </div>
      <div className={rightClasses}>
        <Typography className={dateClasses}>
          {isToday ? `Today, ${DateTime.fromISO(date).toLocaleString({ hour: "2-digit", minute: "2-digit" })}` : DateTime.fromISO(date).toLocaleString({ month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" })}
        </Typography>
        {
          unread && <Badge className={"rc-online-status"} size={"sm"}/>
        }
      </div>
    </div>
    <div className={"rc-subject-message-content"}>
      {
        subject &&
        typeof subject == "string" ?
          <Typography
            weights={unread ? "medium" : "regular"}
            color={unread ? "primary" : "secondary"}
            variant={"base14"}
            className={"rc-text-content"}>
            {subject}
          </Typography> :
          subject
      }
      {
        text
        &&
        <Typography
          weights={unread ? "medium" : "regular"}
          color={unread ? "primary" : "secondary"}
          variant={"base14"}
          className={"rc-text-content"}>
          {text}
        </Typography>
      }
    </div>
    {children}
  </div>;
});

interface ListCardProgressIndicatorProps extends React.HTMLAttributes<HTMLElement> {
  icon: IconType,
  title: string,
  progressPercentage: number,
  count: number
}
export const ListCardProgressIndicator = React.forwardRef((props: ListCardProgressIndicatorProps, ref: React.Ref<HTMLDivElement>) => {
  const { icon, title, progressPercentage, count } = props;
  const { withClassPrefix, prefix, merge } = useClassNames("progress-icon", "rc");
  const classes = merge(
    withClassPrefix({ complete: progressPercentage == 100 })
  );

  return <div className={"rc-progress"}>
    <Icon type={icon} className={classes}/>
    <div className={"rc-progress-bar"}>
      {
        progressPercentage !== 100 ?
          <>
            <Typography className={"rc-progress-title"}>{title}</Typography>
            <div className={"rc-progress-indicator"}
                 style={{ "--rc-progress-indicator": `${progressPercentage}%` } as any}/>
          </> :
          <Typography weights={"regular"} color={"secondary"}>
            Bulk action sent to <Typography as={"span"} weights={"medium"}
                                            color={"secondary"}>{count} leads</Typography></Typography>
      }
    </div>
  </div>;
});

ListCard.ProgressIndicator = ListCardProgressIndicator;
ListCard.displayName = "ListCard";
ListCard.defaultProps = defaultListCardProps;
