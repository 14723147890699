import { useThrottleCallback }          from "@relcu/ui";
import React                            from "react";
import { useEffect }                    from "react";
import { useCallback }                  from "react";
import { useMemo }                      from "react";
import { useAlert }                     from "@relcu/ui";
import { applyDrag }                    from "@relcu/ui";
import { Typography }                   from "@relcu/ui";
import { confirmModal }                 from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { useApolloClient }              from "@apollo/client";
import { gql }                          from "@apollo/client";
import { useMutation }                  from "@apollo/client";
import { useQuery }                     from "@apollo/client";
import { useModal }                     from "@relcu/ui";
import { PriorityQueue }                from "../../../../graph/__types__/PriorityQueue";
import { PriorityView }                 from "../../../../graph/__types__/PriorityView";
import { PRIORITY_QUEUE_FRAGMENT }      from "../../../../graph/operations.graphql";
import { PRIORITY_VIEW_FRAGMENT }       from "../../../../graph/operations.graphql";
import { layoutVar }                    from "../../../../reactiveVars";
import { SubscriptionEvent }            from "../../../../types/graphql-global-types";
import { PriorityQueueDialog }          from "../../Dialog/PriorityQueueDialog";
import { PriorityQueueShareDialog }     from "../../Dialog/PriorityQueueShareDialog";
import { CreatePriorityQueueVariables } from "./__types__/CreatePriorityQueue";
import { CreatePriorityQueue }          from "./__types__/CreatePriorityQueue";
import { CreatePriorityViewVariables }  from "./__types__/CreatePriorityView";
import { CreatePriorityView }           from "./__types__/CreatePriorityView";
import { DeletePriorityQueueVariables } from "./__types__/DeletePriorityQueue";
import { DeletePriorityQueue }          from "./__types__/DeletePriorityQueue";
import { DeletePriorityViewVariables }  from "./__types__/DeletePriorityView";
import { DeletePriorityView }           from "./__types__/DeletePriorityView";
import { GetPriorityView }              from "./__types__/GetPriorityView";
import { SubscribeDeletedPriorityView } from "./__types__/SubscribeDeletedPriorityView";
import { SubscribePriorityView }        from "./__types__/SubscribePriorityView";
import { UpdatePriorityQueueVariables } from "./__types__/UpdatePriorityQueue";
import { UpdatePriorityQueue }          from "./__types__/UpdatePriorityQueue";
import { UpdatePriorityViewVariables }  from "./__types__/UpdatePriorityView";
import { UpdatePriorityView }           from "./__types__/UpdatePriorityView";

export function useFocusView(props) {
  const { $viewer } = useSource();
  const layouts = layoutVar();
  const { success, error } = useAlert();
  const [priorityQueueDialog, priorityQueueDialogContext] = useModal(PriorityQueueDialog);
  const [priorityQueueShareDialog, priorityQueueShareDialogContext] = useModal(PriorityQueueShareDialog);
  const { data: { priorityViews: { edges = [], count } = {} } = {}, subscribeToMore, loading, refetch } = useQuery<GetPriorityView>(GET_PRIORITY_VIEW);
  const [createView] = useMutation<CreatePriorityView, CreatePriorityViewVariables>(CREATE_PRIORITY_VIEW);
  const [updateView] = useMutation<UpdatePriorityView, UpdatePriorityViewVariables>(UPDATE_PRIORITY_VIEW);
  const [createQueue] = useMutation<CreatePriorityQueue, CreatePriorityQueueVariables>(CREATE_PRIORITY_QUEUE);
  const [updateQueue] = useMutation<UpdatePriorityQueue, UpdatePriorityQueueVariables>(UPDATE_PRIORITY_QUEUE);
  const [deleteView] = useMutation<DeletePriorityView, DeletePriorityViewVariables>(DELETE_PRIORITY_VIEW);
  const [deleteQueue] = useMutation<DeletePriorityQueue, DeletePriorityQueueVariables>(DELETE_PRIORITY_QUEUE);
  const reload = useThrottleCallback(() => refetch(), 2000);
  const dialogProps = useMemo(() => layouts[ props.classId ], [props.classId]);
  const handleCreateQueue = useCallback((e, id) => {
    e.stopPropagation();
    priorityQueueDialog({
      action: "create",
      onClose: () => reload(),
      viewId: id,
      availableFields: props.fields,
      className: "PriorityQueue",
      ...dialogProps.dialog.properties
    } as any);

  }, [dialogProps]);
  const handleUpdateQueue = useCallback((e, node) => {
    e.stopPropagation();
    priorityQueueDialog({
      action: "save",
      onClose: () => reload(),
      availableFields: props.fields,
      className: "PriorityQueue",
      record: node,
      ...dialogProps.dialog.properties
    } as any);

  }, [dialogProps]);
  const handleDeleteQueue = useCallback(async (e, id) => {
    e.stopPropagation();
    await confirmModal({
      title: "Remove Section",
      subTitle: "You are removing this Section",
      content: "If you remove this section, it will not be available anymore.",
      label: "REMOVE"
    });

    await deleteQueue({
      variables: {
        id
      }
    });
    reload();
  }, [dialogProps]);
  const handleCopyQueue = useCallback(async (e, queue: PriorityQueue, view: PriorityView) => {
    try {
      e.stopPropagation();
      const { __typename, ACL, updatedAt, createdAt, objectId, objectName, objectIcon, id, ...rest } = queue;
      const queues = sortEdges(view.priorityQueues.edges);
      const { node: lastQueue = {} } = queues[ queues.length - 1 ];

      await createQueue({
        variables: {
          fields: {
            ...rest,
            title: `${queue.title}(Copy)`,
            fields: rest?.fields ? rest?.fields.map(({ __typename, ...field }) => field) : [],
            priorityView: {
              link: queue.priorityView.id
            },
            priority: (lastQueue.priority ?? 0) + 1
          }
        }
      });
      success("Queue copied successfully.");
      reload();
    } catch (e) {
      error(e?.message?.toLocaleString());
    }
  }, []);
  const handleToggleQueue = useCallback(async (id: string, enabled: boolean) => {
    await updateQueue({
      variables: {
        id,
        fields: {
          enabled
        }
      }
    });
    reload();
  }, []);
  const handleShareView = useCallback((e, view) => {
    e.stopPropagation();
    const { destroy } = priorityQueueShareDialog({
      action: "create",
      rPerm: view.rPerm,
      onConfirm: (data) => {
        destroy();
        updateView({
          variables: {
            id: view.id,
            fields: {
              rPerm: data
            }
          }
        });
      },
      className: "PriorityQueue"
    });
  }, []);
  const handleCreateView = useCallback((e) => {
    e.stopPropagation();
    const lastView = edges[ edges.length - 1 ];
    createView({
      variables: {
        fields: {
          createdBy: {
            link: $viewer.id
          },
          title: `Focus view ${lastView ? lastView.node.priority + 1 : ""}`,
          priority: lastView ? lastView.node.priority + 1 : 1
        }
      }
    });
  }, [edges]);
  const handleUpdateView = useCallback((e, id, data) => {
    try {
      e.stopPropagation();
      updateView({
        variables: {
          id,
          fields: data
        }
      });
      success("Changes saved successfully.");
    } catch (e) {
      error(e?.message?.toLocaleString());
    }
  }, []);
  const handleCopyView = useCallback(async (e, view) => {
    try {
      e.stopPropagation();
      const { node } = edges[ edges.length - 1 ];
      const { data: { createPriorityView: { priorityView } = {} } = {} } = await createView({
        variables: {
          fields: {
            title: `${view.title}(Copy)`,
            priority: node.priority + 1,
            createdBy: {
              link: $viewer.id
            },
            rPerm: view.rPerm
          }
        }
      });
      const queues = sortEdges(view.priorityQueues.edges);
      const { node: lastQueue } = queues[ queues.length - 1 ] || {};

      queues.forEach(async ({ node }, index) => {
        const { __typename, ACL, updatedAt, createdAt, objectId, objectName, objectIcon, id, fields, title, ...rest } = node as PriorityQueue;
        await createQueue({
          variables: {
            fields: {
              ...rest,
              title: `${title}(Copy)`,
              priority: lastQueue?.priority + index,
              fields: fields ? fields.map(field => {
                const { __typename, ...p } = field;
                return {
                  ...p
                };
              }) : [],
              priorityView: {
                link: priorityView.id
              }
            }
          }
        });
      });
      success("View copied successfully.");
      reload();
    } catch (e) {
      error(e?.message?.toLocaleString());
    }
  }, [edges]);
  const handleDeleteView = useCallback(async (e, node) => {
    e.stopPropagation();

    await confirmModal({
      title: "Remove View",
      subTitle: "Are you sure you want to remove this entire Focus View?",
      content: <Typography>If you remove this view, all of the sections that are included in this view will be removed
        as well. <br/>This can not be undone..</Typography>,
      label: "REMOVE"
    });

    const queues = [...node.priorityQueues.edges];
    await deleteView({
      variables: {
        id: node.id
      }
    });

    queues.forEach(async ({ node: { id } }) => {
      await deleteQueue({
        variables: {
          id
        }
      });
    });
  }, [confirmModal]);

  const client = useApolloClient();
  function onViewDrop(dropResult) {
    const { priorityViews: { edges = [] } = {} } = client.readQuery<GetPriorityView>({ query: GET_PRIORITY_VIEW });
    let sorted = applyDrag(sortEdges(edges), dropResult);
    const lowestPriority = Math.min(...sorted.map(o => o.node.priority));
    sorted = sorted.map((s, index) => {
      return {
        ...s,
        node: {
          ...s.node,
          priority: lowestPriority + index
        }
      };
    });

    client.writeQuery<GetPriorityView>({
      query: GET_PRIORITY_VIEW,
      data: {
        priorityViews: {
          count: count,
          edges: [...sorted],
          __typename: "PriorityViewConnection"
        }
      }
    });

    sorted.map((edge, index) => {
      updateView({
        variables: {
          id: edge.node.id,
          fields: {
            priority: edge.node.priority
          }
        }
      });
    });
  }
  function sortEdges(edges) {
    return edges.slice().sort((a, b) => {
      if (a.node.priority < b.node.priority) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  function onSectionDrop(viewId, dropResult) {
    const { priorityViews: { edges = [] } = {} } = client.readQuery<GetPriorityView>({ query: GET_PRIORITY_VIEW });
    const { node: view } = edges.find(pr => pr.node.id == viewId);
    let sorted = applyDrag(sortEdges(view.priorityQueues.edges), dropResult);
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      let changed = edges.map(view => {
        if (view.node.id == viewId) {
          const lowestPriority = Math.min(...sorted.map(o => o.node.priority));
          sorted = sorted.map((s, index) => {
            return {
              ...s,
              node: {
                ...s.node,
                priority: lowestPriority + index
              }
            };
          });

          return {
            ...view,
            node: {
              ...view.node,
              priorityQueues: {
                ...view.node.priorityQueues,
                edges: sorted
              }
            }
          };
        } else {
          return view;
        }
      });
      client.writeQuery<GetPriorityView>({
        query: GET_PRIORITY_VIEW,
        data: {
          priorityViews: {
            count: count,
            edges: [...changed],
            __typename: "PriorityViewConnection"
          }
        }
      });
      sorted.map((edge, index) => {
        updateQueue({
          variables: {
            id: edge.node.id,
            fields: {
              priority: edge.node.priority,
              priorityView: {
                link: viewId
              }
            }
          }
        });
      });
    }
  }

  useEffect(() => subscribeToMore<SubscribePriorityView>({
    document: SUBSCRIBE_PRIORITY_VIEW,
    updateQuery(prev, { subscriptionData: { data: { priorityViews: { event, node } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
        case SubscriptionEvent.ENTER: {
          return {
            ...prev,
            priorityViews: {
              ...prev.priorityViews,
              count: prev.priorityViews.count + 1,
              edges: [
                ...prev.priorityViews.edges,
                {
                  __typename: "PriorityViewEdge",
                  node
                }
              ]
            }
          };
        }
        case SubscriptionEvent.LEAVE:
          let priorityViews = prev.priorityViews.edges.filter(edge => edge.node.id !== node.id);
          return {
            ...prev,
            priorityViews: {
              ...prev.priorityViews,
              edges: priorityViews,
              count: priorityViews.length
            }
          };
      }
      return prev;
    }
  }), []);
  useEffect(() => subscribeToMore<SubscribeDeletedPriorityView>({
    document: SUBSCRIBE_DELETED_PRIORITY_VIEW,
    updateQuery(prev, { subscriptionData: { data: { priorityViews: { event, node } } } }) {
      switch (event) {
        case SubscriptionEvent.DELETE:
          let priorityViews = prev.priorityViews.edges.filter(edge => edge.node.id !== node.id);
          return {
            ...prev,
            priorityViews: {
              ...prev.priorityViews,
              edges: priorityViews,
              count: priorityViews.length
            }
          };
      }
      return prev;
    }
  }), []);

  return {
    priorityQueueDialogContext,
    priorityQueueShareDialogContext,
    priorityView: edges,
    count,
    loading,
    sortEdges,
    onViewDrop,
    onSectionDrop,
    handleCopyView,
    handleShareView,
    handleCreateView,
    handleUpdateView,
    handleDeleteView,
    handleCreateQueue,
    handleUpdateQueue,
    handleDeleteQueue,
    handleToggleQueue,
    handleCopyQueue
  };
}

export const CREATE_PRIORITY_VIEW = gql`
  mutation CreatePriorityView($fields: CreatePriorityViewFieldsInput) {
    createPriorityView(input: {fields: $fields}){
      priorityView {
        ...PriorityView
      }
    }
  }
  ${PRIORITY_VIEW_FRAGMENT}
`;

export const UPDATE_PRIORITY_VIEW = gql`
  mutation UpdatePriorityView($id: ID!, $fields: UpdatePriorityViewFieldsInput) {
    updatePriorityView(input: {id:$id, fields: $fields}){
      priorityView {
        ...PriorityView
      }
    }
  }
  ${PRIORITY_VIEW_FRAGMENT}
`;

export const DELETE_PRIORITY_VIEW = gql`
  mutation DeletePriorityView($id: ID!) {
    deletePriorityView(input: {id: $id}){
      priorityView {
        id
        objectId
      }
    }
  }
`;

export const GET_PRIORITY_VIEW = gql`
  query GetPriorityView {
    priorityViews(order: [priority_ASC]){
      count
      edges {
        node {
          ...PriorityView
        }
      }
    }
  }
  ${PRIORITY_VIEW_FRAGMENT}
`;

export const SUBSCRIBE_PRIORITY_VIEW = gql`
  subscription SubscribePriorityView {
    priorityViews(events: [CREATE,LEAVE,ENTER]) {
      event
      node {
        ...PriorityView
      }
    }
  }
  ${PRIORITY_VIEW_FRAGMENT}
`;

const SUBSCRIBE_DELETED_PRIORITY_VIEW = gql`
  subscription SubscribeDeletedPriorityView {
    priorityViews(events: [DELETE]) {
      event
      node {
        id
        objectId
      }
    }
  }
`;

export const CREATE_PRIORITY_QUEUE = gql`
  mutation CreatePriorityQueue($fields: CreatePriorityQueueFieldsInput) {
    createPriorityQueue(input: {fields: $fields}){
      priorityQueue {
        ...PriorityQueue
      }
    }
  }
  ${PRIORITY_QUEUE_FRAGMENT}
`;

export const DELETE_PRIORITY_QUEUE = gql`
  mutation DeletePriorityQueue($id: ID!) {
    deletePriorityQueue(input: {id: $id}){
      priorityQueue {
        id
        objectId
      }
    }
  }
`;

export const UPDATE_PRIORITY_QUEUE = gql`
  mutation UpdatePriorityQueue($id: ID!, $fields: UpdatePriorityQueueFieldsInput) {
    updatePriorityQueue(input: {id:$id, fields: $fields}){
      priorityQueue {
        ...PriorityQueue
      }
    }
  }
  ${PRIORITY_QUEUE_FRAGMENT}
`;
