import React                         from "react";
import { FC }                        from "react";
import { useState }                  from "react";
import { useCallback }               from "react";
import { useMemo }                   from "react";
import { gql }                       from "@apollo/client";
import { useLazyQuery }              from "@apollo/client";
import { useMutation }               from "@apollo/client";
import { useQuery }                  from "@apollo/client";
import { BackDropLoader}             from "@relcu/ui";
import { modal }                     from "@relcu/ui";
import { StateWarningDialog}         from "@relcu/ui";
import { Avatar, useSelect }         from "@relcu/ui";
import { MenuItem }                  from "@relcu/ui";
import { ModalProps }                from "@relcu/ui";
import { Modal }                     from "@relcu/ui";
import { ModalBody }                 from "@relcu/ui";
import { ButtonVariants }            from "@relcu/ui";
import { Button }                    from "@relcu/ui";
import { ModalFooter }                                     from "@relcu/ui";
import { SearchInput }                                     from "@relcu/ui";
import { TypographyColor }                                 from "@relcu/ui";
import { TypographySize }                                  from "@relcu/ui";
import { Typography }                                      from "@relcu/ui";
import { NODE_FRAGMENT }                                   from "../../../graph/operations.graphql";
import { DOCUMENT }                                        from "../../../graph/operations.graphql";
import { AllUsers }                                        from "../../__types__/AllUsers";
import { GetLeadsByDynamicWhere }                          from "../../Bulk/BulkRecipientList/__types__/GetLeadsByDynamicWhere";
import { ALL_USERS }                                       from "../../operations.graphql";
import { CommonModalClasses }                              from "../CommonModal/CommonModalClasses";
import { GetMismatchesLeadsByUserLicensedStatesVariables } from "./__types__/GetMismatchesLeadsByUserLicensedStates";
import { GetMismatchesLeadsByUserLicensedStates }          from "./__types__/GetMismatchesLeadsByUserLicensedStates";
import { UpdateLeadAssignVariables }                       from "./__types__/UpdateLeadAssign";
import { UpdateLeadAssign }                                from "./__types__/UpdateLeadAssign";
import "../CommonModal/common-modal.css";

export interface BulkAssignModalProps extends Partial<ModalProps> {
  ids: string[];
  destroyHandler: () => void
  onSubmit(data);
}

export const BulkUserAssignModal: FC<BulkAssignModalProps> = React.memo(function BulkAssignModal(props) {
  const { onClose, onSubmit, ids, destroyHandler } = props;
  const [loading, setLoading] = useState(false);
  const [updateLead] = useMutation<UpdateLeadAssign, UpdateLeadAssignVariables>(UPDATE_LEAD_ASSIGN);
  const { data: { users: { edges = [] } = {} } = {}, loading: userLoading } = useQuery<AllUsers>(ALL_USERS);
  const [loadMismatchLeads] = useLazyQuery<GetMismatchesLeadsByUserLicensedStates, GetMismatchesLeadsByUserLicensedStatesVariables>(ALL_MISMATCHES_LEADS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const options = useMemo(() => {
    return edges.filter(({ node }) => !node.deactivated).map(({ node }) => ({
      label: node.objectName, value: node.id, icon: node.objectIcon, licensedStates: node.licensedStates
    }));
  }, [edges]);

  const {
    selected,
    setSearch,
    search,
    handleSelect,
    items
  } = useSelect({
    options,
    optionLabel: "label",
    optionKey: "value"
  });

  const handleSubmit = useCallback(async () => {
console.log(selected.licensedStates, ids)
    await loadMismatchLeads({
      variables: { ids, states: selected.licensedStates }
    }).then(async ({data: {leads: {edges = []} = {}} = {}}) => {
      if (edges.length) {
        destroyHandler()
        const {destroy: destroyWarningDialog} = modal(StateWarningDialog, {
          closeIcon: true,
          leads: edges.map(({node}) => `${node.borrowerFirstName} ${node.borrowerLastName}`),
          onAssignAllAnyway: async () => {
            await assignLeads(ids)
            destroyWarningDialog();
          },
          onSkipMismatches: async () => {
            await assignLeads(ids.filter(item => !edges.map(({node}) => node.id).includes(item)))
            destroyWarningDialog();
          },
          onClose: () => {
            destroyWarningDialog();
          }
        });
      } else {
        await assignLeads(ids)
      }

    }).catch(console.error);
  }, [selected, onSubmit, ids]);

  const assignLeads = useCallback(async (ids) => {

    setLoading(true);

    const promises = ids.map(async id => {
      return await updateLead({variables: {id, assignTo: selected.value}});
    });
    const leads = await Promise.allSettled(promises);

    const data = leads.map(lead => {
      if (lead.status == "fulfilled") {
        return lead.value.data;
      } else {
        return lead.reason.toLocaleString();
      }
    });
    setLoading(false);
    destroyHandler()
    onSubmit?.(data);
  },[selected, onSubmit, setLoading])

  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    disableBackdropClick={false}
    title={"Assign to a user"}
    className={CommonModalClasses.BulkModal}
  >
    <ModalBody container direction={"column"} flex={1} className={CommonModalClasses.Container}>
      <SearchInput value={search} onChange={setSearch}/>
      <div className={CommonModalClasses.ScrollContainer}>
        {
          userLoading ?
            <BackDropLoader/>
            :
            items.map((item, index) => {
              return <MenuItem
                className={CommonModalClasses.Item}
                disabled={item.disabled}
                selected={item.value == selected?.value}
                key={index}
                thumbnail={<Avatar text={item.label} icon={item.icon}/>}
                onClick={(e) => {
                  handleSelect(item);
                }}>
                <Typography
                  size={item.disabled ? TypographySize.TextTiny : TypographySize.Text}
                  color={item.disabled ? TypographyColor.Secondary : TypographyColor.ExtraDark}
                >
                  {item.disabled ? item.label.toUpperCase() : item.label}
                </Typography>
              </MenuItem>;
            })
        }
      </div>
    </ModalBody>
    <ModalFooter borderOnTop>
      <Button variant={ButtonVariants.Outline} onClick={onClose}>CANCEL</Button>
      <Button onClick={handleSubmit} disabled={!selected}>ASSIGN</Button>
    </ModalFooter>
  </Modal>;
});

export const UPDATE_LEAD_ASSIGN = gql`
  mutation UpdateLeadAssign($id:ID!, $assignTo: ID!){
    updateLead(input: {
      id: $id,
      fields: {
        assignedTo: {
          link: $assignTo
        }
      }
    }){
      lead{
        id
        assignedTo {
          ...Node
          objectId
          objectName
          objectIcon
        }
      }
    }
  }
  ${NODE_FRAGMENT}
`;

const ALL_MISMATCHES_LEADS = gql`
    query GetMismatchesLeadsByUserLicensedStates($ids:[ID], $states: [String]) {
        leads(where: {
            AND: [
                {id: {in: $ids}}
                {propertyState: {notIn: $states}}
                {propertyState: {notEqualTo: null}}
            ]
        }) {
            edges {
                node {
                    id
                    objectId
                    borrowerFirstName
                    borrowerLastName
                    propertyState
                }
            }
        }
    }
`;
