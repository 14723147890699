import React                          from "react";
import { FC }                         from "react";
import { Avatar }                     from "@relcu/rc";
import { Stack }                      from "@relcu/rc";
import { RelcuCallActionsCollapsed }  from "./RelcuCallActionsCollapsed";
import { TwilioCallActionsCollapsed } from "./TwilioCallActionsCollapsed";
import { useHybridCall }              from "./useHybridCall";
import "./hybrid-call.css";

export const HybridCallCollapsed: FC<{ advancedPhone?: boolean }> = React.memo(function HybridCallCollapsed(props) {
    const { activeCalls } = useHybridCall();
    return <Stack spacing={24} direction={"column"}>
      {
        activeCalls?.length == 2 &&
        <Stack direction={"column"} childrenRenderMode={"clone"} className={"hybrid-call-parties-collapsed"}>
          {
            props.advancedPhone &&
            activeCalls.map((call, index) => {
              return <Avatar
                key={index}
                circle
                src={call.objectIcon}
                showIndicator={false}
                alt={call.objectName}>
                {call.objectName}
              </Avatar>;
            })
          }
        </Stack>
      }
      {
        !!activeCalls?.length &&
        props.advancedPhone ?
          <RelcuCallActionsCollapsed/> :
          <TwilioCallActionsCollapsed/>
      }
    </Stack>;
  }
);
