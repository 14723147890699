import React                  from "react";
import { FC }                 from "react";
import { formatPhoneNumber }  from "../../../utils/helpers";
import { parsePhoneNumber }   from "../../../utils/helpers";
import { PhoneNumberProps }   from "../../Input/PhoneNumber";
import { BaseFieldProps }     from "../BaseField";
import { BaseField }          from "../BaseField";
import { PhoneNumberFlags }   from "./PhoneNumberOptions";
import { PhoneNumberOptions } from "./PhoneNumberOptions";
import PhoneNumberReadField   from "./PhoneNumberReadField";
import PhoneNumberEditField   from "./PhoneNumberEditField";

export type PhoneNumberFieldProps = BaseFieldProps & PhoneNumberProps;

export const PhoneNumberField: FC<PhoneNumberFieldProps> = React.memo(function PhoneNumberField(props) {
  return (
    <BaseField
      validate={(value) => {
        if (String(value).includes("_")) {
          return `${props.label || "Phone number"} is invalid`;
        }
      }}
      {...props}
      format={(value) => {
        const option = PhoneNumberOptions.find(o => String(value).startsWith(o.code));
        const [US] = PhoneNumberOptions;

        return {
          country: option?.country || US.country,
          code: option?.code || US.code,
          number: String(value || option?.code || US.code),
          flag: option?.flag || PhoneNumberFlags.US,
          formatted : (value && value.trim()!="") ? formatPhoneNumber(value.trim()) : null
        };
      }}
      parse={(value) => {
        return (value.number ? parsePhoneNumber(value.number) : "");
      }}
      readView={<PhoneNumberReadField/>}
      editView={<PhoneNumberEditField/>}
    />
  );
});


