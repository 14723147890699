import React               from "react";
import { FC }              from "react";
import { ToggleDropdown }  from "@relcu/rc";
import { Icon }            from "@relcu/rc";
import { Alignment }       from "@relcu/ui";
import { Box }             from "@relcu/ui";
import { VerticalDivider } from "@relcu/ui";
import { CallOut }         from "@relcu/ui";
import { IconButton }      from "@relcu/rc";
import { Chips }           from "@relcu/ui";
import { ChipsColors }     from "@relcu/ui";
import { Tooltip }         from "@relcu/ui";
import { TypographyColor } from "@relcu/ui";
import { Typography }      from "@relcu/ui";
import { HeaderCall }      from "../HeaderCall";
import { useLeadHeader }   from "./useLeadHeader";
import "./lead-header.css";

export const LeadHeader: FC = React.memo(function LeadHeader(props) {
  const api = useLeadHeader();
  const config = window.__CONFIG__;
  if (api.isDuplicate) {
    return null;
  }

  return <Box container alignItems={"center"} justify={"end"} gap={"XXS"} flexBasis={"50%"}
              className={"lead-header"}>
    {api.modalHolder}
    {api.recordModalHolder}
    <CallOut
      avatar={
        <ToggleDropdown
          dropDownStyle={{ maxHeight: 500, overflowY: "auto" }}
          placement={"auto"}
          toggleRenderer={
            <IconButton
              disabled={!api.canUpdate}
              icon={<Icon type={"flash_on"}/>}
              size={"md"}
            />
          }
        >
          {
            api.availableActions.map((action) =>
              <ToggleDropdown.Item key={action.value}
                                   onSelect={() => api.handleAction(action)}>{action.label}</ToggleDropdown.Item>)
          }
        </ToggleDropdown>
      }
      content={
        <Tooltip title={`${api.states[ api.status ]?.stage} - ${api.states[ api.status ]?.status}`}
                 alignment={Alignment.Bottom}>
            <Chips color={ChipsColors.Grey} label={api.states[ api.status ]?.status}/>
        </Tooltip>
      }
      header={<Typography color={TypographyColor.Secondary} noWrap>{api.states[ api.status ]?.stage}</Typography>}
    />
    {!api.states[ api.status ]?.not_contact && <>
      <VerticalDivider/>
      <HeaderCall/>
    </>
    }
    {props.children}
    <VerticalDivider/>
    {
      api.showMore ?
        <ToggleDropdown
          placement={"autoVerticalEnd"}
          toggleRenderer={
            <IconButton
              icon={<Icon type={"keyboard_control"}/>}
              size={"md"}
              style={{flexShrink: 0}}
            />}
        >
          {config.losExport && <ToggleDropdown.Item  disabled={!api.canExport} onSelect={api.onExportLead}>Export lead to LOS</ToggleDropdown.Item>}
          {config.directMail && <ToggleDropdown.Item onSelect={api.onSearchRecord}>Direct mail records search</ToggleDropdown.Item>}
          <ToggleDropdown.Item onSelect={api.navigateToSms}>
            Send SMS
          </ToggleDropdown.Item>
          <ToggleDropdown.Item onSelect={api.navigateToEmail}>
            Write email
          </ToggleDropdown.Item>
          <ToggleDropdown.Item onSelect={api.onAddTask}>
            Create task
          </ToggleDropdown.Item>
        </ToggleDropdown>
        // <ButtonGroup tooltip={"More"} buttonMenu collapse height={50}>
        //   <Button icon={"keyboard_control"} onlyIcon size={ButtonSizes.Big}>...</Button>
        //   <Button onClick={api.navigateToSms}>Send SMS</Button>
        //   <Button disabled={!api.canContact} onClick={api.navigateToEmail}>Write email</Button>
        //   <Button disabled={!api.canUpdate} onClick={api.onAddTask}>Create task</Button>
        // </ButtonGroup>
        :
        <>
          {
            config.losExport && <>
              <Tooltip title={"Export lead to LOS"} alignment={Alignment.Bottom}>
                <IconButton
                  style={{flexShrink: 0}}
                  icon={<Icon type={"ios_share"}/>}
                  disabled={!api.canExport}
                  size={"md"} onClick={api.onExportLead}/>
              </Tooltip>
              <VerticalDivider/>
            </>
          }
          {
          config.directMail && <>
            <Tooltip title={"Direct mail records search"} alignment={Alignment.Bottom}>
              <IconButton
                style={{flexShrink: 0}}
                icon={<Icon type={"contact_mail"}/>}
                disabled={!api.canUpdate}
                size={"md"} onClick={api.onSearchRecord}/>
            </Tooltip>
            <VerticalDivider/>
          </>
        }
          {!api.states[ api.status ]?.not_contact && <>
            <Tooltip title={"Send SMS"} alignment={Alignment.Bottom}>
              <IconButton
                style={{flexShrink: 0}}
                icon={<Icon type={"forum"}/>}
                size={"md"}
                onClick={api.navigateToSms}
              />
            </Tooltip>
            {/*<VerticalDivider/>*/}
            {/*<Tooltip title={"Go to pricing page"} alignment={Alignment.Bottom}>*/}
            {/*  <Button onlyIcon icon={"rc_price"} size={ButtonSizes.Big} onClick={api.navigateToPricing}*/}
            {/*          disabled={!api.readyToPullPricing || !api.canUpdate}/>*/}
            {/*</Tooltip>*/}
            <VerticalDivider/>
            <Tooltip title={"Send email"} alignment={Alignment.Bottom}>
              <IconButton
                style={{flexShrink: 0}}
                icon={<Icon type={"email"}/>}
                size={"md"}
                onClick={api.navigateToEmail}
                disabled={!api.canContact}
              />
            </Tooltip>
            <VerticalDivider/>
          </>
          }
          <Tooltip title={"Create a task"} alignment={Alignment.Bottom}>
            <IconButton
              style={{flexShrink: 0}}
              icon={<Icon type={"add_task"}/>}
              size={"md"}
              onClick={api.onAddTask}
              disabled={!api.canUpdate}
            />
          </Tooltip>
        </>
    }
  </Box>;
});
