import { gql }                                          from "@apollo/client";
import { useSubscription }                              from "@apollo/client";
import { useQuery }                                     from "@apollo/client";
import { useSource }                                    from "@relcu/ui";
import { useEffect }                                    from "react";
import { SubscriptionEvent }                            from "../../../../types/graphql-global-types";
import { GetLeadStatsVariables }                        from "./__types__/GetLeadStats";
import { GetLeadStats }                                 from "./__types__/GetLeadStats";
import { LeadStatInBoundCallSubscriptionVariables }     from "./__types__/LeadStatInBoundCallSubscription";
import { LeadStatInBoundCallSubscription }              from "./__types__/LeadStatInBoundCallSubscription";
import { LeadStatInComingMessageSubscriptionVariables } from "./__types__/LeadStatInComingMessageSubscription";
import { LeadStatInComingMessageSubscription }          from "./__types__/LeadStatInComingMessageSubscription";
import { LeadStatOutBoundCallSubscriptionVariables }    from "./__types__/LeadStatOutBoundCallSubscription";
import { LeadStatOutBoundCallSubscription }             from "./__types__/LeadStatOutBoundCallSubscription";
import { LeadStatOutBoundMessageSubscriptionVariables } from "./__types__/LeadStatOutBoundMessageSubscription";
import { LeadStatOutBoundMessageSubscription }          from "./__types__/LeadStatOutBoundMessageSubscription";

export function useLeadStats() {
  const { $object } = useSource();
  const { id, loanProposals } = $object;
  const query = useQuery<GetLeadStats, GetLeadStatsVariables>(GET_LEAD_STATS, {
    variables: { id }
  });

  useSubscription<LeadStatOutBoundCallSubscription, LeadStatOutBoundCallSubscriptionVariables>(LEAD_STAT_OUTBOUND_CALL_SUBSCRIPTION, {
    variables: { id },
    onData({ client, data: { data: { conferences: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          query.refetch();
      }
    }
  });
  useSubscription<LeadStatInBoundCallSubscription, LeadStatInBoundCallSubscriptionVariables>(LEAD_STAT_INBOUND_CALL_SUBSCRIPTION, {
    variables: { id },
    onData({ client, data: { data: { conferences: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
        case SubscriptionEvent.UPDATE:
          query.refetch();
      }
    }
  });
  useSubscription<LeadStatOutBoundMessageSubscription, LeadStatOutBoundMessageSubscriptionVariables>(LEAD_STAT_OUTBOUND_MESSAGE_SUBSCRIPTION, {
    variables: { id },
    onData({ client, data: { data: { phoneMessages: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          query.refetch();
      }
    }
  });
  useSubscription<LeadStatInComingMessageSubscription, LeadStatInComingMessageSubscriptionVariables>(LEAD_STAT_INCOMING_MESSAGE_SUBSCRIPTION, {
    variables: { id },
    onData({ client, data: { data: { phoneMessages: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          query.refetch();
      }
    }
  });
  const api = {
    get data() {
      return {
        loanProposals,
        messageStats: {
          sent: query.data?.messageSent?.count || 0,
          received: query.data?.messageReceived?.count || 0
        },
        callStats: {
          sent: query.data?.callSent?.count || 0,
          received: query.data?.callReceived?.count || 0,
          miss: query.data?.callMissed?.count || 0
        }
      };
    },
    get loaded() {
      return !!query.data;
    },
    reload() {
      if (api.loaded) {
        query.refetch().catch(console.error);
      }
    }
  };
  useEffect(api.reload, []);
  return api;
}

export const GET_LEAD_STATS = gql`
  query GetLeadStats($id: ID!) {
    messageReceived:phoneMessages(where: {
      direction: {equalTo: "incoming"}
      scope: {have: {id: {equalTo: $id}}}
    }){
      count
    }
    messageSent:phoneMessages(where: {
      direction: {notEqualTo: "incoming"}
      scope: {have: {id: {equalTo: $id}}}
    }){
      count
    }
    callMissed:conferences(where: {
      direction: {equalTo: "inbound"}
      missed: {equalTo: true}
      scope: {have: {id: {equalTo: $id}}}
    }){
      count
    }
    callReceived:conferences(where: {
      direction: {equalTo: "inbound"}
      scope: {have: {id: {equalTo: $id}}}
    }){
      count
    }
    callSent:conferences(where: {
      direction: {notEqualTo: "inbound"}
      scope: {have: {id: {equalTo: $id}}}
    }){
      count
    }
  }
`;
export const LEAD_STAT_OUTBOUND_CALL_SUBSCRIPTION = gql`
  subscription LeadStatOutBoundCallSubscription($id: ID!) {
    conferences(
      where:{
        direction: {notEqualTo: "inbound"}
        scope: {have: {link: $id}}
      }
    ){
      event
    }
  }
`;
export const LEAD_STAT_INBOUND_CALL_SUBSCRIPTION = gql`
  subscription LeadStatInBoundCallSubscription($id: ID!) {
    conferences(
      where:{
        direction: {equalTo: "inbound"}
        scope: {have: {link: $id}}
      }
    ){
      event
    }
  }
`;
export const LEAD_STAT_OUTBOUND_MESSAGE_SUBSCRIPTION = gql`
  subscription LeadStatOutBoundMessageSubscription($id: ID!) {
    phoneMessages(
      where:{direction: {notEqualTo: "incoming"}
        scope: {have: {link: $id}}
      }
    ){
      event
    }
  }
`;
export const LEAD_STAT_INCOMING_MESSAGE_SUBSCRIPTION = gql`
  subscription LeadStatInComingMessageSubscription($id: ID!) {
    phoneMessages(
      where:{direction: {equalTo: "incoming"}
        scope: {have: {link: $id}}
      }
    ){
      event
    }
  }
`;
