import React                  from "react";
import { FC }                 from "react";
import { useMemo }            from "react";
import { Box }                from "../../../Box";
import { LinkExternalColors } from "../../../LinkExternal";
import { LinkExternal }       from "../../../LinkExternal";
import { ICellProps }         from "../ICellProps";

export interface UrlCellProps extends ICellProps {
  mailTo?: boolean;
}

export const UrlCell: FC<UrlCellProps> = React.memo(function UrlCell({ rowData, dataKey, className, getCellValue, defaultValue, mailTo, ...props }) {
    const value = getCellValue(rowData, dataKey);
    const to = useMemo(() => {
      if (mailTo && value) {
        return `mailto:${value}`;
      }
      return value;
    }, [value, mailTo]);

    return <Box className={className}>
      {value ? <LinkExternal target={mailTo ? "_self" : null} to={to} color={LinkExternalColors.Primary} allowCopy>
        {value}
      </LinkExternal> : defaultValue || ""}
    </Box>;
  }
);