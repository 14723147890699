import { Form }                        from "@relcu/form";
import { Icon }                        from "@relcu/rc";
import { IconButton }                  from "@relcu/rc";
import { Avatar }                      from "@relcu/rc";
import { Alignment }                   from "@relcu/ui";
import { Tooltip }                     from "@relcu/ui";
import { Ellipsis }                    from "@relcu/ui";
import { SelectColors }                from "@relcu/ui";
import { SelectVariants }              from "@relcu/ui";
import { SelectSizes }                 from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { BoxComponentProps }           from "@relcu/ui";
import { CallOut }                     from "@relcu/ui";
import { ChoiceField }                 from "@relcu/ui";
import React                           from "react";
import { FC }                          from "react";
import { useEffect }                   from "react";
import { isObject }                    from "../../../../utils/helpers";
import { LeadConnection }              from "../../../LeadConnection";
import { HeaderCallClasses }           from "./HeaderCallClasses";
import IndicatorVoiceWaveQuieterSvgUrl from "./indicator-voice-wave-quieter.svg";
import { useHeaderCall }               from "./useHeaderCall";

export const HeaderCall: FC<BoxComponentProps> = React.memo(function HeaderCall(props) {
  const call = useHeaderCall();
  useEffect(() => {
    !call.isActive && call.setUpdatedScope(null);
  }, [call.isActive]);
  return (
    <Box container flexBasis={200} flexShrink={0} flexGrow={0} className={HeaderCallClasses.HeaderCall} {...props}>
      {call.isActive &&
        <Box container alignItems={"center"} gap={"XXS"} className={HeaderCallClasses.HeaderActiveCall}>
          <Avatar
            circle
            showIndicator={false}
            alt={call?.initialDisplayCall?.objectName}>
            {call?.initialDisplayCall?.objectName}
          </Avatar>
          <Box container direction={"column"} gap={"XXXS"} style={{ width: "180px" }}>
            <Box container gap={"XXXS"}>
              <img src={IndicatorVoiceWaveQuieterSvgUrl} alt=""/>
              {call?.initialDisplayCall?.objectName}
            </Box>
            <LeadConnection
              onClick={(item) => {
                call.assign({
                  variables: {
                    id: call?.myCall?.callSid,
                    fields: {
                      scope: {
                        link: item.id
                      }
                    }
                  }
                });
                call.setUpdatedScope({
                  objectId: item.id,
                  objectName: item.objectName
                });
              }}
              onRemove={() => {
                call.assign({
                  variables: {
                    id: call?.myCall?.callSid,
                    fields: {
                      scope: {
                        link: null
                      }
                    }
                  }
                });
                call.setUpdatedScope({});
              }}
              justify={"start"}
              leadId={call.leadId}
              lead={call.scope}/>
          </Box>
        </Box>
      }
      {
        call.isPending &&
        <Box container alignItems={"center"} gap={"XXS"} className={HeaderCallClasses.HeaderActiveCall}>
          <Avatar
            circle
            showIndicator={false}
            alt={call?.initialDisplayCall?.objectName}>
            {call?.initialDisplayCall?.objectName}
          </Avatar>
          <Box container direction={"column"} gap={"XXXS"} style={{ width: "180px" }}>
            <Box
              className={HeaderCallClasses.HeaderCallText}>
              {call.direction == "outgoing" ? "Connecting..." : "Incoming Call"}
            </Box>
          </Box>
        </Box>
      }
      {!call.isPending && !call.isActive &&
        <Form
          onSubmit={call.call}
          subscription={{
            initialValues: true,
            invalid: true,
            submitting: true,
            values: true
          }}
          initialValues={{
            from: call.from,
            to: call.to
          }}
        >
          {({ handleSubmit, invalid, submitting, values }) => {
            const disable = isObject(values.to) ? values.to.callOptOut : false;
            return (
              <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
                <CallOut
                  gap={"XXXS"}
                  verticalGap={"XXXXS"}
                  avatar={
                    <Tooltip title={"Make a call"} alignment={Alignment.Bottom}>
                      <IconButton
                        type={"submit"}
                        size={"md"}
                        icon={<Icon type={"call"}/>}
                        color={"green"}
                        disabled={invalid || submitting || call.isDisabled || !call.callDevice || disable}
                      />
                    </Tooltip>
                  }
                  header={
                    <ChoiceField
                      width={280}
                      renderSelect={(select) => <Ellipsis from={20}>{call.getSelectFrom(select)}</Ellipsis>}
                      label={"From"}
                      showLabel={false}
                      required={true}
                      options={call.fromNumbers}
                      disabled={call.isDisabled}
                      name={"from"}
                      mode={"button"}
                      size={SelectSizes.Medium}
                      variant={SelectVariants.Ghost}
                      color={SelectColors.Grey}
                      alignment={Alignment.BottomRight}
                    />
                  }
                  content={
                    <ChoiceField
                      width={280}
                      renderSelect={select => <Ellipsis from={20}>{call.getSelectTo(select)}</Ellipsis>}
                      label={"To"}
                      showLabel={false}
                      required={true}
                      options={call.toNumbers}
                      disabled={call.isDisabled}
                      name={"to"}
                      mode={"button"}
                      size={SelectSizes.Medium}
                      variant={SelectVariants.Ghost}
                      color={SelectColors.Grey}
                      alignment={Alignment.BottomRight}
                    />
                  }
                />
              </form>
            );
          }}
        </Form>
      }
    </Box>
  );
});
