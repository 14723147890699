import { useMemo }                    from "react";
import { useEffect }                  from "react";
import { getHumanized }               from "../../../utils/schemaUtils";
import { RecipientSectionProps }      from "./BulkRecipientList";
import { formatNumber }               from "@relcu/ui";
import { useRelayPagination }         from "@relcu/ui";
import { ApolloClient }               from "@apollo/client";
import { gql }                        from "@apollo/client";
import { useQuery }                   from "@apollo/client";
import { DOCUMENT }                   from "../../../graph/operations.graphql";
import { MEMBER_OWN_FIELDS_FRAGMENT } from "../../../graph/operations.graphql";
import { NODE_FRAGMENT }              from "../../../graph/operations.graphql";

export function useBulkRecipientList(props: RecipientSectionProps) {
  const { query: { where = {}, order = [] }, selected, onSelect } = props;

  const { queryVariables, subscriptionVariables } = useMemo(() => createWhereInputVariables(where, order), [where, order]);
  const query = useQuery(GET_LEAD_BY_DYNAMIC_WHERE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: queryVariables
  });
  // useSubscription(SUBSCRIBE_LEAD_BY_DYNAMIC_WHERE, {
  //   variables: subscriptionVariables,
  //   onData({ client, data: { data: { leads: { event, node } } } }) {
  //     const { count } = api.data;
  //     switch (event) {
  //       case SubscriptionEvent.CREATE:
  //         const cursor = nextCursor(api.data.edges.length);
  //         pagination.cursorRef.current = cursor;
  //         writeLead(client, queryVariables, node, count, cursor);
  //     }
  //   }
  // });
  const api = {
    get data() {
      // @ts-ignore
      const { data: { leads: { edges = [], pageInfo, count = 0 } = {} } = {} } = query;
      return { pageInfo, edges, count };
    },
    get loaded() {
      return !!query.data;
    },
    reload() {
      if (api.loaded) {
        query.refetch().catch(console.error);
      }
    },
    async fetchMore() {
      if (api.data.pageInfo) {
        const variables = {
          ...queryVariables,
          after: api.data.pageInfo.endCursor
        };

        return query.fetchMore({
          query: GET_LEAD_BY_DYNAMIC_WHERE,
          variables
        });
      }
    },
    get selectedId() {
      return selected?.id;
    },
    getInfo(lead) {
      const {
        loanPurpose,
        timezone,
        loanAmount = 0,
        leadSource,
        loanProgram,
        loanProduct
      } = lead;

      return [
        {
          label: "Lead source:",
          value: leadSource?.objectName || ""
        },
        {
          label: "Amount:",
          value: `$ ${formatNumber(loanAmount, 0)}`
        },
        {
          label: "Purpose:",
          value: getHumanized("Lead", "loanPurpose", loanPurpose)
        },
        {
          label: "Program:",
          value: getHumanized("Lead", "loanProgram", loanProgram)
        },
        {
          label: "Product:",
          value: getHumanized("Lead", "loanProduct", loanProduct)
        },
        {
          label: "Timezone:",
          value: getHumanized("Lead", "timezone", timezone)
        }
      ];
    }
  };
  const pagination = useRelayPagination({
    pageInfo: api.data.pageInfo,
    onLoadMore: api.fetchMore,
    fromTop: false
  });

  useEffect(api.reload, []);

  useEffect(() => {
    if (!selected) {
      const lead = api.data.edges[ 0 ]?.node;
      onSelect(lead);
    }
  }, [api.data.edges]);

  return {
    ...api,
    ...pagination
  };
}

const createWhereInputVariables = (where, order) => {
  return {
    queryVariables: { where, order },
    subscriptionVariables: { where, order }
  };
};
const readLead = (client: ApolloClient<object>, variables, count: number) => {
  const query = { query: GET_LEAD_BY_DYNAMIC_WHERE, variables };
  let data = {
    leads: {
      count: 0,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: null,
        endCursor: null,
        __typename: "PageInfo"
      },
      __typename: "LeadConnection",
      edges: []
    }
  };
  if (count) {
    data = client.readQuery(query);
  }
  return data;
};
const writeLead = (client: ApolloClient<object>, variables, node, count: number, cursor: string) => {
  const data = readLead(client, variables, count);
  const pageInfo = data.leads?.pageInfo;
  client.writeQuery({
    query: GET_LEAD_BY_DYNAMIC_WHERE,
    variables: {
      ...variables,
      before: pageInfo.startCursor
    },
    data: {
      ...data,
      leads: {
        ...data.leads,
        count: count + 1,
        pageInfo: {
          ...pageInfo,
          hasNextPage: pageInfo.hasNextPage || false,
          hasPreviousPage: pageInfo.hasPreviousPage || false,
          startCursor: cursor,
          endCursor: pageInfo?.endCursor || cursor
        },
        edges: [
          {
            __typename: "LeadEdge",
            cursor,
            node
          }
        ]
      }
    }
  });
};
const LEAD_FRAGMENT = gql`
  fragment Lead on Lead {
    __typename
    ...Node
    ...Document
    ...on Lead {
      timezone
      leadStatus{
        status
      }
      borrowerFirstName
      borrowerLastName
      loanPurpose
      loanAmount
      loanProgram
      loanProduct
      previousAssignedTo{
        objectName
      }
      leadSourceTitle
      leadSourcePartner
      leadSource{
        ...Node
        objectId
        objectName
        objectIcon
      }
      assignedTo {
        id
        objectId
        objectName
        firstName
        lastName
        applyUrl
        team
        nmlsId
        calendar
        custom1
        custom2
        custom3
        custom4
        custom5
        custom6
        custom7
        custom8
        custom9
        custom10
      }
      members{
        ...MemberOwnFields
        contact{
          ...Node
          objectId
          objectName
          objectIcon
          firstName
          lastName
          middleName
          phones{
            isPrimary
            default
            number
            type
            callOptOut
            smsOptOut
          }
          emails{
            type
            address
          }
        }
      }
    }
  }
  ${NODE_FRAGMENT}
  ${MEMBER_OWN_FIELDS_FRAGMENT}
  ${DOCUMENT}
`;
const GET_LEAD_BY_DYNAMIC_WHERE = gql`
  query GetLeadsByDynamicWhere($before:String,$after:String,$where: LeadWhereInput! $order: [LeadOrder!]) {
    leads(
      where: $where,
      order: $order,
      first: 30,
      before: $before,
      after: $after,
    ) {
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...Lead
        }
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

