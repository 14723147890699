import { useSource }           from "@relcu/ui";
import { useMemo }             from "react";
import { useCallback }         from "react";
import React                   from "react";
import { FC }                  from "react";
import { ButtonVariants }      from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { classNames }          from "@relcu/ui";
import { CircularLoader }      from "@relcu/ui";
import { List }                from "@relcu/ui";
import { Section }             from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { useNavigate }         from "@relcu/react-router";
import { EmptyList }           from "@relcu/ui";
import { JsonViewProps }       from "@relcu/ui";
import { useLoanProposalView } from "./useLoanProposalView";

export const LoanProposalView: FC<JsonViewProps> = React.memo(function LoanProposalView(props) {
  const { loanProposals = [], getColumnValue, loading, count = 0, onPreview, objectId, viewerRole,modalContext } = useLoanProposalView();
  const { $object } = useSource();
  const navigate = useNavigate();
  const disable = useMemo(() => {
    return !!$object.duplicateOf || (viewerRole === "loan_officer" && objectId !== $object.assignedTo?.objectId);
  }, [objectId, $object.assignedTo?.objectId, viewerRole, $object.duplicateOf]);
  const navigateToPricing = useCallback(() => {
    navigate(`/${$object.__typename.toLowerCase()}/${$object.objectId}/pricing`);
  }, [$object]);
  return (
      <Box container direction={"column"}>
        {modalContext}
        {
          (count == 0 || loading) ?
            <Box
              container
              direction={"column"}
              gap={"S"}
              style={{ minHeight: 300 }}
              justify={(loading || count == 0) ? "center" : null}
              className={classNames("list-view", {
                [ "list-view--empty" ]: loading || count == 0
              })} flexGrow={1}>
              {
                loading ?
                  <CircularLoader alignContent={"center"} flexGrow={1} justify={"center"}/>
                  :
                  <>
                    <EmptyList icon={"rc_lp"}
                               content={"When you save offers, you will see them here. \n" +
                               "Click on “Make an offer” button to configure and save offers"}
                               title={`No loan proposals found`}
                               alignSelf={"center"} justify={"center"}/>
                    <Box container justify={"center"}>
                      <Button disabled={disable} style={{ paddingLeft: 21, paddingRight: 21 }}
                              onClick={navigateToPricing}>MAKE AN
                        OFFER</Button>
                    </Box>
                  </>
              }
            </Box>
            :
            loanProposals.map(({ node }) => {
              return <Section key={node.objectId} title={node.objectName} editable={false}>
                <Box container direction={"column"} gap={"XXS"} flex={1}>
                  <List
                    flex={1}
                    headers={props.headers}
                    fields={props.fields}
                    objects={node.offers.edges.map(o => o.node)}
                    getColumnValue={getColumnValue}
                  />
                  <Button variant={ButtonVariants.Ghost} onClick={() => onPreview(node)} alignSelf={"end"}>
                    PREVIEW AND SEND
                  </Button>
                </Box>
              </Section>;
            })
        }
      </Box>
  );
});

export default LoanProposalView;
