import { useMemo }                                         from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box }                                             from "../../Box";
import { FontIcon }                                        from "../../Icon";
import { AudioPlayerClasses }                              from "./AudioPlayerClasses";

const DRAG = (left) => {
  return {
    left
  };
};
interface AudioPlayerProps {
  atoPlay?: boolean,
  children?: any,
  className?: string,
  hidePlayer?: boolean,
  listenInterval?: number,
  loop?: boolean,
  muted?: boolean,
  onAbort?: Function,
  onCanPlay?: Function,
  onCanPlayThrough?: Function,
  onEnded?: Function,
  onError?: Function,
  onListen?: Function,
  onPause?: Function,
  onPlay?: Function,
  onDragStart?: Function,
  onDragMove?: Function,
  onDragEnd?: Function,
  preload?: any,
  autoPlay?: any
  volumeIndicator?: boolean
  progressUpdateInterval?: number,
  src?: string,
  volume?: number,
  transcriptionUrl?: string
  record?: boolean
  recording?: boolean
  onRecord?()
  onStopRecord?()
  currentTime?(time:number)
}
export const AudioPlayer = React.memo((props: AudioPlayerProps) => {
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);
  const sliderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [dragLeft, setDragLeft] = useState("");
  const {
    src,
    preload,
    muted,
    loop
  } = props;

  const togglePlay = useCallback((e) => {
    if (audioRef.current.paused && audioRef.current.src) {
      audioRef.current.play();
    } else if (!audioRef.current.paused) {
      audioRef.current.pause();
    }
  }, [audioRef]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener("error", (e) => {
      props.onError && props.onError(e);
    });
    audio.addEventListener("play", (e) => {
      setIsPlaying(true);
    });
    audio.addEventListener("ended", (e) => {
      setCurrentTime(0);
      setDragLeft("");
    });
    audio.addEventListener("pause", (e) => {
      if (!audioRef.current) {
        return;
      }
      setIsPlaying(false);
    });

    audio.addEventListener("loadedmetadata", (e) => {
      setDuration(audio.duration == Infinity ? 0 : audio.duration);//todo tmp find better solution
    });

    audio.addEventListener("timeupdate", (e) => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
      props.currentTime && props.currentTime(audio.currentTime)
      setDragLeft((audio.currentTime / audio.duration) * 100 + "%");
    });
  }, [audioRef, src]);

  const onChangeCurrentTime = useCallback((e) => {

    const bar = progressBarRef.current;
    const slider = sliderRef.current;
    const audio = audioRef.current;
    let position = bar.getBoundingClientRect().left;
    let width = bar.offsetWidth - slider.offsetWidth;
    let percent = ((e.clientX - position) / width);
    let currentTime = audio.duration * percent;
    setDuration(currentTime);
    setDragLeft((percent * 100) + "%");
    audio.currentTime = currentTime;
    audio.play();
  }, [currentTime, progressBarRef, sliderRef, audioRef]);

  const addHeadingZero = useCallback(num => (num > 9 ? num.toString() : `0${num || "0"}`), []);
  let currentTimeMin = useMemo(() => addHeadingZero(Math.floor(currentTime / 60)), [addHeadingZero, currentTime]);
  let currentTimeSec = useMemo(() => addHeadingZero(Math.floor(currentTime % 60)), [addHeadingZero, currentTime]);
  let durationMin = useMemo(() => addHeadingZero(Math.floor(duration / 60)), [addHeadingZero, duration]);
  let durationSec = useMemo(() => addHeadingZero(Math.floor(duration % 60)), [addHeadingZero, duration]);

  return <Box container direction={"column"} gap={"XXXS"} flex={1} className={AudioPlayerClasses.AudioPlayer}
              onClick={(e) => e.stopPropagation()}>
    <Box container justify={"center"} gap={"S"} alignItems={"center"} className={AudioPlayerClasses.Player}>
      <audio
        src={src}
        controls={false}
        muted={muted}
        loop={loop}
        preload={preload}
        ref={audioRef}
      >
        Your browser does not support the <code>audio</code> element.
      </audio>
      <Box container gap={"XXS"} alignItems={"center"}>
        {/*{*/}
        {/*  record &&*/}
        {/*  <FontIcon className={AudioPlayerClasses.PlayerToggleRecord}*/}
        {/*            type={recording ? "stop" : "mic"}*/}
        {/*            onClick={onRecord}/>*/}
        {/*}*/}
        <FontIcon className={AudioPlayerClasses.PlayerTogglePlay}
                  type={isPlaying ? "pause_circle_filled" : "play_circle_filled"}
                  onClick={togglePlay}/>
        <Box className={AudioPlayerClasses.PlayerTime}>
          {currentTimeMin}:{currentTimeSec}/{durationMin}:{durationSec}
        </Box>
      </Box>

      <Box container flex={1} alignItems={"center"} className={AudioPlayerClasses.PlayerProgressBarWrapper}>
        <Box
          className={AudioPlayerClasses.PlayerProgressBar}
          onClick={onChangeCurrentTime}
          ref={progressBarRef}
        />
        <Box
          className={AudioPlayerClasses.PlayerProgressBarIndicator}
          draggable={true}
          ref={sliderRef}
          style={DRAG(dragLeft) as any}
        />
      </Box>
    </Box>
  </Box>;
});
