import React                  from "react";
import { FC }                 from "react";
import { useMemo }            from "react";
import { Tooltip }            from "@relcu/ui";
import { ButtonSizes }        from "@relcu/ui";
import { Button }             from "@relcu/ui";
import { Alignment }          from "@relcu/ui";
import { IActionCellProps }   from "@relcu/ui";
import { getPrimaryBorrower } from "../../../../../utils/helpers";
import { useMailAccess }      from "../../../../useMailAccess";

export const MailAction: FC<IActionCellProps> = React.memo(function MailAction(props) {
  const { rowData, disabled, tooltip, path, bulkPermissions, onClick, ...p } = props;
  const canContact = useMailAccess(rowData);
  const primary = getPrimaryBorrower(rowData);
  const emails = useMemo(() => primary?.contact?.emails.filter(({optOut}) => !optOut) || [], [primary]);

  return (
    <Tooltip title={tooltip} alignment={Alignment.Bottom}>
      <Button
        onlyIcon
        size={ButtonSizes.Small}
        onClick={() => onClick(rowData)}
        disabled={(rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length) || !canContact || !emails.length}
        {...p}/>
    </Tooltip>
  );
});
