import { gql }                          from "@apollo/client";
import { useQuery }                     from "@apollo/client";
import { useSource }                    from "@relcu/ui";
import { useMemo }                      from "react";
import { MEMBER_OWN_FIELDS_FRAGMENT }   from "../../../../graph/operations.graphql";
import { getField }                     from "../../../../utils/schemaUtils";
import { GetLeadsByContactIdVariables } from "./__types__/GetLeadsByContactId";
import { GetLeadsByContactId }          from "./__types__/GetLeadsByContactId";

export function useLeads() {
  const { $object: { id, objectId } } = useSource();
  const { data: { leads: { edges: leadEdges = [] } = {} } = {} } = useQuery<GetLeadsByContactId, GetLeadsByContactIdVariables>(GET_LEADS_BY_CONTACT_ID, {
    variables: {
      id
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });
  const { options } = getField("LeadMember", "type");
  // const { options } = useSchemaField<SchemaChoiceField>("LeadMember", "type");
  const leads = useMemo(() => {
    let data = {};
    leadEdges.forEach(({ node }) => {
      node.members.forEach((member) => {
        if (member.contact?.objectId == objectId) {
          if (data[ member.type ]) {
            data[ member.type ].push(node);
          } else {
            data[ member.type ] = [node];
          }
        }
      });
    });
    return data;
  }, [leadEdges]);
  return {
    get data() {
      return leads;
    },
    get edges() {
      return leadEdges;
    },
    get options() {
      return options;
    }
  };
}
export const GET_LEADS_BY_CONTACT_ID = gql`
  ${MEMBER_OWN_FIELDS_FRAGMENT}
  query GetLeadsByContactId($id: ID!) {
    leads(where: {
      members: {
        have: {
          contact: {
            have: {
              id: {
                equalTo: $id
              }
            }
          }
        }
      }
    }) {
      edges {
        node {
          id
          objectId
          objectName
          leadStatus{
            action
            status
            updatedAt
          }
          loanPurpose
          loanAmount
          leadSource{
            id
            leadPartner{
              objectId
              title
            }
          }
          createdAt
          members{
            ...MemberOwnFields
            contact{
              id
              objectId
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  }
`;
