import React                from "react";
import { FC }               from "react";
import { JsonSectionProps } from "@relcu/ui";
import { Form }             from "@relcu/form";
import { Section }          from "@relcu/ui";
import { JsonField }        from "@relcu/ui";
import { useSchemaField }   from "../../../useSchemaField";

export interface PipelineDetailsSectionProps extends Omit<JsonSectionProps, "component"> {
  data?: any;
  className?:string
}

export const PipelineDetailsSection: FC<PipelineDetailsSectionProps> = React.memo(function PipelineDetailsSection(props) {
  const { fields, data ,className} = props;

  return <Section editable={false} title={"Loan details"}>
    <Form onSubmit={() => undefined} initialValues={data}>
      {() => {
        return <>
          {
            fields.map((field, index) => {
              let schemaField = useSchemaField(className,field.name);
              let props = {...schemaField,...field};
              return (
                <JsonField
                  key={index}
                  {...props}
                  name={field.name}
                  view={"read"}
                />
              );
            })
          }
        </>;
      }}
    </Form>
  </Section>;
});

PipelineDetailsSection.defaultProps = {
  data: {},
  className:"Loan"
};
