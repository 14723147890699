import React                             from "react";
import { useMutation }                   from "@apollo/client";
import { gql }                           from "@apollo/client";
import { useReactiveVar }                from "@apollo/client";
import { useQuery }                      from "@apollo/client";
import { useAlert }                      from "@relcu/ui";
import { deviceVar }                     from "../../../../reactiveVars";
import { smsTemplate }                   from "../../../../utils/helpers";
import { toNodeId }                      from "../../../../utils/helpers";
import { GetMessageTemplatesVariables }  from "../../../__types__/GetMessageTemplates";
import { GetMessageTemplates }           from "../../../__types__/GetMessageTemplates";
import { SendPhoneMessageVariables }     from "../../../__types__/SendPhoneMessage";
import { SendPhoneMessage }              from "../../../__types__/SendPhoneMessage";
import { SEND_MESSAGE }                  from "../../../operations.graphql";
import { GET_MESSAGE_TEMPLATES }         from "../../../operations.graphql";
import { useMessageTemplateQueryParams } from "../../../useMessageTemplateGqlParams";
import { GetContactNodeInfoVariables }   from "./__types__/GetContactNodeInfo";
import { GetContactNodeInfo }            from "./__types__/GetContactNodeInfo";
import { SmsReplayUser }                 from "./__types__/SmsReplayUser";

const SMS_REPLAY_USER = gql`
  query SmsReplayUser {
    viewer {
      user {
        firstName
        lastName
        nmlsId
        calendar
      }
    }
  }
`;

export function useSmsReply(props) {
  const [menus, setMenus] = React.useState<{ [ key: string ]: DOMRect }>({});
  const { active, hybridCalls } = useReactiveVar(deviceVar);
  const { data: { viewer: { user } } } = useQuery<SmsReplayUser>(SMS_REPLAY_USER, {
    fetchPolicy: "cache-only"
  });
  const { data: { node } = {} } = useQuery<GetContactNodeInfo, GetContactNodeInfoVariables>(GET_CONTACT_NODE_INFO, {
    skip: !hybridCalls?.initialDisplayCall?.objectId,
    variables: {
      id: toNodeId({ className: "Contact", objectId: hybridCalls?.initialDisplayCall?.objectId })
    }
  });
  const { error } = useAlert();
  const where = useMessageTemplateQueryParams({
    type: "call",
    considerOwner: true
  });
  const [send] = useMutation<SendPhoneMessage, SendPhoneMessageVariables>(SEND_MESSAGE);
  const query = useQuery<GetMessageTemplates, GetMessageTemplatesVariables>(GET_MESSAGE_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      ...where
    }
  });
  return {
    menus,
    get templates() {
      const { data: { phoneMessageOwnerTemplates: { edges: ownerEdges = [] } = {}, phoneMessageNoOwnerTemplates: { edges: noOwnerEdges = [] } = {} } = {} } = query;
      return [...ownerEdges, ...noOwnerEdges];
    },
    async send(templateNode) {
      try {
        props?.onClick();
        active.reject();
        await send({
          variables: {
            from: hybridCalls?.called.number,
            to: hybridCalls?.caller.number,
            leadId: hybridCalls?.initialDisplayCall?.leadId,
            templateId: templateNode.objectId,
            message: smsTemplate(templateNode.text, node, user, undefined)
          }
        });
      } catch (e) {
        active.drop();
        error(e);
      }
    },
    onOpen(event, name) {
      setMenus({
        ...menus,
        [ name ]: menus[ name ] ? null : event.currentTarget.getBoundingClientRect()
      });
    },
    onClose(name) {
      setMenus({
        ...menus,
        [ name ]: null
      });
    }
  };
}

const GET_CONTACT_NODE_INFO = gql`
  query GetContactNodeInfo($id: ID!) {
    node(id: $id){
      ...on Contact{
        firstName
        lastName
        middleName
        objectName
      }
    }
  }
`;
