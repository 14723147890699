import { useQuery }                                from "@apollo/client";
import { usePrevious }                             from "@relcu/ui";
import { GET_SETTINGS }                            from "../../../../graph/operations.graphql";
import { layoutVar }                               from "../../../../reactiveVars";
import { SubscriptionEvent }                       from "../../../../types/graphql-global-types";
import { HybridCall }                              from "../../../../types/hybridCall";
import { toNodeId }                                from "../../../../utils/helpers";
import { useJqlSubscription }                      from "../../../Layout/Jql";
import { useJqlQuery }                             from "../../../Layout/Jql";
import { usePhone }                                from "../../../usePhone";


export function useHybridCall() {
  const { active, hybridCalls, status, direction, isConference } = usePhone();
  const layouts = layoutVar();
  const { data: { settings: $settings } } = useQuery(GET_SETTINGS, { fetchPolicy: "cache-only" });
  const { data: { lead } = {} } = useJqlQuery(layouts.Lead.jql.query.get, {
    operationName: `Call${layouts.Lead.className}Query`,
    variables: { id: toNodeId({ className: "Lead", objectId: active?.params?.leadId }) },
    skip: !active?.params?.leadId
  });

  useJqlSubscription(layouts.Lead.jql.subscription, {
    operationName: `Call${layouts.Lead.className}Subscription`,
    variables: { where: { id: { equalTo: active?.params?.leadId } }, events: [SubscriptionEvent.UPDATE] },
    skip: !active?.params?.leadId
  });

  let { activeCalls, myCall, calls } = Object(hybridCalls);
  activeCalls?.sort((call: HybridCall) => call.__typename == "Contact" ? -1 : 1);
  const rejectedCalls = calls?.filter(c => c.status == "busy" || c.status == "no-answer" || c.status == "completed"); //rejected calls except me
  const prevCalls = usePrevious(activeCalls);
  const isFailedCall = prevCalls?.length && prevCalls.find(prevCall => rejectedCalls?.find(ringingOrFailedCall => ringingOrFailedCall.call == prevCall.call));
  const secondActiveCall: HybridCall = activeCalls?.find(c => !c.hold);
  const onHoldCall = activeCalls?.find(c => c.hold);
  const secondLOActiveCall: any = activeCalls?.find(c => !c.hold && c.__typename == "User");
  const contactCall = activeCalls?.find(c => c.__typename == "Contact");
  const number = active?.direction === "incoming" ? active?.params?.to : active?.params?.from;
  const isMerged = activeCalls?.filter(c => c.status == "in-progress" && !c.hold)?.length > 1;
  const propertyState = lead?.property?.propertyAddress?.state;
  const isLicensedStatesMatch = !propertyState || (propertyState && secondLOActiveCall?.licensedStates.includes(propertyState));
  const isModerator = myCall?.moderator;
  const availableForMerge = activeCalls?.length == 2 && onHoldCall;
  const isAllPartiesContact = activeCalls?.every(c => c.__typename == "Contact") && calls.length == 2;
  const initiatingConference = activeCalls?.length == 2 && !!activeCalls?.find(c => (c.status == "ringing")) && !isConference;// if there is 2 calls it means initiating conference, not receive this case in local when subscription trigger conference sid already exists

  return {
    calls,
    status,
    myCall,
    number,
    active,
    isMerged,
    direction,
    onHoldCall,
    isModerator,
    activeCalls,
    contactCall,
    isFailedCall,
    rejectedCalls,
    secondActiveCall,
    availableForMerge,
    secondLOActiveCall,
    isAllPartiesContact,
    initiatingConference,
    isLicensedStatesMatch,
    assignedToObjectId: lead?.assignedTo?.objectId,
    showManageCall: $settings.enableCallTransfer && myCall?.status == "in-progress" && activeCalls?.length < 2 && ((!contactCall && isModerator) || contactCall)
  };
}
