import React                                     from "react";
import { SyntheticEvent }                        from "react";
import { ReactNode }                             from "react";
import { IconType }                              from "../Icon";
import { Icon }                                  from "../Icon";
import { Button }                                from "../index";
import Typography                                from "../Typography";
import { useClassNames }                         from "../utils";
import { WithAsProps, RsRefForwardingComponent } from "../@types/common";

export interface EmptyStateProps extends WithAsProps {
  icon?: IconType;
  title?: ReactNode;
  subtitle?: string | ReactNode;
  compact?: boolean;
  background?: boolean;
  buttonDisabled?: boolean;
  onClick?(e: SyntheticEvent);
  buttonLabel?: string;
}

export const EmptyState: RsRefForwardingComponent<"div", EmptyStateProps> = React.forwardRef(
  (props: EmptyStateProps, ref) => {
    const {
      as: Component = "div",
      icon,
      title,
      className,
      classPrefix = "empty-state",
      children,
      subtitle,
      onClick,
      background = false,
      buttonLabel,
      buttonDisabled = false,
      compact = false,
      ...rest
    } = props;
    const { prefix, withClassPrefix, merge } = useClassNames(classPrefix);
    const classes = merge(
      className,
      withClassPrefix({ compact })
    );

    return (
      <div className={prefix("wrapper", background && "background")}>
        <Component
          {...rest}
          ref={ref}
          className={classes}
        >
          {
            icon &&
            <div className={prefix("icon")}>
              <Icon type={icon}/>
            </div>
          }
          {
            title &&
            <Typography variant={"base14"} weights="medium">{title}</Typography>
          }
          {
            subtitle &&
            <Typography className={prefix("subtitle")} color={"secondary"} variant={"small12"}>{subtitle}</Typography>
          }
          {
            onClick &&
            <Button disabled={buttonDisabled} className={prefix("button")}
                    onClick={onClick}>
              {buttonLabel || "Create"}
            </Button>
          }
        </Component>
      </div>

    );
  }
);

EmptyState.displayName = "EmptyState";

export default EmptyState;
