import React                      from "react";
import { FC }                     from "react";
import { useMemo }                from "react";
import { useLazyCondition }       from "@relcu/ui";
import { EmptyList }              from "@relcu/ui";
import { CircularLoader }         from "@relcu/ui";
import { AvatarSizes }            from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { MultiSelect }            from "@relcu/ui";
import { useSource }              from "@relcu/ui";
import { MenuItem }               from "@relcu/ui";
import { Label, Section }         from "@relcu/ui";
import { List }                   from "@relcu/ui";
import { Avatar }                 from "@relcu/ui";
import { Role }                   from "../../../../constants/Role";
import { useUnreadNotifications } from "../../../useUnreadNotifications";
import { PriorityViewClasses }    from "../PriorityView/PriorityViewClasses";
import { usePipelineView }        from "./usePipelineView";

export const PipelineView: FC<any> = React.memo(function PipelineView(props) {
  const { title, fields, headers } = props;
  const { $object } = useSource();
  const evaluate = useLazyCondition();
  const { data, members, filter, onFilter, handleNavigate, loading, total, onPage, offset, currentPage } = usePipelineView(props);
  const notification = useUnreadNotifications();
  const { edges = [] } = notification || {};
  const unreadMilestoneNotifications = edges.filter(({ node }) => node.type === "milestone_comment");
  const notifiableItems = useMemo(() => data.filter(d => unreadMilestoneNotifications.find(({ node }) => node.data.loanId == d.objectId)), [data, unreadMilestoneNotifications]);

  let selectedFields = useMemo(() => {
    return fields.filter((field) => {
      if (field.conditions) {
        const { apply } = evaluate({ conditions: field.conditions });
        return apply;
      }
      return true;
    });
  }, [
    fields
  ]);
  let selectedHeaderFields = useMemo(() => {
    return headers.filter((field) => {
      if (field.conditions) {
        const { apply } = evaluate({ conditions: field.conditions });
        return apply;
      }
      return true;
    });
  }, [
    headers
  ]);

  return <Box container direction={"column"} gap={"XXS"} className={PriorityViewClasses.PriorityView}
              style={{ "overflow": "no-scroll" }}>
    {
      (!!members.length && $object.role != Role.LOAN_OFFICER && $object.role != Role.SALES_MANAGER) &&
      <Box container className={PriorityViewClasses.PriorityFilter} gap={"XS"} alignItems={"center"}>
        <span>Filter</span>
        <Box container alignItems={"center"}>
          <Label>User</Label>
          <MultiSelect
            keepErrorSpace={false}
            showSelectionActions
            getOptionValueByKey
            headerProps={{
              showBorder: false,
              style: {
                background: "transparent"
              }
            }}
            showSelectedCount
            placeholder="Select Member"
            optionKey={"id"}
            optionLabel={"objectName"}
            value={filter}
            options={members}
            onChange={onFilter}
            renderOption={(option) => {
              return <MenuItem
                thumbnail={<Avatar icon={option.objectIcon} size={AvatarSizes.Small} text={option.objectName}/>}>
                {option.objectName}
              </MenuItem>;
            }}/>
        </Box>
      </Box>
    }
    <Section title={title} editable={false}>
      {
        loading ?
          <Box container justify={"center"} flex={1}>
            <CircularLoader alignContent={"center"} flexGrow={1} justify={"center"}/>
          </Box>
          :
          data?.length == 0 ?
            <Box container justify={"center"} flex={1}>
              <EmptyList icon={"rc_real-estate-agent"}
                         content={"There are no loans in the pipeline."}
                         title={`Pipeline is empty`}
                         alignSelf={"center"} flexGrow={1} justify={"center"}/>
            </Box>
            :
            <List
              notifiableItems={notifiableItems}
              matchingKey={"objectId"}
              total={total}
              count={offset}
              objects={data}
              currentPage={currentPage}
              fields={selectedFields}
              flex={1}
              headers={selectedHeaderFields}
              onPage={onPage}
              onRowClick={handleNavigate}
              rowProps={{ justify: "start" }}
            />
      }
    </Section>
  </Box>;
});
