import { useQuery }                   from "@apollo/client";
import { useLazyQuery }               from "@apollo/client";
import { gql }                        from "@apollo/client";
import { useForm }                    from "@relcu/final-form";
import { Cell }                       from "@relcu/rc";
import { ReadCell }                   from "@relcu/rc";
import { FieldCell }                  from "@relcu/rc";
import { useEffect }                  from "react";
import { useContext }                 from "react";
import React                          from "react";
import { FC }                         from "react";
import { CircularLoader }             from "@relcu/ui";
import { LoanEstimateOffer }          from "../../../../../../graph/__types__/LoanEstimateOffer";
import { DOCUMENT }                   from "../../../../../../graph/operations.graphql";
import { NODE_FRAGMENT }              from "../../../../../../graph/operations.graphql";
import { GetFeeByStateVariables }     from "../../../PricingView/__types__/GetFeeByState";
import { GetFeeByState }              from "../../../PricingView/__types__/GetFeeByState";
import { STATE_BASED_FEE_FRAGMENT }   from "../../../PricingView/usePricingView";
import { GET_FEE_BY_STATE }           from "../../../PricingView/usePricingView";
import { Proposal }                   from "../../Proposal";
import { GetStateBasedFees }          from "./__types__/GetStateBasedFees";
import { GetStateBasedFeesVariables } from "./__types__/GetStateBasedFees";

export const CondoCertificateFeeCell: FC = React.memo(function CondoCertificateFeeCell() {
  const form = useForm<LoanEstimateOffer>();
  const loanEstimate = useContext(Proposal.Context);
  const { data: { stateBasedFees } = {}, loading } = useQuery<GetStateBasedFees, GetStateBasedFeesVariables>(GET_STATE_BASED_FEES, {
    variables: {
      state: loanEstimate.propertyState
    }
  });
  useEffect(() => {
    const condoFee = stateBasedFees?.edges?.at(0).node.condoCertFee;
    form.change("condoCertificateFee", condoFee);
  }, [stateBasedFees, form]);
  return <>
    {loading ? <Cell space icon={"attach_money"}><CircularLoader/></Cell> :
      <FieldCell.Number plaintext icon={"attach_money"} name={"condoCertificateFee"}/>}
  </>;
});

export const GET_STATE_BASED_FEES = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  query GetStateBasedFees($state: String!) {
    stateBasedFees(where: {state: {equalTo: $state}}) {
      edges {
        node {
          ...Node
          ...Document
          state
          convCondo
          convMultiUnit
          convSingleUnit
          fhaCondo
          fhaMultiUnit
          fhaSingleUnit
          jumboCondo
          jumboMultiUnit
          jumboSingleUnit
          nonConfCondo
          nonConfMultiUnit
          nonConfSingleUnit
          vaCondo
          vaMultiUnit
          vaSingleUnit
          condoCertFee
        }
      }
    }
  }
`;
