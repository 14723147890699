import { useCallback }         from "react";
import { useState }            from "react";
import { useThrottleCallback } from "../../..";
import { IconType }            from "../../Icon";
import { useBaseField }        from "../BaseField";
import { AddressFieldProps }   from "./AddressField";

export function useAddressField(props: AddressFieldProps) {
  const WAIT_INTERVAL = 1000;
  const hereUrl = "https://geocode.search.hereapi.com/v1/geocode";
  const [extendedView, setExtendedView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const field = useBaseField<AddressFieldProps>(props);
  const [input, setInput] = useState<string>();
  const search = useThrottleCallback(
    useCallback((formatted: string) => api.input.search(formatted), []), WAIT_INTERVAL, true
  );
  const api = {
    ...field,
    input:{
      ...field.input,
      loading,
      data,
      extendedView,
      setExtendedView,
      get message() {
        return (input !== undefined && !loading && !data.length && "No result");
      },
      get formatted() {
        return input === undefined ? field.input.value?.formatted : input;
      },
      async search(formatted: string) {
        try {
          const response = await fetch(hereUrl + `?q=${formatted}&apiKey=${props.apiKey}&in=countryCode:USA`);
          const { items } = await response.json();
          setData(items);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      },
      async onSearch(formatted) {
        setInput(formatted);
        if (formatted && formatted.length > 5) {
          setLoading(true);
          await search(formatted);
        }
      },
      onBlur(e) {
        setInput(undefined);
        field.input.onBlur(e);
      },
      onSelect(value) {
        api.input.onChange({
          formatted: value.address.label,
          street: `${value.address.houseNumber || ""} ${value.address.street || ""}`.trim(),
          city: value.address.city,
          zipCode: value.address.postalCode?.split("-")[0],
          state: value.address.stateCode,
          county: value.address.county,
          country: value.address.countryName,
          lat: value.position.lat,
          lng: value.position.lng
        });
        setInput(undefined);
      },
      getOptionIcon(option) {
        let icon: IconType = "location_city";
        switch (option.resultType) {
          case "houseNumber" :
            icon = ((option.houseNumberType == "PA") ? "house" : "rc_home_interpolated");
            break;
          case "street" :
            icon = "rc_street";
            break;
          case "intersection" :
            icon = "rc_street_intersection";
            break;
          case "locality" :
            switch (option.localityType) {
              case "postalCode" :
                icon = "rc_postal_code";
                break;
              case "district" :
                icon = "rc_district";
                break;
              case "subdistrict" :
                icon = "rc_subdistrict";
                break;
              default :
                icon = "location_city";
                break;
            }
            break;
          case "administrativeArea" :
            switch (option.administrativeAreaType) {
              case "state" :
                icon = "rc_state";
                break;
              case "county" :
                icon = "rc_county";
                break;
              default :
                icon = "flag";
                break;
            }
            break;
          default :
            icon = "rc_place";
            break;
        }
        return icon;
      }
    }
  };

  return api;

}
