import React                       from "react";
import { FC }                      from "react";
import { Box }                     from "../Box";
import { BoxComponentProps }       from "../Box";
import { FontIcon }                from "../Icon";
import { MicrophoneAccessClasses } from "./MicrophoneAccessClasses";
import MicAccessUrl from "./mic_access.svg";

export const MicrophoneAccess: FC<BoxComponentProps> = React.memo(function MicrophoneAccess(props) {
  return <Box className={MicrophoneAccessClasses.Container}
       flexGrow={1}
       alignItems={"center"}
       justify={"center"}
       gap={"M"}
       container
       direction={"column"} {...props}>
    <img src={MicAccessUrl} alt="Access microphone"/>
    <Box container alignItems={"center"} justify={"center"} direction={"column"} gap={"XXS"}
         className={MicrophoneAccessClasses.Empty}>
      <FontIcon type={"mic_off"}/>
      <p>Your microphone is blocked</p>
      <p>Please go to your browser settings and allow “relcu” to use your microphone.</p>
    </Box>
  </Box>
});
