import React                          from "react";
import { FC }                         from "react";
import { useMemo }                    from "react";
import { useCallback }                from "react";
import { useState }                   from "react";
import { ListRow }                    from "@relcu/ui";
import { useField }                   from "@relcu/form";
import { classNames }                 from "@relcu/ui";
import { CommonClasses, Tooltip }     from "@relcu/ui";
import { IconType }                   from "@relcu/ui";
import { ListItemIcon, ListItemText } from "@relcu/ui";
import { SearchInput }                from "@relcu/ui";
import { VerticalDivider }            from "@relcu/ui";
import { StepHeader }                 from "@relcu/ui";
import { StepInterface }              from "@relcu/ui";
import { StepContainer }              from "@relcu/ui";
import { StepBody }                   from "@relcu/ui";
import { TypographyColor }            from "@relcu/ui";
import { Typography }                 from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { DropResult }                 from "react-smooth-dnd";
import { applySmoothDrag }            from "../../../../../../utils/helpers";
import { Column }                     from "../../../../../DroppableColumn/Column";
import { ColumnContainer }            from "../../../../../DroppableColumn/ColumnContainer";
import { DroppableRows }              from "../../../../../DroppableColumn/DroppableRows";
import { PageViewDialogProps }        from "../../PageViewDialog";
import { Container, Draggable }       from "react-smooth-dnd";
import "./columns.css";

const icons = {
  "available": "add",
  "selected": "remove"
};

export const Columns: FC<StepInterface & PageViewDialogProps> = React.memo(function Columns(props) {
  const { title, data: { availableFields } } = props;

  const [availableValue, setAvailableValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const { input: { value, onChange }, meta } = useField("columns");
  const available = useMemo(() => {
    return availableFields.map(aField => {
        const { name, ...restField } = aField;
        return { id: name, data: { ...restField, key: name }, column: "available" };
      }
    ).filter(item => {
      if (value.some(s => s.id == item.id)) {
        return false;
      }

      return availableValue ? item.data.label?.toLowerCase().includes(availableValue?.toLowerCase().trim()) : true;
    });
  }, [availableFields, value, availableValue]);

  const selected = useMemo(() => {
    return sortWithFixed(value.filter(item => {
      return selectedValue ? item.data.label?.toLowerCase().includes(selectedValue?.toLowerCase().trim()) : true;
    }));
  }, [selectedValue, value]);

  const [currentColumn, setCurrentColumn] = useState(null);
  const moveCardHandler = (dragIndex, hoverIndex, columnName) => {
    if (currentColumn && (currentColumn != columnName)) {
      return;
    }
    const dragItem = value[ dragIndex ];
    if (dragItem && !dragItem.data.fixed) {
      const p = [...value];
      p.splice(hoverIndex, 0, p.splice(dragIndex, 1)[ 0 ]);
      onChange(p);
    }
  };
  function sortWithFixed(data) {
    const items = [];
    const right = [];
    const left = [];
    data.forEach(item => {
      if (item.data.fixed === "right") {
        right.push(item);
      } else if (item.data.fixed === "left") {
        left.push(item);
      } else {
        items.push(item);
      }
    });
    return [...left, ...items, ...right];
  }
  function selectItem(item) {
    const { index, ...rest } = item;
    const leftCols = [];
    const cols = [];
    for (let s of value) {
      if (s.data.fixed == "left") {
        leftCols.push(s);
      } else {
        cols.push(s);
      }
    }
    onChange([...leftCols, rest, ...cols]);

    // onChange([rest, ...value]);
  }
  function unSelectItem(item) {
    const oldValue = [...value];
    onChange(oldValue.filter(v => v.id != item.id));
  }
  function onToggleFixed(item, { fixed }) {
    let columns = [...value].map(v => {
      if (v.id == item.id) {
        return { ...v, data: { ...v?.data, fixed } };
      }
      return v;
    });
    onChange(sortWithFixed(columns));
  }

  function changeItemColumn(currentItem, columnName, rootColumn) {
    // if (columnName && (rootColumn != columnName)) {
    //   if (columnName == "available") {
    //     unSelectItem(currentItem);
    //   } else {
    //     selectItem({ ...currentItem, column: "selected" });
    //   }
    // }
  }

  function handleDrop(drop: DropResult, acceptColumn: string) {
    const list = selected;
    if (acceptColumn == "selected") {
      const prevIndex = drop.addedIndex - 1;
      const nextIndex = drop.addedIndex;
      const prev = list[ prevIndex ];
      const next = list[ nextIndex ];
      if (prev?.data?.fixed && next?.data?.fixed) {
        if (next?.data?.fixed == "left") {
          drop.payload.data.fixed = "left";
        } else {
          drop.payload.data.fixed = "right";
        }
      } else if (!prev && next?.data?.fixed == "left") {
        drop.payload.data.fixed = "left";
      } else if (!next && prev?.data?.fixed == "right") {
        drop.payload.data.fixed = "right";
      }
      const result = applySmoothDrag(list, drop);
      onChange(result);
    }
  }

  const onSetColumn = useCallback((name: string, isOver: boolean) => {
    if (isOver) {
      setCurrentColumn(name);
    } else {
      setCurrentColumn(null);
    }
  }, [setCurrentColumn]);
  // language=SQL format=false
  return (
    <StepContainer>
      <StepHeader title={title}/>
      {
        !!meta.submitError && !meta.dirtySinceLastSubmit &&
        <Typography color={TypographyColor.Error} style={{ paddingLeft: "var(--layout-box-gap-l)" }}>
          {meta.submitError}
        </Typography>
      }
      <StepBody className={ColumnsClasses.Column} direction={"row"} flex={1} gap={"XS"} alignSelf={"stretch"}>
        <ColumnContainer flex={1} title={"Available columns"}>
          <SearchInput className={ColumnsClasses.ColumnSearch} value={availableValue} onChange={setAvailableValue}
                       placeholder={"Search here"}/>
          <div style={{ overflowY: "auto" }}>
            <Container
              autoScrollEnabled
              shouldAcceptDrop={(sourceContainerOptions, payload) => payload.from == "selected"}
              orientation={"vertical"}
              style={{ display: "flex", flexDirection: "column", gap: 8 }}
              groupName="push-col"
              dragHandleSelector=".column-drag-handle"
              onDrop={e => handleDrop(e, "available")}
              getChildPayload={index => ({ ...available[ index ], column: "selected", from: "available" })}
              dragClass="card-ghost"
              dropClass="card-ghost-drop"
              dropPlaceholder={{
                showOnTop: true,
                className: "drop-preview"
              }}>
              {
                available.map((item, index) => {
                  return <Draggable key={item.id}>
                    <ListRow className={classNames("movable-item prevent-reset column__row--draggable")}>
                      <ListItemIcon type={"drag_indicator"}
                                    className={classNames(ColumnsClasses.ColumnDraggableIcon, "column-drag-handle")}/>
                      <ListItemText text={item.data.label} flex={1}/>
                      <ListItemIcon type={icons[ "available" ] as IconType} className={CommonClasses.ClickableIcon}
                                    onClick={() => selectItem({ ...item, column: "selected"/*to indicate that column changed*/ })}/>
                    </ListRow>
                  </Draggable>;
                })
              }
            </Container>
          </div>
          {/*<Column*/}
          {/*  accept={["column", "fixedColumn"]}*/}
          {/*  name={"available"}*/}
          {/*  onHover={onSetColumn}*/}
          {/*  emptyTitle={"No available field."}*/}
          {/*  emptyDescription={`Unselect from "Selected fields"`}*/}
          {/*>*/}
          {/*  {*/}
          {/*    available.map((item, index) => {*/}
          {/*      return <DroppableRows*/}
          {/*        key={item.id}*/}
          {/*        name={item.id}*/}
          {/*        column={"available"}*/}
          {/*        index={index}*/}
          {/*        type={"column"}*/}
          {/*        accept={"none"}*/}
          {/*        data={item.data}*/}
          {/*        className={ColumnsClasses.ColumnRowDraggable}*/}
          {/*        changeItemColumn={(...args) => changeItemColumn(...args, "available")}*/}
          {/*        gap={"XXS"}*/}
          {/*      >*/}
          {/*        <ListItemIcon type={"drag_indicator"} className={ColumnsClasses.ColumnDraggableIcon}/>*/}
          {/*        <ListItemText text={item.data.label} flex={1}/>*/}
          {/*        <ListItemIcon type={icons[ "available" ] as IconType} className={CommonClasses.ClickableIcon}*/}
          {/*                      onClick={() => selectItem({ ...item, column: "selected"/*to indicate that column changed*/ /*})}/>*/}
          {/*      </DroppableRows>;*/}
          {/*    })*/}
          {/*  }*/}
          {/*</Column>*/}
        </ColumnContainer>
        <VerticalDivider style={{ marginTop: 40, height: "calc(100% - 40px)" }}/>
        <ColumnContainer flex={1} title={"Selected columns"}>
          <SearchInput className={ColumnsClasses.ColumnSearch} value={selectedValue} onChange={setSelectedValue}
                       placeholder={"Search here"}/>
          <div style={{ overflowY: "auto" }}>
            <Container
              autoScrollEnabled
              shouldAcceptDrop={() => true}
              orientation={"vertical"}
              style={{ display: "flex", flexDirection: "column", gap: 8 }}
              groupName="push-col"
              dragHandleSelector=".column-drag-handle"
              onDrop={e => handleDrop(e, "selected")}
              getChildPayload={index => {
                return { ...selected[ index ], column: "available", from: "selected" };
              }}
              dragClass="card-ghost"
              dropClass="card-ghost-drop"
              dropPlaceholder={{
                showOnTop: true,
                className: "drop-preview"
              }}>
              {
                selected.map((item, index) => {
                  return !item.data.fixed ? <Draggable key={item.id}>
                      <ListRow className={classNames("movable-item prevent-reset column__row--draggable")}>
                        <ListItemIcon type={"drag_indicator"}
                                      className={classNames(ColumnsClasses.ColumnDraggableIcon, { [ "column-drag-handle" ]: !item.data.fixed })}/>
                        <ListItemText text={item.data.label} flex={1}/>
                        {
                          !!item.data.fixed &&
                          <div className={ColumnsClasses.ColumnRowIconSwitcher}>
                            <ListItemIcon type={"https"} className={CommonClasses.ClickableIcon}/>
                            <Tooltip title={"Unfreeze column"}>
                              <ListItemIcon type={"lock_open"} onClick={() => onToggleFixed(item, { fixed: null })}
                                            className={CommonClasses.ClickableIcon}/>
                            </Tooltip>
                          </div>
                        }
                        {!item.data.fixed &&
                          <Box container gap="XXS" className="column__row--unfixed">
                            <Tooltip title={"Freeze left"}>
                              <ListItemIcon onClick={() => onToggleFixed(item, { fixed: "left" })} type={"rc_freeze_left"}
                                            className={CommonClasses.ClickableIcon}/>
                            </Tooltip>
                            <Tooltip title={"Freeze right"}>
                              <ListItemIcon onClick={() => onToggleFixed(item, { fixed: "right" })}
                                            type={"rc_freeze_right"}
                                            className={CommonClasses.ClickableIcon}/>
                            </Tooltip>
                          </Box>
                        }
                        <ListItemIcon type={icons[ item.column ] as IconType} className={CommonClasses.ClickableIcon}
                                      onClick={() => unSelectItem({ ...item, column: "available"/*to indicate that column changed*/ })}/>
                      </ListRow>
                    </Draggable> :
                    <ListRow className={classNames("column__row--draggable")}>
                      <ListItemIcon type={"drag_indicator"}
                                    className={classNames(ColumnsClasses.ColumnDraggableIcon, { [ "column-drag-handle" ]: !item.data.fixed })}/>
                      <ListItemText text={item.data.label} flex={1}/>
                      {
                        !!item.data.fixed &&
                        <div className={ColumnsClasses.ColumnRowIconSwitcher}>
                          <ListItemIcon type={"https"} className={CommonClasses.ClickableIcon}/>
                          <Tooltip title={"Unfreeze column"}>
                            <ListItemIcon type={"lock_open"} onClick={() => onToggleFixed(item, { fixed: null })}
                                          className={CommonClasses.ClickableIcon}/>
                          </Tooltip>
                        </div>
                      }
                      {!item.data.fixed &&
                        <Box container gap="XXS" className="column__row--unfixed">
                          <Tooltip title={"Freeze left"}>
                            <ListItemIcon onClick={() => onToggleFixed(item, { fixed: "left" })} type={"rc_freeze_left"}
                                          className={CommonClasses.ClickableIcon}/>
                          </Tooltip>
                          <Tooltip title={"Freeze right"}>
                            <ListItemIcon onClick={() => onToggleFixed(item, { fixed: "right" })}
                                          type={"rc_freeze_right"}
                                          className={CommonClasses.ClickableIcon}/>
                          </Tooltip>
                        </Box>
                      }
                      <ListItemIcon type={icons[ item.column ] as IconType} className={CommonClasses.ClickableIcon}
                                    onClick={() => unSelectItem({ ...item, column: "available"/*to indicate that column changed*/ })}/>
                    </ListRow>;
                })
              }
            </Container>
          </div>
          {/*<Column*/}
          {/*  accept={["column"]}*/}
          {/*  name={"selected"}*/}
          {/*  onHover={onSetColumn}*/}
          {/*  emptyTitle={"No selected field."}*/}
          {/*  emptyDescription={`Select*/}
          {/*                     from "Available fields"`}*/}
          {/*>*/}
          {/*  {*/}
          {/*    selected.map((item, index) => {*/}
          {/*      return <DroppableRows*/}
          {/*        key={item.id}*/}
          {/*        name={item.id}*/}
          {/*        type={item.data.fixed ? "fixedColumn" : "column"}*/}
          {/*        accept={item.data.fixed ? "none" : "column"}*/}
          {/*        column={item.column}*/}
          {/*        index={index}*/}
          {/*        data={item.data}*/}
          {/*        moveCardHandler={moveCardHandler}*/}
          {/*        changeItemColumn={(...args) => changeItemColumn(...args, "selected")}*/}
          {/*        className={classNames(({*/}
          {/*          [ ColumnsClasses.ColumnRowFixed ]: !!item.data.fixed*/}
          {/*        }), ColumnsClasses.ColumnRowDraggable)}*/}
          {/*        gap={"XXS"}*/}
          {/*      >*/}
          {/*        <ListItemIcon type={"drag_indicator"} className={ColumnsClasses.ColumnDraggableIcon}/>*/}
          {/*        <ListItemText text={item.data.label} flex={1}/>*/}
          {/*        {*/}
          {/*          !!item.data.fixed &&*/}
          {/*          <div className={ColumnsClasses.ColumnRowIconSwitcher}>*/}
          {/*            <ListItemIcon type={"https"} className={CommonClasses.ClickableIcon}/>*/}
          {/*            <Tooltip title={"Unfreeze column"}>*/}
          {/*              <ListItemIcon type={"lock_open"} onClick={() => onToggleFixed(item, { fixed: null })}*/}
          {/*                            className={CommonClasses.ClickableIcon}/>*/}
          {/*            </Tooltip>*/}
          {/*          </div>*/}
          {/*        }*/}
          {/*        {!item.data.fixed &&*/}
          {/*          <Box container gap="XXS" className="column__row--unfixed">*/}
          {/*            <Tooltip title={"Freeze left"}>*/}
          {/*              <ListItemIcon onClick={() => onToggleFixed(item, { fixed: "left" })} type={"rc_freeze_left"}*/}
          {/*                            className={CommonClasses.ClickableIcon}/>*/}
          {/*            </Tooltip>*/}
          {/*            <Tooltip title={"Freeze right"}>*/}
          {/*              <ListItemIcon onClick={() => onToggleFixed(item, { fixed: "right" })} type={"rc_freeze_right"}*/}
          {/*                            className={CommonClasses.ClickableIcon}/>*/}
          {/*            </Tooltip>*/}
          {/*          </Box>*/}
          {/*        }*/}
          {/*        <ListItemIcon type={icons[ item.column ] as IconType} className={CommonClasses.ClickableIcon}*/}
          {/*                      onClick={() => unSelectItem({ ...item, column: "available"/*to indicate that column changed*/ /*})}/>*/}
          {/*      </DroppableRows>;*/}
          {/*    })*/}
          {/*  }*/}
          {/*</Column>*/}
        </ColumnContainer>
      </StepBody>
    </StepContainer>
  );
});

export const enum ColumnsClasses {
  Column = "column",
  ColumnSearch = "column__search",
  ColumnRowFixed = "column__row--fixed",
  ColumnRowIconSwitcher = "column__icon-switcher",
  ColumnDraggableIcon = "column__row--drag-icon",
  ColumnRowDraggable = "column__row--draggable"
}
