import { Link }                                           from "@relcu/ui";
import { ItemsEllipsis }                                  from "@relcu/ui";
import React                                              from "react";
import { useQuery }                                       from "@apollo/client";
import { gql }                                            from "@apollo/client";
import { CircularLoader }                                 from "@relcu/ui";
import { useSource }                                      from "@relcu/ui";
import { TypographyWeight }                               from "@relcu/ui";
import { CommonClasses }                                  from "@relcu/ui";
import { FontIcon }                                       from "@relcu/ui";
import { Box }                                            from "@relcu/ui";
import { TypographyLine }                                 from "@relcu/ui";
import { TimelineItemBody }                               from "@relcu/ui";
import { TimelineItem }                                   from "@relcu/ui";
import { TimelineItemHeaderContent }                      from "@relcu/ui";
import { TypographyColor }                                from "@relcu/ui";
import { Typography }                                     from "@relcu/ui";
import { TimelineItemSubject }                            from "@relcu/ui";
import { TimelineItemIconColors }                         from "@relcu/ui";
import { TimelineItemIcon }                               from "@relcu/ui";
import { MailMessageActivity as MailMessageActivityType } from "../../../../__types__/MailMessageActivity";
import { GetActivityMailMessageVariables }                from "./__types__/GetActivityMailMessage";
import { GetActivityMailMessage }                         from "./__types__/GetActivityMailMessage";

export interface MailActivityProps extends MailMessageActivityType {
  createdAt: string;
  initiatorObjectId: string
}

export const MailMessageActivity = React.memo<MailActivityProps>(function MailMessageActivity(props) {
  const { mailMessage, bulkMailConversation, initiatorObjectId, mailMessageAttachments, mailMessageFrom, mailMessageTo, mailMessageSubject, createdAt } = props;
  const { snippet, text, subject: contentSubject, objectId: msgObjectId, conversation, loanEstimate } = Object(mailMessage);
  const { $object, $viewer } = useSource();
  const toObjectIds = mailMessageTo.map((t: any) => t.objectId)
  const expandable = $viewer.role=="admin"
    || $object.__typename=="Lead" || [initiatorObjectId, mailMessageFrom.objectId, ...toObjectIds].includes($viewer.objectId);

  const navigateTo = (obj) => {
    if (obj.__typename == "User") {
      if (bulkMailConversation) {
        return `/user/${obj.objectId}/emails/${bulkMailConversation.objectId}`;
      }
      return `/user/${obj.objectId}/emails${conversation?.objectId
        ? `/${conversation.objectId}${msgObjectId ? `/${msgObjectId}` : ""}` : ""}`;
    } else if (mailMessageSubject?.objectId) {
      return `/lead/${mailMessageSubject.objectId}/emails${conversation?.objectId
        ? `/${conversation.objectId}${msgObjectId ? `/${msgObjectId}` : ""}` : ""}`;
    } else {
      return `/contact/${obj.objectId}/emails${conversation?.objectId
        ? `/${conversation.objectId}${msgObjectId ? `/${msgObjectId}` : ""}` : ""}`;
    }
  };
  if (mailMessageFrom && ($object.objectId === mailMessageFrom.objectId || ($object.__typename === "Lead" && mailMessageFrom.__typename === "Contact"))) {
    return <TimelineItem
      primary={<TimelineItemSubject>
        {
          mailMessageFrom?.objectId && !mailMessageFrom.objectId.startsWith("int:") ?
            <Link
              to={navigateTo(mailMessageFrom)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{mailMessageFrom?.objectName || "Unknown"}</Typography>
            </Link>
            :
            <Typography>{mailMessageFrom?.objectName || "Unknown"}</Typography>
        }

        <Typography color={TypographyColor.Secondary}>sent</Typography>
        <Typography>email</Typography>
        {loanEstimate && <>
          <Typography color={TypographyColor.Secondary}>with</Typography>
          <Typography>a loan proposal</Typography>
        </>
        }
        <Typography color={TypographyColor.Secondary}>to</Typography>
        {mailMessageTo?.length ?
          <ItemsEllipsis
            showLength={3}
            items={mailMessageTo}
            renderItem={
              (item, index) => item?.objectId ?
                <Link key={index} to={navigateTo(item)}>
                  <Typography className={CommonClasses.ClickableIcon}>
                    {item?.objectName || "Unknown"}
                    {index !== (mailMessageTo.length > 3 ? 3 : mailMessageTo.length) - 1 ? "," : ""}
                  </Typography>
                </Link>
                : <Typography
                  key={index}>{item?.objectName || "Unknown"}{index !== mailMessageTo.length - 1 ? "," : ""}</Typography>
            }
          />
          : <Typography>{"Relcu"}</Typography>
        }
        <Typography style={{ paddingLeft: 10 }} weight={TypographyWeight.Medium}>{contentSubject}</Typography>
      </TimelineItemSubject>}
      secondary={expandable && <TimelineItemHeaderContent withAttachment={!!mailMessageAttachments}>
        {snippet || text}
      </TimelineItemHeaderContent>}
      creationDate={createdAt}
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Warning}
                                icon={bulkMailConversation ? "rc_bulk_email" : "email"}/>}
    >
      {expandable && <Box container direction={"column"} gap={"XS"}>
        <Box container gap={"XXS"} flexGrow={1}>
          <TimelineItemBody>
            <Typography lineHeight={TypographyLine.Line17}>
              {snippet || text}
            </Typography>
            {!!mailMessageAttachments && <Box container gap={"XXXS"} alignItems={"center"}>
              <FontIcon style={{ fontSize: 20 }} type={"attachment"} className={CommonClasses.GrayIcon}/>
              <Typography color={TypographyColor.Secondary}>{mailMessageAttachments} attachments</Typography>
            </Box>}
          </TimelineItemBody>
        </Box>
      </Box>}
    </TimelineItem>;
  } else {
    return <TimelineItem
      primary={<TimelineItemSubject>
        {mailMessageTo?.length ?
          <ItemsEllipsis
            showLength={3}
            items={mailMessageTo}
            renderItem={
              (item, index) => item?.objectId ?
                <Link key={index} to={navigateTo(item)}>
                  <Typography className={CommonClasses.ClickableIcon}>
                    {item?.objectName || "Unknown"}
                    {index !== (mailMessageTo.length > 3 ? 3 : mailMessageTo.length) - 1 ? "," : ""}
                  </Typography>
                </Link>
                : <Typography
                  key={index}>{item?.objectName || "Unknown"}{index !== mailMessageTo.length - 1 ? "," : ""}</Typography>
            }
          /> : <Typography>{"Relcu"}</Typography>
        }
        <Typography color={TypographyColor.Secondary}>received</Typography>
        <Typography>email</Typography>
        {loanEstimate && <>
          <Typography color={TypographyColor.Secondary}>with</Typography>
          <Typography>a loan proposal</Typography>
        </>
        }
        <Typography color={TypographyColor.Secondary}>from</Typography>
        {
          mailMessageFrom?.objectId && !mailMessageFrom.objectId.startsWith("int:") ?
            <Link
              to={navigateTo(mailMessageFrom)}>
              <Typography
                className={CommonClasses.ClickableIcon}>{!!mailMessageFrom ? mailMessageFrom.objectName : "Relcu"}</Typography>
            </Link>
            :
            <Typography>{!!mailMessageFrom ? mailMessageFrom.objectName : "Relcu"}</Typography>
        }
        <Typography style={{ paddingLeft: 10 }} weight={TypographyWeight.Medium}>{contentSubject}</Typography>
      </TimelineItemSubject>}
      secondary={expandable && <TimelineItemHeaderContent withAttachment={!!mailMessageAttachments}>
        {snippet || text}
      </TimelineItemHeaderContent>}
      creationDate={createdAt}
      avatar={<TimelineItemIcon variant={TimelineItemIconColors.Warning}
                                icon={bulkMailConversation ? "rc_bulk_email" : "email"}/>}>
      {expandable && <MailMessageActivityBody objectId={mailMessage?.objectId}/>}
    </TimelineItem>;
  }
});

export interface MailMessageActivityBodyProps {
  objectId: string
}

export const MailMessageActivityBody = React.memo<MailMessageActivityBodyProps>(function MailMessageActivityBody(props) {
  const { objectId } = props;
  const { data: { mailMessage } = {}, loading } = useQuery<GetActivityMailMessage, GetActivityMailMessageVariables>(GET_ACTIVITY_MAIL_MESSAGE, { variables: { id: objectId } });

  if (loading) {
    return <Box container direction={"column"} alignItems={"center"} gap={"XS"}>
      <CircularLoader/>
    </Box>;
  }
  const { snippet, text, subject, attachments } = mailMessage;

  return <Box container direction={"column"} gap={"XS"}>
    <Box container gap={"XXS"} flexGrow={1}>
      <TimelineItemBody>
        <Typography lineHeight={TypographyLine.Line17}>
          {snippet || text}
        </Typography>
        {!!attachments?.length && <Box container gap={"XXXS"} alignItems={"center"}>
          <FontIcon style={{ fontSize: 20 }} type={"attachment"} className={CommonClasses.GrayIcon}/>
          <Typography color={TypographyColor.Secondary}>{attachments.length} attachments</Typography>
        </Box>}
      </TimelineItemBody>
    </Box>
  </Box>;
});

const GET_ACTIVITY_MAIL_MESSAGE = gql`
  query GetActivityMailMessage($id:ID!){
    mailMessage(id:$id){
      objectId
      subject
      text
      snippet
      attachments {
        url
      }
    }
  }
`;
