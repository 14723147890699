import { useQuery }         from "@apollo/client";
import { useFormState }     from "@relcu/form";
import { useMemo }          from "react";
import { ViewerUserNode }   from "../../../../graph/__types__/ViewerUserNode";
import { VIEWER_USER_NODE } from "../../../../graph/operations.graphql";
import { useSource }        from "@relcu/ui";
import { compileVars }      from "../../../../utils/helpers";

export function usePointerFilters(filters: object) {
  const { data: { viewer: { user: $viewer } } } = useQuery<ViewerUserNode>(VIEWER_USER_NODE, {
    fetchPolicy: "cache-only"
  });
  const {$object} = useSource();
  const { values } = useFormState({ subscription: { values: true } });
  return useMemo(() => {
    const source = { ...values, $viewer, $object };
    return compileVars(filters, source);
  }, [$viewer, $object, values]);
}
