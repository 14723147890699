import { useEffect }                           from "react";
import { useCallback }                         from "react";
import { useReactiveVar }                      from "@apollo/client";
import { usePrevious }                         from "@relcu/ui";
import { gql }                                 from "@apollo/client";
import { useQuery }                            from "@apollo/client";
import { useMutation }                         from "@apollo/client";
import { StatusBarProps }                      from "@relcu/ui";
import { USER_NOTIFICATION_CONTROLS_FRAGMENT } from "../../graph/operations.graphql";
import { snackbarVar }                         from "../../reactiveVars";
import { UPDATE_VIEWER_STATUS }                from "../SideBar";
import { usePhone }                            from "../usePhone";
import { SnackBarUser }                        from "./__types__/SnackBarUser";
import { UpdateViewerStatusVariables }         from "./__types__/UpdateViewerStatus";
import { UpdateViewerStatus }                  from "./__types__/UpdateViewerStatus";

const SNACK_BAR_USER = gql`
  query SnackBarUser {
    viewer {
      user {
        lastNotificationSeenAt
        objectId
        status
        role
        id
        ...UserNotificationControls
      }
    }
  }
  ${USER_NOTIFICATION_CONTROLS_FRAGMENT}
`;

export const UPDATE_USER_NOTIFICATION_LAST_TIME_SEEN = gql`
  mutation UpdateUserNotificationLastTimeSeen($input:UpdateUserInput!) {
    updateUser(input: $input){
      user{
        id
        lastNotificationSeenAt
      }
    }
  }
`;

export function useSnackBar() {
  const [updateStatus] = useMutation<UpdateViewerStatus, UpdateViewerStatusVariables>(UPDATE_VIEWER_STATUS);
  const { data: { viewer: { user: { status, id, role, lastNotificationSeenAt, notificationControls } } } } = useQuery<SnackBarUser>(SNACK_BAR_USER, {
    fetchPolicy: "cache-only"
  });
  const phone = usePhone();
  const wasBusy = usePrevious(!!phone?.hybridCalls?.myCall);
  const isBusy = !!phone?.hybridCalls?.initialDisplayCall;
  const isSystemCall = phone?.active?.to.includes("system");
  const snackBarState = useReactiveVar(snackbarVar);

  const toggleSnackBar = useCallback((type) => {
    const prev = snackbarVar();
    if (prev.current == type) {
      return snackbarVar({
        previous: null,
        current: null
      });
    }

    return snackbarVar({
      previous: type,
      current: type
    });
  }, [snackBarState, isSystemCall]);

  useEffect(() => {
    if (isSystemCall && snackBarState?.current !== null) {
      const prev = snackbarVar();
      prev.current == "notifications" ?
        snackbarVar({
          previous: prev.current,
          current: prev.current
        }) :
        snackbarVar({
          previous: prev.current,
          current: null
        });
    }

    if (isBusy && !wasBusy && !isSystemCall && snackBarState?.current !== "dial") {
      const prev = snackbarVar();
      //open/close snackBar
      snackbarVar({
        previous: prev.current,
        current: "dial"
      });
    }
    if (!isBusy && wasBusy) {
      const prev = snackbarVar();
      snackbarVar({
        previous: prev.previous,
        current: prev.previous
      });
    }
  }, [wasBusy, isBusy, snackBarState?.current, isSystemCall]);

  const hotBar = {
    phone,
    id,
    toggleSnackBar,
    userRole: role,
    snackBarState: snackBarState?.current,
    lastNotificationSeenAt,
    notificationControls,
    updateStatus(status: string) {
      return updateStatus({
        variables: {
          id,
          status
        }
      });
    },
    get status() {
      return status as StatusBarProps["status"];
    },
    get showStatusBar() {
      return !phone?.hybridCalls?.myCall && ["away", "busy", "out_of_work"].includes(status);
    },
    get showLeadQueue() {
      return !phone?.hybridCalls?.myCall && !hotBar.showStatusBar;
    },
    isSystemCall,
    get showCall() {
      return !!phone?.hybridCalls?.myCall;
    }
  };
  return hotBar;
}
