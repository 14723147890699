import { useNavigate }       from "@relcu/react-router";
import { useSource }         from "@relcu/ui";
import { useMemo }           from "react";
import { layoutVar }         from "../../../../reactiveVars";
import { schemaVar }         from "../../../../reactiveVars";
import { useJqlQuery }       from "../../Jql";
import { SchemaDialogProps } from "./SchemaDialog";

export function useSchemaDialog(props: SchemaDialogProps) {
  let { action, className, record, formProps, ...modal } = props;
  const schemas = schemaVar();
  const layouts = layoutVar();
  const { $viewer: user, $settings, $object } = useSource();
  const schema = schemas[ className ];
  let { dialog: { jql, sections }, tabs } = layouts[ className ];
  const isAssignable = schema.isAssignable;
  const { data, loading } = useJqlQuery(jql.query.get, {
    operationName: `Get${className}SchemaDialog`,
    skip: action === "create" || !record?.id,
    variables: {
      id: record?.id
    }
  });
  if (data) {
    record = data[ jql.query.get.operation ];
  }
  const navigate = useNavigate();
  const afterSubmit = (data) => {
    data = data[ jql.query.get.operation ];
    if (action == "create" && data?.objectId && tabs.length) {
      navigate(`/${className}/${data.objectId}`);
    }
    props.onClose();
  };

  const initialValues = useMemo(() => {
    if (isAssignable) {
      const assignedTo = record?.assignedTo || (user.role == "loan_officer" ? user : undefined);
      if (assignedTo) {
        return {
          ...record,
          assignedTo
        };
      }
    }
    return record;
  }, [record, isAssignable, user.role, user.objectId]);
  return {
    ...modal,
    className,
    action,
    jql,
    sections,
    initialValues,
    formProps,
    afterSubmit,
    loading,
    user
  };
}
