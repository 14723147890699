import { Reference }            from "@apollo/client";
import { FieldPolicy }          from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { getIn }                from "@relcu/form";
import { parsePhoneNumber }     from "@relcu/ui";
import { modal }                from "@relcu/ui";
import { TimeWarningDialog }    from "@relcu/ui";
import { Condition }            from "@relcu/ui";
import Handlebars               from "handlebars/dist/handlebars";
import { Interval }             from "luxon";
import { DateTime }             from "luxon";
import { SpecialClass }         from "../constants/SpecialClass";
import { schemaVar }            from "../reactiveVars";
import { loadingVar }           from "../reactiveVars";
import { getNullValues }        from "./schemaUtils";

export function uuid() {
  // If we have a cryptographically secure PRNG, use that
  // https://stackoverflow.com/questions/6906916/collisions-when-generating-uuids-in-javascript
  let buf = new Uint16Array(8);
  window.crypto.getRandomValues(buf);
  function S4(num) {
    let ret = num.toString(16);
    while (ret.length < 4) {
      ret = "0" + ret;
    }
    return ret;
  }
  return (S4(buf[ 0 ]) + S4(buf[ 1 ]) + "-" + S4(buf[ 2 ]) + "-" + S4(buf[ 3 ]) + "-" + S4(buf[ 4 ]) + "-" + S4(buf[ 5 ]) + S4(buf[ 6 ]) + S4(buf[ 7 ]));
}
export const createPhoneNumbers = (node, optOut) => {
  let numbers = [];
  if (node.__typename == "Lead") {
    (node.members || []).slice().sort((a, b) => +b.isPrimary - +a.isPrimary).map((member) => {
      let phones = member.contact ? member.contact.phones : [];
      phones.map((p) => {
        const label = (!member.contact.firstName) ? p.number : `${member.contact.firstName || ""} (${p.number})`;
        numbers.push({
          value: p.number,
          label: label,
          isPrimary: p.isPrimary,
          callOptOut: p.callOptOut,
          smsOptOut: p.smsOptOut,
          isPrimaryMember: member.isPrimary,
          disabled: p[ optOut ]
        });
      });
    });
  } else if (node.__typename == "Contact") {
    (node.phones || []).map((phone) => {
      const label = (!node.firstName) ? phone.number : `${node.firstName || ""} (${phone.number})`;
      numbers.push({
        value: phone.number,
        label: label,
        isPrimary: phone.isPrimary,
        smsOptOut: phone.smsOptOut,
        callOptOut: phone.callOptOut,
        disabled: phone[ optOut ]
      });
    });
  }
  return numbers;
};
export const getPrimaryBorrowerName = (members) => {
  let primaryBorrower = members.find(b => b.type == "borrower");
  return (primaryBorrower?.contact?.firstName || primaryBorrower?.contact?.lastName)
    ? primaryBorrower.contact.middleName
      ? `${primaryBorrower.contact.firstName} ${primaryBorrower.contact.middleName} ${primaryBorrower.contact.lastName}`
      :`${primaryBorrower.contact.firstName} ${primaryBorrower.contact.lastName}`
    : "N/A";
};
export function transformNameToLabel(name: string) {
  name = name.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  name = name.trim();
  name = name[ 0 ].toUpperCase() + name.substring(1);
  return name;
}
export function toNodeId({ className, objectId }) {
  return btoa(`${className}:${objectId}`);
}
export function toGraphQlType(className: string = "") {
  return className.indexOf("_") == 0 ? className.substring(1) : className;
}
export function checkTimezone(lead, { startHour, startMinute, endHour, endMinute }) {
  const { members, timezone } = lead;
  if (!timezone) {
    return new Promise((accept) => {
      const { destroy } = modal(TimeWarningDialog, {
        icon: "rc_timezone_error",
        title: `Lead's time zone information is not found`,
        content: `It may be a late hour for this lead. Click on “Contact anyway” to contact the lead.`,
        onConfirm: () => {
          accept(true);
          destroy();
        },
        onCancel: () => {
          accept(false);
          destroy();
        }
      });
    });
  }
  const now = DateTime.local();
  let time = now.setZone(timezone);
  let start = DateTime.fromObject({
    day: time.day,
    month: now.month,
    year: now.year,
    hour: Number(startHour),
    minute: Number(startMinute),
    second: 0
  }, { zone: timezone });
  let end = DateTime.fromObject({
    day: time.day,
    month: now.month,
    year: now.year,
    hour: Number(endHour),
    minute: Number(endMinute),
    second: 0
  }, { zone: timezone });
  if (Interval.fromDateTimes(start, end).contains(time)) {
    return true;
  }
  const primaryBorrower = members.find(({ isPrimary }) => isPrimary);
  return new Promise((accept) => {
    const { destroy } = modal(TimeWarningDialog, {
      date: time,
      name: primaryBorrower.contact.firstName ?? primaryBorrower.contact.objectName,
      onConfirm: () => {
        accept(true);
        destroy();
      },
      onCancel: () => {
        accept(false);
        destroy();
      }
    });
  });
}

export function relayStyleCustomPagination(...args) {
  const policy = relayStylePagination(...args);
  return {
    ...policy,
    read(existing, options) {
      if (existing?.edges) {
        return policy.read(existing, options);
      }
      return existing;
    },
    merge(existing, incoming, options) {
      if (!options.args.before && !options.args.after) {
        return incoming || existing;
      }
      const { merge } = policy;
      return (<Function>merge)(existing, incoming, options);
    }
  };
}
type KeyArgs = FieldPolicy<any>["keyArgs"];
export function skipLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : [];
      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { skip = 0 } = args;
        for (let i = 0; i < incoming.length; ++i) {
          merged[ skip + i ] = incoming[ i ];
        }
      } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        merged.push.apply(merged, incoming);
      }
      return merged;
    }
  };
}

export function nextCursor(index) {
  return btoa(`arrayconnection:${index + 1}`);
}

export const dividedDataByFixedDate = (edges, dateField = "createdAt", lastTimeSeen?) => {
  const dividedData = {
    "new": [],
    "today": [],
    "week": [],
    "month": [],
    "year": [],
    "old": []
  };

  edges.forEach(edge => {
    const date = DateTime.fromISO(edge.node[ "createdAt" ]);
    // const lastSeen = lastTimeSeen && DateTime.fromISO(lastTimeSeen);
    const currentDate = DateTime.local();

    // if (lastSeen && lastSeen < date) {
    //   dividedData.new.push(edge);
    // } else
    if (date.day == currentDate.day && date.month == currentDate.month && date.year == currentDate.year) {
      dividedData.today.push(edge);
    } else if (date.weekNumber == currentDate.weekNumber && date.year == currentDate.year) {
      dividedData.week.push(edge);
    } else if (date.month == currentDate.month && date.year == currentDate.year) {
      dividedData.month.push(edge);
    } else if (date.year == currentDate.year) {
      dividedData.year.push(edge);
    } else {
      dividedData.old.push(edge);
    }
  });

  return dividedData;
};

export const divideDataByDate = (edges, dateField = "createdAt") => {
  let divider = null;
  const dividedData = [];
  edges.map((edge) => {
    if (divider == null || dateCheckDiff(divider, edge.node[ dateField ])) {
      divider = edge.node[ dateField ];
      const now = new Date();
      const date = DateTime.fromISO(divider);
      if (now.getFullYear() == date.year) {
        dividedData.push(date.toFormat("ccc, d MMM"));
      } else {
        dividedData.push(date.toFormat(" d MMM, yyyy"));
      }
    }
    dividedData.push(edge);
  });
  return dividedData;
};
export const dateCheckDiff = (currentDate, newDate) => {
  currentDate = DateTime.fromISO(currentDate);
  newDate = DateTime.fromISO(newDate);
  return currentDate.day != newDate.day || currentDate.month != newDate.month || currentDate.year != newDate.year;
};

// export function getDefaultPhoneNumber(phoneNumbers: any[]) {
//   const defaultPhoneNumber = phoneNumbers.find(p => p.default);
//   return defaultPhoneNumber || phoneNumbers[ 0 ];
// }

export function getDefaultPhoneNumberForSMS(phoneNumbers: any[], type) {
  let numbers = phoneNumbers;
  if (type == "Lead") {
    numbers = phoneNumbers.filter(phone => phone.isPrimaryMember);
  }
  const filtered = numbers.filter(phone => !phone.smsOptOut) || [];
  const defaultPhoneNumber = filtered.find(p => p.isPrimary);
  return defaultPhoneNumber || filtered[ 0 ] || numbers.find(p => p.isPrimary);
}

export function getDefaultPhoneNumberForCall(phoneNumbers: any[] = [], type) {
  if (type == "Lead") {
    const filtered = phoneNumbers.filter(phone => phone.isPrimaryMember);
    const defaultPhoneNumber = filtered?.find(p => p.isPrimary);
    const selected = (!defaultPhoneNumber || defaultPhoneNumber?.callOptOut) ? filtered.find(p => p.callOptOut != true) : defaultPhoneNumber;
    return selected ? selected : defaultPhoneNumber;
  } else if (type == "Contact") {
    const filtered = phoneNumbers.filter(phone => !phone.callOptOut) || [];
    const defaultPhoneNumber = filtered.find(p => p.isPrimary);
    return defaultPhoneNumber || filtered[ 0 ] || phoneNumbers.find(p => p.isPrimary);
  }
}
export function getDefaultEmailAddress(emailAddresses: any[]) {
  const filtered = emailAddresses.filter(email => !email.optOut);
  const defaultEmailAddresses = filtered.find(p => p.isPrimary);
  return defaultEmailAddresses || filtered[ 0 ];
}

export function isOverdue(task: { dueDate: string }) {
  return DateTime.fromISO(task.dueDate).diffNow().get("milliseconds") <= 0;
}

export const checkDuplicateOf = (node: any): boolean => {
  return node.__typename == "Lead" && !!node.duplicateOf;
};

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let amMatch = cleaned.match(/^(374|)?(\d{2})(\d{3})(\d{3})$/);
  if (match) {
    let intlCode = (match[ 1 ] ? "+1" : "");
    return ["(", match[ 2 ], ") ", match[ 3 ], "-", match[ 4 ]].join("");
  } else if (amMatch) {
    let intlCode = (amMatch[ 1 ] ? "+374" : "");
    return ["(", amMatch[ 2 ], ") ", amMatch[ 3 ], "-", amMatch[ 4 ]].join("");
  }
  return null;
}

export const getContactName = (contact: { objectId, objectName }, node: { __typename, members: any[] }): string => {
  if (node?.__typename == "Lead" && contact.objectId) {
    let borrower = node.members.find(member => member.contact.objectId == contact.objectId);
    return borrower ?
      (borrower.contact?.firstName && borrower.contact?.lastName)
        ? borrower.contact?.middleName
        ? `${borrower.contact.firstName} ${borrower.contact.middleName} ${borrower.contact.lastName}`
        : `${borrower.contact.firstName} ${borrower.contact.lastName}`
        : contact.objectName
      : contact.objectName;
  }
  return contact.objectName;
};

export function getCaller(call) {
  return call.calls.find(c => c.type == "caller").party;
}
export function toFirstLower(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
export function toFirstUpper(string: string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const getPurelizedLead = (scope: any) => {
  return {
    objectId: scope.objectId,
    name: getPrimaryBorrowerName(scope.members),
    loanProgram: scope.loanProgram,
    loanPurpose: scope.loanPurpose,
    loanAmount: scope.loanAmount,
    leadSource: scope.leadSource?.leadPartner?.title,
    leadStatus: scope.leadStatus,
    createdAt: scope.createdAt
  };
};
export function pad(num: number) {
  return num.toString().length > 1 ? num : "0" + num;
}
export function toPointer(id: string) {
  const [className, objectId] = atob(id).split(":");
  return pointer({ className, objectId });
}
export function toParseClass(typename: string) {
  if (typename.toLowerCase() === "user") {
    return SpecialClass.USER;
  }
  if (typename.toLowerCase() === "role") {
    return SpecialClass.ROLE;
  }
  return toFirstUpper(typename);
}
export function pointer({ className, objectId }) {
  return {
    __type: "Pointer",
    className: toParseClass(className),
    objectId
  };
}

export function getReplacement(contact, user, object?) {
  return {
    user: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      nmls: user?.nmlsId,
      team: user?.team,
      calendar: user?.calendar,
      applyUrl: user?.applyUrl,
      custom1: user?.custom1,
      custom2: user?.custom2,
      custom3: user?.custom3,
      custom4: user?.custom4,
      custom5: user?.custom5,
      custom6: user?.custom6,
      custom7: user?.custom7,
      custom8: user?.custom8,
      custom9: user?.custom9,
      custom10: user?.custom10,
    },
    receiver: {
      firstName: contact?.firstName || "",
      lastName: contact?.lastName || ""
    },
    previousAssigneeName: object?.previousAssignedTo?.objectName,
    leadSourceTitle: object?.leadSourceTitle,
    leadSourcePartner: object?.leadSourcePartner
  };
}

export function compileVars(expression: object | string, source: object, root: string = null) {
  if (typeof expression === "string" && expression.startsWith("$")) {
    const path = Condition.getPath(expression, root);
    return getIn(source, path);
  }
  if (typeof expression !== "object") {
    return expression;
  }
  if (Array.isArray(expression)) {
    return expression.map(obj => compileVars(obj, source, root));
  }
  return Object.fromEntries(
    Object.entries(expression).map(([k, v]) => {
      if ((isObject(v) || Array.isArray(v)) || typeof v === "string") {
        return [k, compileVars(v, source, root)];
      }
      return [k, v];
    })
  );
}
export function isObject(value) {
  return Object.prototype.toString.call(value) === "[object Object]";
}
export function isEmpty(value) {
  if (value == null) {
    return true;
  }
  if (Array.isArray(value) || typeof value == "string") {
    return !value.length;
  }
  if (typeof value === "object") {
    return !Object.keys(value).length;
  }
  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
}
export function flatten<T extends Record<string, any>>(object: T, path: string | null = null, separator = "."): T {
  return Object.keys(object).reduce((acc: T, key: string): T => {
    const value = object[ key ];
    const newPath = Array.isArray(object)
      ? `${path ? path : ""}[${key}]`
      : [path, key].filter(Boolean).join(separator);
    const isObject = [
      typeof value === "object",
      value !== null,
      !(value instanceof Date),
      !(value instanceof RegExp),
      !(Array.isArray(value) && value.length === 0)
    ].every(Boolean);

    return isObject
      ? { ...acc, ...flatten(value, newPath, separator) }
      : { ...acc, [ newPath ]: value };
  }, {} as T);
}
export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

export function delay(ms = 0) {
  return new Promise(accept => setTimeout(accept, ms));
}

export function fileToBase64(reader: FileReader) {
  let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
  if ((encoded.length % 4) > 0) {
    encoded += "=".repeat(4 - (encoded.length % 4));
  }
  return encoded;
}

export const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[ i ];
};

export async function downloadFile(attachment: { name: string, type: string, url: string }) {
  try {
    loadingVar(true);
    const response = await fetch(attachment.url, { method: "GET", redirect: "follow" });
    const blob = new Blob([await response.blob()], { type: attachment.type });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${attachment.name}`;
    document.body.appendChild(a);
    a.click();
  } finally {
    loadingVar(false);
  }

}
export async function loadFile(url: string) {
  try {
    // loadingVar(true);
    const response = await fetch(url, { method: "GET", redirect: "follow" });
    return await response.text();
  } finally {
    // loadingVar(false);
  }

}
export function totalLoanAmount(lead, settings) {
  const { total } = loanAmountDetails(lead, settings);
  return total;
}

export function loanAmountDetails(lead:
    {
      loanProgram: string,
      loanPurpose: string,
      loanAmount: number
      members: { type: string, veteranStatus: string }[]
      downPayment: number,
      financeFf: boolean,
      financeMip: boolean,
      firstUseOfVaProgram: boolean,
      mortech: { streamLine: boolean }
      property: { value: number }
    },
  settings) {
  let percentage = 0;
  let type = "other";
  const downPaymentPercentage = ((lead.downPayment / lead.property.value) * 100);
  if (lead.loanProgram == "va") {
    type = "ff";
    const primaryBorrower = lead.members.find(b => b.type == "borrower");
    if (primaryBorrower?.veteranStatus == "exempt") {
      percentage = 0;
    } else if (lead.loanPurpose == "purchase") {
      if (downPaymentPercentage < 5) {
        percentage = lead.firstUseOfVaProgram ? settings.va.purchase.firstUseFiveDownFF : settings.va.purchase.secondUseFiveDownFF;
      } else if (downPaymentPercentage < 10) {
        percentage = lead.firstUseOfVaProgram ? settings.va.purchase.firstUseFiveOrMoreDownFF : settings.va.purchase.secondUseFiveOrMoreDownFF;
      } else {
        percentage = lead.firstUseOfVaProgram ? settings.va.purchase.firstUseTenOrMoreDownFF : settings.va.purchase.secondUseTenOrMoreDownFF;
      }
    } else if (lead.mortech.streamLine) {
      percentage = settings.va.refinance.streamlineRefinanceFF;
    } else {
      percentage = lead.firstUseOfVaProgram ? settings.va.refinance.firstUseCashOutRefinanceFF : settings.va.refinance.secondUseCashOutRefinanceFF;
    }
    const fee = (lead.loanAmount * percentage / 100);
    if (lead.financeFf) {
      return {
        type,
        amount: lead.loanAmount,
        fee,
        total: !!(lead.loanAmount + fee) && Number((lead.loanAmount + fee).toFixed(2))
      };
    } else {
      return {
        type,
        amount: lead.loanAmount,
        fee,
        total: lead.loanAmount && Number(lead.loanAmount.toFixed(2))
      };
    }
  }
  if (lead.loanProgram == "fha") {
    type = "mip";
    percentage = settings.fha.mip;
    const fee = (lead.loanAmount * percentage / 100);
    if (lead.financeMip) {
      return {
        type,
        amount: lead.loanAmount,
        fee,
        total: !!(lead.loanAmount + fee) && Number((lead.loanAmount + fee).toFixed(2))
      };
    } else {
      return {
        type,
        amount: lead.loanAmount,
        fee,
        total: lead.loanAmount && Number(lead.loanAmount.toFixed(2))
      };
    }
  }
  return { total: lead.loanAmount && Number(lead.loanAmount.toFixed(2)), fee: 0, amount: lead.loanAmount, type };
}
export function isPositiveInteger(value) {
  if (value < 0) {
    return "Please input positive numbers";
  }
}
export const format = (value, precision) => {
  return Number((!value ? 0 : value)).toLocaleString("en-US", { minimumFractionDigits: precision, maximumFractionDigits: precision });
};
Handlebars.registerHelper("format", function (text, options) {
  const value = format(text, options.fn());
  return new Handlebars.SafeString(value);
});
Handlebars.registerHelper("sum", function (...param) {
  let sum = 0;
  for (let i = 0; i < param.length - 1; i++) {
    sum += param[ i ];
  }
  const value = format(sum, 2);
  return new Handlebars.SafeString(value);
});

Handlebars.registerHelper("get", function (data, fieldName) {
  let value;
  const isVA = data.selectedRate.productName.includes("VA");
  const isFha = data.selectedRate.productName.includes("FHA");
  const isPurchase = data.loanCriteria.loanPurpose.toLowerCase() === "purchase";
  switch (fieldName) {
    case "loanAmount":
      value = (data.loanCriteria.totalLoanAmount ?? data.loanCriteria.loanAmount);
      break;
    case "monthlyMIPMI":
      value = data.monthlyPayment.mi || data.monthlyPayment.pmi || 0;
      break;
    case "MIPMI":
      value = data.closingCost.pmi || data.closingCost.mip || 0;
      break;
    case "estClosingCost":
      value = data.closingCost.total + data.closingCost.earnestMoneyPaid + data.closingCost.sellerConcession;
      break;
    case "financeFfAmount":
      value = isVA ? data.loanCriteria.totalLoanAmount - data.loanCriteria.loanAmount : 0;
      break;
    case "financeMipAmount":
      value = isFha ? data.loanCriteria.totalLoanAmount - data.loanCriteria.loanAmount : 0;
      break;
    case "earnestMoneyPaid":
      if (isPurchase) {
        value = data.closingCost.earnestMoneyPaid || 0;
      }
      break;
    case "sellerConcession":
      if (isPurchase) {
        value = data.closingCost.sellerConcession || 0;
      }
      break;
    case "yourClosingCost":
      value = data.closingCost.total + data.closingCost.earnestMoneyPaid + data.closingCost.sellerConcession - data.lenderCredits;
      if (value < 0) {
        value = 0;
      }
      break;
    case "cashAtClosing":
      if (isPurchase) {
        value = data.totalClosingCost + data.loanCriteria.property.value - data.loanCriteria.loanAmount;
      } else {
        value = data.totalClosingCost - data.loanCriteria.loanAmount + data.loanCriteria.property.currentMortgageBalance;
      }
      value = Math.abs(value);
      break;
    case "cashAtClosingLabel":
      let val;
      if (isPurchase) {
        val = data.totalClosingCost + data.loanCriteria.property.value - data.loanCriteria.loanAmount;
      } else {
        val = data.totalClosingCost - data.loanCriteria.loanAmount + data.loanCriteria.property.currentMortgageBalance;
      }
      value = val > 0 ? "From You" : "To You";
  }
  value = isNaN(value) ? value || 0 : format(value, 2);
  return new Handlebars.SafeString(value);
});
Handlebars.registerHelper("show", function (offers, fieldName) {
  if (!offers) {
    return;
  }
  const isVa = offers.some((o) => o.selectedRate.productName.includes("VA"));
  const isFha = offers.some((o) => o.selectedRate.productName.includes("FHA"));
  switch (fieldName) {
    case "mortgageInsurance":
      return offers.some(o => o.loanCriteria.isPmiEligible || isFha);
      break;
    case "attorney":
      return offers.some(o => o.closingCost.attorney > 0);
      break;
    case "survey":
      return offers.some(o => o.closingCost.survey > 0);
      break;
    case "subordinationFee":
      return offers.some(o => o.loanCriteria.secondaryFinancing != "none");
      break;
    case "condo":
      return offers.some(o => o.loanCriteria.property.type?.includes("condo"));
    case "docReviewFee":
      return offers.some(o => o.closingCost.docReview > 0);
      break;
    case "pestInspectionFee":
      return offers.some(o => o.closingCost.pestInspection);
      break;
    case "discountPoints":
      return offers.some(o => !!o.closingCost.discountPoints);
      break;
    case "fundingFee":
      return offers.some(o => isVa && !o.loanCriteria.financeFf);
      break;
    case "miPremiumAccount":
      return offers.some(o => (isFha && !o.loanCriteria.financeMip) || o.loanCriteria.isPmiEligible);
      break;
    case "financedFundingFee":
      return offers.some(o => isVa && o.loanCriteria.financeFf);
      break;
    case "financedMiPremiumAccount":
      return offers.some(o => isFha && o.loanCriteria.financeMip);
      break;
    case "lenderCredit":
      return offers.some(o => !!o.lenderCredits);
      break;
    case "isPurchase":
      return offers.some(o => o.loanCriteria.loanPurpose.toLowerCase() === "purchase");
      break;
  }
});
export function daysOfaYear(year) {
  return isLeapYear(year) ? 366 : 365;
}
function isLeapYear(year) {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}
export const IS_DEV_MODE = import.meta.env.MODE == "development";

export function smsTemplate(path, selected, user, receiver?, object?) {
  const contact = typeof selected == "string" ? receiver.find(r => r.number == selected) : selected;
  const replacement = getReplacement(contact, user, object);
  return Handlebars.compile(path)(replacement);
}

export function emailTemplate(path, user) {
  const replaceObject = {
    ...user,
    userID: user.objectId,
    firstName: user.firstName,
    lastName: user.lastName,
    userNmls: user.nmlsId,
    reviewUrl: user.reviewUrl,
    calendar: user.calendar,
    applyUrl: user.applyUrl,
    custom1: user.custom1,
    custom2: user.custom2,
    custom3: user.custom3,
    custom4: user.custom4,
    custom5: user.custom5,
    custom6: user.custom6,
    custom7: user.custom7,
    custom8: user.custom8,
    custom9: user.custom9,
    custom10: user.custom10,
    username: user.username,
    positionName: user.positionName,
    profilePicture: user.objectIcon,
    phone: formatPhoneNumber(user.phoneLines?.edges[ 0 ]?.node.number)
  };

  try {
    return Handlebars.compile(path)(replaceObject);
  } catch (ex) {
    return `<pre>${ex.message}</pre>`;
  }
}

export const readFileAsync = (file): Promise<FileReader> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      resolve(reader);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const getPrimaryBorrower = (lead) => {
  return lead?.members?.find(({ isPrimary }) => isPrimary) ?? lead?.members?.find(({ type }) => type == "borrower");
};
export const getBorrowerName = primaryBorrower => {
  return (primaryBorrower?.contact?.firstName && primaryBorrower?.contact?.lastName) ? `${primaryBorrower?.contact?.firstName} ${primaryBorrower?.contact?.middleName ?? ""} ${primaryBorrower?.contact?.lastName}` : primaryBorrower?.contact?.objectName;
};

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) {
    return arr;
  }

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[ 0 ];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const phoneNumberMatchers = (value) => {
  let safe = value?.trim() || "";

  if (value && (value.includes("+") || value.includes("(") || value.includes(")"))) {
    safe = (value).replace(/[+()]/g, "").trim();
  }
  let phone = safe;
  if (safe && (safe.includes("(") || safe.includes(" ") || safe.includes("-") || safe.includes(")"))) {
    phone = parsePhoneNumber(safe);
  }
  if (phone && phone.includes(".")) {
    phone = (phone).replace(/[.]/g, "");
  }
  return phone;
};

export const checkContactIsUnknown = (name: string) => {
  return (name?.startsWith("+1") && name?.length == 12) || !name;
};

export async function fetchJson(path: string) {
  const response = await fetch(window.__CONFIG__.api + `/${path}?v=${window.__CONFIG__.versions.web}`, {
    headers: {
      "X-RC-Cache": window.__CONFIG__.etag
    },
    credentials: "include"
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
}

export function cleanObject<T>(obj: T): T {
  for (let key in obj) {
    let val = obj[ key ] as any;
    if (typeof val == "undefined" || val === null) {
      delete obj[ key ];
    }
  }
  return obj;
}

export function applySmoothDrag (arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[ 0 ];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
