import { ModalBody }                     from "@relcu/ui";
import React                             from "react";
import { FC }                            from "react";
import { Box }                           from "@relcu/ui";
import { ModalProps }                    from "@relcu/ui";
import { Modal }                         from "@relcu/ui";
import "./distribution-stats-modal.css";
import { DistributionStatsLeads }        from "./DistributionStatsLeads";
import { DistributionStatsModalClasses } from "./DistributionStatsModalClasses";
import { DistributionStatsUsers }        from "./DistributionStatsUsers";
import { TabContainer }                  from "@relcu/ui";
import { TabPanel }                      from "@relcu/ui";
import { TabPanelContainer }             from "@relcu/ui";
import { Tabs }                          from "@relcu/ui";
import { TabItem }                       from "@relcu/ui";

export interface DistributionStatsProps extends Partial<ModalProps> {
  queue: string;
  rule:string
  strategy: string
  distributeOfflineUsers: boolean
}

export const DistributionStatsModal: FC<DistributionStatsProps> = React.memo(function DistributionStatsModal(props) {
  const { onClose, queue,rule, strategy, distributeOfflineUsers } = props;
  console.log(distributeOfflineUsers)
  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    variant="large"
    disableBackdropClick={false}
    className={DistributionStatsModalClasses.Modal}
    title={`${rule} Distribution stats`}
  >
   <ModalBody container direction={"column"} flex={1}>
     <TabContainer gap={"S"} flex={1} style={{overflow: "hidden"}}>
       <TabPanelContainer style={{overflow: "hidden"}}>
         <TabPanel >
           <DistributionStatsUsers queue={queue} strategy={strategy} onClose={onClose} distributeOfflineUsers={distributeOfflineUsers}/>
         </TabPanel>
         <TabPanel>
           <DistributionStatsLeads queue={queue} strategy={strategy} onClose={onClose}/>
         </TabPanel>
       </TabPanelContainer>
       <Tabs>
         <TabItem active={true}>
           Users
         </TabItem>
         <TabItem active={false}>
           Leads
         </TabItem>
       </Tabs>
     </TabContainer>
   </ModalBody>
  </Modal>;
});

export default DistributionStatsModal;
