import React                  from "react";
import { FC }                 from "react";
import { useState }           from "react";
import { useCallback }        from "react";
import { useMemo }            from "react";
import { useMutation }        from "@apollo/client";
import { useSelect }          from "@relcu/ui";
import { MenuItem }           from "@relcu/ui";
import { ModalProps }         from "@relcu/ui";
import { groupBy }            from "@relcu/ui";
import { Modal }              from "@relcu/ui";
import { ModalBody }          from "@relcu/ui";
import { ButtonVariants }     from "@relcu/ui";
import { Button }             from "@relcu/ui";
import { ModalFooter }        from "@relcu/ui";
import { SearchInput }        from "@relcu/ui";
import { TypographyColor }    from "@relcu/ui";
import { TypographySize }     from "@relcu/ui";
import { Typography }         from "@relcu/ui";
import { getField }           from "../../../utils/schemaUtils";
import { UPDATE_LEAD_STATUS } from "../../Layout/Header/LeadHeader/useLeadHeader";
import { CommonModalClasses } from "../CommonModal/CommonModalClasses";
import "../CommonModal/common-modal.css";

export interface BulkStatusModalProps extends Partial<ModalProps> {
  ids: string[];
  onSubmit(data);
}

export const BulkStatusModal: FC<BulkStatusModalProps> = React.memo(function BulkStatusModal(props) {
  const { onClose, onSubmit, ids } = props;
  const [updateStatus] = useMutation(UPDATE_LEAD_STATUS);
  const [loading, setLoading] = useState(false);
  const { states } = getField("Lead", "leadStatus");
  const options = useMemo(() => {
    const statuses = Object.entries<{ stage: string, status: string }>(states).map(([value, { stage, status }]) => {
      return { value, label: status, parent: stage };
    });
    const byStages = groupBy(statuses, "parent");
    const options = [];
    for (let [stage, items] of Object.entries(byStages)) {
      options.push({ value: stage, label: stage, disabled: true });
      options.push(...items);
    }
    return options;
  }, [states]);
  const {
    selected,
    setSearch,
    search,
    handleSelect,
    items
  } = useSelect({
    options,
    optionLabel: "label",
    optionKey: "value"
  });

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const promises = ids.map(async id => {
      return await updateStatus({ variables: { id, status: selected.value, action: "manual" } });
    });
    const leads = await Promise.allSettled(promises);
    const data = leads.map(lead => {
      if (lead.status == "fulfilled") {
        return lead.value.data;
      } else {
        return lead.reason.toLocaleString();
      }
    });

    setLoading(false);
    onSubmit?.(data);
  }, [selected, onSubmit, ids, setLoading]);
  return <Modal
    onClose={onClose}
    open={true}
    closeIcon={true}
    disableBackdropClick={false}
    title={"Set status"}
    className={CommonModalClasses.BulkModal}
  >
    <ModalBody container direction={"column"} flex={1} className={CommonModalClasses.Container}>
      <SearchInput value={search} onChange={setSearch}/>
      <div className={CommonModalClasses.ScrollContainer}>
        {items.map((item, index) => {
          return <MenuItem
            className={CommonModalClasses.Item}
            disabled={item.disabled}
            selected={item.value == selected?.value}
            key={index}
            onClick={(e) => {
              handleSelect(item);
            }}>
            <Typography
              size={item.disabled ? TypographySize.TextTiny : TypographySize.Text}
              color={item.disabled ? TypographyColor.Secondary : TypographyColor.ExtraDark}
            >
              {item.disabled ? item.label.toUpperCase() : item.label}
            </Typography>
          </MenuItem>;
        })}
      </div>
    </ModalBody>
    <ModalFooter borderOnTop>
      <Button variant={ButtonVariants.Outline} onClick={onClose}>CANCEL</Button>
      <Button onClick={handleSubmit} disabled={!selected || loading}>SET</Button>
    </ModalFooter>
  </Modal>;
});
