import { useReactiveVar } from "@apollo/client";
import { AlertColors }    from "@relcu/ui";
import { Alert }          from "@relcu/ui";
import React              from "react";
import { versionVar }     from "../../reactiveVars";
import "./alert-bar.css";

const version = import.meta.env.VITE_VERSION;

export const AlertBar = React.memo(function AlertBar() {
  const latestVersion = useReactiveVar(versionVar);
  const hasNewVersion = useNewVersion();
  const url = new URL(location.href);
  url.searchParams.set("version", latestVersion);
  return <>{hasNewVersion && <Alert direction={"column"} className={"alert-bar"} variant={AlertColors.Warning}>
    A new version of Relcu is available.
    <a href={url.toString()} style={{ textDecoration: "underline", cursor: "pointer" }}>
      Refresh to see the latest version.
    </a>
  </Alert>}</>;
});

export function useNewVersion() {
  const currentVersion = version || window.__CONFIG__.versions.web;
  const latestVersion = useReactiveVar(versionVar);
  return currentVersion !== latestVersion;
}
