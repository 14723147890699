import React                 from "react";
import { useMemo }           from "react";
import { classNames }        from "../../..";
import { Tooltip }           from "../../..";
import { BoxComponentProps } from "../../..";
import { Box }               from "../../..";
import { Alignment }         from "../../..";
import { DateTime }          from "luxon";
import { timeTo }            from "../../../utils/helpers";

export interface ListItemDateProps extends BoxComponentProps {
  date?: string
  format?: "onlyTime" | "withTime" | "onlyDate" | "relative" | string,
  label?: string
}

const defaultListItemDateProps: ListItemDateProps = {
  date: new Date().toISOString()
};

const dateFormats = {
  onlyTime: "h:mm a",
  withTime: "MMM dd, h:mm a",
  onlyDate: "MMM dd, kkkk"
};

const getFormat = (format) => {
  if (!format) {
    return dateFormats.onlyDate;
  } else {
    return dateFormats[ format ] || format;
  }
};

ListItemDate.defaultProps = defaultListItemDateProps;
export function ListItemDate(props: ListItemDateProps) {
  const { className, children, date, format, label, ...p } = props;
  const title = useMemo(() => format == "relative" ? DateTime.fromISO(date).toFormat(dateFormats.withTime) : "",
    [format, date]);
  const classes = classNames(ListItemDateClasses.ListItemDate, className);
  return <Box container className={classes} flexShrink={0} {...p}>
    {
      date ?
        <Tooltip title={title} alignment={Alignment.Top}>
          <time
            dateTime={date}>{format == "relative" ? timeTo(DateTime.fromISO(date)) : DateTime.fromISO(date).toFormat(getFormat(format))}</time>
        </Tooltip> :
        <small>-</small>
    }
  </Box>;
}
export enum ListItemDateClasses {
  ListItemDate = "list-item-date"
}
