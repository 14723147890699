import { FormSpy }           from "@relcu/form";
import React                 from "react";
import { FC }                from "react";
import { StepInterface }     from "@relcu/ui";
import { ToggleSwitchField } from "@relcu/ui";
import { StepContainer }     from "@relcu/ui";
import { StepHeader }        from "@relcu/ui";
import { StepSection }       from "@relcu/ui";
import { StepBody }          from "@relcu/ui";

export const Activate: FC<StepInterface> = React.memo(function Activate(props) {
  const { title, description, step } = props;
  return <StepContainer>
    <StepHeader
      step={step}
      title={title}
      description={description}
      edit={false}/>
    <StepBody>
      <FormSpy subscription={{ values: true }}>
        {({ values: { strategy } }) => {
          if (strategy == "round_robin") {
            return <StepSection title="Distribute to users who are not logged in">
              <ToggleSwitchField name={"distributeOfflineUsers"} label={"Disable/Enable"}/>
            </StepSection>;
          }
          return <>
            <StepSection title="Enable notifications for web">
              <ToggleSwitchField name={"notify"} label={"Disable/Enable"}/>
            </StepSection>
            <StepSection title="Enable notifications for mobile">
              <ToggleSwitchField name={"notifyMobile"} label={"Disable/Enable"}/>
            </StepSection>
          </>;
        }}
      </FormSpy>

      <StepSection title="Activate queue">
        <ToggleSwitchField name={"disabled"} label={"Disable/Enable"}/>
      </StepSection>
      <StepSection title="Autodial to lead when taking from distribution queue">
        <ToggleSwitchField name={"autoDial"} label={"Disable/Enable"}/>
      </StepSection>
    </StepBody>
  </StepContainer>;
});
