import { useNavigate }  from "@relcu/react-router";
import { usePhone }      from "../../../usePhone";
import { useHybridCall } from "./useHybridCall";

export function useRelcuCallActions() {
  const { active, remove, unHold, hybridCalls } = usePhone();
  const {
    onHoldCall,
    secondActiveCall,
    contactCall,
    activeCalls
  } = useHybridCall();
  const navigate = useNavigate();

  const handleAccept = () => {
    active.accept();
    if (hybridCalls?.initialDisplayCall?.leadId) {
      navigate(`/lead/${hybridCalls?.initialDisplayCall.leadId}/details`);
    } else if (contactCall) {
      navigate(`/contact/${contactCall.objectId}/calls`);
    }
  };

  const handleToggleHold = () => {
    if (activeCalls?.[ 0 ]) {
      unHold(activeCalls[ 0 ]?.call, !activeCalls[ 0 ]?.hold);
    }
  };

  const handleDropOrRemove = () => {
    if (onHoldCall) {
      remove(secondActiveCall.call, onHoldCall.call);
    } else {
      active.drop();
    }
  };

  return {
    handleAccept,
    handleDropOrRemove,
    handleToggleHold
  };
}
