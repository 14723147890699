import React                     from "react";
import { useCallback, useMemo }  from "react";
import { useState }              from "react";
import { DateTime }              from "luxon";
import { classNames }            from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { Widget }                from "@relcu/ui";
import { useInterval }           from "@relcu/ui";
import { useSource }             from "@relcu/ui";
import { TimezoneWidgetClasses } from "./TimezoneWidgetClasses";
import "./timezone-widget.css";

export const TimezoneWidget = React.memo(function TimezoneWidget() {
  const { $object: node } = useSource();
  const timezone = useMemo(() => {
    return node?.timezone;
  }, [node?.timezone]);
  const state = useMemo(() => {
    return node?.property?.propertyAddress?.state;
  }, [node?.property?.propertyAddress?.state]);
  const [formattedTime, setFormattedTime] = useState(DateTime.local().setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE));

  useInterval(useCallback(() => {
    setFormattedTime(DateTime.local().setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE));
  }, [timezone]), 1000);

  if (!timezone && !state) {
    return null;
  }

  return (
    <Widget direction={"unset"} className={TimezoneWidgetClasses.TimezoneWidget}>
      <Box container direction={"column"} justify={"space-between"} flex={1}>
        <span
          className={classNames(TimezoneWidgetClasses.TimezoneWidgetHeader, TimezoneWidgetClasses.TimezoneWidgetHeaderState)}>
          {timezone}
        </span>
        <span className={TimezoneWidgetClasses.TimezoneWidgetInfo}>
            <span>{formattedTime}</span>
        </span>
      </Box>
      {
        state &&
        <Box container direction={"column"} justify={"space-between"}>
        <span className={TimezoneWidgetClasses.TimezoneWidgetHeader}>
          Property State
        </span>
          <Box container justify={"end"} alignItems={"center"} className={TimezoneWidgetClasses.TimezoneWidgetInfo}>
            <span> {state}</span>
          </Box>
        </Box>
      }
    </Widget>
  );
});
