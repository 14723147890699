import { useLazyQuery }         from "@apollo/client";
import { gql }                  from "@apollo/client";
import { LazyQueryResultTuple } from "@apollo/client/react/types/types";
import { useImperativeHandle }  from "react";
import { useRef }               from "react";
import { useEffect }            from "react";
import React                    from "react";
import { CircularLoader }       from "@relcu/ui";
import { RenderEmailVariables } from "./__types__/RenderEmail";
import { RenderEmail }          from "./__types__/RenderEmail";
import Email                    from "./Email";

interface BaseProps {
  templateId?: string;
  template?: string;
  fromId?: string;
  loanProposalId?: string;
  toEmails?: string[];
  leadId?: string;
  contactId?: string;
  onRender?: (o: { html: string, subject: string }) => void;
}
interface EmailRendererIdProps extends BaseProps {
  templateId: string;
}
interface EmailRendererProps extends BaseProps {
  template: string;
}
export interface EmailRendererRef {
  html: string;
  text: string;
  subject: string;
  visualizer: HTMLDivElement;
}
export const useMailRenderer = ():LazyQueryResultTuple<RenderEmail, RenderEmailVariables>=>{
  const [load, result] = useLazyQuery<RenderEmail, RenderEmailVariables>(RENDER_EMAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only"
  });
  return [load,result]
}
export const EmailRenderer = React.forwardRef<EmailRendererRef, EmailRendererProps | EmailRendererIdProps>(function EmailRenderer({ onRender, template, templateId, fromId, toEmails, leadId, loanProposalId, contactId }, ref) {
  const [load, result] = useMailRenderer()
  const emailRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (!template && !templateId) {
      return;
    }
    let input: RenderEmailVariables["input"] = {
      from: fromId,
      to: toEmails,
      contact: contactId || undefined,
      scope: leadId || undefined,
      proposal: loanProposalId
    };
    if (template) {
      input.template = template;
    } else if (templateId) {
      input.templateId = templateId;
    }
    load({ variables: { input } }).then((re) => {
      onRender?.(re.data.renderMail);
    });
  }, [template, templateId, fromId, toEmails, contactId, leadId, loanProposalId]);
  useImperativeHandle(ref, () => {
    return {
      get html() {
        return result.data?.renderMail?.html;
      },
      get text() {
        return result.data?.renderMail?.text;
      },
      get subject() {
        return result.data?.renderMail?.subject;
      },
      get visualizer() {
        return emailRef.current;
      }
    };
  }, [result.data, emailRef.current]);

  return (
    <>
      {
        result.loading ? <CircularLoader justify={"center"} alignItems={"start"}
                                         style={{ minHeight: `${emailRef.current?.offsetHeight}px` }}/> :
          <Email ref={emailRef} template={result.data?.renderMail?.html || result.error?.message}/>
      }
    </>
  );
});
const RENDER_EMAIL_QUERY = gql`
  query RenderEmail($input:MailRenderInput){
    renderMail(input:$input)
  }
`;
