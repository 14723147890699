import React                                                from "react";
import { useQuery }                                         from "@apollo/client";
import { gql }                                              from "@apollo/client";
import { Link }                                             from "@relcu/ui";
import { CircularLoader }                                   from "@relcu/ui";
import { useSource }                                        from "@relcu/ui";
import { TypographyColor }                                  from "@relcu/ui";
import { TypographyLine }                                   from "@relcu/ui";
import { FontIcon }                                         from "@relcu/ui";
import { CommonClasses }                                    from "@relcu/ui";
import { TimelineItemBody }                                 from "@relcu/ui";
import { Box }                                              from "@relcu/ui";
import { TimelineItem }                                     from "@relcu/ui";
import { TimelineItemHeaderContent }                        from "@relcu/ui";
import { Typography }                                       from "@relcu/ui";
import { TimelineItemSubject }                              from "@relcu/ui";
import { TimelineItemIconColors }                           from "@relcu/ui";
import { TimelineItemIcon }                                 from "@relcu/ui";
import { PhoneMessageActivity_phoneMessageFrom }            from "../../../../__types__/PhoneMessageActivity";
import { PhoneMessageActivity as PhoneMessageActivityType } from "../../../../__types__/PhoneMessageActivity";
import { TimelineViewClasses }                              from "../TimelineViewClasses";
import { GetActivityPhoneMessageVariables }                 from "./__types__/GetActivityPhoneMessage";
import { GetActivityPhoneMessage }                          from "./__types__/GetActivityPhoneMessage";

export interface SMSActivityProps extends PhoneMessageActivityType {
  createdAt: string;
  initiatorObjectId: string
}

export const PhoneMessageActivity = React.memo<SMSActivityProps>(function PhoneMessageActivity(props) {
  const { createdAt, phoneMessageFrom, initiatorObjectId, bulkConversation, phoneMessageTo, phoneMessageSubject, phoneMessage, phoneMessageAttachments } = props;
  const { errorCode, content, objectId, loanEstimate } = Object(phoneMessage);
  const { $object, $viewer } = useSource();
  const onInitiatorsPage = !!(phoneMessageFrom && ($object.objectId === phoneMessageFrom.objectId || ($object.__typename === "Lead" && phoneMessageFrom.__typename === "Contact")));
  const withError = !!($object.__typename === "User" && errorCode && $object.objectId === phoneMessageFrom.objectId);
  const expandable = $viewer.role=="admin"
    || $object.__typename=="Lead" || [initiatorObjectId, phoneMessageFrom.objectId, phoneMessageTo.objectId].includes($viewer.objectId);

  const navigateTo = (obj) => {
    if (obj.__typename == "User") {
      if (bulkConversation) {
        return `/user/${obj.objectId}/sms/${bulkConversation.objectId}`;
      }
      return `/user/${obj.objectId}/sms${(objectId && phoneMessage.phoneMessageConversation) ? `/${phoneMessage.phoneMessageConversation.objectId}/${objectId}` : ""}`;
    } else if (phoneMessageSubject?.objectId) {
      return `/lead/${phoneMessageSubject.objectId}/sms${objectId ? `/${objectId}` : ""}`;
    } else {
      return `/contact/${obj.objectId}/sms${objectId ? `/${objectId}` : ""}`;
    }
  };

  return <TimelineItem
    primary={
      !withError ?
        <TimelineItemSubject>
          {
            onInitiatorsPage ?
              <>
                {
                  phoneMessageFrom?.objectId ?
                    <Link
                      to={navigateTo(phoneMessageFrom)}>
                      <Typography
                        className={CommonClasses.ClickableIcon}>{phoneMessageFrom.objectName || "Unknown"}</Typography>
                    </Link>
                    :
                    <Typography>{phoneMessageFrom?.objectName || "Unknown"}</Typography>
                }
                <Typography color={TypographyColor.Secondary}>sent</Typography>
                <Typography>SMS</Typography>
                {loanEstimate && <>
                  <Typography color={TypographyColor.Secondary}>with</Typography>
                  <Typography>a loan proposal</Typography>
                </>}
                <Typography color={TypographyColor.Secondary}>to</Typography>
                {
                  phoneMessageTo?.objectId ?
                    <Link
                      to={navigateTo(phoneMessageTo)}>
                      <Typography
                        className={CommonClasses.ClickableIcon}>{phoneMessageTo.objectName || "Unknown"}</Typography>
                    </Link>
                    :
                    <Typography>{phoneMessageTo?.objectName || "Unknown"}</Typography>
                }
              </> :
              <>
                {
                  phoneMessageTo?.objectId ?
                    <Link
                      to={navigateTo(phoneMessageTo)}>
                      <Typography
                        className={CommonClasses.ClickableIcon}>{phoneMessageTo.objectName || "Unknown"}</Typography>
                    </Link>
                    :
                    <Typography>{phoneMessageTo?.objectName || "Unknown"}</Typography>
                }
                <Typography color={TypographyColor.Secondary}>received</Typography>
                <Typography>SMS</Typography>
                {loanEstimate && <>
                  <Typography color={TypographyColor.Secondary}>with</Typography>
                  <Typography>a loan proposal</Typography>
                </>}
                <Typography color={TypographyColor.Secondary}>from</Typography>
                {
                  phoneMessageFrom?.objectId ?
                    <Link
                      to={navigateTo(phoneMessageFrom)}>
                      <Typography
                        className={CommonClasses.ClickableIcon}>{phoneMessageFrom.objectName || "Unknown"}</Typography>
                    </Link>
                    :
                    <Typography>{phoneMessageFrom?.objectName || "Unknown"}</Typography>
                }
              </>
          }
        </TimelineItemSubject>
        :
        <TimelineItemSubject>
          <Box container alignItems={"center"} gap={"XXXS"} className={TimelineViewClasses.TimelineViewSMSFailed}>
            <FontIcon type={"info"}/>
            <Typography color={TypographyColor.Error}> Message not delivered </Typography>
          </Box>
          {
            phoneMessageFrom?.objectId ?
              <Link
                to={navigateTo(phoneMessageFrom)}>
                <Typography
                  className={CommonClasses.ClickableIcon}>{phoneMessageFrom.objectName || "Unknown"}</Typography>
              </Link>
              :
              <Typography>{phoneMessageFrom?.objectName || "Unknown"}</Typography>
          }
          <Typography
            color={TypographyColor.Secondary}>{`failed to send SMS ${loanEstimate ? "with a loan proposal" : ""} to`}</Typography>
          {
            phoneMessageTo?.objectId ?
              <Link
                to={navigateTo(phoneMessageTo)}>
                <Typography
                  className={CommonClasses.ClickableIcon}>{phoneMessageTo.objectName || "Unknown"}</Typography>
              </Link>
              :
              <Typography>{phoneMessageTo?.objectName || "Unknown"}</Typography>
          }
        </TimelineItemSubject>
    }
    secondary={
      !errorCode &&
      <TimelineItemHeaderContent withAttachment={!!phoneMessageAttachments}>
        {content}
      </TimelineItemHeaderContent>
    }
    creationDate={createdAt}
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Primary}
                              icon={bulkConversation ? "rc_bulk_sms" : "forum"}/>}
  >
    {expandable && <PhoneMessageActivityBody
      phoneMessageFrom={phoneMessageFrom}
      objectId={phoneMessage?.objectId}/>}
  </TimelineItem>;
});

export interface PhoneMessageActivityBodyProps {
  objectId: string;
  phoneMessageFrom: PhoneMessageActivity_phoneMessageFrom;
}

export const PhoneMessageActivityBody = React.memo<PhoneMessageActivityBodyProps>(function PhoneMessageActivityBody(props) {
  const { objectId } = props;
  const { data: { phoneMessage } = {}, loading } = useQuery<GetActivityPhoneMessage, GetActivityPhoneMessageVariables>(GET_ACTIVITY_PHONE_MESSAGE, { variables: { id: objectId } });

  if (loading) {
    return <Box container direction={"column"} alignItems={"center"} gap={"XS"}>
      <CircularLoader/>
    </Box>;
  }
  const { attachments, content } = Object(phoneMessage);
  return <Box container gap={"XXS"}>
    <TimelineItemBody>
      <Typography lineHeight={TypographyLine.Line17}>
        {content}
      </Typography>
      {!!attachments?.length && <Box container gap={"XXXS"} alignItems={"center"}>
        <FontIcon style={{ fontSize: 20 }} type={"attachment"} className={CommonClasses.GrayIcon}/>
        <Typography color={TypographyColor.Secondary}>{attachments.length} attachments</Typography>
      </Box>}
    </TimelineItemBody>
  </Box>;
});

const GET_ACTIVITY_PHONE_MESSAGE = gql`
  query GetActivityPhoneMessage($id:ID!){
    phoneMessage(id:$id){
      content
      errorCode
      sid
      objectId
      attachments {
        url
      }
    }
  }
`;
