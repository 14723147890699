import React           from "react";
import { FC }          from "react";
import { useRef }      from "react";
import { ModalProps }  from "@relcu/ui";
import { Modal }       from "@relcu/ui";
import { MailMessage } from "../../../../../graph/__types__/MailMessage";
import { Compose }     from "../Content/Compose/Compose";

export interface ComposeModalProps extends Partial<ModalProps> {
  initialData: any;
  draftId: string;
  draftReplyToMessage?: MailMessage;
  onShrink?(data: any);
}

export const ComposeModal: FC<ComposeModalProps> = React.memo(function ComposeModal(props) {
  const { initialData, onShrink, draftId, draftReplyToMessage, onClose } = props;
  const ref = useRef(null);

  const handleClose = (data?) => {
    onShrink(data ?? ref.current?.getState()?.values);
    props.onClose();
  };

  return <Modal
    onClose={handleClose}
    open={true}
    variant="big"
    disableBackdropClick={true}
    style={{ height: "90%" }}
    title={initialData.subject}>
    <Compose
      mode={"expanded"}
      initialValues={initialData}
      draftReplyToMessage={draftReplyToMessage}
      draftId={draftId}
      ref={ref}
      onSubmit={onClose}
      onDelete={onClose}
    />
  </Modal>;
});

export default ComposeModal;
