import { FC }                         from "react";
import React, { useEffect, useState } from "react";
import { ReactNode }                  from "react";
import { Dispatch }                   from "react";
import { SetStateAction }             from "react";
import { useQuery }                 from "@apollo/client";
import { BadgeColors, StatusBadge } from "@relcu/ui";
import { BadgeAlignment }           from "@relcu/ui";
import { MenuItem }                 from "@relcu/ui";
import { Avatar }                   from "@relcu/ui";
import { Tooltip }                  from "@relcu/ui";
import { BoxComponentProps }        from "@relcu/ui";
import { GlobalClasses }            from "@relcu/ui";
import { AvatarSizes }              from "@relcu/ui";
import { NavBarItemAction }         from "@relcu/ui";
import { NavBarItemContent }        from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { useDroppable }             from "@relcu/ui";
import { FontIcon }                 from "@relcu/ui";
import { NavBarItem }               from "@relcu/ui";
import { classNames as clsNames }   from "@relcu/ui";
import { useDraggable }             from "@relcu/ui";
import { NavBarItemMenu }           from "@relcu/ui";
import { VIEWER_USER_NODE }         from "../../graph/operations.graphql";
import { NavBarClasses }            from "./NavBarClasses";

export enum Half {
  TOP = "TOP",
  BOTTOM = "BOTTOM"
}

export interface NavBarContainerProps extends BoxComponentProps {
  effectAllowed: "copy" | "move" | "link" | "none" | "copyMove" | "copyLink" | "linkMove" | "all",
  dropEffect: "copy" | "move" | "link" | "none",
  acceptFrom?: string[],
  droppableId: string,
  objectTypes?: string[], //file format and our object types, //todo Files
  data: any[],
  acceptFiles?: boolean,
  header?: ReactNode,
  small?: boolean,
  onChange: Dispatch<SetStateAction<any>>,
  empty: ReactNode
  onVisibility?(item),
  onRemove?(item),
}

export const NavBarContainer: FC<NavBarContainerProps> = React.memo(function NavBarContainer(props) {
  const { className, children, data, acceptFrom, acceptFiles, dropEffect, effectAllowed, objectTypes, droppableId, header, onVisibility, onRemove, small, empty, ...p } = props;
  const [enter, setEnter] = useState(false);
  const [active, setActive] = useState(null);
  const items = [...data];
  const setItems = props.onChange;
  const [overItem, setOverItem] = useState<Element>(null);
  const [overItemHalf, setOverItemHalf] = useState<Half>(Half.TOP);
  const [dropFrom, setDropFrom] = useState<string[]>([]);
  const [overContainer, setOverContainer] = useState<Element>(null);
  const [draggableItem, setDraggableItem] = useState<Element>(null);
  const { data: { viewer: { user } }} = useQuery(VIEWER_USER_NODE, { fetchPolicy: "cache-only" });

  const classNames = clsNames(NavBarClasses.NavMenuContainer, {
    [ NavBarClasses.Empty ]: items.length == 0
  }, className);

  const findDraggableParent = (target, current?) => {
    while (target) {
      if (current && target == current) {
        return false;
      }

      if (target !== document && target.getAttribute("draggable") == "true") {//document check made for tooltip case
        return target;
      }

      target = target.parentNode;
    }
    return false;
  };//TODO check target equal currentTarget case

  const arrayReorder = (arr: any[], oldIndex: number, newIndex: number): any[] => {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[ 0 ]);
    return arr;
  };

  const getPlaceHolder = () => {
    let placeHolder: any = document.getElementById("tmp-placeholder");
    placeHolder.classList.remove(GlobalClasses.VisuallyHidden);
    return placeHolder;
  };

  const clearState = (): void => {
    setEnter(false);
    setOverItem(null);
    setOverContainer(null);
  };

  const parseId = (id: string): string[] => {
    return id.split("-");
  };

  const checkOverItemHalf = (bounding: DOMRect, mouseY: number): Half => {
    return Math.abs(bounding.top - mouseY) > Math.abs(bounding.bottom - mouseY) ? Half.BOTTOM : Half.TOP;
  };

  const onDrop = (data, e): void => {
    setDropFrom(e.dataTransfer.types); // to check if drop is reorder on same container
    let newItems = [];
    let placeHolderElement = document.getElementById("tmp-placeholder");

    if (placeHolderElement.previousElementSibling && placeHolderElement.previousElementSibling.id) {//todo try to change placeholder element to overItem element
      let objectData = parseId(placeHolderElement.previousElementSibling.id);
      data.map(d => {
        let index = items.findIndex(i => i.id == d.id && i.type == d.type);
        if (index > -1) { //reordering case
          let newIndex = items.findIndex(i => i.type == objectData[ 0 ] && i.id == objectData[ 1 ]);
          newItems = arrayReorder(items, index, (index > newIndex ? newIndex + 1 : newIndex));
        } else { // new add case
          items.map(item => {
            if (item.type == objectData[ 0 ] && item.id == objectData[ 1 ]) {
              newItems.push(item, d);
            } else {
              newItems.push(item);
            }
          });
        }
      });
    } else {
      newItems = data;
      items.map(item => {
        if (!newItems.find(n => item.id == n.id && item.type == n.type)) {
          newItems.push(item);
        }
      });
    }

    // setOverContainer(null);
    removePlaceHolder();
    clearState();
    setItems(newItems);
  };

  const onEnter = (e): void => {
    if (!checkDroppableArea(e) && !enter) { //check if Enter container
      setEnter(true);
    }
  };

  const onOver = (e): void => {
    if (e.target == e.currentTarget) { // container over case
      if (!overContainer || e.currentTarget != overContainer) {
        setOverContainer(e.currentTarget);
      }
    } else {// item over case
      let dragOverItem = findDraggableParent(e.target, e.currentTarget);
      if ((!overItem || (overItem && dragOverItem != overItem)) && dragOverItem && dragOverItem.id != undefined) {
        setOverItem(dragOverItem);
      }

      if (dragOverItem.id != undefined) {//set over item only if event not in placeholder
        let half: Half = checkOverItemHalf(e.target.getBoundingClientRect(), e.clientY);

        if (half != overItemHalf) {
          setOverItemHalf(half);
        }
      }
    }
  };

  const onLeave = (e): void => {
    if (checkDroppableArea(e) && enter) {//check if leave container
      clearState();
    }
  };

  const onDragEnd = (e): void => {
    if (e.dataTransfer.dropEffect == "move" && !dropFrom.find(type => type.includes(droppableId))) {
      let draggedItem = findDraggableParent(e.target);
      let itemData = parseId(draggedItem.id);
      setItems(items.filter(i => i.type != itemData[ 0 ] || i.id != itemData[ 1 ]));
    }

    setDropFrom([]);
    clearState();
  };

  const onDragStart = (e): void => {
    let draggingItem = findDraggableParent(e.target);
    // makePlaceHolder();
    makePlaceHolder(document.importNode(draggingItem, true), small);
    if (!draggableItem || (draggingItem.id != draggableItem.id)) {//in order not to show placeholder on draggable element dragover
      setDraggableItem(draggingItem);
    }
  };

  useEffect(() => {
    removePlaceHolder();
    if (overItem != null && (!draggableItem || draggableItem.id != overItem.id)) {//second part added for checking if draggable item exist in droppable container(in order not to show placeholder on draggable element drag over)
      let placeholder = getPlaceHolder();
      if (overItemHalf == Half.TOP) {
        overItem.parentNode.insertBefore(placeholder, overItem);
      } else {
        overItem.parentNode.insertBefore(placeholder, overItem.nextElementSibling);
      }
    }
  }, [overItem, overItemHalf]);

  useEffect(() => {
    removePlaceHolder();
    if (overContainer != null) {
      let placeholder = getPlaceHolder();
      overContainer.appendChild(placeholder);
    }
  }, [overContainer]);

  const renderer = (item) => {
    const draggableProps: any = useDraggable({
      item: { ...item, objectType: item.type },
      draggable: true,
      effectAllowed: "move",
      droppableId,
      onDragEndCb: (e) => onDragEnd(e),
      onDragStartCb: (e) => onDragStart(e),
      id: `${item.type}-${item.id}`
    });

    return <NavBarItem active={active === item.id} small={small} color={item.color} key={`${item.type}-${item.id}`}
                       to={item.to}
                       count={item.count} {...draggableProps}>
      {item.type == "User" ?
        <StatusBadge status={item.status}>
          <Avatar icon={(item.icon || item.image)} text={item.name}
                  size={AvatarSizes.Small}/>
        </StatusBadge> :
        small && (item.count > 0) ?
          <StatusBadge status={null} color={item.color ? BadgeColors.Error : BadgeColors.Primary}
                       alignment={BadgeAlignment.TopRight}>
            <Avatar icon={(item.icon || item.image)}
                    text={((item.type == "Lead") ? (item.name || "").split(" - ")[ 0 ].replaceAll("  ", " ") : item.name)}
                    size={AvatarSizes.Small}/>
          </StatusBadge>
          :
          <Avatar icon={(item.icon || item.image)}
                  text={((item.type == "Lead") ? (item.name || "").split(" - ")[ 0 ] : item.name)}
                  size={AvatarSizes.Small}/>
      }
      <NavBarItemContent flex={1} direction={"column"} style={{ overflow: "hidden" }} gap={"XXXXS"}>
        <span
          data-self={item.id == user.id ? " (You)" : ""}
          className={NavBarClasses.NavMenuContentTitle}>
          {item.name}
        </span>
        <Box container alignItems={"center"} gap={"XXXS"}>
          <span className={NavBarClasses.NavMenuContentType}>{item.type}</span>
          {item.deactivated && <span className={NavBarClasses.NavMenuContentDeactivated}>Deactivated account</span>}
        </Box>
      </NavBarItemContent>
      {
        small
          ?
          <NavBarItemMenu
            onToggle={(id) => setActive(id)}
            active={active == item.id}
            id={item.id}
            droppableId={droppableId}>
            <MenuItem onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onVisibility(item);
            }} icon={droppableId == "watch" ? "rc_unpin" : "rc_pin"}>
              {droppableId == "watch" ? "Unpin" : "Pin"}
            </MenuItem>
            {
              droppableId == "current" &&
              <MenuItem onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onRemove(item);
              }} icon={"clear"}>
                Remove
              </MenuItem>
            }
          </NavBarItemMenu>
          :
          <NavBarItemAction>
            <FontIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onVisibility(item);
              }}
              type={droppableId == "watch" ? "rc_unpin" : "rc_pin"}
            />
            {
              droppableId == "current" && (
                <FontIcon
                  type="clear"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove(item);
                  }}
                />
              )
            }
          </NavBarItemAction>
      }
    </NavBarItem>;//todo need to discuss about using droppableId for switch component parts with each other
  };

  const itemRenderer = (item) => {
    return (
      small
        ?
        <Tooltip key={item.id} title={item.name}>
          {renderer(item)}
        </Tooltip>
        :
        renderer(item)
    );
  };

  const contentRender = () => {
    if (items.length == 0 && !enter) {
      return empty;
    } else if (items.length > 0) {
      return items.map(item => itemRenderer(item));
    }
  };

  const droppableProps = useDroppable({ acceptFrom, acceptFiles, dropEffect, objectTypes, onDropCb: onDrop, onOverCb: onOver, onLeaveCb: onLeave, onEnterCb: onEnter });
  return <Box direction={"column"} container className={classNames} {...p}>
    {
      header
    }
    <Box direction={"column"} container {...droppableProps} className={NavBarClasses.NavMenuContent}>
      {contentRender()}
    </Box>
  </Box>;
});
NavBarContainer.defaultProps = {
  onVisibility: noop,
  onRemove: noop,
  small: false
};
function noop() {
}

const makePlaceHolder = (element?, small?): void => {
  let placeHolderElement: any = document.getElementById("tmp-placeholder");
  if (!placeHolderElement) {
    placeHolderElement = document.createElement("div");
    placeHolderElement.id = "tmp-placeholder";
    placeHolderElement.className = `box-item box-row ${NavBarClasses.PlaceHolder} ${small ? NavBarClasses.Small : null} ${GlobalClasses.VisuallyHidden}`;
  }

  if (element) {
    element.setAttribute("draggable", false);
    delete element.id;

    if (placeHolderElement.firstChild) {
      placeHolderElement.removeChild(placeHolderElement.firstChild);
    }

    placeHolderElement.appendChild(element);
  }

  document.body.appendChild(placeHolderElement);
};

const checkDroppableArea = (e, container = true): boolean => {
  let mouseX = e.clientX;
  let mouseY = e.clientY;
  let bounding = container ? e.currentTarget.getBoundingClientRect() : e.target.getBoundingClientRect();
  return (mouseY > bounding.top + bounding.height || mouseY < bounding.top) || (mouseX > bounding.left + bounding.width || mouseX < bounding.left);
};

const removePlaceHolder = (): void => {
  let placeholder = document.getElementById("tmp-placeholder");
  if (placeholder) {
    placeholder.classList.add(GlobalClasses.VisuallyHidden);
    document.body.appendChild(placeholder);
  }
};
