import React                          from "react";
import { FC }                         from "react";
import { useCallback }                from "react";
import { useContext }                 from "react";
import { useMemo }                    from "react";
import { useEffect }                  from "react";
import { Whisper }                    from "@relcu/rc";
import { Icon }                       from "@relcu/rc";
import { Tooltip as RsTooltip }       from "@relcu/rc";
import { useNavigate }                from "@relcu/react-router";
import { Alignment }                  from "@relcu/ui";
import { Badge }                      from "@relcu/ui";
import { BadgeAlignment }             from "@relcu/ui";
import { BadgeColors }                from "@relcu/ui";
import { BadgeVariants }              from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { Button }                     from "@relcu/ui";
import { ButtonColors }               from "@relcu/ui";
import { ButtonVariants }             from "@relcu/ui";
import { classNames }                 from "@relcu/ui";
import { CommonClasses }              from "@relcu/ui";
import { DropDown }                   from "@relcu/ui";
import { FontIcon }                   from "@relcu/ui";
import { IColumn }                    from "@relcu/ui";
import { Pagination }                 from "@relcu/ui";
import { PaginationProps }            from "@relcu/ui";
import { Table as RCTable }           from "@relcu/ui";
import { TableProps as RCTableProps } from "@relcu/ui";
import { toFirstLower }               from "@relcu/ui";
import { Tooltip }                    from "@relcu/ui";
import { Typography }                 from "@relcu/ui";
import { TypographyColor }            from "@relcu/ui";
import { TypographySize }             from "@relcu/ui";
import { TypographyWeight }           from "@relcu/ui";
import { useAlert }                   from "@relcu/ui";
import { useSource }                  from "@relcu/ui";
import JsonPath                       from "jsonpath";
import { SortType }                   from "rsuite-table";
import { getHumanized }               from "../../utils/schemaUtils";
import { MailboxDialogClasses }       from "../Layout/Dialog/MailboxDialog/MailboxDialogClasses";
import { TableViewFragment }          from "../Layout/View/TableView/__types__/TableViewFragment";
import { usePhone }                   from "../usePhone";
import "./table.css";
import { TableClasses }               from "./TableClasses";
import { useTable }                   from "./useTable";
import { UseTableOptions }            from "./useTable";

export interface TableProviderProps extends UseTableOptions {
  className?: string;
}

export const TableProvider: FC<TableProviderProps> = React.memo(function Table(props) {
  const context = useTable(props);
  return (
    <div style={{ height: context.tableHeight }} className={classNames(TableClasses.Table, props.className)}>
      <TableContext.Provider value={context}>
        {context.quickSendMailModalContext}
        {context.quickSendSmsModalContext}
        {context.bulkUserAssignModalContext}
        {context.quickCreateTaskModalContext}
        {context.bulkStatusModalContext}
        {context.bulkSendPhoneMessageModalContext}
        {context.bulkSendMailModalContext}
        {context.statusModalContext}
        {context.modalContext}
        {props.children}
      </TableContext.Provider>
    </div>
  );
});
export const TableContainer: FC = React.memo(function TableContainer(props) {
  return (
    <div className={TableClasses.Container}>
      {props.children}
    </div>
  );
});
export interface TableColumn extends Omit<IColumn, "sortable"> {
  sortable?: boolean | string;
}
export interface TableProps {
  onColumnsChange?: (columns: RCTableProps["columns"]) => void;
  availableColumns?: TableColumn[];
  columns: TableColumn[];
  order?: string;
  draggable?: boolean;
  onSort?: (dataKey: string, sortType?: SortType) => void;
  clickableRow?: boolean;
  getCellValue?: (rowData, dataKey: string) => any;
}
export const Table = React.forwardRef<any, TableProps>(function Table(props, ref) {
  const { order, availableColumns, draggable, columns, clickableRow = true } = props;
  const context = useContext(TableContext);
  const phone = usePhone();
  const navigate = useNavigate();
  const onRowClick = useCallback((obj, event) => {
    if (event.target.type == "button" || event.target.parentElement.type == "button" || !clickableRow) {
      return;
    }//todo fix this sheet

    if (obj.__typename == "Loan") {
      navigate(`/lead/${obj.lead.objectId}/loan`);
    } else {
      navigate(`/${obj.__typename.toLowerCase()}/${obj.objectId}`);
    }
  }, []);
  const inCallRow = (rowData) => {
    return phone?.hybridCalls?.initialDisplayCall?.leadId && phone.hybridCalls.initialDisplayCall.leadId == rowData?.objectId;
  };
  const getCellValue = (row, column) => {
    const normColumn = /^\$/.test(column) ? column : `$.${column}`;
    return getHumanized(row.__typename, column, JsonPath.value(row, normColumn));
  };
  const sortColumn = useMemo(() => {
    if (order) {
      const sort = order.split("_")[ 0 ];
      const key = availableColumns.find(c => c.sortable == sort)?.key;
      return key ?? sort;
    }

  }, [order, availableColumns]);
  const sortType = useMemo(() => {
    if (order) {
      return order.split("_")[ 1 ].toLowerCase() as SortType;
    }
  }, [order]);
  const onSort = useCallback((field, type) => {
    const fieldConfig = availableColumns.find(c => c.key == field);
    const sortField = typeof fieldConfig.sortable == "string" ? fieldConfig.sortable : fieldConfig.key;
    props.onSort?.(sortField, type);
  }, [props.onSort, availableColumns]);

  useEffect(() => {
    if (!context.bulkActions) {
      context.setSelectedRows([])
    }
  }, [context.bulkActions])

  return (
    <RCTable
      className={TableClasses.Body}
      selectedRows={context.selectedRows}
      onRowSelection={context.bulkActions?.length ? context.setSelectedRows : null}
      ref={ref}
      translate3d={false}
      loading={context.loading}
      data={context.data}
      rowClassName={(rowData) => {
        return classNames({
          "table__row--selected": context.selectedRows.some(item => item === rowData?.id || item === "*"),
          "table__row--highlighted": rowData?.notifications,
          "table__row--in-call": inCallRow(rowData)
        });
      }}
      onRowClick={onRowClick}
      getCellValue={props.getCellValue || getCellValue}
      onColumnsChange={props.onColumnsChange}
      actions={context.actions}
      sortType={sortType}
      sortColumn={sortColumn}
      onSortColumn={onSort}
      standardColumn={!draggable}
      columns={columns as IColumn[]}
    />
  );
});
export const TableSubheader = React.memo<{ title: string, name?: string }>(function TableSubheader({ title, name }) {
  const context = useContext(TableContext);
  const count = useMemo(() => context.selectedRows.some(item => item === "*") ? context.total : context.selectedRows.length, [context.total, context.selectedRows]);
  const { success, error } = useAlert();
  const handleSelectAllRows = useCallback(() => {
    context.setSelectedRows(["*"]);
  }, [context]);
  const handleBulkActionCLick = useCallback(async (option) => {
    switch (option.type) {
      case "LeadStatus":
        const { destroy: destroyLeadStatusModal } = context.bulkStatusModal({
          ids: context.selectedRows,
          onSubmit(leads) {
            destroyLeadStatusModal();
            const failed = leads.filter(d => typeof d == "string");
            const succeed = leads.length - failed.length;

            if (failed.length) {
              error(`${failed.length} lead${failed.length > 1 ? "s" : ""} status change have failed.`);
            }

            if (succeed) {
              success(`Status changed for ${succeed} lead${succeed > 1 ? "s" : ""} successfully.`);
            }
          }
        });
        break;
      case "AssignUser":
        const { destroy: destroyAssignModal } = context.bulkUserAssignModal({
          ids: context.selectedRows,
          destroyHandler: () => {
            destroyAssignModal();
          },
          onSubmit(resolves) {
            const failed = resolves.filter(d => typeof d == "string");
            const succeed = resolves.length - failed.length;

            if (failed.length) {
              error(`${failed.length} lead${failed.length > 1 ? "s" : ""} assign have failed.`);
            }

            if (succeed) {
              success(`${succeed} lead${succeed > 1 ? "s" : ""} were assigned successfully.`);
            }
          }
        });
        break;
      case "Mail":
        const { destroy: destroyMailModal } = context.bulkSendMailModal({
          variables: context.variables,
          ids: context.selectedRows,
          onSubmit(resolves) {
            destroyMailModal();
            const failed = resolves.filter(d => typeof d == "string");
            const succeed = resolves.length - failed.length;

            if (failed.length) {
              error(`${failed.length} lead${failed.length > 1 ? "s" : ""} assign have failed.`);
            }

            if (succeed) {
              success(`${succeed} lead${succeed > 1 ? "s" : ""} were assigned successfully.`);
            }
          }
        });
        break;
      case "SMS":
        const { destroy: destroyPhoneMessageModal } = context.bulkSendPhoneMessageModal({
          variables: context.variables,
          ids: context.selectedRows,
          onSubmit(resolves) {
            destroyPhoneMessageModal();
            const failed = resolves.filter(d => typeof d == "string");
            const succeed = resolves.length - failed.length;

            if (failed.length) {
              error(`${failed.length} lead${failed.length > 1 ? "s" : ""} assign have failed.`);
            }

            if (succeed) {
              success(`${succeed} lead${succeed > 1 ? "s" : ""} were assigned successfully.`);
            }
          }
        });
        break;
    }
  }, [context.selectedRows]);
  if (!context.selectedRows.length) {
    return null;
  }
  const LIMIT = 20000;
  const total = context.total >= LIMIT ? LIMIT : context.total;
  const isLimitExceeded = count > LIMIT;
  return (
    <div className={TableClasses.SubHeader}>
      <Box container>
        {context.bulkActions?.map((action, i) => (
          action.disabled
            ? <Whisper key={i} placement="top" trigger="hover"
                       speaker={<RsTooltip>{action.disabledMessage}</RsTooltip>}>
              <Button key={i} disabled={action.disabled} icon={action.icon} onClick={() => handleBulkActionCLick(action)}
                      variant={ButtonVariants.Ghost} color={ButtonColors.Gray}>
                {action.label}
              </Button>
            </Whisper>
            :
            <Button key={i} disabled={action.disabled} icon={action.icon} onClick={() => handleBulkActionCLick(action)}
                    variant={ButtonVariants.Ghost} color={ButtonColors.Gray}>
              {action.label}
            </Button>
        ))}
      </Box>
      <Box container gap={"XS"} alignItems={"center"}>
        <Typography>
          {
            !context.selectedRows.length ?
              `No ${toFirstLower(title)}s selected` :
              `${isLimitExceeded ? LIMIT : count} ${toFirstLower(title)}${count > 1 ? "s" : ""} selected`
          }
        </Typography>
        {(context.bulkActions?.some(({ type }) => type === "Mail" || type == "SMS") && context.data.length < context.total) &&
        <Typography
          onClick={handleSelectAllRows}
          weight={TypographyWeight.Regular}
          color={TypographyColor.Primary}
          className={CommonClasses.ClickableIcon}
        >
          {
            !context.selectedRows.length ?
              `No ${toFirstLower(title)}s in ${name}` :
              `Select ${total} ${toFirstLower(title)}s out of all ${toFirstLower(title)}s`
          }
        </Typography>
        }
        {isLimitExceeded &&
        <Whisper placement="topEnd" trigger="hover"
                 speaker={<RsTooltip>You have a limit of {LIMIT} messages for bulk operations.</RsTooltip>}>
          <Icon type={"info"} className={MailboxDialogClasses.SecColor}/>
        </Whisper>
        }
      </Box>
    </div>
  );
});
export interface TableHeaderProps {
  expanded?: boolean;
  onExpand?: (expanded: boolean) => void;
  title: string;
  selectedView: TableViewFragment;
  draft: boolean;
  onSelect: ({ label, value }) => void;
}
export const TableHeader = React.memo<TableHeaderProps>(function TableHeader(props) {
  const { selectedView, draft, onSelect } = props;
  const { $viewer } = useSource();
  const options = useMemo(() => {
    let options = [
      {
        label: "Create",
        value: "create",
        icon: "add"
      }
    ];
    if (draft && selectedView) {
      options.unshift({
        label: "Save changes",
        value: "save",
        icon: "autorenew"
      });
    }
    if (selectedView) {
      options.push({
        label: "Clone",
        value: "clone",
        icon: "file_copy"
      });
      if (selectedView.objectId === $viewer.objectId || selectedView.createdBy.objectId === $viewer.objectId) {
        if (!$viewer.dataTableView || $viewer.dataTableView.share) {
          options.push({
            label: "Share",
            value: "share",
            icon: "ios_share"
          });
        }
        options = [
          ...options,
          {
            label: "Edit",
            value: "edit",
            icon: "create"
          },
          {
            label: "Delete",
            value: "delete",
            icon: "delete"
          }
        ];
      }
    }

    return options;
  }, [selectedView, draft]);
  return <div className={TableClasses.Header}>
    <Tooltip title={"Page views"} alignment={Alignment.Top}>
      <FontIcon className={CommonClasses.ClickableIcon} onClick={() => props.onExpand?.(!props.expanded)}
                type={props.expanded ? "rc_expand_left" : "rc_expand_right"}/>
    </Tooltip>
    <Typography
      size={TypographySize.Subtitle}>{props.title}</Typography>
    <Box flex={1}/>
    {(!$viewer.dataTableView || $viewer.dataTableView.create) && <DropDown
      renderOptionValue={(option) =>
        <Typography color={option.value == "delete" ? TypographyColor.Error : TypographyColor.ExtraDark}>
          {option.label}
        </Typography>
      }
      renderSelect={() =>
        <Box>
          {(draft && selectedView) ? <Badge
            alignment={BadgeAlignment.TopRight}
            color={BadgeColors.Warning}
            variant={BadgeVariants.Dot}
          >
            <FontIcon type={"more_vert"} className={CommonClasses.ClickableIcon}/>
          </Badge> : <FontIcon type={"more_vert"} className={CommonClasses.ClickableIcon}/>}
        </Box>
      }
      onSelect={onSelect}
      options={options}
    />}
  </div>;
});
export const TableFooter = React.memo<PaginationProps>(function TableFooter(props) {
  return (
    <div className={TableClasses.Footer}>
      <Pagination {...props} alignSelf={"end"}/>
    </div>
  );
});
export const TableContext = React.createContext<ReturnType<typeof useTable>>(null);
