import { Loader }         from "@relcu/rc";
import { Dropdown }       from "@relcu/rc";
import { Popover }        from "@relcu/rc";
import { CircularLoader } from "@relcu/ui";
import { useEffect }      from "react";
import { useCallback }    from "react";
import { useState }       from "react";
import React              from "react";
import { FC }             from "react";
import { gql }            from "@apollo/client";
import { useQuery }       from "@apollo/client";
import { Link }           from "@relcu/react-router";
import { ToggleDropdown } from "@relcu/rc";
import { Tooltip }        from "@relcu/rc";
import { Whisper }        from "@relcu/rc";
import { Icon }           from "@relcu/rc";
import { IconButton }     from "@relcu/rc";
import { Stack }          from "@relcu/rc";
import { Typography }     from "@relcu/rc";
import { confirmModal }   from "@relcu/ui";
import { useThrottle }    from "@relcu/ui";
import "./lead-connection-rc.css";

export interface LeadConnectionRCProps {
  leadId?: any
  lead?: any,
  type?: string
  onClick(item)
  onRemove()
}
export const FIND_LEAD_BY_OBJECT_NAME = gql`
  query GetLeadsByObjectName($objectName:String!){
    leads(where: {
      objectName: {
        matchesRegex: $objectName
        options: "i"
      }
      duplicateOf: {
        exists: false
      }
    }, first: 10) {
      edges {
        node {
          id
          objectId
          objectName
        }
      }
    }
  }
`;

export const LeadConnectionRC: FC<LeadConnectionRCProps> = React.memo((props) => {
    const { onClick, lead, onRemove, type } = props;
    const [skipQuery, setSkipQuery] = useState(true);
    const [q, setQ] = useState("");
    let [value, setValue] = useThrottle(q, 1000);

    useEffect(() => {
      setValue(q);
    }, [q]);

    const {
      data: { leads: { edges = [] } = {} } = {},
      loading
    } = useQuery(FIND_LEAD_BY_OBJECT_NAME, {
      fetchPolicy: "network-only",
      skip: skipQuery,
      variables: {
        objectName: value
      }
    });

    const apply = useCallback((item) => {
      onClick(item);
    }, []);

    const removeLink = async () => {
      try {
        await confirmModal({
          title: "Unlink Lead",
          subTitle: `You are unlinking lead relation.`,
          content: `You can restore lead relation by clicking on "Relink" button.`,
          label: "UNLINK"
        });
        await onRemove();
      } catch (e) {
        console.error(e);
      }
    };

    const renderMenu = ({ onClose, left, top, className }, ref) => {
      const handleSelect = (node) => {
        apply(node);
        onClose();
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu
            onSelect={handleSelect}
            style={{ width: "300px" }}>
            <div className={"lead-connection-rc_searchbar"}>
              <Icon type={"search"}/>
              <input
                onKeyDown={(e) => {
                  e.code === "Space" ? e.stopPropagation() : null;
                }}
                style={{ width: "100%", height: "20px" }}
                value={q}
                onChange={(e) => setQ(e.target.value)}
                placeholder={"Search..."}
              />
            </div>
            {
              <div style={{ height: "calc(100% - 38px)" }}>
                {
                  loading
                    ?
                    <Dropdown.Item>
                      <CircularLoader justify={"center"}/>
                    </Dropdown.Item>
                    :
                    edges.length > 0 ?
                      edges.map(({ node }, i) => {
                        return (
                          <Dropdown.Item
                            style={{ display: "flex", padding: "10px 12px", color: "var(--rs-primary-600)" }}
                            key={node.id} eventKey={i}
                            className={"lead-connection-rc_menu_item"}
                            onClick={() => handleSelect(node)}>
                            <Typography className={"lead-connection-rc_menu_item_text"}
                                        variant={"base14"}>
                              {node.objectName}
                            </Typography>
                            {node.objectId === lead?.objectId && <Icon type={"done"}/>}
                          </Dropdown.Item>);
                      })
                      :
                      <Dropdown.Item>
                        <Typography className={"lead-connection-rc_menu_item_text"}
                                    variant={"base14"}>
                          No results found
                        </Typography>
                      </Dropdown.Item>
                }
              </div>
            }
          </Dropdown.Menu>
        </Popover>
      );
    };

    return (
      type !== "bulk" &&
      <Stack
        spacing={4}
        alignItems={"center"}
        className={"lead-connection-rc"}>
        {
          lead ?
            <Stack>
              <Link to={`/lead/${lead.objectId}/details`}
                    className={"lead-connection-rc__link"} style={{ whiteSpace: "nowrap" }}>
                <Icon type={"launch"}/>
                {lead?.objectName}
              </Link>
              <Whisper
                placement="bottom"
                controlId="control-id-focus"
                trigger="hover"
                speaker={<Tooltip>Unlink Lead</Tooltip>}>
                <IconButton circle appearance={"subtle"} onClick={removeLink}
                            icon={<Icon className={"header-icon"} type={"link_off"}/>}/>
              </Whisper>
            </Stack>
            : null
        }
        <ToggleDropdown
          onOpen={() => setSkipQuery(false)}
          onClose={() => setQ("")}
          placement="bottomEnd"
          speaker={renderMenu}
          toggleRenderer={
            <IconButton circle appearance={"subtle"} icon={<Icon className={"header-icon"} type={"link"}/>}/>}
        />
      </Stack>
    );
  }
);
