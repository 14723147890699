import { gql }                             from "@apollo/client";
import { useContext }                      from "react";
import { useLayoutEffect }                 from "react";
import { useState }                        from "react";
import { useEffect }                       from "react";
import { useRef }                          from "react";
import { useMemo }                         from "react";
import { useCallback }                     from "react";
import { useQuery }                        from "@apollo/client";
import { NavBarClasses }                   from "@relcu/ui";
import { useWindowDimensions }             from "@relcu/ui";
import { ViewerBookmarksAndhistory }       from "../__types__/ViewerBookmarksAndhistory";
import { VIEWER_HISTORY_BOOKMARK }         from "../operations.graphql";
import { UserStatusSubscription }          from "./__types__/UserStatusSubscription";
import { UserStatusSubscriptionVariables } from "./__types__/UserStatusSubscription";
import { BookmarkContext }                 from "./useBookmarks";
import { useBookmarks }                    from "./useBookmarks";

export function useNavBar() {
  const { subscribeToMore } = useQuery<ViewerBookmarksAndhistory>(VIEWER_HISTORY_BOOKMARK, {
    fetchPolicy: "cache-only",
    returnPartialData: true
  });
  const bookmarks = useContext(BookmarkContext);
  const navBarRef = useRef(null);
  const [top, setTop] = useState(() => new Set());
  const [bottom, setBottom] = useState(() => new Set());
  const windowDimensions = useWindowDimensions();
  const small = useMemo(() => {
    return windowDimensions.width < 1700;
  }, [windowDimensions]);
  const api = {
    get subscribers() {
      return [...bookmarks.current, ...bookmarks.watch].filter(e => e.type === "User").map(e => {
        return String(e.id);
      });
    },
    subscribeToUserStatus() {
      if (subscribers.length) {
        return subscribeToMore<UserStatusSubscription, UserStatusSubscriptionVariables>({
          document: USER_STATUS_SUBSCRIPTION,
          variables: {
            users: subscribers
          }
        });
      }
    },
    handleScrollTo(side: "top" | "bottom") {
      let element;
      if (side == "top") {
        element = Array.from(top)[ 0 ];
      } else {
        element = Array.from(bottom).pop();
      }
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const subscribers = useMemo(() => api.subscribers, [bookmarks.current, bookmarks.watch]);

  useEffect(api.subscribeToUserStatus, [subscribers.length]);

  const handleScrollTo = useCallback(api.handleScrollTo, [top, bottom]);

  // useLayoutEffect(() => {
  //   let topMentions = new Set();
  //   let bottomMentions = new Set();
  //
  //   const listener = entries => {
  //     entries.forEach(
  //       ({ isIntersecting, boundingClientRect, target }) => {
  //         const currentRef = navBarRef.current.getBoundingClientRect();
  //         if (!isIntersecting) {
  //           if ((boundingClientRect.top - currentRef.top) > 0) {
  //             topMentions.delete(target);
  //             bottomMentions.add(target);
  //           } else {
  //             topMentions.add(target);
  //             bottomMentions.delete(target);
  //           }
  //         } else {
  //           if (bottomMentions.has(target)) {
  //             bottomMentions.delete(target);
  //           } else {
  //             topMentions.delete(target);
  //           }
  //         }
  //         setTop(new Set(topMentions));
  //         setBottom(new Set(bottomMentions));
  //       }
  //     );
  //   };
  //
  //   const observer = new IntersectionObserver(listener, {
  //     root: navBarRef.current,
  //     rootMargin: "0px"
  //   });
  //
  //   const activesItems = navBarRef.current.querySelectorAll(`.${NavBarClasses.NavBar} .${NavBarClasses.NavBarItem}.${NavBarClasses.WithCounter}`);
  //   if (!activesItems.length) {
  //     if (top.size) {
  //       setTop(new Set());
  //     }
  //     if (bottom.size) {
  //       setBottom(new Set());
  //     }
  //   }// if activesItems is empty and mention is active need to clear top and bottom
  //   for (let i = 0; i < activesItems.length; i++) {
  //     observer.observe(activesItems[ i ]);
  //   }
  //   return () => {
  //     const activesItems = navBarRef.current.querySelectorAll(`.${NavBarClasses.NavBar} .${NavBarClasses.NavBarItem}.${NavBarClasses.WithCounter}`);
  //     for (let i = 0; i < activesItems.length; i++) {
  //       observer.unobserve(activesItems[ i ]);
  //     }
  //     observer.disconnect();
  //   };
  // }, [bookmarks.watch, bookmarks.current, small]);

  return {
    ...bookmarks,
    handleScrollTo,
    navBarRef,
    small,
    top,
    bottom
  };
}
const USER_STATUS_SUBSCRIPTION = gql`
  subscription UserStatusSubscription($users:[ID!]) {
    users(where: {id: {in: $users}}) {
      event
      node{
        id
        status
        online
      }
    }
  }
`;
