import { Stack }            from "@relcu/rc";
import { IconButton }       from "@relcu/rc";
import { Icon }             from "@relcu/rc";
import { Typography }       from "@relcu/rc";
import { Page }             from "@relcu/rc";
import { Toolbar }          from "@relcu/rc";
import { Header }           from "@relcu/rc";
import { FC }               from "react";
import React                from "react";
import "./header.css";
import { LeadConnectionRC } from "../../../../LeadConnectionRC";

export interface HeaderProps {
  expand?: boolean
  lead?: {
    objectName?: string
    objectId?: string
    id?: string
  }
  subject?: string
  onExtend?(data)
  onToggle?()
  mailId?: string,
  onRemove?(id),
  handleClick?(item)
  toggleAll?(boolean)
  allToggled?: boolean
}
export const ThreadHeader: FC<HeaderProps> = React.memo(function ThreadHeader(props) {
  const { expand, toggleAll, allToggled, onToggle, handleClick, onRemove, subject, lead, mailId } = props;
  return <Header>
    <Toolbar childrenRenderMode={"clone"} spacing={16} size={expand ? "md" : "sm"}
             style={{ padding: `0  16px 0 ${expand ? "16px" : "8px"}`, height: "56px", verticalAlign: "center" }}>
      {!expand && <Page.Toggle size={"md"} expand={expand} onToggle={onToggle}/>}
      <Stack style={{ width: "100%" }} justifyContent={"space-between"}>
        <Stack.Item>
          <Typography style={{
            // wordBreak: "break-word",
            // // whiteSpace: "nowrap",
            // overflow:"hidden",
            // textOverflow: "ellipsis"
          }} weights={"medium"} variant={"base16"}>{subject}</Typography>
        </Stack.Item>
        <Stack.Item shrink={1}>
          <Stack spacing={16}>
            <LeadConnectionRC
              leadId={lead?.id}
              lead={lead}
              onClick={handleClick}
              onRemove={() => onRemove(mailId)}/>
            <IconButton onClick={()=>toggleAll(!allToggled)} circle appearance={"subtle"} icon={<Icon className={"header-icon"} type={!allToggled ? "keyboard_arrow_down1" : "keyboard_arrow_up1"}/>}/>
          </Stack>
        </Stack.Item>
      </Stack>
    </Toolbar>
  </Header>;
});
