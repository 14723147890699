import React                         from "react";
import { FC }                        from "react";
import { useContext }                from "react";
import { DateTime }                  from "luxon";
import { getIn }                     from "@relcu/form";
import { FormSpy }                   from "@relcu/form";
import { Field, Form }               from "@relcu/form";
import { useNavigate }                from "@relcu/react-router";
import { ModalBody }                  from "@relcu/ui";
import { ModalFooter }                from "@relcu/ui";
import { Box }                        from "@relcu/ui";
import { ToggleSwitch }               from "@relcu/ui";
import { ButtonVariants }             from "@relcu/ui";
import { Button }                     from "@relcu/ui";
import { Where }                      from "@relcu/ui";
import { formatPhoneNumber }          from "@relcu/ui";
import { defaultMutators }            from "@relcu/ui";
import { ContactDialogClasses }       from "../../ContactDialogClasses";
import { WHEN_TEM_MIDDLENAME_EXISTS } from "../../ContactDialogConditions";
import { WHEN_EX_MIDDLENAME_EXISTS }  from "../../ContactDialogConditions";
import { WHEN_TEM_LASTNAME_EXISTS }   from "../../ContactDialogConditions";
import { WHEN_EX_LASTNAME_EXISTS }    from "../../ContactDialogConditions";
import { WHEN_TEM_BIRTHDAY_EXISTS }   from "../../ContactDialogConditions";
import { WHEN_EX_BIRTHDAY_EXISTS }    from "../../ContactDialogConditions";
import { WHEN_EX_COMPANY_EXISTS }     from "../../ContactDialogConditions";
import { WHEN_TEM_COMPANY_EXISTS }    from "../../ContactDialogConditions";
import { WHEN_TEM_FIRSTNAME_EXISTS }  from "../../ContactDialogConditions";
import { WHEN_EX_FIRSTNAME_EXISTS }   from "../../ContactDialogConditions";
import { ContactDialogContext }       from "../../ContactDialog";
import { MergeField }                from "../../Field/MergeField";
import { MultiField }                from "../../Field/MultiField";
import HeaderRow                     from "../../Row";
import { RowTitle }                  from "../../Row";
import { Row }                       from "../../Row";
import { useMergeContacts }          from "./useMergeContacts";

export const MergeForm: FC<any> = React.memo(function MergeContacts(props) {
  const navigate = useNavigate();
  const { resolveContactInitialValues, resolveDuplicationErrors, handleSubmitErrors, action } = useContext(ContactDialogContext);
  const { marks, onMark, formatType, handleSubmit } = useMergeContacts();

  return (
    <Form
      mutators={defaultMutators}
      onSubmit={handleSubmit}
      initialValues={resolveContactInitialValues}>
      {({ handleSubmit }) =>
        <form onSubmit={handleSubmit} noValidate style={{ display: "contents" }}>
          <Field name={"exists.id"} type="hidden" component="input"/>
          <Field name={"exists.objectId"} type="hidden" component="input"/>
          <Field name={"temporary.id"} type="hidden" component="input"/>
          <Field name={"temporary.objectId"} type="hidden" component="input"/>
          <Field name={"__typename"} type="hidden" component="input"/>
          <ModalBody container direction={"column"} gap={"XS"} className={ContactDialogClasses.Body}>
            <Box container direction={"column"} gap={"XXXS"}>
              <ToggleSwitch label="Merge contact" checked={true} onChange={() => navigate(-1)}/>
              <p className={ContactDialogClasses.MergeInfo}>Select contact information to keep</p>
            </Box>
            <Box container direction={"column"} flex={1}>
              <Box container flex={1} alignSelf="stretch">
                <HeaderRow flexBasis={"20%"}/>
                <HeaderRow flexBasis={"40%"} title="Existing contact"/>
                <HeaderRow flexBasis={"40%"} title={`${action === "save" ? "Existing" : "New"} contact`}/>
              </Box>
              <Where source={resolveContactInitialValues}
                     conditions={{ or: [WHEN_EX_FIRSTNAME_EXISTS, WHEN_TEM_FIRSTNAME_EXISTS] }}>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>First Name</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"exists.firstName"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"temporary.firstName"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                </Box>
              </Where>
              <Where source={resolveContactInitialValues}
                     conditions={{ or: [WHEN_EX_MIDDLENAME_EXISTS, WHEN_TEM_MIDDLENAME_EXISTS] }}>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Middle Name</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"exists.middleName"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"temporary.middleName"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                </Box>
              </Where>
              <Where source={resolveContactInitialValues}
                     conditions={{ or: [WHEN_EX_LASTNAME_EXISTS, WHEN_TEM_LASTNAME_EXISTS] }}>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Last Name</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"exists.lastName"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"temporary.lastName"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                </Box>
              </Where>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>Phone(s)</RowTitle>
                </Row>
                <Row direction={"column"} flexBasis={"40%"}
                     style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <MultiField name={"exists.phones"} fieldName={"number"} marks={marks} errors={resolveDuplicationErrors}
                              onMark={onMark} format={formatPhoneNumber}/>
                </Row>
                <Row direction={"column"} flexBasis={"40%"}
                     style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <MultiField name={"temporary.phones"} fieldName={"number"} marks={marks}
                              errors={resolveDuplicationErrors} onMark={onMark} format={formatPhoneNumber}/>
                </Row>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>Emails(s)</RowTitle>
                </Row>
                <Row direction={"column"} flexBasis={"40%"}
                     style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <MultiField name={"exists.emails"} fieldName={"address"} marks={marks} errors={resolveDuplicationErrors}
                              onMark={onMark}/>
                </Row>
                <Row direction={"column"} flexBasis={"40%"}
                     style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <MultiField name={"temporary.emails"} fieldName={"address"} marks={marks}
                              errors={resolveDuplicationErrors} onMark={onMark}/>
                </Row>
              </Box>
              <Box container flex={1} alignSelf="stretch">
                <Row flexBasis={"20%"}>
                  <RowTitle>Contact types</RowTitle>
                </Row>
                <Row direction={"column"} flexBasis={"40%"}
                     style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <MultiField name={"exists.types"} fieldName={""} marks={marks}
                              errors={resolveDuplicationErrors} onMark={onMark} format={formatType}/>
                </Row>
                <Row direction={"column"} flexBasis={"40%"}
                     style={{ "--row-padding": "var(--layout-box-gap-x-s) 0" } as any}
                     alignItems={"start"}>
                  <MultiField name={"temporary.types"} fieldName={""} marks={marks}
                              errors={resolveDuplicationErrors} onMark={onMark} format={formatType}/>
                </Row>
              </Box>
              <Where source={resolveContactInitialValues}
                     conditions={{ or: [WHEN_EX_BIRTHDAY_EXISTS, WHEN_TEM_BIRTHDAY_EXISTS] }}>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Birthday</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"exists.birthday"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected}
                                           value={input.value ? DateTime.fromISO(input.value).toFormat("MM/dd/kkkk") : ""}
                                           onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"temporary.birthday"}>
                      {({ input, meta }) => {
                        const birthday = input.value ?
                          (DateTime.fromJSDate(input.value).isValid ?
                            DateTime.fromJSDate(input.value).toFormat("MM/dd/kkkk") :
                            DateTime.fromISO(input.value).toFormat("MM/dd/kkkk")) : "";
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected}
                                           value={birthday}
                                           onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                </Box>
              </Where>
              <Where source={resolveContactInitialValues}
                     conditions={{ or: [WHEN_EX_COMPANY_EXISTS, WHEN_TEM_COMPANY_EXISTS] }}>
                <Box container flex={1} alignSelf="stretch">
                  <Row flexBasis={"20%"}>
                    <RowTitle>Company</RowTitle>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"exists.company"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                  <Row flexBasis={"40%"}>
                    <Field name={"temporary.company"}>
                      {({ input, meta }) => {
                        const selected = getIn(marks, input.name);
                        return <MergeField selected={selected} value={input.value} onChange={() => onMark(input.name)}/>;
                      }}
                    </Field>
                  </Row>
                </Box>
              </Where>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button type="button" variant={ButtonVariants.Outline}
                    onClick={() => navigate(-1)}>CANCEL</Button>
            <Button type="submit">MERGE AND ADD</Button>
          </ModalFooter>
          <FormSpy subscription={{ submitError: true, submitErrors: true }}
                   onChange={({ submitError, submitErrors }) => handleSubmitErrors(submitError || submitErrors)}/>
        </form>
      }
    </Form>
  );
});
