import React                                       from "react";
import { useState }                                from "react";
import { useMemo }                                 from "react";
import { Fields }                                  from "@relcu/gql-query-builder";
import { ButtonVariants }                          from "@relcu/ui";
import { ButtonSizes }                             from "@relcu/ui";
import { Button }                                  from "@relcu/ui";
import { Box }                                     from "@relcu/ui";
import { PointerField as BasePointerField }        from "@relcu/ui";
import { PointerFieldProps as IPointerFieldProps } from "@relcu/ui";
import { permissionUtils }                         from "@relcu/ui";
import { useSource }                               from "@relcu/ui";
import { getSelectionSet }                         from "../../../../utils/graphQlUtils";
import { toFirstUpper }                            from "../../../../utils/helpers";
import { toFirstLower }                            from "../../../../utils/helpers";
import { pluralize }                               from "../../../../utils/pluralize";
import { useJqlQuery }                             from "../../Jql";
import { usePointerFilters }                       from "./usePointerFilters";

export interface PointerFieldProps extends Partial<Omit<IPointerFieldProps, "onSelectedFilter">> {
  fields?: Fields;
  onSelectedFilter?(selected: any[]);
}

export const PointerField = React.memo<PointerFieldProps>(withFiltersCheck(function PointerField({ fields, ...props }) {
  const first = useMemo(() => {
    return props?.first ?? (Array.isArray(props.targetClass) ? 3 : 10);
  }, []);
  const [q, setQ] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const className = Array.isArray(props.targetClass) ? props.targetClass[ 0 ] : props.targetClass;
  const operation = useMemo(() => pluralize(toFirstLower(className)), [className]);
  const selectionSet = useMemo(() => getSelectionSet(fields), [fields]);
  const query = useMemo(() => {
    let query: any;
    let value = {
      objectName: {
        matchesRegex: q ? `^${(q || "").trim()}` : "",
        options: "i"
      },
      ...props.filters
    };

    if (selectedId) {
      value[ "objectId" ] = { equalTo: selectedId };
    }

    if (Array.isArray(props.targetClass)) {
      query = props.targetClass.map(n => {
        const operation = pluralize(toFirstLower(n));
        return {
          operation,
          variables: {
            after: "",
            first,
            [ `${operation}Where` ]: {
              type: `${n}WhereInput`,
              name: "where",
              value
            }
          },
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        };
      });
    } else {
      query = {
        operation,
        variables: {
          after: "",
          first: 10,
          where: {
            type: `${className}WhereInput`,
            value
          }
        },
        fields: [
          {
            pageInfo: [
              "endCursor",
              "startCursor",
              "hasNextPage",
              "hasPreviousPage"
            ]
          },
          {
            edges: [
              {
                node: selectionSet
              }
            ]
          }
        ]
      };
    }
    return query;
  }, [q, props.targetClass, props.filters, selectedId]);
  const { data = { [ operation ]: { edges: [] } }, loading, fetchMore } = useJqlQuery(query, {
    skip: props.view === "read",
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    operationName: `${toFirstUpper(props.name)}PointerSelector`
  });
  const options = useMemo(() => {
    const items = [];
    if (Array.isArray(props.targetClass)) {
      Object.keys(data).forEach(t => {
        const label = t == "leadSources" ? "CAMPAIGN" : t.toUpperCase();
        const options = data[ t ].edges.map(({ node }) => node);
        if (options.length) {
          items.push({
            label,
            options: data[ t ].edges.map(({ node }) => node)
          });
        }
      });
      return items;
    }
    return (
      Object(data[ operation ])[ "edges" ] || []
    ).map(({ node }) => node);
  }, [data, operation]);
  const pageInfo = useMemo(() => {
    return Object(data[ operation ])[ "pageInfo" ];
  }, [data, operation]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        where: query.variables.where.value,
        first: query.variables.first,
        after: pageInfo.endCursor
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          [ operation ]: {
            ...prev[ operation ],
            pageInfo: fetchMoreResult[ operation ].pageInfo,
            edges: [...prev[ operation ].edges, ...fetchMoreResult[ operation ].edges]
          }
        };
      }
    });
  };

  return (
    <BasePointerField
      thumbnail={true}
      {...props}
      onLoadMoreHandler={
        pageInfo?.hasNextPage &&
        <Box container justify={"center"} style={{ position: "sticky", bottom: 0, background: "white", paddingTop: 8 }}>
          <Button size={ButtonSizes.Small} variant={ButtonVariants.Ghost} onClick={handleLoadMore}>Load more</Button>
        </Box>
      }
      onSelectedFilter={(selected) => setSelectedId(selected ? selected[ "objectId" ] : null)}
      disabled={props.disabled || (props.mode == "edit" && props.edit === false)}
      options={options}
      searchText={q}
      onType={setQ}
      loading={loading}
    />
  );
}));

function withFiltersCheck(PointerField) {
  function WithFilters(props: PointerFieldProps) {
    const filters = usePointerFilters(props.filters);
    return <PointerField {...props} filters={filters}/>;
  }
  return function withFiltersCheckHok(props: PointerFieldProps) {
    const { $viewer } = useSource();
    if (!props.filters) {
      return <PointerField {...props}/>;
    }
    if (!props.permissions?.filters || permissionUtils.checkAccess($viewer, props.permissions.filters)) {
      return <WithFilters {...props}/>;
    }
    return <WithFilters {...props} filters={undefined}/>;
  };
}

