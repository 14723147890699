import { ListItemDate }              from "@relcu/ui";
import { Box }                       from "@relcu/ui";
import { ListItemTitle }             from "@relcu/ui";
import { ListItemText }              from "@relcu/ui";
import { useSource }                 from "@relcu/ui";
import React                         from "react";
import { AssignmentActivityProps }   from "../../View/TimelineView/components/AssignmentActivity";
import { BulkMailActivityProps }     from "../../View/TimelineView/components/BulkMailActivity";
import { CallActivityProps }         from "../../View/TimelineView/components/PhoneCallActivity";
import { ContactMergeActivityProps } from "../../View/TimelineView/components/ObjectMergeActivity";
import { FocusActivityProps }        from "../../View/TimelineView/components/PrioritizationActivity";
import { GenericActivityProps }      from "../../View/TimelineView/components/GenericActivity";
import { MailActivityProps }         from "../../View/TimelineView/components/MailMessageActivity";
import { SMSActivityProps }          from "../../View/TimelineView/components/PhoneMessageActivity";
import { ProposalActivityProps }     from "../../View/TimelineView/components/ProposalActivity";
import { StatusActivityProps }       from "../../View/TimelineView/components/StatusChangeActivity";
import { TakeLeadActivityProps }     from "../../View/TimelineView/components/DistributionActivity";
import { ActivityWidgetClasses }     from "./ActivityWidgedClasses";
import { WorkflowActivityProps } from '../../View/TimelineView/components/WorkflowActivity';

export const AssignmentActivityWidget = React.memo<AssignmentActivityProps>((props) => {
  const { assignedBy, assignedTo, assignedFrom, assignmentEvent, createdAt } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={assignedBy?.objectName || "Relcu"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText text={assignmentEvent === "unassigned" ? "Unassigned from" : "Assigned to"}/>
      <ListItemTitle
        title={assignmentEvent === "unassigned" ? assignedFrom?.objectName || "Unknown" : assignedTo?.objectName || "Unknown"}
      />
    </Box>
  </>;
});

export const SMSActivityWidget = React.memo<SMSActivityProps>((props) => {
  const { $object } = useSource();
  const { createdAt, phoneMessageFrom, phoneMessageTo } = props;
  if (phoneMessageFrom && ($object.objectId === phoneMessageFrom.objectId || ($object.__typename === "Lead" && phoneMessageFrom.__typename === "Contact"))) {
    return <>
      <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
        <ListItemText
          tiny
          className={ActivityWidgetClasses.ActivityViewItemName}
          text={phoneMessageFrom?.objectName || "Unknown"}
        />
        <ListItemDate format="withTime" date={createdAt}/>
      </Box>
      <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
        <ListItemText text={`Sent SMS to`}/>
        <ListItemTitle
          title={phoneMessageTo?.objectName || "Unknown"}
        />
      </Box>
    </>;
  } else {
    return <>
      <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
        <ListItemText
          tiny
          className={ActivityWidgetClasses.ActivityViewItemName}
          text={phoneMessageTo?.objectName || "Unknown"}
        />
        <ListItemDate format="withTime" date={createdAt}/>
      </Box>
      <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
        <ListItemText
          text={`Received SMS from`}/>
        <ListItemTitle
          title={phoneMessageFrom?.objectName || "Unknown"}
        />
      </Box>
    </>;
  }
});

export const CallActivityWidget = React.memo<CallActivityProps>((props) => {
  const { $object } = useSource();
  const { phoneCallFrom, phoneCallTo, createdAt } = props;
  if (phoneCallFrom && ($object.objectId === phoneCallFrom.objectId || ($object.__typename === "Lead" && phoneCallFrom.__typename === "Contact"))) {
    return <>
      <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
        <ListItemText
          tiny
          className={ActivityWidgetClasses.ActivityViewItemName}
          text={phoneCallFrom?.objectName || "Unknown"}
        />
        <ListItemDate format="withTime" date={createdAt}/>
      </Box>
      <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
        <ListItemText text={"Called to"}/>
        <ListItemTitle
          title={phoneCallTo?.objectName || "Unknown"}
        />
      </Box>
    </>;
  } else {
    return <>
      <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
        <ListItemText
          tiny
          className={ActivityWidgetClasses.ActivityViewItemName}
          text={phoneCallTo?.objectName || "Unknown"}
        />
        <ListItemDate format="withTime" date={createdAt}/>
      </Box>
      <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
        <ListItemText text={"Received a call from"}/>
        <ListItemTitle
          title={phoneCallFrom ? phoneCallFrom.objectName || "Unknown" : "Relcu"}
        />
      </Box>
    </>;
  }
});

export const MailActivityWidget = React.memo<MailActivityProps>((props) => {
  const { mailMessageFrom, mailMessageTo, createdAt } = props;
  const { $object } = useSource();
  if (mailMessageFrom && ($object.objectId === mailMessageFrom.objectId || ($object.__typename === "Lead" && mailMessageFrom.__typename === "Contact"))) {
    return <>
      <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
        <ListItemText
          tiny
          className={ActivityWidgetClasses.ActivityViewItemName}
          text={mailMessageFrom?.objectName || "Unknown"}
        />
        <ListItemDate format="withTime" date={createdAt}/>
      </Box>
      <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
        <ListItemText
          text={`Sent email to "${mailMessageTo?.length ? mailMessageTo?.map((m: any) => m?.objectName).join(", ") : "Relcu"}"`}/>
      </Box>
    </>;
  } else {
    return <>
      <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
        <ListItemText
          tiny
          className={ActivityWidgetClasses.ActivityViewItemName}
          text={mailMessageTo?.length ? mailMessageTo?.map((m: any) => m?.objectName).join(", ") : "Relcu"}
        />
        <ListItemDate format="withTime" date={createdAt}/>
      </Box>
      <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
        <ListItemText
          text={`Received email from "${!!mailMessageFrom ? mailMessageFrom.objectName || "Unknown" : "Relcu"}"`}/>
      </Box>
    </>;
  }
});

export const FocusActivityWidget = React.memo<FocusActivityProps>((props) => {
  const { prioritizationQueue, prioritizationEvent, createdAt } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={"Relcu"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText
        text={`${prioritizationEvent === "leave" ? "Left" : "Entered"} "${prioritizationQueue?.objectName || "Unknown"}" section`}/>
    </Box>
  </>;
});

export const TakeLeadActivityWidget = React.memo<TakeLeadActivityProps>((props) => {
  const { distributedTo, distributionQueue, createdAt } = props;
  const isPush = distributionQueue.strategy == "round_robin";
  return <>
    {
      isPush ?
        <>
          <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
            <ListItemText
              tiny
              className={ActivityWidgetClasses.ActivityViewItemName}
              text={distributionQueue?.objectName || "Unknown"}
            />
            <ListItemDate format="withTime" date={createdAt}/>
          </Box>
          <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
            <ListItemText text={`distributed to`}/>
            <ListItemTitle
              title={distributedTo?.objectName || "Unknown"}
            />
          </Box>
        </>
        :
        <>
          <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
            <ListItemText
              tiny
              className={ActivityWidgetClasses.ActivityViewItemName}
              text={distributedTo?.objectName || "Unknown"}
            />
            <ListItemDate format="withTime" date={createdAt}/>
          </Box>
          <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
            <ListItemText text={`Took lead from`}/>
            <ListItemTitle
              title={`"${distributionQueue?.objectName || "Unknown"}"`}
            />
            <ListItemText text={` queue`}/>
          </Box>
        </>
    }
  </>;
});

export const StatusActivityWidget = React.memo<StatusActivityProps>((props) => {
  const { statusChangedBy, createdAt, statusNewValue } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={statusChangedBy?.objectName || "Relcu"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText
        text={"Updated status to "}/>
      <ListItemTitle
        className={ActivityWidgetClasses.ActivityViewItemLink}
        title={statusNewValue?.statusName || "Unknown"}/>
    </Box>
  </>;
});

export const ContactMergeActivityWidget = React.memo<ContactMergeActivityProps>((props) => {
  const { sourceObject, mergeBy: contactMergeBy, targetObject, createdAt } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={contactMergeBy ? contactMergeBy.objectName || "Unknown" : "Relcu"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText text={"Merged"}/>
      <ListItemTitle title={sourceObject?.objectName || "Unknown"}/>
      <ListItemText text={"with"}/>
      <ListItemTitle title={targetObject?.objectName || "Unknown"}/>
    </Box>
  </>;
});

export const GenericActivityWidget = React.memo<GenericActivityProps>((props) => {
  const { generatedBy, createdAt, message } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={generatedBy ? generatedBy.objectName || "Unknown" : "Relcu"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText text={message}/>
    </Box>
  </>;
});

export const ProposalActivityWidget = React.memo<ProposalActivityProps>((props) => {
  const { proposalGeneratedBy, createdAt } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={proposalGeneratedBy ? proposalGeneratedBy.objectName : "Unknown"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText text={"Generated a loan proposal"}/>
    </Box>
  </>;
});

export const WorkflowActivityWidget = React.memo<WorkflowActivityProps>((props) => {
  const { createdAt, title, message, activityCreatedBy: createdBy } = props;
  return <>
    <Box container gap={"XXS"} justify={"space-between"} direction={"row"}>
      <ListItemText
        tiny
        className={ActivityWidgetClasses.ActivityViewItemName}
        text={createdBy?.objectName || "Unknown"}
      />
      <ListItemDate format="withTime" date={createdAt}/>
    </Box>
    <Box container direction={"row"} flexGrow={1} alignItems={"center"} gap={"XXXS"}>
      <ListItemText text={title}/>
    </Box>
  </>;
});
