import React                               from "react";
import { useCallback }                     from "react";
import { useState }                        from "react";
import { useMemo }                         from "react";
import { useMutation }                     from "@apollo/client";
import { Box }                             from "@relcu/ui";
import { useSource }                       from "@relcu/ui";
import { checkDuplicateOf }                from "../../../../utils/helpers";
import { createWhereInputVariables }       from "../../../../utils/phoneMessageUtils";
import { LeadConnection }                  from "../../../LeadConnection";
import { UpdatePhoneMessageLeadVariables } from "../../../PhoneMessageThread/__types__/UpdatePhoneMessageLead";
import { UpdatePhoneMessageLead }          from "../../../PhoneMessageThread/__types__/UpdatePhoneMessageLead";
import { ASSIGN_LEAD_TO_MESSAGE }          from "../../../PhoneMessageThread/PhoneMessageThread";
import { PhoneMessageThread }              from "../../../PhoneMessageThread/PhoneMessageThread";
import { MailSectionPosition }             from "../MailView/MailSection/MailSection";
import { MailSection }                     from "../MailView/MailSection/MailSection";
import { MailSubSection }                  from "../MailView/MailSubSection";

export const MessageView = React.memo(function MessageView() {
  const { $object, $viewer } = useSource();
  const duplicateOf = checkDuplicateOf($object);
  const { queryVariables, subscriptionVariables } = useMemo(() => createWhereInputVariables($object, $viewer), [
    $object.__typename, $object.id
  ]);
  const [update] = useMutation<UpdatePhoneMessageLead, UpdatePhoneMessageLeadVariables>(ASSIGN_LEAD_TO_MESSAGE);
  const [selectedMsgs, setSelectedMsgs] = useState<string[]>([]);

  const changeScope = useCallback((item) => {
    selectedMsgs.map(id => {
      update({
        variables: {
          id: id,
          fields: {
            scope: {
              link: item.id
            }
          }
        }
      });
    });
    setSelectedMsgs([]);
  }, [selectedMsgs, update]);

  const onRemove = useCallback(() => {
    selectedMsgs.map(id => {
      update({
        variables: {
          id: id,
          fields: {
            scope: {
              link: null
            }
          }
        }
      });
    });
    setSelectedMsgs([]);
  }, [selectedMsgs, update]);

  return <Box container direction={"column"} gap={"XXXS"} flex={1}>
    {$object.__typename === "Contact" && !!selectedMsgs.length && <MailSection position={MailSectionPosition.Top}>
      <MailSubSection>
        <LeadConnection onClick={changeScope} onRemove={onRemove} type={"bulk"}/>
      </MailSubSection>
    </MailSection>}
    <PhoneMessageThread
      nearBy={null}
      allowBulkConnection={$object.__typename === "Contact"}
      setSelectedMsgs={setSelectedMsgs}
      selectedMsgs={selectedMsgs}
      duplicateOf={duplicateOf}
      queryVariables={queryVariables}
      subscriptionVariables={subscriptionVariables}/>
  </Box>;
});

export default MessageView;
