import Editor                   from "@monaco-editor/react";
import { createCalculation }    from "@relcu/form";
import { Field }                from "@relcu/form";
import { Modal }                from "@relcu/ui";
import { TextField }            from "@relcu/ui";
import { Button }               from "@relcu/ui";
import { ModalFooter }          from "@relcu/ui";
import { ModalBody }            from "@relcu/ui";
import { SwitchField }          from "@relcu/ui";
import { useMemo }              from "react";
import { useCallback }          from "react";
import React                    from "react";
import { transformNameToLabel } from "../../../../utils/helpers";
import { EmailRenderer }        from "../../../EmailVisualizer";
import { PointerField }         from "../../Field/PointerField";
import { PointerListField }     from "../../Field/PointerListField";
import { ClassForm }            from "../../Form/ClassForm";
import { useSchemaDialog }      from "../SchemaDialog";
import { SchemaDialogProps }    from "../SchemaDialog";
import { Box }                  from "@relcu/ui";
import { BackDropLoader }       from "@relcu/ui";
import { ButtonVariants }       from "@relcu/ui";
import { Section }              from "@relcu/ui";
import { useSource }            from "@relcu/ui";
import "./email-template-dialog.css";

export const EmailTemplateDialog = React.memo<SchemaDialogProps>(function EmailTemplateDialog(props) {
  const {
    initialValues = {},
    user,
    sections,
    jql,
    afterSubmit,
    formProps,
    action,
    className,
    loading,
    ...modal
  } = useSchemaDialog(props);
  const { $viewer: { objectId = null, __typename = "User", id = null } = {} } = useSource();
  const viewer = useMemo(() => ({ objectId, __typename, id }), [objectId, __typename, id]);
  const { lead: hasLead, loanProposal: hasLoanProposal, owner = viewer, ...initial } = initialValues;
  const transformValues = useCallback((values) => {
    const { hasLead: lead, hasLoanProposal: loanProposal, from, to, ...initial } = values;
    return { ...initial, lead, loanProposal };
  }, []);
  return (
    <Modal
      {...modal}
      open={props.open}
      onClose={props.onClose}
      title={`${action === "save" ? "Edit" : "Create"} ${props.title ? String(props.title)?.toLowerCase() : transformNameToLabel(className)?.toLowerCase()}`}
      closeIcon={true}
      disableBackdropClick={false}
      variant="large"
      className="email-template-dialog-modal"
    >
      <ClassForm
        group={false}
        className={className}
        decorators={[decorators]}
        jql={jql}
        transformValues={transformValues}
        initialValues={useMemo(() => ({ ...initial, hasLead, hasLoanProposal, owner }), [
          owner, hasLead, initial, hasLoanProposal
        ])}
        subscription={{ submitting: true, values: true }}
        sections={[
          ...sections,
          {
            fields: [
              { name: "hasLead", component: null },
              { name: "hasLoanProposal", component: null },
              { name: "from", component: null },
              { name: "to", component: null }
            ]
          }
        ]}
        user={user}
        afterSubmit={afterSubmit}
        {...formProps}
      >
        {({ submitting, values }) => (
          <>
            <ModalBody className="email-template-dialog__container" style={{ position: "relative" }} container
                       direction={"column"} gap={"XXS"} flexGrow={1}>
              <Box container className="email-template-dialog__body">
                <Box container gap={"XS"} flex={1} style={{ overflowY: "hidden" }}>
                  <Section view={"read"} editable={false} title="Email template" gap={"XS"}
                           flex={"1 0 calc(50% - 16px)"} bodyProps={{ flex: 1 }}>
                    <Box container direction={"column"} gap={"XS"} alignSelf={"stretch"} flex={1}>
                      <Box container gap={"XS"}>
                        <TextField
                          name={`title`}
                          halfSize
                          label={"Template name"}
                          required={true}
                          placeholder={"Enter template name"}
                          flex={1}
                        />
                        <TextField
                          name={`subject`}
                          halfSize
                          label={"Subject"}
                          placeholder={"Enter subject"}
                          flex={1}
                        />
                        <SwitchField label={"For automation"} name={`forAutomation`}/>
                        <SwitchField label={"Enabled"} name={`enabled`}/>
                      </Box>
                      <Box container gap={"XS"}>
                        <SwitchField placeholder={"Lead"} name={`hasLead`}/>
                        {values.hasLead && <SwitchField placeholder={"Loan proposal"} name={`hasLoanProposal`}/>}
                      </Box>
                      <Field name={`content`}>
                        {({ input: { value, onChange } }) => {
                          return <Editor
                            defaultLanguage={"handlebars"}
                            value={value}
                            onChange={onChange}
                            options={{ minimap: { enabled: false } }}
                          />;
                        }}
                      </Field>
                    </Box>
                  </Section>
                  <Section view={"read"}
                           editable={false}
                           title={"Preview"}
                           flex={"1 0 calc(50% - 16px)"}
                           style={{ overflowY: "scroll" }}
                           bodyProps={{ direction: "column", gap: "XXXS" }}
                  >
                    <Box container direction="row" gap={"XS"}>
                      <PointerField
                        placeholder={"Select value"}
                        flex={1}
                        label="From"
                        name="from"
                        targetClass="User"/>
                      <PointerListField
                        flex={1}
                        fields={["primaryEmail"]}
                        initialValue={[]}
                        targetClass="Contact"
                        name={"to"}
                        label="To"
                        placeholder={"Select value"}/>
                    </Box>
                    <Box container direction="row" gap={"XS"} style={{ minHeight: 57 }}>
                      {!!values?.hasLead &&
                        <PointerField placeholder={"Select value"} flex={1} label="Lead"
                                      name="lead" targetClass="Lead"/>}
                      {!!values?.hasLoanProposal &&
                        <PointerField placeholder={"Select value"} label="Loan Proposal"
                                      flex={1} name="loanProposal"
                                      targetClass="LoanProposal"/>}
                    </Box>
                    <Box style={{ overflow: "auto", maxWidth: "100%" }}>
                      <EmailRenderer
                        template={values.content}
                        fromId={values.from?.objectId}
                        loanProposalId={values.loanProposal?.objectId}
                        toEmails={values.to?.map(t => t?.primaryEmail)}
                        leadId={values.lead?.objectId}
                      />
                    </Box>
                  </Section>
                </Box>
                {loading && <BackDropLoader/>}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button disabled={submitting} type="button" variant={ButtonVariants.Outline}
                      onClick={props.onClose}>CANCEL</Button>
              <Button disabled={submitting} type="submit">{action.toUpperCase()}</Button>
            </ModalFooter>
          </>
        )}
      </ClassForm>
    </Modal>
  );
});

const decorators = createCalculation(
  {
    field: "hasLead",
    updates: {
      hasLoanProposal(value, allValues: any) {
        if (allValues.hasLead) {
          return allValues.hasLoanProposal;
        }
        return false;
      }
    }
  }
);
