import React                            from "react";
import { FC }                           from "react";
import { useEffect }                    from "react";
import { useState }                     from "react";
import { useQuery }                     from "@apollo/client";
import { useMutation }                  from "@apollo/client";
import { gql }                          from "@apollo/client";
import { Checkbox }                     from "@relcu/rc";
import { CheckboxGroup }                from "@relcu/rc";
import { HybridCalls }                  from "../../../../types/hybridCall";
import { HybridCall as HybridCallType } from "../../../../types/hybridCall";
import { checkContactIsUnknown }        from "../../../../utils/helpers";
import { usePhone }                     from "../../../usePhone";
import { Contact }                      from "../../__types__/Contact";
import { Caller }                       from "../../Caller";
import { CallStatus }                   from "../../CallStatus";
import { DialContact }                  from "../ContactList/__types__/DialContact";
import { ManageCall }                   from "../ManageCall/ManageCall";
import { DialUser }                     from "../UserList/__types__/DialUser";
import { DialBarUser }                  from "./__types__/DialBarUser";
import { ReassignLeadVariables }        from "./__types__/ReassignLead";
import { ReassignLead }                 from "./__types__/ReassignLead";
import { RelcuCallActions }             from "./RelcuCallActions";
import { TwilioCallActions }            from "./TwilioCallActions";
import { useHybridCall }                from "./useHybridCall";
import "./hybrid-call.css";

export const HybridCall: FC = React.memo(function HybridCall() {
  const { data: { viewer: { user: { username, phoneLines, objectId } = {} } = {} } = {} } = useQuery<DialBarUser>(DIAL_BAR_USER, { fetchPolicy: "cache-only" });
  const { active, invite, unHold, advancedPhone, hybridCalls, direction } = usePhone();
  const { initialDisplayCall } = Object(hybridCalls) as HybridCalls;
  const {
    activeCalls,
    onHoldCall,
    number,
    isModerator,
    isMerged,
    myCall,
    secondLOActiveCall,
    isLicensedStatesMatch,
    assignedToObjectId,
    showManageCall
  } = useHybridCall();

  const [reassignLead] = useMutation<ReassignLead, ReassignLeadVariables>(REASSIGN_LEAD);
  const [mergeRules, setMergeRules] = useState(["stayOnCall", "reassignTheLead"]);
  const [voiceMessageOpen, setVoiceMessageOpen] = useState(false);
  const [dialPadOpen, setDialPadOpen] = useState(false);
  const handleCall = (value: DialUser | DialContact | Contact) => {
    let from = number;
    let to;
    if (value.__typename == "Contact") {
      to = value.phones[ 0 ].number;//todo always take 0 item from phones

      if ((!from || from.startsWith("client")) && to.startsWith("+1")) {
        from = phoneLines?.edges[ 0 ]?.node?.number;
      }
    } else {
      from = `client:${username}`;
      to = `client:${value.username}`;
    }

    invite(from, to, activeCalls[ 0 ].call);
  };

  const handleMerge = async () => {
    try {
      await unHold(onHoldCall.call);
      if (mergeRules.findIndex(item => item == "reassignTheLead") > -1 && initialDisplayCall?.leadId && secondLOActiveCall) {
        reassignLead({ variables: { id: initialDisplayCall.leadId, assignedTo: secondLOActiveCall.id } });
      }

      if (mergeRules.findIndex(item => item == "stayOnCall") == -1) {
        active.drop();
      }
    } catch (e) {
      console.error("handle merge", e);
    }
  };

  function getStatus(call: HybridCallType): any {
    if (direction == "incoming" && (call.status != "in-progress" && call.status != "completed" && call.status != "open")) {
      return "pending";
    }

    if (call.status == "in-progress" && call.hold) {
      return "hold";
    }

    return call.status;
  }

  useEffect(() => {
    if (!secondLOActiveCall || !isLicensedStatesMatch || !initialDisplayCall?.leadId || assignedToObjectId != objectId) {
      setMergeRules(prevRules => prevRules.filter(rule => rule != "reassignTheLead"));
    }
  }, [secondLOActiveCall, isLicensedStatesMatch, initialDisplayCall?.leadId, assignedToObjectId, objectId]);

  return <>
    {
      isModerator && !isMerged
        ?
        <div className={"hybrid-call-conference-container"}>
          {
            activeCalls?.map((call: HybridCallType, index) => {
              return <div
                className={"hybrid-call-parties-moderator"}
                key={index}>
                {
                  call?.status &&
                  <CallStatus
                    timer={call?.startDate ? new Date(call?.startDate) : new Date()}
                    status={getStatus(call)}/>
                }
                {
                  !!activeCalls?.length &&
                  <Caller
                    hold={call.hold}
                    parties={[
                      {
                        isUnknown: checkContactIsUnknown(call.objectName),
                        name: call.objectName,
                        icon: call.objectIcon,
                        objectId: call.objectId,
                        id: call?.id,
                        number: call?.number,
                        company: call?.__typename == "Contact" && call?.company,
                        role: call?.__typename == "User" && call?.role,
                        className: call?.__typename
                      }
                    ]}
                  />
                } {/*todo tmp remove after switch bar collapse toggle to recluCall*/}
                {
                  isModerator && index == 1 && onHoldCall &&
                  <CheckboxGroup name="mergeConfig" value={mergeRules} disabled={call.status != "in-progress"}
                                 onChange={(value: string[]) => setMergeRules(value)}
                                 style={{ alignSelf: "start" }}>
                    <Checkbox value="stayOnCall">
                      Staying on call after merge
                    </Checkbox>
                    <Checkbox value="reassignTheLead"
                              disabled={!isLicensedStatesMatch || !secondLOActiveCall || call.status != "in-progress" || !initialDisplayCall?.leadId || (assignedToObjectId != objectId)}>
                      Reassign the lead
                    </Checkbox>
                  </CheckboxGroup>
                }
              </div>;
            })
          }
          {
            !!activeCalls?.length &&
            <RelcuCallActions
              onMerge={handleMerge}
              dialPadOpen={dialPadOpen}
              onDialPadOpen={setDialPadOpen}
              onVoiceMessagesOpen={setVoiceMessageOpen}
              voiceMessagesOpen={voiceMessageOpen}/>
          }
        </div>
        :
        <>
          <div
            className={"hybrid-call-parties"}>
            {
              myCall?.status &&
              <CallStatus
                timer={myCall?.startDate ? new Date(myCall?.startDate) : new Date()}
                status={getStatus(myCall)}/>
            }
            {
              !!activeCalls?.length &&
              <Caller
                parties={
                  activeCalls?.map(call => {
                    return {
                      isUnknown: checkContactIsUnknown(call.objectName),
                      name: call.objectName,
                      icon: call.objectIcon,
                      id: call.id,
                      objectId: call.objectId,
                      className: call?.__typename,
                      number: call?.number,
                      company: call?.__typename == "Contact" && call?.company,
                      role: call?.__typename == "User" && call?.role
                    };
                  }) as any
                }
              />
            }
          </div>
          {
            advancedPhone ?
              !!activeCalls?.length &&
              <RelcuCallActions
                dialPadOpen={dialPadOpen}
                onDialPadOpen={setDialPadOpen}
                onVoiceMessagesOpen={setVoiceMessageOpen}
                voiceMessagesOpen={voiceMessageOpen}/>
              :
              <TwilioCallActions/>
          }
        </>
    }
    {
      showManageCall &&
      !voiceMessageOpen &&
      !dialPadOpen &&
      <ManageCall onCall={handleCall}/>
    }
  </>;
});

const REASSIGN_LEAD = gql`
  mutation ReassignLead($id:ID!,$assignedTo:ID!)
  {
    updateLead(input
    :
    {
      id:$id, fields
    :
    {
      assignedTo:{
        link: $assignedTo
      }
    }
    }
    )
    {
      lead
      {
        id
        objectId
        assignedTo
        {
          id
          objectId
        }
      }
    }
  }
`;
const DIAL_BAR_USER = gql`
  query DialBarUser {
    viewer {
      user {
        id
        objectId
        objectName
        username
        phoneLines{
          edges {
            node {
              number
            }
          }
        }
      }
    }
  }
`;
