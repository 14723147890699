import { useState }        from "react";
import { useEffect }       from "react";
import { useMemo }         from "react";
import { useSearchParams } from "@relcu/react-router";
import { gql }             from "@apollo/client";
import { useSource }       from "@relcu/ui";
import { toNodeId }        from "../../../../utils/helpers";
import { useJqlQuery }     from "../../Jql";

export function useRelcuLoanView(props) {
  const { jql } = props;
  const { $object } = useSource();
  const [searchParams] = useSearchParams();
  const selectedLoanId = searchParams.get("lid");
  const [loanId, setLoanId] = useState(selectedLoanId || $object?.loan?.objectId);

  const query = useMemo(() => ({
    operation: "relcuLoans",
    operationName: "GetRelcuLoans",
    variables: {
      where: {
        type: "RelcuLoanWhereInput",
        name: "where"
      },
      order: {
        type: "[RelcuLoanOrder!]",
        value: ["updatedAt_DESC"]
      }
    },
    fields: [{ edges: [{ node: ["objectId", ...jql.query.get.fields] }] }]
  }), [jql.query.get.fields]);
  const { data: { relcuLoans } = {}, subscribeToMore, refetch } = useJqlQuery(query, {
    operationName: "GetRelcuLoans",
    variables: {
      where: { lead: { have: { objectId: { equalTo: $object.objectId } } } }
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  const loan = useMemo(() => {
    let loanEdge = (relcuLoans?.edges || []).find((l) => (l.node.objectId == loanId));
    let selectedLoan = loanEdge?.node || relcuLoans?.edges?.[ 0 ]?.node;

    if (selectedLoan) {
      const milestones = selectedLoan.milestones.map(m => {
        return {
          ...m,
          tasks: selectedLoan.tasks.filter(t => t.milestoneLosId === m.losId),
          documents: selectedLoan.documents.filter(d => d.milestoneLosId === m.losId)
        };
      });
      selectedLoan = {
        ...selectedLoan,
        milestones: milestones
      };
    }

    return selectedLoan;
  }, [relcuLoans, loanId]);
  const loanOptions = useMemo(() => ((relcuLoans?.edges || []).map((l) => ({ label: l.node.objectName, value: l.node.objectId }))), [relcuLoans]);

  useEffect(() => {
    return subscribeToMore({
      document: SUBSCRIBE_RELCU_LOAN,
      variables: {
        leadId: toNodeId({ objectId: $object.objectId, className: "Lead" })
      },
      updateQuery: (prev) => {
        refetch();
        return prev;
      }
    });
  }, []);
  const sections = props.sections.map(s => {
    if (s.fields.find(f => f.name.startsWith("milestones"))) {
      return {
        ...s,
        fields: [
          { name: "milestones" },
          { name: "documents" },
          { name: "tasks" }
        ]
      };
    }
    return s;
  });

  return {
    loan,
    relcuLoans,
    sections,
    setLoanId,
    loanOptions
  };
}

const SUBSCRIBE_RELCU_LOAN = gql`
  subscription SubscribeRelcuLoan($leadId:ID!) {
    relcuLoans(where: {lead: {have: {link: $leadId}}}) {
      event
      node {
        id
      }
    }
  }
`;
