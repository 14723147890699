import { classNames }        from "../..";
import React                 from "react";
import { FC }                from "react";
import { Box }               from "../Box";
import { BoxComponentProps } from "../Box";
import { CounterClasses }    from "./CounterClasses";

export interface CounterItemProps extends BoxComponentProps {
  header: string
}

export const CounterItem: FC<CounterItemProps> = React.memo((props) => {
  const { header, children, ...p } = props;
  return <Box container className={CounterClasses.Item} direction={"column"} gap={"XXS"} flexGrow={1} {...p}>
    <Box className={CounterClasses.ItemHeader}>
      {header}
    </Box>
    <Box container alignItems={"center"} justify={"center"} gap={"XXS"} className={CounterClasses.ItemContent}>
      {children}
    </Box>
  </Box>;
});

export interface CounterItemRecordProps {
  name: string
  count: number
}

export const CounterItemRecord: FC<CounterItemRecordProps> = React.memo((props) => {
  const { name, count } = props;
  return <Box container alignItems={"center"} gap={"XXXS"}>
    <span>
      {name}
    </span>
    {count}
  </Box>;
});

export interface CounterProps extends BoxComponentProps {
  counters: { header: string, items: { name: string, count: number }[] }[]
}

export const Counter: FC<CounterProps> = React.memo((props) => {
  const { className, children, counters, ...p } = props;
  const classes = classNames(CounterClasses.Counter, className);

  return <Box container className={classes} justify={"space-between"} flexGrow={1} {...p}>
    {counters.map((counter, key) => {
      return <CounterItem key={key} header={counter.header}>
        {counter.items.map((item, key) => {
          return <CounterItemRecord key={key} name={item.name} count={item.count}/>;
        })}
      </CounterItem>;
    })}
  </Box>;
});
