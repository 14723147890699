import { useCallback }                     from "react";
import { useState }                        from "react";
import { useMemo }                         from "react";
import { useMutation }                     from "@apollo/client";
import { DeepPartial }                     from "@apollo/client/utilities";
import { UpdatePhoneMessageLeadVariables } from "../../../../../PhoneMessageThread/__types__/UpdatePhoneMessageLead";
import { UpdatePhoneMessageLead }          from "../../../../../PhoneMessageThread/__types__/UpdatePhoneMessageLead";
import { ASSIGN_LEAD_TO_MESSAGE }          from "../../../../../PhoneMessageThread/PhoneMessageThread";
import { PhoneMessageConversation }        from "../../__types__/PhoneMessageConversation";

export function usePhoneMessages(props:{conversation: DeepPartial<PhoneMessageConversation>}) {
  const { conversation } = props
  const contact = useMemo(() => conversation?.participants?.find((p) => p.party.__typename == "Contact")?.party, [conversation?.participants]);
  const [selectedMsgs, setSelectedMsgs] = useState<string[]>([])
  const [update] = useMutation<UpdatePhoneMessageLead, UpdatePhoneMessageLeadVariables>(ASSIGN_LEAD_TO_MESSAGE);

  const changeScope = useCallback((item)=>{
   selectedMsgs.map(id => {
      update({
        variables: {
          id: id,
          fields: {
            scope: {
              link: item.id
            }
          }
        }
      })
    })
    setSelectedMsgs([])
  },[selectedMsgs, update])

  const onRemove = useCallback(()=>{
    selectedMsgs.map(id => {
      update({
        variables: {
          id: id,
          fields: {
            scope: {
              link: null
            }
          }
        }
      })
    })
    setSelectedMsgs([])
  },[selectedMsgs, update])

  return {
    selectedMsgs,
    setSelectedMsgs,
    changeScope,
    onRemove,
    leadId: conversation?.scope?.objectId,
    id: conversation.id,
    get scope() {
      return conversation.scope;
    },
    contact,
    toContactPath: `/${contact.__typename.toLowerCase()}/${contact.objectId}`,
    toLeadPath: conversation.scope ? `/${conversation.scope.__typename.toLowerCase()}/${conversation.scope.objectId}` : null
  };
}
