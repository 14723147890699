import { useState }                       from "react";
import { useLazyQuery }                   from "@apollo/client";
import { useReactiveVar }                 from "@apollo/client";
import { gql }                            from "@apollo/client";
import { useMutation }                    from "@apollo/client";
import { useSource }                      from "@relcu/ui";
import { MEMBER_OWN_FIELDS_FRAGMENT }     from "../../../../graph/operations.graphql";
import { deviceVar }                      from "../../../../reactiveVars";
import { getDefaultPhoneNumberForCall }   from "../../../../utils/helpers";
import { createPhoneNumbers }             from "../../../../utils/helpers";
import { usePermissions }                 from "../../../AccessControl";
import { ASSIGN_LEAD_TO_CONFERENCE }      from "../../../operations.graphql";
import { useMarkConferenceRead }          from "../../../useMarkConferenceRead";
import { usePhone }                       from "../../../usePhone";
import { useViewerPhoneLines }            from "../../../useViewerPhoneLines";
import { GetConferenceByCallIdVariables } from "./__types__/GetConferenceByCallId";
import { GetConferenceByCallId }          from "./__types__/GetConferenceByCallId";

export function useHeaderCall() {
  const { active, hybridCalls, direction, call } = usePhone();
  const { initialDisplayCall, myCall } = Object(hybridCalls);
  const { $object, $viewer } = useSource();
  const { canUpdate } = usePermissions($object);
  const { fromNumbers } = useViewerPhoneLines();
  const device = useReactiveVar(deviceVar);
  const [getConferenceByCallId] = useLazyQuery<GetConferenceByCallId, GetConferenceByCallIdVariables>(GET_CONFERENCE_BY_CALL_ID);
  const [assignLeadToConference] = useMutation(ASSIGN_LEAD_TO_CONFERENCE);

  const node = $object;
  const [from] = fromNumbers;
  const { markAsRead } = useMarkConferenceRead({ id: $object.id, __typename: $object.__typename, userObjectId: $viewer.objectId });
  const [updatedScope, setUpdatedScope] = useState(null);

  const api = {
    from,
    active,
    myCall,
    direction,
    updatedScope,
    setUpdatedScope,
    initialDisplayCall,
    callDevice: device?.call,
    get to() {
      return !!api.toNumbers.length && getDefaultPhoneNumberForCall(api.toNumbers, node?.__typename);
    },
    get node() {
      return $object;
    },
    canUpdate,
    fromNumbers,
    async assign(variables) {
      if (direction == "outgoing") {
        assignLeadToConference(variables);
      } else {
        const conf = await getConferenceByCallId({ variables: { callId: myCall?.callSid } });
        if (conf.data.conferences.edges[ 0 ].node.objectId) {
          const vars = {
            ...variables,
            variables: {
              ...variables.variables,
              id: conf.data.conferences.edges[ 0 ].node.objectId
            }
          };
          assignLeadToConference(vars);
        }
      }
    },
    get toNumbers() {
      return node ? createPhoneNumbers(node, "callOptOut") : [];
    },
    getSelectFrom(select) {
      return fromNumbers.find(f =>
          f.value === select)?.label ||
        (fromNumbers.length == 0 ? "No available numbers" : "Select Number");
    },
    getSelectTo(select) {
      return api.toNumbers.find(t =>
          t.value === select)?.label ||
        (api.toNumbers.length == 0 ? "No available numbers" : "Select Number");
    },
    get leadId() {
      return $object.__typename === "Lead" ? $object.id : null;
    },
    get isSameNode() {
      return !!initialDisplayCall && (
        ($object.__typename === "Lead" && $object.objectId === api?.initialDisplayCall?.leadId) ||
        ($object.__typename === "Contact" && $object.objectId === api?.initialDisplayCall?.objectId)
      );
    },
    get scope() {
      const scope = updatedScope
        ? updatedScope.objectId ? updatedScope : null
        : $object.__typename === "Lead" ? {
          objectId: initialDisplayCall.leadId,
          objectName: initialDisplayCall.leadName
        } : null;
      return scope;
    },
    get isOpen() {
      return !!active && active?.status === "open";
    },
    get isDisabled() {
      return (
        (api.isOpen && !api.isSameNode) ||
        (!canUpdate || fromNumbers.length == 0 || api.toNumbers.length == 0)
      );
    },
    get isActive() {
      return api.isOpen && api.isSameNode;
    },
    get isPending() {
      return api.isSameNode && (
        active.status === "pending" ||
        active.status === "ringing" ||
        active.status === "connecting"
      );
    },
    get contact() {
      if (node?.__typename === "Lead") {
        return node.members.find(borrower => borrower.isPrimary)?.contact;
      }
      return node;
    },
    markAsRead,
    call({ from, to }) {
      const params = {
        get from() {
          return from?.value || from;
        },
        get to() {
          return to?.value || to;
        },
        get leadId() {
          return $object.__typename === "Lead" ? $object.objectId : "";
        },
        get leadName() {
          return $object.__typename === "Lead" ? $object.objectName : "";
        },
        get contactId() {
          return api.contact?.objectId;
        },
        get contactName() {
          if ($object.__typename === "Lead") {
            const callee = $object.members.find(borrower => borrower.contact?.phones?.find(phone => phone.number === (to?.value || to)));
            return callee?.contact?.objectName;
          }
          return $object.objectName;
        },
        get contactCompany() {
          return ($object.__typename == "Contact" && $object.company) || "";
        },
        markAsRead: api.markAsRead
      };
      call(Object.assign({}, params));
    }
  };
  return api;
}
const GET_CONFERENCE_BY_CALL_ID = gql`
  query GetConferenceByCallId($callId: String) {
    conferences (where: {calls: {have: {call: {equalTo: $callId}}}}) {
      edges {
        node {
          objectId
        }
      }
    }
  }
`;

const GET_NODE_NUMBERS = gql`
  ${MEMBER_OWN_FIELDS_FRAGMENT}
  query GetNodeNumbers($id: ID!) {
    node(id: $id){
      ...on Lead{
        id
        timezone
        objectId
        members{
          ...MemberOwnFields
          contact{
            id
            objectId
            firstName
            lastName
            objectName
            phones{
              number
              default
              isPrimary
              callOptOut
            }
          }
        }
      }
      ...on Contact{
        firstName
        lastName
        middleName
        objectName
        id
        objectId
        phones{
          number
          isPrimary
          default
          callOptOut
        }
      }
    }
  }
`;
