import { AlertContextValue, AlertOptions } from "../Alert";
import { AlertMessage }                    from "../Alert";
import { AlertContext }                    from "../Alert";
import React                               from "react";

export class ErrorBoundary extends React.PureComponent {
  static contextType: React.Context<AlertContextValue> = AlertContext;

  render() {
    if (this.state.hasError) {
      return <React.Fragment/>;
    }
    return this.props.children;
  }
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  error(message: AlertMessage, options?: AlertOptions) {
    console.error(message)
    this.context.error(message);
  }
  info(message: AlertMessage, options?: AlertOptions) {
    this.context.info(message, options);
  }
  componentDidCatch(error, errorInfo) {
    this.error(`An unknown error has occurred. Please contact relcu@support.com for further guidance.`);
  }
}
