import { useRef }                    from "react";
import { useEffect }                 from "react";
import { useState }                  from "react";
import { gql }                       from "@apollo/client";
import { useLazyQuery }              from "@apollo/client";
import { useNavigate }               from "@relcu/react-router";
import { isEmail }                   from "@relcu/ui";
import { useThrottle }               from "@relcu/ui";
import { parsePhoneNumber }          from "@relcu/ui";
import { useMounted }                from "@relcu/ui";
import { schemaVar }                 from "../../../reactiveVars";
import { transformNameToLabel }      from "../../../utils/helpers";
import { pluralize }                 from "../../../utils/pluralize";
import { useSearchPermissions }      from "../../AccessControl/useSearchPermision";
import { SearchQueryAtlasVariables } from "./__types__/SearchQueryAtlas";
import { SearchQueryAtlas }          from "./__types__/SearchQueryAtlas";

export const SEARCH_QUERY_ATLAS = gql`
  query SearchQueryAtlas(
    $query:String!,
    $offsetLeads: Int,
    $offsetUsers: Int,
    $offsetContacts: Int,
    $offsetTasks: Int,
    $skipLeads:Boolean = false,
    $skipUsers:Boolean = false,
    $skipContacts:Boolean = false,
    $skipTasks:Boolean = false
  ) {
    leads:search(className: "Lead",query:$query,skip:$offsetLeads,limit: 15  ) @skip(if: $skipLeads){
      count
      data
    }
    users:search(className: "User",query:$query,skip:$offsetUsers,limit: 15) @skip(if: $skipUsers){
      count
      data
    }
    contacts:search(className: "Contact",query:$query,skip:$offsetContacts,limit: 15) @skip(if: $skipContacts){
      count
      data
    }
    tasks:search(className: "Task",query: $query,skip: $offsetTasks,limit: 15) @skip(if: $skipTasks){
      count
      data
    }
  }
`;

export function useAtlasSearch(onClose?) {
  const [value, setValue] = useState("");
  let [query, setQuery] = useThrottle(value, 1000);
  const navigate = useNavigate();
  const schema = schemaVar();
  const { canSearch } = useSearchPermissions();

  const [visibleSchemas] = useState(() => {
    let schemas = Object.values(schema).filter(s => canSearch(s.className));
    return schemas.map((schema) => {
      return {
        label: transformNameToLabel(schema.className),
        value: schema.className
      };
    }).sort((a, b) => {
      if (a.value == "Lead") {
        return -1;
      } else {
        return 1;
      }
    }).filter(s => s.value !== "Loan");
  });
  function skipQuery(__typename: string) {
    return !classNames.includes(__typename);
  }
  const limit = 15;
  const [classNames, setClassNames] = useState<string[]>(["Lead"]);
  if (!isEmail(query)) {
    if (query && !/[A-Z]/ig.test(query)) {
      query = parsePhoneNumber(query);
    }
    if (query && query.includes("+")) {
      query = String(query).replace(/[+]/g, "");
    }
  }
  query = query ? query.trim() : null;
  const dataRef = useRef<SearchQueryAtlas>();
  const [send, { loading, data, called, refetch, variables }] = useLazyQuery<SearchQueryAtlas, SearchQueryAtlasVariables>(SEARCH_QUERY_ATLAS, {
    fetchPolicy: "no-cache"
  });
  dataRef.current = { ...dataRef.current, ...data };
  const load = () => {
    if (query) {
      send({
        variables: {
          offsetLeads: 0,
          offsetUsers: 0,
          offsetContacts: 0,
          offsetTasks: 0,
          skipLeads: skipQuery("Lead"),
          skipUsers: skipQuery("User"),
          skipContacts: skipQuery("Contact"),
          skipTasks: skipQuery("Task"),
          query
        }
      });
    }
  };
  useEffect(() => setQuery(value), [value]);
  useMounted(load, [query, classNames]);
  const search = {
    loading,
    value,
    load,
    setValue,
    classNames,
    setClassNames,
    visibleSchemas,
    onRowClick(item) {
      if (item.className.toLowerCase() == "task" && item?.subject?.objectId) {
        navigate(`/lead/${item.subject?.objectId}/tasks`);
      } else {
        navigate(`/${item.className.toLowerCase()}/${item.objectId}`);
      }
    },
    getObjects(__typename: string) {
      if (!query) {
        return [];
      }
      const key = pluralize(__typename.toLowerCase());
      return search.data?.[ key ]?.data || [];
    },
    getCount(__typename: string) {
      if (!query) {
        return 0;
      }
      const key = pluralize(__typename.toLowerCase());
      return search.data?.[ key ]?.count || 0;
    },
    onPage(__typename: string, page: number) {
      return refetch({
        skipLeads: __typename !== "Lead",
        skipUsers: __typename !== "User",
        skipContacts: __typename !== "Contact",
        skipTasks: __typename !== "Task",
        offsetLeads: __typename == "Lead" ? (page - 1) * limit : variables.offsetLeads,
        offsetContacts: __typename == "Contact" ? (page - 1) * limit : variables.offsetContacts,
        offsetTasks: __typename == "Task" ? (page - 1) * limit : variables.offsetTasks,
        offsetUsers: __typename == "User" ? (page - 1) * limit : variables.offsetUsers
        // skip: (page - 1) * limit
      });
    },
    get data() {
      return dataRef.current;
    },
    get isEmpty() {
      return !query || !called || (!loading && (
        Object.keys(Object(data)).length === 0 || (
          !Object.keys(data).some(k => data[ k ]?.count > 0)
        )
      )) || !classNames.length;
    }
  };

  return search;
}
