import { Class }             from "./Policies/Class";
import { Contact }           from "./Policies/Contact";
import { EmailAddress }      from "./Policies/EmailAddress";
import { Filters }           from "./Policies/Filters";
import { Layout }            from "./Policies/Layout";
import { LayoutSection }     from "./Policies/LayoutSection";
import { LeadStat }          from "./Policies/Lead";
import { Lead }              from "./Policies/Lead";
import { Loan }              from "./Policies/Loan";
import { LoanEstimateOffer } from "./Policies/LoanEstimateOffer";
import { MailDomain }        from "./Policies/MailDomain";
import { Mortech }           from "./Policies/Mortech";
import { PhoneNumber }       from "./Policies/PhoneNumber";
import { Property }          from "./Policies/Property";
import { Query }             from "./Policies/Query";
import { Subscription }      from "./Policies/Subscription";
import { TableView }         from "./Policies/TableView";
import { TabProperties }     from "./Policies/TabProperties";
import { User }              from "./Policies/User";
import { MailMessage }       from "./Policies/MailMessage";

export const typePolicies = {
  Class,
  Layout,
  Mortech,
  Filters,
  LayoutSection,
  User,
  Property,
  Lead,
  Loan,
  Contact,
  TabProperties,
  Query,
  Subscription,
  LeadStat,
  PhoneNumber,
  EmailAddress,
  MailDomain,
  TableView,
  LoanEstimateOffer,
  MailMessage
};
