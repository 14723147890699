const createQueryWhereInput = (node, viewer) => {
  const { __typename, id } = node;
  switch (__typename) {
    case "Contact" :
      return createQueryMap(id, `${__typename}Query`, viewer);
    case "Lead" :
      return createQueryMap(id, `${__typename}Query`, viewer);
  }
};
const createQueryMap = (id: string, key: string, viewer) => {
  const { role, id: viewerId } = viewer;
  let whereMap = {
    "LeadQuery": {
      AND: [
        {
          scope: {
            have: {
              id: {
                equalTo: id
              }
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                exists: true
              }
            }
          }
        }
      ]

    },
    "ContactSub": {
      participants: {
        have: {
          party: {
            have: {
              link: id
            }
          }
        }
      }
    },
    "LeadSub": {
      AND: [
        {
          scope: {
            have: {
              link: id
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                exists: true
              }
            }
          }
        }
      ]
    }
  };
  if (role != "admin") {
    whereMap[ "ContactQuery" ] = {
      AND: [
        {
          participants: {
            have: {
              party: {
                have: {
                  link: id
                }
              }
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                have: {
                  link: viewerId
                }
              }
            }
          }
        }

      ]
    };
    whereMap[ "ContactSub" ] = ({
      AND: [
        {
          participants: {
            have: {
              party: {
                have: {
                  link: id
                }
              }
            }
          }
        },
        {
          participants: {
            have: {
              party: {
                have: {
                  link: viewerId
                }
              }
            }
          }
        }
      ]
    } as any);
  } else {
    whereMap[ "ContactQuery" ] = {
      participants: {
        have: {
          party: {
            have: {
              link: id
            }
          }
        }
      }
    };
  }
  return whereMap[ key ];
};
const createSubscriptionWhereInput = (node, viewer) => {
  switch (node.__typename) {
    case "Contact" :
      return createQueryMap(node.id, `${node.__typename}Sub`, viewer);
    case "Lead" :
      return createQueryMap(node.id, `${node.__typename}Sub`, viewer);
  }
};
export const createWhereInputVariables = (node, viewer) => {
  return {
    queryVariables: { where: createQueryWhereInput(node, viewer) },
    subscriptionVariables: { where: createSubscriptionWhereInput(node, viewer) }
  };
};
