import React                   from "react";
import { TypographySize }      from "@relcu/ui";
import { TypographyColor }     from "@relcu/ui";
import { Typography }          from "@relcu/ui";
import { CommonClasses }       from "@relcu/ui";
import { classNames }          from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { Accordion }           from "@relcu/ui";
import { TableProvider }       from "../../../../Table";
import { TableSubheader }      from "../../../../Table";
import { TableContainer }      from "../../../../Table";
import { Table }               from "../../../../Table";
import { PriorityViewClasses } from "../PriorityViewClasses";
import { usePriorityQueue }    from "./usePriorityQueue";
import { UsePriorityQueue }    from "./usePriorityQueue";
import { FontIcon }            from "@relcu/ui";
import { TypographyVariant }   from "@relcu/ui";

export interface PriorityQueueProps extends UsePriorityQueue {
  actions: any[];
  count: number;
}

export const PriorityQueue = React.memo<PriorityQueueProps>(function PriorityQueue(props) {
  const {
    count,
    leadCount,
    pageInfo,
    onLoadMore,
    onLoadLess,
    leadCountInQueue,
    columns,
    data,
    loading,
    isOpen,
    toggle,
    selectedRows,
    handleRowSelect,
    variables
  } = usePriorityQueue(props);
  return (
    <>{!!count &&
      <Box container direction={"column"} gap={"XS"} className={PriorityViewClasses.PriorityList}>
        <Accordion
          collapse={isOpen}
          onToggle={toggle}
          header={() =>
            <Box container alignItems={"center"}
                 className={classNames(PriorityViewClasses.PriorityListTitle, CommonClasses.ClickableIcon)}>
              <Typography size={TypographySize.SubtitleSmall}>{props.queue.title}</Typography>
              <Box flex={1}/>
              <Box container alignItems={"center"} gap={"XXS"} alignContent={"center"}>
                <Typography variant={TypographyVariant.span} size={TypographySize.Text}>{count + " "}</Typography>
                <Typography variant={TypographyVariant.span} color={TypographyColor.Secondary}
                            size={TypographySize.TextSmall}>lead{count > 1 && "s"}</Typography>
                <FontIcon type={isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                          className={CommonClasses.ClickableIcon}/>
              </Box>
            </Box>
          }
        >
          <>
            <Box style={{ position: "relative" }}>
              <TableProvider
                variables={variables}
                total={count}
                data={data}
                loading={loading}
                actions={props.actions}
                classId="Lead"
                selectedRows={selectedRows}
                onRowSelect={handleRowSelect}
              >
                <TableContainer>
                  <TableSubheader title="Lead" name={props.queue.title}/>
                  <Table columns={columns}/>
                </TableContainer>
              </TableProvider>
            </Box>
            {!!pageInfo.hasNextPage && (count - leadCount) > 0 &&
              <Typography
                alignSelf={"start"}
                color={TypographyColor.Secondary}
                size={TypographySize.TextSmall}
                className={classNames(
                  PriorityViewClasses.PriorityNextPage,
                  CommonClasses.ClickableIcon,
                  { [ PriorityViewClasses.PriorityExpandableDisabled ]: !props.queue.expandable }
                )}
                onClick={onLoadMore}
              >
                {`${count - leadCount} more lead${count - leadCount > 1 ? "s" : ""} in this queue`}
              </Typography>
            }
            {((!pageInfo.hasNextPage && leadCount < count) || ((count - leadCount) <= 0 && leadCountInQueue < count)) &&
              <Typography
                alignSelf={"start"}
                color={TypographyColor.Secondary}
                size={TypographySize.TextSmall}
                className={classNames(
                  PriorityViewClasses.PriorityNextPage,
                  CommonClasses.ClickableIcon
                )}
                onClick={onLoadLess}
              >
                Show less
              </Typography>
            }
          </>
        </Accordion>
      </Box>
    }</>
  );
});
