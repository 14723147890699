import { useNavigate } from "@relcu/react-router";
import { useSource }   from "@relcu/ui";
export function useContactHeader() {
  const navigate = useNavigate();
  const { $object: node } = useSource();
  const api = {
    navigateToSms: () => {
      navigate(`/${node.__typename.toLowerCase()}/${node.objectId}/sms`);
    }
  };
  return api;
}
