import { useContext }                          from "react";
import { useEffect }                           from "react";
import { useMemo }                             from "react";
import { useCallback }                         from "react";
import { useState }                            from "react";
import { useSubscription }                     from "@apollo/client";
import { useMutation }                         from "@apollo/client";
import { useQuery }                            from "@apollo/client";
import { gql }                                 from "@apollo/client";
import { useSource }                           from "@relcu/ui";
import { Action }                              from "@relcu/ui";
import { MailConversationWhereInput }          from "../../../../../types/graphql-global-types";
import { TableColumn }                         from "../../../../Table";
import { ContentContext }                      from "../Content/Content";
import { CancelBulkMailVariables }             from "./__types__/CancelBulkMail";
import { CancelBulkMail }                      from "./__types__/CancelBulkMail";
import { GetBulkConversationsVariables }       from "./__types__/GetBulkConversations";
import { GetBulkConversations }                from "./__types__/GetBulkConversations";
import { MarkMailConversationAsReadVariables } from "./__types__/MarkMailConversationAsRead";
import { MarkMailConversationAsRead }          from "./__types__/MarkMailConversationAsRead";
import { SubscribeBulkConversationsVariables } from "./__types__/SubscribeBulkConversations";
import { SubscribeBulkConversations }          from "./__types__/SubscribeBulkConversations";

export function useBulkContent() {
  const perPage = 10;
  const [page, setPage] = useState(1);
  const skip = perPage * (page - 1);
  const [status, setStatus] = useState(null);
  const { $object } = useSource();
  const { conversation } = useContext(ContentContext);
  const onBehalf = (conversation.lastSender?.party?.objectId !== $object.objectId);

  const [markAsRead] = useMutation<MarkMailConversationAsRead, MarkMailConversationAsReadVariables>(MARKS_MAIL_CONVERSATION_AS_READ);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    if (!conversation.bulkStats.queued && !onBehalf) {
      markAsRead({ variables: { objectId: conversation.objectId } });
    }
    setPage(1);
    setStatus(null);
    setSelectedItem(null);
  }, [conversation.objectId]);
  const columns = useMemo(() => {
    const columns: TableColumn[] = [
      {
        title: "Name",
        key: "scope",
        width: 300,
        minWidth: 200,
        resizable: false,
        visible: true,
        component: "AvatarCell"
      },
      {
        title: "Status",
        key: "status",
        width: 100,
        minWidth: 100,
        resizable: false,
        visible: true,
        component: "TextCell"
      },
      {
        title: "Details",
        key: "error",
        width: 400,
        minWidth: 400,
        visible: true,
        component: "TextCell"
      }
    ];
    if (!onBehalf && $object.role !== "loan_officer") {
      columns.push({
        title: "AssignedTo",
        key: "scope.assignedTo",
        width: 300,
        minWidth: 200,
        resizable: false,
        visible: true,
        component: "AvatarCell"
      });
    }
    return columns;
  }, [onBehalf]);
  const actions: Action[] = [{
    type: "SeeEmail",
    width: 200,
    onClick: (event, data) => {
      const cursor = edges.find(({ node }) => node.objectId === data.objectId).cursor;
      setSelectedItem({ conversationId: data.objectId, cursor });
    }
  }];

  const variables = useMemo(() => {
    let where: MailConversationWhereInput = {
      parent: { have: { objectId: { equalTo: conversation.objectId } } }
    };
    if (onBehalf) {
      where.lastSender = { party: { have: { link: $object.id } } };
    }
    if (status) {
      switch (status) {
        case "open":
        case "click":
        case "unsubscribe":
        case "spam":
          where = {
            ...where,
            messages: {
              have: {
                [ status ]: { equalTo: true }
              }
            }

          };
          break;
        case "completed":
          where = {
            ...where,
            status: { in: ["sent", "processed", "delivered"] }
          };
          break;
        case "failed":
          where = {
            ...where,
            status: { in: ["failed", "dropped"] }
          };
          break;
        default:
          where = {
            ...where,
            status: { equalTo: status }
          };
      }
    }
    return {
      first: perPage,
      skip: skip,
      where: where
    };
  }, [perPage, page, skip, conversation.objectId, status, selectedItem]);

  const { data: { mailConversations: { edges = [], count } = {} } = {}, loading, refetch, fetchMore, subscribeToMore } = useQuery<GetBulkConversations, GetBulkConversationsVariables>(GET_BULK_CONVERSATIONS, {
    fetchPolicy: "no-cache",
    // nextFetchPolicy: "cache-only",
    variables
  });
  useSubscription<SubscribeBulkConversations, SubscribeBulkConversationsVariables>(SUBSCRIBE_BULK_CONVERSATIONS, {
    variables: { where: { parent: { have: { link: conversation.id } } } },
    onData: () => {
      refetch();
    }
  });
  const slide = useCallback((path) => {
    let newVariables = null;
    if (!path) {
      setSelectedItem(null);
      fetchMore({ query: GET_BULK_MORE_CONVERSATIONS, variables });
    }

    if (path === "after") {
      newVariables = {
        first: 1,
        after: selectedItem.cursor,
        where: variables.where
      };
    } else if (path === "before") {
      newVariables = {
        last: 1,
        before: selectedItem.cursor,
        where: variables.where
      };
    }
    if (newVariables) {
      fetchMore({ query: GET_BULK_MORE_CONVERSATIONS, variables: newVariables }).then(({ data: { mailConversations: { edges = [] } = {} } = {} }) => {
        if (edges.length) {
          const con = edges[ 0 ];
          setSelectedItem({ conversationId: con.node.objectId, cursor: con.cursor });
        }
      });
    }
  }, [selectedItem, variables.where]);

  const onPage = useCallback((page) => {
    setPage(page);
  }, []);
  const onFilter = useCallback((status) => {
    setPage(1);
    setStatus(status);
  }, []);
  const [cancel, { loading: cancelLoading }] = useMutation<CancelBulkMail, CancelBulkMailVariables>(CANCEL_BULK_MAIL);
  const onCancelAll = useCallback(async () => {
    await cancel({ variables: { bulkId: conversation.objectId } });
    await refetch();
  }, [conversation.objectId]);

  useEffect(() => {
    if (status === "queued" && Math.ceil(count / perPage) < page && edges.length === 0 && page > 1) {
      setPage(Math.floor(count / perPage));
    }
  }, [count, status, page, perPage]);
  const stats = useMemo(() => {
    if (onBehalf && $object.__typename !== "Lead") {
      const userStats = conversation.bulkStatsPerUser.find(s => s.user.id == $object.id);
      return userStats.stats;
    } else {
      return conversation.bulkStats;
    }
  }, [conversation.bulkStatsPerUser, $object, onBehalf, conversation.bulkStats]);
  return {
    skip,
    onCancelAll,
    onBehalf,
    loading,
    actions,
    columns,
    onFilter,
    status,
    onPage,
    count,
    page,
    slide,
    selectedItem,
    setSelectedItem,
    cancelLoading,
    stats,
    get pageCount() {
      return Math.ceil(count / perPage);
    },
    conversations: edges.map(({ node }) => node),
    bulkConversation: conversation
  };
}
const CANCEL_BULK_MAIL = gql`
  mutation CancelBulkMail($bulkId:String!,$conversationId:String) {
    cancelBulkMail(bulkId: $bulkId,conversationId: $conversationId){
      id
      objectId
      scope {
        objectId
        objectIcon
        objectName
      }
      subject
      status
      error
    }
  }
`;
const GET_BULK_MORE_CONVERSATIONS = gql`
  query GetBulkMoreConversations($where:MailConversationWhereInput!,$skip:Int, $after:String, $before:String, $last:Int, $first:Int){
    mailConversations(
      order: [createdAt_DESC, objectId_ASC]
      where: $where,
      first: $first,
      skip: $skip,
      after:$after,
      before:$before,
      last:$last
    ){
      edges {
        cursor
        node {
          id
          objectId
          scope {
            objectId
            objectIcon
            objectName
            assignedTo {
              objectId
              objectIcon
              objectName
            }
          }
          subject
          status
          error
        }
      }
    }
  }
`;

const GET_BULK_CONVERSATIONS = gql`
  query GetBulkConversations($where:MailConversationWhereInput!,$skip:Int, $after:String, $before:String, $last:Int, $first:Int){
    mailConversations(
      order: [createdAt_DESC, objectId_ASC]
      where: $where,
      first: $first,
      skip: $skip,
      after:$after,
      before:$before,
      last:$last
    ){
      count
      edges {
        cursor
        node {
          id
          objectId
          scope {
            objectId
            objectIcon
            objectName
            assignedTo {
              objectId
              objectIcon
              objectName
            }
          }
          subject
          status
          error
        }
      }
    }
  }
`;

export const MARKS_MAIL_CONVERSATION_AS_READ = gql`
  mutation MarkMailConversationAsRead($objectId:String!) {
    markMailConversationAsRead(objectId: $objectId)
  }
`;

const SUBSCRIBE_BULK_CONVERSATIONS = gql`
  subscription SubscribeBulkConversations($where:MailConversationSubscriptionWhereInput!){
    mailConversations(
      where: $where,
    ){
      event
      node {
        id
        objectId
        scope {
          objectId
          objectIcon
          objectName
        }
        subject
        status
        error
      }
    }
  }
`;
