import React                   from "react";
import { FC }                  from "react";
import { BaseField }           from "../BaseField";
import { BaseFieldProps }      from "../BaseField";
import { PermissionEditField } from "./PermissionEditField";
import { PermissionReadField } from "./PermissionReadField";

export interface PermissionFieldProps {
  description?: string,
  options: {
    name: string,
    label: string
    readStateLabel: string
  }[]
}

export type PermissionProps = BaseFieldProps & PermissionFieldProps

export const PermissionField: FC<PermissionProps> = React.memo(function PermissionField(props) {
  return (
    <BaseField
      {...props}
      readView={<PermissionReadField/>}
      editView={<PermissionEditField/>}
    />
  );
});