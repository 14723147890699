import { useEffect }       from "react";
import { useMemo }         from "react";
import { useState }        from "react";
import { getIn }           from "@relcu/form";
import { useSearchParams } from "@relcu/react-router";
import { useNavigate }     from "@relcu/react-router";
import { useQuery }        from "@apollo/client";
import { gql }             from "@apollo/client";
import { useSource }       from "@relcu/ui";
import { useMounted }      from "@relcu/ui";
import { Role }            from "../../../../constants/Role";
import { toFirstUpper }    from "../../../../utils/helpers";
import { toFirstLower }    from "../../../../utils/helpers";
import { pluralize }       from "../../../../utils/pluralize";
import { getHumanized }    from "../../../../utils/schemaUtils";
import { TEAM_MEMBERS }    from "../../../operations.graphql";
import { useJqlQuery }     from "../../Jql";

export function usePipelineView(props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentPage = +searchParams.get("page") || 1;
  const { $object } = useSource();
  const pageCount = useMemo(() => Math.round((window.innerHeight - ($object.role!=Role.TEAM_MANAGER ? 336 : 416) ) / 44), [window.innerHeight,$object.role]);
  const offset = pageCount;
  const { data: teamData, loading: teamLoading } = useQuery(TEAM_MEMBERS, {
    variables: {
      id: $object.id,
      team: $object.team
    },
    skip: $object.role != Role.TEAM_MANAGER
  });
  const api = {
    offset,
    count: pageCount,
    currentPage,
    get total() {
      return rest.count;
    },
    get loading() {
      return loading;
    },
    get members() {
      return teamData ? [$object, ...teamData.users.edges.map(e => e.node)] : [$object];
    },
    get memberObjectIds() {
      return api.members.map(m => m.id);
    },
    get data() {
      return edges.map(({ node }) => node);
    },
    get filter() {
      return filter;
    },
    onFilter(data) {
      setFilter(data);
    },
    getColumnValue(row, column) {
      return getHumanized(row.__typename, column.name, getIn(row, column.name));
    },
    handleNavigate(e) {
      navigate(`/lead/${e.lead.objectId}/loan`);
    },
    onPage(page) {
      searchParams.set("page", page.toString());
      navigate(`/${location.pathname}?${searchParams.toString()}`);
    }
  };

  const operation = useMemo(() => pluralize(toFirstLower("Loan")), []);
  const [filter, setFilter] = useState<string[]>(api.memberObjectIds);
  const queryVariables = useMemo(() => ({
    first: offset,
    skip: offset * (currentPage - 1),
    order: {
      type: "[LoanOrder!]",
      value: ["updatedAt_DESC"]
    },
    where: {
      type: `LoanWhereInput`,
      value: {
        assignedTo: {
          have: {
            id: {
              in: filter
            }
          }
        },
        lead: {
          exists: true
        }
      }
    }
  }), [filter, offset, currentPage]);
  const fields = useMemo(() => props.jql.query.find.fields.find(f => Array.isArray(f.edges)).edges.find(n => Array.isArray(n.node)).node, [props.jql.query]);
  const query = useMemo(() => ({
    operation: operation,
    variables: queryVariables,
    fields: [
      "count",
      {
        edges: [
          {
            node: [...fields, { lead: ["objectId"] }]
          }
        ]
      }
    ]
  }), [fields, operation, queryVariables]);

  const { data: { loans: { edges = [], ...rest } = {} } = {}, subscribeToMore, loading, refetch } = useJqlQuery(query,{
    operationName: `ListPipelineLoans`,
  });

  useMounted(() => {
    if (!teamLoading) {
      setFilter(api.memberObjectIds);
    }
  }, [teamLoading]);

  useEffect(() => {
    return subscribeToMore({
      document: SUBSCRIBE_LOANS,
      variables: {
        assignedTo: {
          have: {
            id: {
              in: filter
            }
          }
        },
        lead: {
          exists: true
        }
      },
      updateQuery: (prev) => {
        refetch();
        return prev;
      }
    });
  }, [filter, operation]);

  return {
    ...api,
    total: useMemo(() => api.total, [edges, operation])
  };
}

const SUBSCRIBE_LOANS = gql`
  subscription SubscribeLoans($where:LoanSubscriptionWhereInput) {
    loans(where: $where) {
      event
      node {
        id
      }
    }
  }
`;
