import { Section }                from "@relcu/ui";
import { SelectVariants }         from "@relcu/ui";
import { Select }                 from "@relcu/ui";
import React                      from "react";
import { FC }                     from "react";
import { Box }                    from "@relcu/ui";
import { PipelineDetailsSection } from "../../Section/PipelineSections/DetailsSection";
import { MilestoneSection }       from "../../Section/PipelineSections/MilestoneSection";
import { useMilestoneView }       from "./useMIlestoneView";

export const MilestoneView: FC<any> = React.memo(function MilestoneView(props) {
  const { detailsFields } = props;
  const { loan,loanOptions,setLoanId } = useMilestoneView(props);

  return <Box container direction={"column"} gap={"XXS"} >
    {/*<AlertsSection alerts={[]}/>*/}
    {
      (loanOptions.length > 1) &&
      <Section container gap={"XXS"} alignItems={"end"} alignContent={"center"} direction={"column"}  editable={false} >
        <Select
          label={"Selected loan :"}
          mode={"button"}
          variant={SelectVariants.Ghost}
          placeholder="Choose loan"
          value={loan?.objectId}
          onChange={(select) => setLoanId(select.value)}
          options={loanOptions}
          flexGrow={0}
        />
      </Section>
    }
    <MilestoneSection milestones={loan?.milestones} loanId={loan?.objectId} editable={false}/>
    <PipelineDetailsSection data={loan} fields={detailsFields}/>
  </Box>;
});
