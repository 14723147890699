import React                       from "react";
import { FC }                      from "react";
import { VoiceTemplate }           from "../../../../Advanced/Voice/VocieTemplate";
import { VoiceTemplateClasses }    from "../../../../Advanced/Voice/VocieTemplate/VoiceTemplateClasses";
import { VoiceAction }             from "../../../../Advanced/Voice/VoiceAction";
import { Box }                     from "../../../../Box";
import { Radio }                   from "../../../../Input/Radio";
import { ListRow }                 from "../../../../List";
import { ListRowColors }           from "../../../../List";
import { Section }                 from "../../../../Section";
import { JsonSectionProps }        from "../../JsonSection";
import { VoiceMailSectionClasses } from "./VoiceMailSectionClasses";

export const VoiceMailSection: FC<VoiceMailSectionProps> = React.memo(function VoiceMailSection(props) {
  const { options, ...section } = props;
  return (
    <Section {...section}>
      <Box container direction={"column"} gap={"XS"} flexGrow={1} className={VoiceMailSectionClasses.VoiceMail}>
        <Box container direction="column" gap={"XS"}>
          <Radio name={"default"}
                 onChange={() => options.onChangeDefault(null)}
                 checked={!options.defaultVoicemail} label={"Default voicemail"}/>
          <Box container direction="column" className={VoiceMailSectionClasses.DefaultVoiceMail} gap={"XXS"}>
            <p>Default voicemail text:</p>
            <p>{options.defaultVoiceMailText || `Hi! Thank you for calling. Please leave a brief message and your phone number after the beep and we will get back to you as soon as possible. Have a great day!`}</p>
          </Box>
        </Box>
        <Box container direction="column" gap={"XXS"} className={VoiceMailSectionClasses.NewVoiceMail}>
          <p>Record your own default voicemail</p>
          <VoiceAction busy={options.busy} active={options.active} onClick={options.toggleRecording}/>
        </Box>
        <Box container direction="column" gap={"XS"}>
          {options.voiceMails.map((recording, index) => {
            return recording.uploaded ?
              <Box container key={recording.id + index} gap="XXS">
                {
                  options.editId != recording.id &&
                  <Radio
                    name={"default"}
                    onChange={() => options.onChangeDefault(recording.id)}
                    checked={recording.id == options.defaultVoicemail?.id}/>
                }
                <VoiceTemplate
                  flex={1}
                  key={recording.id + index}
                  onEdit={() => options.setEditId(recording.id)}
                  editing={options.editId == recording.id}
                  voiceTemplate={recording}
                  onCancel={options.onCancel}
                  onDelete={options.deleteRecording}
                  onSave={options.handleSave}
                />
              </Box> :
              recording?.uploadStatus != 'failed' &&
              <ListRow
                key={recording.id + index}
                gap={"XXXL"}
                color={ListRowColors.Grey}
                outlined={false}
                className={VoiceTemplateClasses.VoiceRow}
                style={{ marginLeft: 33 }}>
                <p
                  style={{ color: "var(--color-gray-extra-dark)", fontSize: "var(--typography-body-font-size)" }}>{recording.name} recording
                  is being processed. </p>
              </ListRow>;
          })}
        </Box>
      </Box>
    </Section>
  );
});

export interface VoiceMailSectionProps extends JsonSectionProps {
  options: {
    busy: boolean
    active: boolean
    editId: string
    defaultVoiceMailText?: string
    defaultVoicemail: { id? }
    toggleRecording: (e) => any
    onChangeDefault: (e) => any
    onCancel: () => void
    setEditId: (e) => any
    deleteRecording: (e) => any
    handleSave: (e) => any
    voiceMails: { id, name, uploaded, uploadStatus, url }[]
  };
}
