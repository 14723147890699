import { useApolloClient }                 from "@apollo/client";
import { format }                          from "@relcu/ui";
import { AlertColors }                     from "@relcu/ui";
import { CircularLoader }                  from "@relcu/ui";
import { Ellipsis, FontIcon, ModalFooter } from "@relcu/ui";
import { ModalBody }                       from "@relcu/ui";
import { ListItemText }                    from "@relcu/ui";
import { BackDropLoader }                  from "@relcu/ui";
import { ButtonVariants }                  from "@relcu/ui";
import { ModalProps }                      from "@relcu/ui";
import { Button }                          from "@relcu/ui";
import { ListItemTitle }                   from "@relcu/ui";
import { ListRow }                         from "@relcu/ui";
import { Radio }                           from "@relcu/ui";
import { Alert }                           from "@relcu/ui";
import { TabItem }                         from "@relcu/ui";
import { Tabs }                            from "@relcu/ui";
import { TabPanel }                        from "@relcu/ui";
import { TabPanelContainer }               from "@relcu/ui";
import { TabContainer }                    from "@relcu/ui";
import { EmptyList }                       from "@relcu/ui";
import { Modal }                           from "@relcu/ui";
import { CommonClasses }                   from "@relcu/ui";
import { useSource }                       from "@relcu/ui";
import { Box }                             from "@relcu/ui";
import { SetStateAction }                  from "react";
import { Dispatch }                        from "react";
import { useCallback }                     from "react";
import React                               from "react";
import { FC }                              from "react";
import { useMemo }                         from "react";
import { useState }                        from "react";
import { GetPmi }                          from "../__types__/GetPmi";
import "./pmi-dialog.css";
import { PmiDialogClasses }                from "./PmiDialogClasses";

export const productTypesMap = {
  monthly: "MONTHLY",
  single_lp: "LENDER SINGLE",
  single_nr: "SINGLE NON REFUNDABLE",
  single_r: "SINGLE",
  split_bd: "SPLIT PREMIUM"
};

export interface PmiPartnerDialogProps extends Partial<ModalProps> {
  reFetch: (providerIds: string[], providerTypes: string[]) => void
  pmiRates: GetPmi["getPmiRates"]
  loading?: boolean
  onApply: ()=>void
  selectedPmi: any
  selectedType: string
  setSelectedId: Dispatch<SetStateAction<string>>
  setSelectedType: Dispatch<SetStateAction<string>>
  selectedId: string
}
export const PmiDialog: FC<PmiPartnerDialogProps> = React.memo(function PmiDialog(props) {
    const { loading, onClose, onApply, setSelectedId, selectedId, setSelectedType, pmiRates, reFetch, open, selectedPmi, selectedType } = props;
    const pmiErrors = pmiRates?.errors;
    const client = useApolloClient();
    const [reFetchingIdentifiers, setReFetchingIdentifiers] = useState<{ option, providerId }>();
    const { $settings: { pricing: { pmiProducts = [] } } } = useSource();
    const reloadRate = useCallback(async (providerId: string, option: string) => {
      setReFetchingIdentifiers({ providerId, option });
      await reFetch([providerId], [option]);
    }, [client]);
    const rates = pmiRates?.rates;
    const options = useMemo(() => Object.keys(rates || {}).filter(k => pmiProducts.includes(k)), [rates]);//todo filter is tmp solution for do not interfere getPmiRates merge logic
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(selectedPmi ? options.indexOf(selectedPmi.option) : 0);

    return <Modal
      title={"PMI"}
      onClose={props.onClose}
      open={open}
      closeIcon={true}
      disableBackdropClick={false}
      className={PmiDialogClasses.Modal}
    >
      <ModalBody direction={"column"} gap={"S"} className={PmiDialogClasses.Container}>
        {pmiErrors ?
          <Box container gap={"XXXS"} direction={"column"} flex={"1 0 auto"} alignItems={"center"} justify={"end"}>
            {pmiErrors.map((err, key) => {
              return <Alert key={key} variant={AlertColors.Error}
                            children={`${err.providerId}: ${err.message}`}
                            width={"100%"}/>;
            })}
          </Box> : null}
        {
          options.length > 0 ?
            <>
              <TabContainer gap={"S"} outerState={[selectedOptionIndex, setSelectedOptionIndex]}>
                <TabPanelContainer>
                  {
                    options.map((option, key) => {
                      return <TabPanel style={{ overflowY: "hidden" }} key={`tab-panel-${key}`}
                                       active={selectedOptionIndex == key}>
                        <Box container direction={"column"} gap={"XXS"} flex={1}>
                          {rates[ option ].map((rate, k) => {
                            return <Radio
                              disabled={rate.errorMessage}
                              checked={selectedType == option && selectedId == rate.providerId}
                              onChange={() => {
                                setSelectedId(rate.providerId);
                                setSelectedType(option);
                              }}
                              name="pmiPartner"
                              key={`tab-panel-item-${k}`}
                              label={<ListRow className={PmiDialogClasses.PmiPartner} flex={1} gap={"XS"}>
                                <ListItemTitle title={rate.providerName} flexBasis={"20%"}/>
                                {
                                  (loading && reFetchingIdentifiers.providerId == rate.providerId && reFetchingIdentifiers.option == option) ?
                                    <Box container gap={"XXS"} flex={"1 0 15%"} alignItems={"center"} justify={"end"}>
                                      <CircularLoader/>
                                      <ListItemText text={"Loading..."}/>
                                    </Box>
                                    :
                                    rate.errorMessage ?
                                      <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"}
                                           justify={"end"}>
                                        <ListItemText text={<Ellipsis
                                          style={{ pointerEvents: "auto", color: "var(--color-error)" }}
                                          from={90}>{rate.errorMessage}</Ellipsis>} tiny/>
                                        <Button style={{ pointerEvents: loading ? "none" : "auto" }}
                                                disabled={loading} variant={ButtonVariants.Ghost}
                                                onClick={() => reloadRate(rate.providerId, option)}>
                                          Reload
                                        </Button>
                                      </Box>
                                      :
                                      <>
                                        <Box container gap={"XXXS"} flexBasis={"30%"} flexShrink={0}
                                             alignItems={"center"}>
                                          <ListItemText text={"Rate:"} tiny/>
                                          <ListItemText
                                            text={`${format(rate.rate, 3)}% / $${format(rate.premium, 2)}`}/>
                                        </Box>
                                        <Box container gap={"XXXS"} flex={"1 0 15%"} alignItems={"center"}>
                                          <ListItemText text={"Ref:"} tiny/>
                                          <ListItemText text={rate.ref}/>
                                        </Box>
                                        <Box container gap={"XXXS"} alignItems={"center"}>
                                          <ListItemText text={"Full quote:"} alignItems={"center"} tiny/>
                                          <a href={rate.link} style={{ textDecoration: "none" }} target="_blank">
                                            <FontIcon className={CommonClasses.ClickableIcon} type={"rc_pdf_doc"}/>
                                          </a>
                                        </Box>
                                      </>
                                }
                              </ListRow>
                              }/>;
                          })
                          }
                        </Box>
                      </TabPanel>;
                    })
                  }
                </TabPanelContainer>
                <Tabs>
                  {
                    options.map((option, key) => (
                      <TabItem active={selectedOptionIndex == key} onClick={() => setSelectedOptionIndex(key)}
                               key={key}>{productTypesMap[ option ]}</TabItem>))
                  }
                </Tabs>
              </TabContainer>
              <ModalFooter>
                <Button variant={ButtonVariants.Outline} onClick={() => onClose()}>CANCEL</Button>
                <Button variant={ButtonVariants.Fill} onClick={() => onApply()}>APPLY</Button>
              </ModalFooter>
            </>
            :
            pmiErrors && !pmiErrors.length && <Box flex={"1 1 auto"} justify={"center"}>
              <EmptyList style={{ padding: 20 }} icon={"pie_chart"} content={"Please update lead data and try again."}
                         title={"No matching rates"}
                         alignSelf={"center"}/>
            </Box>
        }
      </ModalBody>
      {
        (loading && !reFetchingIdentifiers?.providerId) && <BackDropLoader/>
      }
    </Modal>;
  }
);

export default PmiDialog;
