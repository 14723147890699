import React              from "react";
import { useState }       from "react";
import { useRef }         from "react";
import { HTMLAttributes } from "react";
import { useClassNames }  from "../utils";
import { TabClasses }     from "./TabClasses";

export interface TabsSwitcherProps extends HTMLAttributes<HTMLDivElement> {
  onChange?(index);
  size?: "md" | "sm";
  itemsWidth?: number;
}
export function TabsSwitcher(props: TabsSwitcherProps) {
  const { className, children, onChange, size = "sm", itemsWidth = 131, ...p } = props;
  const { merge, withClassPrefix } = useClassNames(TabClasses.TabsSwitcher, "rc");
  const classes = merge(className, withClassPrefix(size));
  const ref = useRef(null);
  const [activePosition, setActivePosition] = useState(2);
  const handleClick = (event) => {
    const targetBounding = event.target.getBoundingClientRect();
    const containerBounding = ref.current.getBoundingClientRect();
    const left = targetBounding.left - containerBounding.left;
    setActivePosition(left);
  };

  return <div
    ref={ref}
    style={{ "--active-position": `${activePosition}px`, "--item-width": `${itemsWidth}px` } as any}
    className={classes}
    {...p}>
    {
      React.Children.map(children, (item) => {
        if (React.isValidElement<any>(item)) {
          return React.cloneElement(item, {
            ...item.props,
            onClick: handleClick
          });
        }
        return item;
      })
    }
  </div>;
}
