import React                                from "react";
import { useCallback }                      from "react";
import { useContext }                       from "react";
import { useMemo }                          from "react";
import { FC }                               from "react";
import { setIn }                            from "@relcu/final-form";
import { modal }                            from "@relcu/ui";
import { pick }                             from "@relcu/ui";
import { useLatest }                        from "@relcu/ui";
import { deepEqual }                        from "@relcu/ui";
import { omit }                             from "@relcu/ui";
import { useApolloClient }                  from "@apollo/client";
import { useLazyQuery }                     from "@apollo/client";
import { gql }                              from "@apollo/client";
import { useMutation }                      from "@apollo/client";
import { FormApi }                          from "@relcu/final-form";
import { getIn }                            from "@relcu/final-form";
import { arrayMutators }                    from "@relcu/final-form";
import { useNavigate }                      from "@relcu/react-router";
import { Form }                             from "@relcu/rc";
import { EmptyModal }                       from "@relcu/rc";
import { useSource }                        from "@relcu/ui";
import { LoanEstimateOffer }                from "../../../../../../graph/__types__/LoanEstimateOffer";
import { LOAN_ESTIMATE_OFFER }              from "../../../../../../graph/operations.graphql";
import { cleanObject }                      from "../../../../../../utils/helpers";
import { useSchemaField }                   from "../../../../useSchemaField";
import { StateRef }                         from "../../PricingBetaView";
import { Proposal }                         from "../../Proposal";
import { GetLoanEstimateVariables }         from "../../Proposal/__types__/GetLoanEstimate";
import { GetLoanEstimate }                  from "../../Proposal/__types__/GetLoanEstimate";
import { GET_LOAN_ESTIMATE }                from "../../Proposal/ProposalProvider";
import { useAppendObCustomFields }          from "../../useDefaultOffer";
import { useDefaultOffer }                  from "../../useDefaultOffer";
import { totalLoanAmount }                  from "../../utils";
import { CreateLoanEstimateOfferVariables } from "./__types__/CreateLoanEstimateOffer";
import { CreateLoanEstimateOffer }          from "./__types__/CreateLoanEstimateOffer";
import { GetPricingRatesVariables }         from "./__types__/GetPricingRates";
import { GetPricingRates }                  from "./__types__/GetPricingRates";
import { UpdateLoanEstimateOfferVariables } from "./__types__/UpdateLoanEstimateOffer";
import { UpdateLoanEstimateOffer }          from "./__types__/UpdateLoanEstimateOffer";
import { OfferCalculate }                   from "./OfferCalculate";
import { OfferHeader }                      from "./OfferHeader";
import { PricingEngineContext }             from "./PricingEngineProvider";
import { useCalculations }                  from "./useCalculations";

export const Offer: FC<{ data: Partial<LoanEstimateOffer>, onRatesRedirect?: () => void }> = React.memo((props) => {
  const { children, data, onRatesRedirect } = props;
  const { $object: lead } = useSource();
  const { data: { rate = 0, apr = 0, price = 100 } = {} } = useDefaultOffer();
  const navigate = useNavigate();
  const calculate = useCalculations();
  const loanEstimate = useContext(Proposal.Context);
  const { defaultValue: pricingEngineDefaultValue } = useSchemaField("LoanEstimateOffer", "pricingEngine");
  const { defaultValue: lockInDaysDefaultValue } = useSchemaField("LoanEstimateOffer", "lockInDays");
  const { defaultValue: veteranStatusDefaultValue } = useSchemaField("LoanEstimateOffer", "veteranStatus");
  const { defaultValue: documentTypeDefaultValue } = useSchemaField("LoanEstimateOffer", "documentType");
  const { defaultValue: initialArmTermValue } = useSchemaField("LoanEstimateOffer", "initialArmTerm");

  const { defaultValue: dtiDefaultValue } = useSchemaField("LoanEstimateOffer", "dti");
  const client = useApolloClient();
  const { $settings: { pricing: settings } } = useSource();
  const fico = lead.members.find((members) => members.type == "borrower")?.creditScore;
  const fico1 = lead.members.find((members) => members.type == "co_borrower")?.creditScore;
  const formData = useMemo(() => {
    return pick(data, [
      "id",
      "objectId",
      "objectName",
      "mortech.investor",
      "mortech.loanProductId",
      "mortech.source",
      "mortech.view",
      "optimalBlue.productTypes",
      "pricingEngine",
      "loanProduct",
      "productType",
      "conforming",
      "amortizationType",
      "loanTerm",
      "propertyValue",
      "isHUDReo",
      "cashAmount",
      "currentMortgageBalance",
      "loanAmount",
      "mip",
      "financeMip",
      "ff",
      "financeFf",
      "totalLoanAmount",
      "downPayment",
      "ltv",
      "cltv",
      "dti",
      "secondaryFinancing",
      "fico",
      "fico1",
      "firstTimeHomeBuyer",
      "isStreamLine",
      "withAppraisal",
      "withCredit",
      "firstTimeUse",
      "firstUseOfVaProgram",
      "veteranStatus",
      "exempt",
      "waiveEscrow",
      "lockInDays",
      "amiWaiverEligibility",
      "loanPurpose",
      "initialArmTerm",
      "documentType",
      "propertyUse",
      "monthlyIncome",
      "lienAmount"
    ], true);
  }, [data]);

  const context = useContext(PricingEngineContext);
  const initialValues = useMemo(() => {
    let initialData: Partial<LoanEstimateOffer> = formData;
    if (!data.objectId) {
      let borrower = lead.members.find((b) => b.type == "borrower");
      initialData = {
        propertyUse: getIn(lead, "property.use"),
        propertyType: loanEstimate.propertyType,
        pricingEngine: pricingEngineDefaultValue,
        propertyValue: getIn(lead, "property.value") || 500000,
        loanAmount: getIn(lead, "loanAmount"),
        secondaryFinancing: getIn(lead, "secondaryFinancing"),
        cltv: getIn(lead, "cltv"),
        downPayment: getIn(lead, "downPayment"),
        fico: fico,
        fico1: fico1,
        lockInDays: lockInDaysDefaultValue,
        amortizationType: "fixed",
        conforming: true,
        loanTerm: "360",
        productType: "conventional",
        documentType: documentTypeDefaultValue,
        initialArmTerm: initialArmTermValue,
        firstUseOfVaProgram: getIn(lead, "firstUseOfVaProgram"),
        veteranStatus: getIn(borrower, "veteranStatus") || veteranStatusDefaultValue,
        financeFf: getIn(lead, "financeFf"),
        financeMip: getIn(lead, "financeMip"),
        dti: getIn(lead, "dti") || dtiDefaultValue,
        waiveEscrow: getIn(lead, "waiveEscrow"),
        monthlyIncome: borrower.monthlyIncome,
        get currentMortgageBalance() {
          const currentMortgageBalance = getIn(lead, "property.currentMortgageBalance");
          if (["rate_term_refinance", "cash_out_refinance"].includes(loanEstimate.loanPurpose)) {
            return currentMortgageBalance ? currentMortgageBalance : getIn(lead, "loanAmount");
          } else {
            return getIn(lead, "loanAmount");
          }
        },
        get cashAmount() {
          return this.loanAmount - this[ "currentMortgageBalance" ] > 0 ? this.loanAmount - this[ "currentMortgageBalance" ] : 0;
        },
        get totalLoanAmount() {
          return totalLoanAmount(loanEstimate, initialData, settings);
        },
        ...initialData
      };
    }
    initialData.mortech = {
      investor: context.investorIds,
      loanProductId: context.loanProductId,
      ...initialData.mortech
    };

    if (initialData.pricingEngine === "optimalblue") {
      initialData.optimalBlue = { productTypes: [], ...cleanObject({ ...data.optimalBlue }) };
    }

    initialData.propertyType = loanEstimate.propertyType;

    const changes = calculate(initialData);
    Object.entries(changes).forEach(([key, value]) => {
      initialData = setIn(initialData, key, value);
    });
    return cleanObject({ ...initialData });
  }, [loanEstimate, lead, formData, data.objectId, settings, pricingEngineDefaultValue, context]);

  const dataIdRef = useLatest(data.id);
  const [create] = useMutation<CreateLoanEstimateOffer, CreateLoanEstimateOfferVariables>(useAppendObCustomFields(CREATE_LOAN_ESTIMATE_OFFER), {
    refetchQueries: ["GetDefaultOffer"],
    update(cache, { data: { createLoanEstimateOffer: { loanEstimateOffer } } }) {
      const { loanEstimates } = cache.readQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true },
            deleted: { equalTo: false }
          }
        }
      });
      cache.writeQuery<GetLoanEstimate, GetLoanEstimateVariables>({
        query: useAppendObCustomFields(GET_LOAN_ESTIMATE),
        data: {
          loanEstimates: {
            ...loanEstimates,
            edges: [
              {
                ...loanEstimates.edges[ 0 ],
                node: {
                  ...loanEstimates.edges[ 0 ].node,
                  offers: {
                    ...loanEstimates.edges[ 0 ].node.offers,
                    edges: [
                      ...loanEstimates.edges[ 0 ].node.offers.edges.filter(e => e.node.id !== dataIdRef.current),
                      {
                        node: {
                          ...loanEstimateOffer,
                          isValid: true,
                          isDirty: false,
                          isTitleFeeEditable: false
                        },
                        __typename: "LoanEstimateOfferEdge"
                      }
                    ]
                  }
                }
              }
            ]
          }
        },
        variables: {
          where: {
            lead: { have: { id: { equalTo: lead.id } } },
            draft: { equalTo: true }
          }
        }
      });
      cache.evict({ id: client.cache.identify({ __typename: "LoanEstimateOffer", id: dataIdRef.current }) });
    }
  });
  const [update] = useMutation<UpdateLoanEstimateOffer, UpdateLoanEstimateOfferVariables>(UPDATE_LOAN_ESTIMATE_OFFER, {
    refetchQueries: ["GetDefaultOffer"]
  });
  const [getRates] = useLazyQuery<GetPricingRates, GetPricingRatesVariables>(GET_PRICING_RATES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const handleCreate = useCallback(async (values, form: FormApi) => {
    const { id, objectId, points, __typename, objectIcon, isValid, isTitleFeeEditable, isDirty, ...rest } = values;
    let offer;
    const dirty = form.getState().dirty;
    const resetFees = dirty ? {
      titleCompany: null,
      titleCompanyName: null,
      ownersTitle: null,
      recordingCharges: null,
      settlementFee: null,
      titleInsurance: null,
      transferTax: null,
      lendersTitle: null,
      pmiCompany: null,
      pmiType: null,
      pmi: null,
      appraisalFee: rest.withAppraisal ? rest.appraisalFee : null
    } : {};
    const params = rest.optimalBlue ? {
      ...rest, optimalBlue: omit(rest.optimalBlue, ["__typename"]
      )
    } : rest;
    if (objectId) {
      offer = await update({
        variables: {
          input: {
            id: objectId,
            fields: {
              loanEstimate: {
                link: loanEstimate.id
              },
              ...omit({
                ...params,
                ...resetFees,
                mortech: params.mortech ? omit(params.mortech, ["__typename"]) : params.mortech
              }, ["__typename", "objectIcon"])
            }
          }
        }
      });
    } else {
      offer = await create({
        variables: {
          input: {
            fields: {
              lead: {
                link: lead.id
              },
              loanEstimate: {
                link: loanEstimate.id
              },
              ...{
                ...params,
                appraisalFee: params.withAppraisal ? params.appraisalFee : null,
                rate: params.pricingEngine != "manual" ? null : params.rate ?? rate,
                apr: params.pricingEngine != "manual" ? null : params.apr ?? apr,
                price: params.pricingEngine != "manual" ? null : params.price ?? price,
                createdAt: data.createdAt,
                mortech: params.mortech ? omit(params.mortech, ["__typename"]) : params.mortech
              }
            }
          }
        }
      });
    }

    const loanEstimateOffer = offer.data[ `${objectId ? "update" : "create"}LoanEstimateOffer` ].loanEstimateOffer;
    const getRatesData = client.readQuery<GetPricingRates, GetPricingRatesVariables>({
      query: GET_PRICING_RATES,
      variables: {
        offerId: loanEstimateOffer.objectId
      }
    });
    if (values.pricingEngine === "manual") {
      return;
    } else if (values.pricingEngine === "optimalblue" && loanEstimateOffer.propertyType === "5_unit") {
      const { destroy } = modal(EmptyModal, {
        header: "Not supported",
        label: "CONTINUE",
        title: `Selected property type is not supported by OptimalBlue. Please adjust the provided information and click on 'UPDATE RATES' to see results`,
        onSubmit() {
          destroy();
        }
      });
    } else if (!getRatesData?.getRates?.rates?.length || dirty) {
      const { data: { getRates: { searchId, rates = [], errors } } = {}, loading } = await getRates({
        variables: {
          offerId: loanEstimateOffer.objectId
        }
      });
      await update({
        variables: {
          input: {
            id: loanEstimateOffer.objectId,
            fields: {
              rateId: null,
              rate: null,
              apr: null,
              price: null

            }
          }
        }
      });
      if (rates?.length) {
        onRatesRedirect?.();
        return navigate(`${loanEstimateOffer.objectId}/rates`);
      } else {
        let message = `Please adjust the provided information and click on 'UPDATE RATES' to see results.${searchId ? `\nSearchID:${searchId}` : ""}`;
        if (errors) {
          message = errors;
        }
        const { destroy } = modal(EmptyModal, {
          header: "No rate results",
          label: "CONTINUE",
          title: message,
          onSubmit() {
            destroy();
          }
        });
      }
    } else {
      onRatesRedirect?.();
      return navigate(`${loanEstimateOffer.objectId}/rates`);
    }
  }, [lead]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        onSubmit={handleCreate}
        initialValuesEqual={(a, b) => deepEqual(a, b)}
        formProps={{
          onKeyPress: handleKeyPress
        }}
        mutators={{
          ...arrayMutators
        }}>
        <OfferHeader/>
        {children}
        <OfferCalculate/>
      </Form>
    </>
  );
});

export const UPDATE_LOAN_ESTIMATE_OFFER = gql`
  mutation UpdateLoanEstimateOffer($input: UpdateLoanEstimateOfferInput!) {
    updateLoanEstimateOffer(input: $input) {
      loanEstimateOffer {
        ... LoanEstimateOffer
      }
    }
  }
  ${LOAN_ESTIMATE_OFFER}
`;

export const CREATE_LOAN_ESTIMATE_OFFER = gql`
  mutation CreateLoanEstimateOffer($input: CreateLoanEstimateOfferInput!) {
    createLoanEstimateOffer(input: $input) {
      loanEstimateOffer {
        ... LoanEstimateOffer
      }
    }
  }
  ${LOAN_ESTIMATE_OFFER}
`;

export const GET_PRICING_RATES = gql`
  query GetPricingRates ($offerId: String!) {
    getRates (offerId: $offerId) {
      searchId
      engine
      rates {
        __typename
        productName
        productId
        apr
        lastUpdate
        pi
        rate
        vendorName
        price
        monthlyPremium
        rateId
      }
      errors
    }
  }
`;
