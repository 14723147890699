import React                     from "react";
import { useState }              from "react";
import { useCallback }           from "react";
import { useRef }                from "react";
import { AvatarSizes }           from "../../Avatar";
import { Avatar }                from "../../Avatar";
import { ChipsVariants }         from "../../Chips";
import { ChipsColors }           from "../../Chips";
import { Chips }                 from "../../Chips";
import { InputState }            from "../../Input/Input";
import { SearchableMultiSelect } from "../../Input/SearchableMultiSelect";
import { MenuItem }                     from "../../Menu";
import { useConsumeField }              from "../BaseField";
import { PointerListFieldProps }        from "./PointerListField";

export const PointerListEditField = React.memo(function PointerListEditField(props) {
  const selectRef: any = useRef();
  const { input: { thumbnail, collapse, targetClass, targetField, ...input }, meta: { touched, error, submitError } } = useConsumeField<PointerListFieldProps>();
  const [selectedFilter, setSelectedFilter] = useState(false);
  const hasError = (touched && !!error) || !!submitError;
  const handleDelete = (option) => {
    input.onChange([...input.value.filter(i => i.id !== option.id)]);
  };

  const handleSelect = useCallback((data) => {
    input.onChange(data);
  }, [input, selectRef.current]);

  function handleModeChange (selectedMod) {
    setSelectedFilter(selectedMod)
    input?.onSelectedFilter?.(selectedMod ? input.value : [])
  }

  return (
    <SearchableMultiSelect
      ref={selectRef}
      showSelectedCount
      options={input.options}
      onChange={handleSelect}
      value={input.value}
      optionKey={"objectId"}
      renderOption={(option) => (
        <MenuItem
          container
          thumbnail={thumbnail ?
            <Avatar icon={option.objectIcon} size={AvatarSizes.Small} text={option.objectName}/> : null}>
          {option.objectName}
        </MenuItem>
      )}
      renderSelectedItem={(selected) => {
        return <Chips
          key={selected.objectId}
          color={ChipsColors.Grey}
          label={selected.objectName}
          variant={ChipsVariants.Fill}
          onDelete={!input.readOnly ? () => handleDelete(selected) : null}
          thumbnail={thumbnail ?
            <Avatar icon={selected.objectIcon} size={AvatarSizes.Mini} text={selected.objectName}/> : null}
        />
      }}
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      {...input}
      selectedFilter={selectedFilter}
      onSelectedFilter={handleModeChange}
    />
  );
});
export default PointerListEditField;
