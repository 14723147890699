import { ApolloProvider }         from "@apollo/client";
import { AlertColors }            from "@relcu/ui";
import { Alert }                  from "@relcu/ui";
import { useAsync }               from "@relcu/ui";
import { Spinner }                from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { MicrophoneAccessDialog } from "@relcu/ui";
import { ComponentsProvider }     from "@relcu/ui";
import React                      from "react";
import { useContext }             from "react";
import { ClientContext }          from "../Client";
import { useNewVersion }          from "./AlertBar";
import { AlertBar }               from "./AlertBar";
import { components }             from "./Layout";
import { Layout }                 from "./Layout";
import { ReauthorizeModal }       from "./Modal/ReauthorizeModal";
import { NavBar }                 from "./NavBar";
import { Root }                   from "./Root";
import { SettingsSubscription }   from "./SettingsSubscription";
import { SideBar }                from "./SideBar";
import { SnackBar }               from "./SnackBar";
import { Sound }                  from "./Sound";
import { useMicrophoneAccess }    from "./useMicrophoneAccess";
import { useReactiveStorage }     from "./useReactiveStorage";

export const App = React.memo(function App() {
  const context = useContext(ClientContext);
  const microphoneAccess = useMicrophoneAccess();
  const [navbarActive] = useReactiveStorage("@navbar", true);
  const { loading } = useAsync(() => context.setup(), []);
  const isNewVersionAvailable = useNewVersion();

  if (loading || !context.client) {
    return <Spinner/>;
  }

  return (
    <ApolloProvider client={context.client}>
      <Root>
        <ComponentsProvider components={components}>
          <AlertBar/>
          <Box container className="main" style={isNewVersionAvailable ? {height:"calc(100vh - 30px)"} : undefined}>
            <SettingsSubscription/>
            <SideBar/>
            {navbarActive && <NavBar/>}
            <Box container className="content" justify={"end"}>
              <Layout/>
              <SnackBar/>
              <MicrophoneAccessDialog open={!microphoneAccess}/>
              <Sound/>
              <ReauthorizeModal/>
            </Box>
          </Box>
        </ComponentsProvider>
      </Root>
    </ApolloProvider>
  );
});

