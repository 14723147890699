import { gql }                         from "@apollo/client";
import { useNavigate }                 from "@relcu/react-router";
import React                           from "react";
import { useCallback }                 from "react";
import { DateTime }                    from "luxon";
import { useSubscription }             from "@apollo/client";
import { useQuery }                    from "@apollo/client";
import { useMutation }                 from "@apollo/client";
import { classNames }                  from "@relcu/ui";
import { AvatarSizes }                 from "@relcu/ui";
import { Ellipsis }                    from "@relcu/ui";
import { Avatar }                      from "@relcu/ui";
import { Keys }                        from "@relcu/ui";
import { Input }                       from "@relcu/ui";
import { FontIcon }                    from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { EmptyList }                   from "@relcu/ui";
import { Widget }                      from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { SubscriptionEvent }           from "../../../../types/graphql-global-types";
import { usePermissions }              from "../../../AccessControl";
import { GetLastNotesVariables }       from "./__types__/GetLastNotes";
import { GetLastNotes }                from "./__types__/GetLastNotes";
import { SendNewNoteVariables }        from "./__types__/SendNewNote";
import { SendNewNote }                 from "./__types__/SendNewNote";
import { SubscribeLastNotesVariables } from "./__types__/SubscribeLastNotes";
import { SubscribeLastNotes }          from "./__types__/SubscribeLastNotes";
import { NoteWidgetClasses }           from "./NoteWidgedClasses";
import "./note-widget.css";

export const NoteWidget = React.memo(function NoteWidget() {
  const { $object: node, $viewer } = useSource();
  const [note, setNote] = React.useState("");
  const { canUpdate } = usePermissions(node);
  const navigate = useNavigate();
  const {
    data: { notes: { edges = [] } = {} } = {},
    refetch
  } = useQuery<GetLastNotes, GetLastNotesVariables>(GET_LAST_NOTES, {
    variables: { id: node.id },
    fetchPolicy: "no-cache"
  });
  const [send, { loading }] = useMutation<SendNewNote, SendNewNoteVariables>(SEND_NEW_NOTE, {
    update(cache, { data: { createNote: { note: node } } }) {
      setNote("");
    }
  });

  useSubscription<SubscribeLastNotes, SubscribeLastNotesVariables>(SUBSCRIBE_LAST_NOTES, {
    variables: {
      link: node.id
    },
    onData({ client, data: { data: { notes: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          refetch();
      }
    }
  });

  const onSend = useCallback(async () => {
    try {
      await send({
        variables: {
          input: {
            subject: {
              link: node.id
            },
            owner: {
              link: $viewer.id
            },
            text: note
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [send, note]);

  return (
    <Widget title="Notes" className={NoteWidgetClasses.NoteWidget}>
      {edges.length == 0 ?
        <Box
          container
          direction={"column"}
          alignItems={"center"}
          justify={"center"}
          className={NoteWidgetClasses.NoteWidgetEmptyView}
          alignSelf={"center"}
          gap={"XXS"} flex={1}
        >
          <EmptyList icon={"rc_note"} content={"Click to \"Add note...\" below to get started"} title={"No notes"}
                     alignSelf={"center"} small/>
        </Box> :
        <Box
          container
          direction={"column"}
          gap={"XXS"}
          flex={1}>
          {
            edges.map(({ node }, index) =>
              <Box key={index} container gap={"XXS"} alignItems={"center"} className={NoteWidgetClasses.NoteWidgetItem}>
                <Avatar size={AvatarSizes.Small} text={node.owner?.objectName ?? "Relcu"} icon={node.owner?.objectIcon}  onClick={() => node.owner && navigate(`/user/${node.owner.objectId}`)} />
                <Box container gap={"XXS"} flex={1} justify={"space-between"} alignItems={"center"}>
                  <Ellipsis from={22} className={NoteWidgetClasses.NoteWidgetItemContent}>{node.text}</Ellipsis>
                </Box>
                <span>{DateTime.fromISO(node.createdAt).toFormat("MMM dd, h:mm a")}</span>
              </Box>
            )
          }
        </Box>
      }
      <Box container justify={"space-between"} alignItems={"center"} gap={"XXS"}
           className={classNames(NoteWidgetClasses.NoteWidgetActions, {
             [ NoteWidgetClasses.Active ]: !!note?.trim() && !loading
           })}>
        <Input
          placeholder={"Add note..."}
          onChange={(value) => (value?.length < 3000 || value == null) && setNote(value)}
          flex={1}
          readOnly={!canUpdate}
          value={note}
          onKeyPress={(e) => {
            if (e.key == Keys.Enter && !e.shiftKey && note?.trim() && !loading) {
              onSend();
            }
          }}
        />
        <FontIcon type={"done"} onClick={onSend}/>
      </Box>
    </Widget>
  );
});

const GET_LAST_NOTES = gql`
  query GetLastNotes($id: ID!) {
    notes(
      last: 3,
      order: [createdAt_ASC]
      where: {
        subject: {
          have: {
            link: $id
          }
        }
      }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          objectId
          objectIcon
          objectName
          text
          createdAt
          owner {
            id
            objectId
            objectIcon
            objectName
          }
        }
      }
    }
  }
`;
const SEND_NEW_NOTE = gql`
  mutation SendNewNote($input:CreateNoteFieldsInput!){
    createNote(input: {
      fields: $input
    }){
      clientMutationId
      note {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;

const SUBSCRIBE_LAST_NOTES = gql`
  subscription SubscribeLastNotes($link: ID) {
    notes(where: {subject: {have: {link: $link}}}) {
      event
      node {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        subject {
          ...on Document{
            objectId
            objectIcon
            objectName 
          }
        }
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;
