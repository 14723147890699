import { useContext }             from "react";
import React                      from "react";
import { FC }                     from "react";
import { useMemo }                from "react";
import { useState }               from "react";
import { useReactiveVar }         from "@apollo/client";
import { Select }                 from "@relcu/ui";
import { classNames as clsNames } from "@relcu/ui";
import { Box }                    from "@relcu/ui";
import { ClientContext }          from "../../../../../Client";
import { audioDevicesVar }        from "../../../../../reactiveVars";
import { audioInputIdVar }        from "../../../../../reactiveVars";
import { audioOutputIdVar }       from "../../../../../reactiveVars";
import { SoundSectionClasses }    from "../SoundSectionClasses";
import { AudioController }        from "./AudioController";

export const Devices: FC<any> = React.memo(function Devices(props) {
  const { className, ...p } = props;
  const { tw } = useContext(ClientContext);
  const outputId = useReactiveVar(audioOutputIdVar);
  const inputId = useReactiveVar(audioInputIdVar);
  const classNames = clsNames(SoundSectionClasses.Devices, className);
  const [canChangeMic, setCanChangeMic] = useState(true);
  const [canChangeSpeak, setCanChangeSpeak] = useState(true);
  const devices = useReactiveVar(audioDevicesVar);
  const selectedInput = useMemo(() => devices.audioinput.find(d => d.deviceId == inputId), [inputId, devices]);
  const selectedOutput = useMemo(() => devices.audiooutput.find(d => d.deviceId == outputId), [outputId, devices]);
  function setOutput(device) {
    if (device) {
      tw.outputId = device.deviceId;
    }
  }
  function setInput(device) {
    if (device) {
      tw.inputId = device.deviceId;
    }
  }

  return <Box container flexShrink={1} justify={"space-between"} className={classNames} gap={"M"} {...p}>
    <Box container direction={"column"} gap={"XS"}>
      <Select
        disabled={!canChangeMic || !devices.audioinput.length}
        placeholder={"Select microphone"}
        label="Microphone"
        icon="mic"
        value={selectedInput}
        onChange={setInput}
        options={devices.audioinput}
        optionLabel={"label"}
        optionKey={"deviceId"}/>
      <Select
        disabled={!canChangeSpeak || !devices.audiooutput.length}
        placeholder={"Select speaker"}
        label="Speaker"
        icon="speaker"
        value={selectedOutput}
        onChange={setOutput}
        options={devices.audiooutput}
        optionLabel={"label"}
        optionKey={"deviceId"}/>
    </Box>
    <AudioController
      disabled={!devices.audiooutput.length}
      onListen={(play) => setCanChangeSpeak(play)}
      onRecord={(record) => setCanChangeMic(record)}
      inputDeviceId={inputId}
      outputDeviceId={outputId}
    />
  </Box>;
});
