import { useEffect }           from "react";
import { useReactiveVar }      from "@apollo/client";
import { microphoneAccessVar } from "../reactiveVars";
import { isAuthenticatedVar }  from "../reactiveVars";

export function useMicrophoneAccess() {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);
  const microphoneAccess = useReactiveVar(microphoneAccessVar);
  useEffect(() => {
    if (isAuthenticated) {
      navigator.permissions.query({ name: "microphone" as any}).then((permissionStatus) => {
        if (permissionStatus.state == "denied") {
          microphoneAccessVar(false);
        }
        permissionStatus.onchange = function () {
          if (permissionStatus.state == "denied") {
            microphoneAccessVar(false);
          }
        };
      });
    }
  }, [isAuthenticated]);

  return microphoneAccess;
}
