import React                              from "react";
import { Subscription }                   from "@apollo/client/react/components";
import { AlertPosition }                  from "@relcu/ui";
import { SubscribeUnreadNotifications }   from "../../../graph/__types__/SubscribeUnreadNotifications";
import { SUBSCRIBE_UNREAD_NOTIFICATIONS } from "../../../graph/operations.graphql";
import { SubscriptionEvent }              from "../../../types/graphql-global-types";
import { BulkMailNotification }           from "../Notifications/Items/BulkMailNotification";
import { BulkSMSNotification }            from "../Notifications/Items/BulkSMSNotification";
import { CallNotification }               from "../Notifications/Items/CallNotification";
import { DistributionLeadNotification }   from "../Notifications/Items/DistributionLeadNotification";
import { EmailNotification }              from "../Notifications/Items/EmailNotification";
import { LoanNotification }               from "../Notifications/Items/LoanNotification";
import { ReminderNotification }           from "../Notifications/Items/ReminderNotification";
import { SmsNotification }                from "../Notifications/Items/SmsNotification";
import { NotificationTitles }             from "../Notifications/NotificationBar";
import { useNotification }                from "./useNotification";

export enum NotificationTypes {
  SMS = "sms",
  MILESTONE_COMMENT = "milestone_comment",
  CALL = "missed_call",
  EMAIL = "email",
  REMINDER = "reminder",
  BULK_EMAIL = "bulk_email",
  BULK_SMS = "bulk_sms",
  DISTRIBUTED_LEAD = "distributed_lead",
}

export enum NotificationControlsMap {
  "sms" = "sms",
  "email" = "emails",
  "missed_call" = "missedCalls",
  "reminder" = "tasksReminders",
  "milestone_comment" = "loanComments",
  "bulk_email" = "bulkEmail",
  "bulk_sms" = "bulkSMS",
  "distributed_lead" = "distributedLead"
}

export const InAppNotification = ({ snackBarState, notificationControls }) => {
  const { getRecord, onNavigate, push } = useNotification();

  const onUnreadNotification = ({ event, node }) => {
    if ((event === SubscriptionEvent.ENTER || event === SubscriptionEvent.CREATE)
      && (node.type == "distributed_lead" || notificationControls[ NotificationControlsMap[ node.type ] ])) {
      const record = getRecord(node);
      toNotify(node.id, record, onNavigate);
      if (snackBarState === "notifications") {
        return;
      }
      switch (node.type) {
        case NotificationTypes.SMS:
          const { id: smsId } = push(<SmsNotification node={record} inApp={true}
                                                      onNavigate={() => onNavigate(record, smsId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.CALL:
          const { id: callId } = push(<CallNotification node={record} inApp={true}
                                                        onNavigate={() => onNavigate(record, callId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.EMAIL:
          const { id: emailId } = push(<EmailNotification node={record} inApp={true}
                                                          onNavigate={() => onNavigate(record, emailId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.REMINDER:
          const { id: reminderId } = push(<ReminderNotification node={record} inApp={true}
                                                                onNavigate={() => onNavigate(record, reminderId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.MILESTONE_COMMENT:
          const { id: milestoneId } = push(<LoanNotification node={record} inApp={true}
                                                             onNavigate={() => onNavigate(record, milestoneId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.BULK_EMAIL:
          const { id: bulkEmailId } = push(<BulkMailNotification node={record} inApp={true}
                                                                 onNavigate={() => onNavigate(record, bulkEmailId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.BULK_SMS:
          const { id: bulkSMSId } = push(<BulkSMSNotification node={record} inApp={true}
                                                              onNavigate={() => onNavigate(record, bulkSMSId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        case NotificationTypes.DISTRIBUTED_LEAD:
          const { id: distributionLeadId } = push(<DistributionLeadNotification node={record} inApp={true}
                                                                                onNavigate={() => onNavigate(record, distributionLeadId)}/>,
            { position: AlertPosition.BOTTOM_RIGHT, autoCloseTimeout: 5000 });
          return;
        default:
          return;
      }
    }
  };
  return <>
    <Subscription<SubscribeUnreadNotifications>
      subscription={SUBSCRIBE_UNREAD_NOTIFICATIONS}
      fetchPolicy="cache-only"
      onData={({ data: { data: { notifications } } }) => onUnreadNotification(notifications)}
    />
  </>;
};

function toNotify(id, node, navigate) {
  let title = "";
  let body = "";
  switch (node.type) {
    case "sms" :
      title = NotificationTitles[ node.type ];
      body = `From ${node.targetObjectName}`;
      break;
    case "missed_call":
      let type = "missed_call";
      if (node.record.voicemail) {
        type = "missed_call_with_voice";
      }
      title = NotificationTitles[ type ];
      body = `From ${node.targetObjectName}`;
      break;
    case "email":
      title = NotificationTitles[ node.type ];
      body = `From ${node.targetObjectName}`;
      break;
    case "reminder":
      title = NotificationTitles[ node.type ];
      body = node.record.title;
      break;
    case "milestone_comment":
      title = NotificationTitles[ node.type ];
      body = `On ${node.scope.objectName}`;
      break;
    case "bulk_email":
      title = NotificationTitles[ node.type ];
      body = `Operation complete`;
      break;
    case "bulk_sms":
      title = NotificationTitles[ node.type ];
      body = `Operation complete`;
      break;
    case "distributed_lead":
      title = NotificationTitles[ node.type ];
      body = `${node.scope.objectName} is assigned to you through ${node.record.objectName}`;
      break;
  }
  Notification.requestPermission().then(function (status) {
    if (status === "granted") {
      navigator.serviceWorker.ready.then(function (registration) {
        const worker = navigator.serviceWorker.controller || registration.active;
        worker?.postMessage({ action: "check" });//tmp can't reproduce
        navigator.serviceWorker.onmessage = (event) => {
          if (event.data.action == "toShow") {
            registration.showNotification(title, {
              body: body,
              icon: "./assets/icon-512x512.png",
              tag: `${node.type}`,
              requireInteraction: false,
              data: {
                scope: id,
                href: window.location.href
              },
              actions: [{
                action: "open",
                title: "Open"
              }]
            }).then(() => {
              navigator.serviceWorker.onmessage = (e) => {
                if (e.data.action != "take-lead") {
                  navigate(node);
                }
              };
              return registration.getNotifications();
            }).then(notifications => {
              setTimeout(() => notifications.forEach(notification => notification.close()), 10000);
            });
          }
        };
      });
    }
  });
}
