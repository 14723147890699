import React                 from "react";
import { FC }                from "react";
import { useCallback }       from "react";
import { Field }             from "@relcu/form";
import { FORM_ERROR }        from "@relcu/form";
import { Form }              from "@relcu/form";
import { SelectPicker }      from "@relcu/rc";
import { defaultMutators }   from "@relcu/ui";
import { useSource }         from "@relcu/ui";
import { ChoiceField }       from "@relcu/ui";
import { FieldSpy }          from "@relcu/ui";
import { AlertColors }       from "@relcu/ui";
import { Alert, FontIcon }   from "@relcu/ui";
import { SelectVariants }    from "@relcu/ui";
import { InputState }        from "@relcu/ui";
import { classNames }        from "@relcu/ui";
import { Box }               from "@relcu/ui";
import { BoxComponentProps } from "@relcu/ui";
import { Transition }        from "react-transition-group";
import { getField }          from "../../../utils/schemaUtils";
import { usePermissions }    from "../../AccessControl";
import { Messenger }         from "../Messanger/Messenger";
import { ActionBarClasses }  from "./ActionBarClasses";
import { useActionBar }      from "./useActionBar";
import { useAttachments }    from "./useAttachments";
import { useTemplate }       from "./useTemplate";
import "./action-bar.css";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`
};

const transitionStyles = {
  entering: { opacity: .5 },
  entered: { opacity: 1 },
  exiting: { opacity: .5 },
  exited: { opacity: 0 }
};

export interface SmsActionBarProps extends BoxComponentProps {
  from?: {
    value: string
    label: string
  }[];
  preferred?: {
    to?: any
    from?: any
  };
  to?: {
    value: string
    label: string
  }[];
  receiver: { firstName: string, lastName: string, number: string }[];
  templates: any[];
  onSend?(data);
}

export const ActionBar: FC<SmsActionBarProps> = React.memo(function ActionBar(props) {
  const { className, from, to } = props;
  const { $object } = useSource();
  const { states } = getField("Lead", "leadStatus");
  const attachments = useAttachments();
  const { canUpdate } = usePermissions($object);
  const template = useTemplate($object.objectId);
  const classes = classNames(ActionBarClasses.ActionBar, className);
  const { handleSubmit, handleKeyDown, messageTemplates, handleTemplateChange, handleToChange, content, handleFromChange, showConsent, loading, fromNumber, toNumber, setToNumber, setFromNumber } = useActionBar(props);
  const validateForm = useCallback((values: any) => {
    const errors = {};
    if (!values.content?.trim() && !values.attachments.length) {
      errors[ FORM_ERROR ] = true;
    }
    if (values.attachments.length && values.attachments.find(({ attachment }) => !attachment.objectId)) {
      errors[ FORM_ERROR ] = true;
    }

    return errors;
  }, []);
  let canContact = $object.__typename != "Lead" || (!states[ $object.leadStatus.status ].not_contact && canUpdate)

  return (
    <Box container direction={"column"} gap={"XS"} flexShrink={0} className={classes}>
      <Transition mountOnEnter={true} unmountOnExit={true} in={!loading && showConsent} timeout={duration}>
        {state => {
          return <Alert style={{
            ...defaultStyle,
            ...transitionStyles[ state ]
          }} className={ActionBarClasses.Alert} stretch={true} variant={AlertColors.Warning}>
            <span style={{ lineHeight: "24px" }}>
              Your first SMS should always be one of the “initial” templates.  They are written to satisfy compliance requirements.
            </span>
          </Alert>;
        }}
      </Transition>
      <Form
        validate={validateForm}
        keepDirtyOnReinitialize={true}
        onSubmit={handleSubmit}
        mutators={defaultMutators}
        subscription={{
          initialValues: true,
          invalid: true,
          submitting: true,
          submitSucceeded: true,
          values: true
        }}
        initialValues={{
          attachments: attachments,
          template: template?.templateId,
          content,
          from: fromNumber,
          to: toNumber
        }}
      >
        {({ handleSubmit, invalid, submitting, form, values }) => (
          <form
            onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
            onSubmit={handleSubmit}
            noValidate
            style={{ display: "contents" }}
          >
            <Box container justify={"space-between"}>
              <Box container gap={"XS"}>
                <ChoiceField
                  style={{ maxWidth: "250px" }}
                  width={250}
                  mode="button"
                  variant={SelectVariants.Ghost}
                  label={"From:"}
                  disabled={(to.length == 0 || from.length == 0 || !canContact)}
                  required={true}
                  options={from}
                  onChange={(e) => {
                    handleFromChange(e, form);
                  }}
                  message={""}
                  state={"" as InputState}
                  name={"from"}
                  placeholder={(from.length == 0 ? "No available numbers" : "Select Number")}
                />
                <ChoiceField
                  style={{ maxWidth: "250px" }}
                  width={250}
                  mode="button"
                  variant={SelectVariants.Ghost}
                  label={"To:"}
                  disabled={(to.length == 0 || from.length == 0 || !canContact)}
                  required={true}
                  options={to}
                  onChange={(e) => {
                    handleToChange(e, form);
                  }}
                  message={"Error"}
                  state={"" as InputState}
                  name={"to"}
                  placeholder={(to.length == 0 ? "No available numbers" : "Select Number")}
                />
              </Box>
              {
                messageTemplates.length > 0 &&
                <Field name="template">
                  {
                    ({ input: { value } }) => {
                      return <SelectPicker
                        style={{ maxWidth: "calc(100% - 470px)" }}
                        searchable={false}
                        value={value}
                        disabled={(to.length == 0 || from.length == 0 || !canContact)}
                        data={messageTemplates}
                        size={"xs"}
                        appearance={"subtle"}
                        placement={"topEnd"}
                        placeholder="Choose template"
                        onSelect={(value, item) => {
                          handleTemplateChange(value, form);
                        }}/>;
                    }
                  }
                </Field>
              }
            </Box>
            <FieldSpy name={"to"} subscription={{ value: true }}
                      onChange={(value) => setToNumber(value)}/>
            <FieldSpy name={"from"} subscription={{ value: true }}
                      onChange={(value) => setFromNumber(value)}/>
            <Messenger
              required={true}
              name={"content"}
              canUpdate={(to.length > 0 && from.length > 0 && canContact)}>
              <FontIcon
                type="send"
                className={classNames(
                  ActionBarClasses.Submit, {
                    [ ActionBarClasses.Disabled ]: submitting || invalid || !canContact
                  }
                )}
                onClick={handleSubmit}/>
            </Messenger>
          </form>
        )}
      </Form>
    </Box>
  );
});
