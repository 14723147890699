import { FC }                 from "react";
import { ReactNode }          from "react";
import React                  from "react";
import { useImperativeState } from "../../..";
import { Input }              from "../../..";
import { BoxClasses }         from "../../..";
import { Label }      from "../../..";
import { classNames } from "../../..";
import { Box }        from "../../..";
import { BoxComponentProps }  from "../../..";
import { BaseInputClasses }   from "../BaseInput";
import { BaseInputProps }     from "../BaseInput";

export interface PhoneNumberProps extends BoxComponentProps, BaseInputProps {
  options: {
    country: string
    code: string
    mask: string
  }[]
  value?: {
    country: string
    code: string
    number: number
    flag: string
  }
  inputProps?: { [ key: string ]: any; };
  InputProps?: { [ key: string ]: any; };
  message,
  optionKey: string
  optionLabel: string
  readOnly?: boolean,
  renderInput?: ReactNode,
  onChange?(data),
}

const defaultProps = {
  optionKey: "code",
  optionLabel: "country",
  disabled: false,
  readOnly: false
};

export const PhoneNumber: FC<PhoneNumberProps> = React.memo(function PhoneNumber(props) {
  const {
    options,
    value,
    onChange,
    className,
    required,
    label,
    message,
    optionKey,
    optionLabel,
    state,
    readOnly,
    halfSize,
    fullSize,
    disabled,
    renderInput,
    name,
    inputProps,
    InputProps,
    ...p
  } = props;

  const classes = classNames(className, {
    [ BaseInputClasses.ReadOnly ]: readOnly,
    [ BaseInputClasses.Disabled ]: disabled,
    [ BaseInputClasses.HalfSize ]: halfSize,
    [ BaseInputClasses.FullSize ]: fullSize
  }, BaseInputClasses.Input);
  const [stateValue, setStateValue] = useImperativeState(value || "", onChange);

  const handleChange = (value) => {
    setStateValue({
      ...stateValue,
      number: value
    });
  };

  return <Box container className={classes} direction={"column"} {...p}>
    {
      label &&
      <Label mLeft={true} required={required}>
        {
          label
        }
      </Label>
    }
    <Box container gap={"XXS"} style={{display:"block"}}>
      {
        renderInput || <Input
          inputProps={inputProps}
          InputProps={InputProps}
          flexGrow={1}
          state={state}
          message={message}
          name={name}
          value={stateValue.number || ""}
          maskAttr={{
            alwaysShowMask: !readOnly,
            mask: stateValue?.country ? options.find(o => o.country == stateValue.country).mask : options[ 0 ].mask
          }}
          disabled={disabled}
          readOnly={readOnly}
          // placeholder={selectedItems.length > 0 ? "" : placeholder}
          onChange={handleChange}
          // onKeyPress={onKeyPress}
          // onKeyDown={onKeyDown}
          // onKeyUp={onKeyUp}
        />
      }
    </Box>
  </Box>;
});
PhoneNumber.defaultProps = defaultProps;

export enum PhoneNumberClasses {
  PhoneNumberSelect = "phone-number-select",
}

