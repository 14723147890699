import React                     from "react";
import { FC }                    from "react";
import { Form }                  from "@relcu/form";
import { Field }                 from "@relcu/form";
import { StackProps }            from "@relcu/rc";
import { Stack }                 from "@relcu/rc";
import { MenuItem }              from "@relcu/ui";
import { classNames }            from "@relcu/ui";
import { ChoiceField }           from "@relcu/ui";
import { DialPad }               from "@relcu/ui";
import { checkContactIsUnknown } from "../../../utils/helpers";
import { phoneNumberMatchers }   from "../../../utils/helpers";
import { usePhone }              from "../../usePhone";
import { useViewerPhoneLines }   from "../../useViewerPhoneLines";
import { DialInput }             from "./DialInput";
import { ActionType }            from "./useDialBar";
import { useDialBar }            from "./useDialBar";
import { Call }                  from "../Call";
import { Caller }                from "../Caller";
import { CallStatus }            from "../CallStatus";
import "./dial-bar.css";

export interface DialBar extends StackProps {
  userRole: string;
}

export const DialBar: FC<DialBar> = React.memo(function DailBar(props) {
    const { className, userRole, ...p } = props;
    const { handleSubmit } = useDialBar();
    const { fromNumbers } = useViewerPhoneLines();
    const [from] = fromNumbers;
    const phone = usePhone();
    const classes = classNames("dial-bar", className);
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={32}
        className={classes} childrenRenderMode={"clone"} {...p}>
        {
          !!phone?.hybridCalls?.myCall
            ?
            <Call collapsed={false}/>
            :
            <Form
              initialValues={{ from: from?.value, to: "" }}
              keepDirtyOnReinitialize={true}
              onSubmit={handleSubmit}>
              {({ handleSubmit, form }) => {
                return <form
                  onSubmit={handleSubmit}
                  noValidate
                  style={{ display: "contents" }}>
                  <Stack direction={"column"} spacing={12} style={{ height: 168, alignSelf: "stretch" }}
                         alignItems={"center"}
                         justifyContent={"flex-start"}>
                    <CallStatus status={"call"}/>
                    <Field name={"contact"}>
                      {
                        ({ input: { value } }) => {
                          return <Caller
                            parties={[
                              {
                                showNumber: false,
                                isUnknown: checkContactIsUnknown(value.objectName),
                                name: value.objectName,
                                id: value.objectId,
                                company: value.company,
                                number: value.number
                              }
                            ]}/>;
                        }
                      }
                    </Field>
                  </Stack>
                  <Stack direction={"column"} spacing={12} style={{ alignSelf: "center" }} childrenRenderMode={"clone"}
                         className={"dial-bar__inputs-container"}>
                    <DialInput name={"to"} userRole={userRole}/>
                    <ChoiceField
                      keepErrorSpace={false}
                      renderSelect={(option) => {
                        const number = fromNumbers.find(f => f.value == option);
                        const label = number?.label?.split(" - ");
                        return label.length == 2 ? `${label[ 0 ].trim()}: ${label[ 1 ].trim()}` : number.label ?? "";
                      }}
                      renderOption={(option) => {
                        const label = option?.label?.split(" - ");
                        return <MenuItem container>
                          {label?.length == 2 ? `${label[ 0 ].trim()}: ${label[ 1 ].trim()}` : option?.label ?? ""}
                        </MenuItem>;
                      }}
                      showUnderLine={false}
                      options={fromNumbers}
                      width={"252px"}
                      name={"from"}
                      label={"From number:"}
                      alignSelf={"stretch"}
                      justify={"start"}/>
                  </Stack>
                  <Field
                    name={"to"}
                    parse={(value) => {
                      if (value.endsWith("*") || value.endsWith("#")) {
                        value = value.slice(0, -1);
                      }
                      return "+1" + phoneNumberMatchers(value);
                    }}
                    format={(value) => {
                      return value?.slice(2).replace?.(/_/g, "") ?? "";
                    }}>
                    {
                      ({ input: { value, onChange }, meta }) => {
                        return <DialPad
                          onDigit={digit => {
                            if (digit == "Backspace") {
                              // return onChange(value.slice(0, -1));
                              onChange(value);
                            } else if (value.indexOf("_") != -1) {
                              onChange(value.replace("_", digit));
                            } else if (value.indexOf("_") == -1 && value.length < 10) {
                              //todo this case happen because of back button remove digits not replace them with _ symbol
                              onChange(`${value}${digit}`);
                            }
                          }}
                          onSms={async () => {
                            form.change("action", ActionType.SMS);
                            await form.submit();
                          }}
                          onCall={async () => {
                            form.change("action", ActionType.Call);
                            await form.submit();
                          }}
                          disableDigits={value?.length >= 10}
                          disableCall={!from || value?.length < 10}
                          disableSms={!from || value?.length < 10}
                        />;
                      }
                    }
                  </Field>
                </form>;
              }}
            </Form>
        }
      </Stack>
    );
  }
);
