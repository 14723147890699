import React                                                 from "react";
import { FC }                                                from "react";
import { Field }                                             from "@relcu/form";
import { useField }                                          from "@relcu/form";
import { FieldArrayRenderProps }                             from "@relcu/form";
import { useNavigate }                                       from "@relcu/react-router";
import { ButtonSizes }                                       from "@relcu/ui";
import { useSource }                                         from "@relcu/ui";
import { ButtonColors }                                      from "@relcu/ui";
import { Button }                                            from "@relcu/ui";
import { ListActions, Tooltip }                              from "@relcu/ui";
import { ChipsColors }                                       from "@relcu/ui";
import { ListItemText }                                      from "@relcu/ui";
import { Avatar, AvatarSizes }                               from "@relcu/ui";
import { ChipsSizes, ListItemChips, ListItemTitle, ListRow } from "@relcu/ui";
import { getDefaultPhoneNumberForCall }                      from "../../../../utils/helpers";
import { formatPhoneNumber }                                 from "../../../../utils/helpers";
import { getHumanized }                                      from "../../../../utils/schemaUtils";
import { usePhone }                                          from "../../../usePhone";
import { useViewerPhoneLines }                               from "../../../useViewerPhoneLines";

interface LeadMemberRowProps {
  name: string,
  canUpdate: boolean,
  index: number,
  onEdit: (i: number) => void,
  onSms: (c/*: Partial<Contact>*/) => void,
  onCall: (c/*: Partial<Contact>*/) => void,
  onEmail: (c/*: Partial<Contact>*/) => void,
  onRemove: (i: number) => void | Promise<void>
}
export const LeadMemberRow: FC<Omit<FieldArrayRenderProps<any, any>, "meta"> & LeadMemberRowProps> = React.memo(function LeadMemberRow(props) {
  const { fields, canUpdate, index, onEdit, onRemove, name, onCall, onSms, onEmail } = props;
  const { $object, $settings } = useSource();
  const phone = usePhone();
  const { fromNumbers } = useViewerPhoneLines();
  const navigate = useNavigate();
  const { input: { value: type } } = useField(`${name}.type`, { subscription: { value: true } });
  const { input: { value: isPrimary } } = useField(`${name}.isPrimary`, { subscription: { value: true } });

  return (
    <ListRow gap={"XXS"}>
      <Field name={`${name}.type`}
             render={({ input }) => <ListItemTitle
               title={getHumanized("LeadMember", "type", input.value)} flexBasis={"15%"} flexShrink={0}/>}/>
      <Field name={`${name}.contact`}
             subscription={{ value: true }}
             render={({ input }) => {
               let objName = ""
               if (input.value.firstName || input.value.lastName) { // should be or ?

                 if(input.value.firstName){
                   objName = objName + input.value.firstName.trim()
                 }
                 if(input.value.middleName && objName){
                   objName = objName + " " + input.value.middleName.trim()
                 }
                 if(input.value.lastName) {
                   objName = objName
                     ? objName + " " + input.value.lastName.trim()
                     : input.value.middleName
                       ? input.value.middleName + " " + input.value.lastName.trim()
                       : input.value.lastName.trim()
                 }
               }
               const fullName = objName || `Unknown`
               return <ListItemChips
                 length={15}
                 onClick={() => navigate(`/contact/${input.value.objectId}/details`)}
                 size={ChipsSizes.Medium}
                 thumbnail={<Avatar icon={input.value.objectIcon}
                                    text={fullName}
                                    size={AvatarSizes.Tiny}/>}
                 value={fullName}
                 flexBasis={"20%"} flexShrink={0}/>}
             }/>
      <Field name={`${name}.contact.phones`}
             subscription={{ value: true }}
             render={({ input }) => {
               return <ListItemText
                 className={"lead-member--grey"}
                 text={formatPhoneNumber(getDefaultPhoneNumberForCall(input.value || [], "Contact")?.number) ||
                   <small>No phone number</small>}
                 flexBasis={"13%"} flexShrink={0}/>;
             }}/>
      <Field name={`${name}.contact.emails`}
             subscription={{ value: true }}
             render={({ input }) =>
               <ListItemText
                 className={"lead-member--grey"}
                 text={((input.value || [])[ 0 ]?.address) || <small>No email address</small>}
                 flexBasis={"18%"} flexShrink={0}/>
             }/>
      <Field name={`${name}`}
             subscription={{ value: true }}
             render={({ input }) => <ListItemText
               flexGrow={1}
               flexBasis={"15%"}
               className={"lead-member--grey"}
               text={["borrower", "co_borrower"].includes(input.value.type)
                 ? (`Credit score:  ${(input.value.creditScore || "-")}`)
                 : (input.value.type == "custom")
                   ? input.value.typeElaborate
                   : ("Company: " + ((input.value.contact?.company || "-")))
               }/>
             }/>
      <Field name={`${name}.isPrimary`}
             subscription={{ value: true }}
             render={({ input }) =>
               input.value && <ListItemChips
                 flexBasis={"20%"}
                 color={ChipsColors.Success}
                 value={"Primary contact"}/>
             }/>
      {canUpdate && <ListActions>
        <Field name={`${name}.contact`} subscription={{ value: true }}
               render={({ input }) => {
                 let filteredEmails = [];
                 if (!!input.value.emails?.length) {
                   filteredEmails = input.value.emails.filter(email => !email.optOut);
                 }
                 const filteredPhone = input.value.phones?.filter(phone => !phone.callOptOut) || [];

                 return $object.leadStatus.action != "do_not_contact" && <>
                   {
                     !!input.value.phones?.length && <>
                       <Tooltip title={"Make a call"}>
                         <Button disabled={(!!phone.active || fromNumbers.length == 0 || filteredPhone.length == 0)}
                                 color={ButtonColors.Success}
                                 onlyIcon
                                 size={ButtonSizes.Small}
                                 icon={"call"}
                                 onClick={(e) => onCall(input.value)}/>
                       </Tooltip>
                       <Tooltip title={"Send SMS"}>
                         <Button color={ButtonColors.Primary} onlyIcon size={ButtonSizes.Small} icon={"forum"}
                                 onClick={(e) => onSms(input.value)}/>
                       </Tooltip>
                     </>
                   }
                   <Tooltip title={"Email"}>
                     <Button color={ButtonColors.Primary} onlyIcon size={ButtonSizes.Small} icon={"email"}
                             onClick={() => onEmail(input.value)} disabled={!filteredEmails.length || $object?.duplicateOf}/>
                   </Tooltip>
                 </>;
               }}>
        </Field>
        <Tooltip title={"Edit"}>
          <Button color={ButtonColors.White} onlyIcon size={ButtonSizes.Small} icon={"create"}
                  onClick={(e) => onEdit(index)}/>
        </Tooltip>
        {
          fields.length > 1 && type !== "borrower" && !isPrimary &&
          <Tooltip title={"Remove"}>
            <Button color={ButtonColors.White} onlyIcon size={ButtonSizes.Small} icon={"delete"}
                    onClick={(e) => onRemove(index)}/>
          </Tooltip>
        }
      </ListActions>}
    </ListRow>
  );
});
