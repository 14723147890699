import React, {useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {
    GetCallIntelligences,
    GetCallIntelligencesVariables
} from "./__types__/GetCallIntelligences";
import infoIcon from '../../../../assets/info.svg';
import "./callintelligence.css";

interface InfoPopupProps {
    isVisible: boolean;
    toggleVisibility: () => void;
}
interface CallIntelligenceProps {
    conference: string;
    layoutType?: 'conference' | 'activity';
}
const COLOR_LEGEND = [
    {range: '7 - 10', color: '#7ba841'},
    {range: '4 - 7', color: '#87CEFA'},
    {range: '0 - 4', color: '#f5b900'},
]
const DESCRIPTION = {
    Disposition: "actions performed after the call",
    Sentiment: "how positive or negative the conversation was",
    Talktime: "the overall talk time of the call",
    Talkratio: "how much the Loan Officer is speaking on the call"
};
const CALL_INTELLIGENCES_QUERY = gql`
    query GetCallIntelligences($conference: ID!) {
        callIntelligences(where: {
            conference:{have:{id:{equalTo:$conference}}}
        }) {
            edges {
                node {
                    id
                    disposition
                    dispositionScore
                    keywordScore
                    talktimeScore
                    talkratioScore
                    totalScore
                    summary
                }
            }
        }
    }
`;
const capitalizeFirstLetter = (string: string) => {
    return string
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
const getGradient = (score: number) => {
    if (score <= 4) return ['#f5b900', '#f5b900', '#f9edcc'];
    if (score <= 7) return ['#52B9D1', '#52B9D1', '#def0f8'];
    return ['#7ba841', '#7ba841', '#e4edd8'];
};
const InfoPopup: React.FC<InfoPopupProps> = ({isVisible, toggleVisibility}) => {
    const ref = React.useRef(null);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleVisibility();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, toggleVisibility]);
    if (!isVisible) return null;
    return (
        <div ref={ref} className="info-popup">
            <div className="info-section">
                <div className="section-title">Score Metrics</div>
                <div>
                    {["Disposition", "Sentiment", "Talktime", "Talkratio"].map((type, index) => (
                        <div className="info-text">
                            <div className="info-subtitle">{type} Score</div>
                            <div className="info-description">This score is calculated by evaluating {DESCRIPTION[type]}.</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="score-guide-outer">
                <div className="score-guide-inner">Score Guide</div>
                <div className="color-legend-wrapper">
                    {COLOR_LEGEND.map((item, index) => (
                        <div key={index} className="color-item">
                            <span className="color-dot" style={{ background: item.color }}></span>
                            {item.range}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
const CallIntelligence: React.FC<CallIntelligenceProps> = ({conference, layoutType}) => {
    const {
        data
    } = useQuery<GetCallIntelligences, GetCallIntelligencesVariables>(CALL_INTELLIGENCES_QUERY, {
        variables: {conference}
    });

    let call_score: number = data?.callIntelligences.edges[0]?.node?.totalScore;
    let call_summary: string = data?.callIntelligences.edges[0]?.node?.summary;
    let disposition: string = data?.callIntelligences.edges[0]?.node?.disposition;
    let individualScores = {
        sentiment_score: data?.callIntelligences.edges[0]?.node?.keywordScore,
        talktime_score: data?.callIntelligences.edges[0]?.node?.talktimeScore,
        talkratio_score: data?.callIntelligences.edges[0]?.node?.talkratioScore,
        disposition_score: data?.callIntelligences.edges[0]?.node?.dispositionScore,
    };

    const [isInfoPopupVisible, setInfoPopupVisibility] = useState(false);
    const toggleInfoPopup = () => {
        setInfoPopupVisibility(prev => {
            return !prev;
        });
    };

    const colors = getGradient(call_score);
    const [masterProgress, setMasterProgress] = useState(0);
    const strokeDasharray = `${(masterProgress / 10) * 100} 100`;
    const [progress, setProgress] = useState<{ [key: string]: number }>({
        sentiment_score: 0,
        talktime_score: 0,
        talkratio_score: 0,
        disposition_score: 0
    });

    useEffect(() => {
        const interval = setInterval(() => {
            let newProgress = {...progress};
            let newMasterProgress = masterProgress;
            let update = false;

            if (masterProgress < call_score && masterProgress < 10) {
                newMasterProgress = newMasterProgress + 0.1 > call_score ? call_score : newMasterProgress + 0.1;
                if (newMasterProgress > 10) {
                    newMasterProgress = 10;
                }
                update = true;
            }

            for (const key in individualScores) {
                if (progress[key] < individualScores[key]) {
                    newProgress[key] = newProgress[key] + 0.1 > individualScores[key] ? individualScores[key] : newProgress[key] + 0.1;
                    if (newProgress[key] > 10) {
                        newProgress[key] = 10;
                    }
                    update = true;
                }
            }
            if (update) {
                setMasterProgress(newMasterProgress);
                setProgress(newProgress);
            } else {
                clearInterval(interval);
            }
        }, 20);
        return () => clearInterval(interval);
    }, [call_score, masterProgress, progress]);

    const CONFERENCE_LAYOUT = (
        <>
            <div className="conference-main">
                <div className="conference-header">
                    <h2 className="conference-title">Call Analytics</h2>
                    <button onClick={toggleInfoPopup} className="info-button">
                        <img src={infoIcon} alt="Info" width="16" height="16"/>
                    </button>
                    <InfoPopup isVisible={isInfoPopupVisible} toggleVisibility={toggleInfoPopup}/>
                </div>
                <div>
                    <div className="score-section">
                        <div className="total-score-wrapper">
                            <div className="column-wrapper">
                                <div className="svg-container">
                                    <svg width="80" height="80" viewBox="0 0 36 36">
                                        <defs>
                                            <linearGradient id={`gradient-${call_score}`} x1="0%" y1="0%" x2="100%"
                                                            y2="0%">
                                                <stop offset="0%" style={{stopColor: colors[0], stopOpacity: 1}}/>
                                                <stop offset="100%" style={{stopColor: colors[1], stopOpacity: 1}}/>
                                            </linearGradient>
                                        </defs>
                                        <circle
                                            stroke={colors[2]}
                                            fill="none"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            cx="18"
                                            cy="18"
                                            r="16"
                                        />
                                        <circle
                                            stroke={`url(#gradient-${call_score})`}
                                            fill="none"
                                            strokeWidth="4"
                                            strokeDasharray={strokeDasharray}
                                            strokeLinecap="round"
                                            cx="18"
                                            cy="18"
                                            r="16"
                                        />
                                    </svg>
                                    <div className="score-text">
                                        {masterProgress.toFixed(1)}
                                    </div>
                                </div>
                                <div className="score-label">Total Score</div>
                            </div>
                            <div className="individual-scores-wrapper">
                                {Object.entries(individualScores).map(([key, score]) => {
                                    const displayKey = key.split('_').map(capitalizeFirstLetter).join(' ');
                                    const strokeDasharray = `${(progress[key] / 10) * 100} 100`;
                                    const colors = getGradient(progress[key]);
                                    return (
                                        <div key={key} className="individual-score">
                                            <svg width="60" height="60" viewBox="0 0 38 38">
                                                <defs>
                                                    <linearGradient id={`gradient-${progress[key]}`} x1="0%" y1="0%"
                                                                    x2="100%" y2="0%">
                                                        <stop offset="0%"
                                                              style={{stopColor: colors[0], stopOpacity: 1}}/>
                                                        <stop offset="100%"
                                                              style={{stopColor: colors[1], stopOpacity: 1}}/>
                                                    </linearGradient>
                                                </defs>
                                                <circle
                                                    stroke={colors[2]}
                                                    fill="none"
                                                    strokeWidth="4"
                                                    strokeLinecap="round"
                                                    cx="18"
                                                    cy="18"
                                                    r="16"
                                                />
                                                <circle
                                                    stroke={`url(#gradient-${progress[key]})`}
                                                    fill="none"
                                                    strokeWidth="4"
                                                    strokeDasharray={strokeDasharray}
                                                    strokeLinecap="round"
                                                    cx="18"
                                                    cy="18"
                                                    r="16"
                                                />
                                                <text
                                                    x="18"
                                                    y="21"
                                                    fontFamily="Arial"
                                                    fontSize="10px"
                                                    fill="black"
                                                    textAnchor="middle">
                                                    {progress[key].toFixed(1)}
                                                </text>
                                            </svg>
                                            <span className="individual-score-label">{displayKey}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="conference-summary">
                <div className="summary-header">
                    <h2 className="summary-title">Call Summary</h2>
                    <div className="disposition-summary">
                        {disposition}
                    </div>
                </div>
                <p className="summary-text">{call_summary}</p>
            </div>
        </>
    );

    const ACTIVITY_LAYOUT = (
        <>
            <div className="activity-main">
                <h2 className="activity-title">Call Summary</h2>
                <div className="disposition">
                    {disposition}
                </div>
                <div className="call-info">
                    <div className="call-score-label">Call Score :</div>
                    <div className="call-score-value">
                        {masterProgress.toFixed(1)}
                    </div>
                    <div className="score-indicator">
                        <svg width="36" height="36" viewBox="0 0 36 36">
                            {/* ... (rest of SVG content remains unchanged) */}
                        </svg>
                    </div>
                </div>
            </div>
            <p className="call-summary">{call_summary}</p>
        </>
    );

    if (call_score == null) {
        return null
    }

    return (
        <div className="layout-wrapper">
            {layoutType === 'conference' ? CONFERENCE_LAYOUT : ACTIVITY_LAYOUT}
        </div>
    );
}
export default CallIntelligence;