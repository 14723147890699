import React, { useMemo }       from "react";
import { FC }                   from "react";
import { useState }             from "react";
import { FormSpy }              from "@relcu/form";
import { Field }                from "@relcu/form";
import { SectionMessage }       from "@relcu/rc";
import { Stack }                from "@relcu/rc";
import { FormField }            from "@relcu/rc";
import { EmptyState }           from "@relcu/rc";
import { Typography }           from "@relcu/rc";
import { Form }                 from "@relcu/rc";
import { useSource }            from "@relcu/ui";
import { BoxComponentProps }    from "@relcu/ui";
import { Box }                  from "@relcu/ui";
import { Button }               from "@relcu/rc";
import { Modal }                from "@relcu/rc";
import { ModalProps }           from "@relcu/rc";
import { Sidebar }              from "@relcu/rc";
import { Container }            from "@relcu/rc";
import { Content }              from "@relcu/rc";
import { Header }               from "@relcu/rc";
import { BulkModalBody }        from "../../Bulk/BulkModalBody";
import { BulkRecipientList }    from "../../Bulk/BulkRecipientList";
import { EmailRenderer }        from "../../EmailVisualizer";
import { PointerPickerProps }   from "../../Layout/Field/PointerPicker";
import { PointerPicker }        from "../../Layout/Field/PointerPicker";
import { useBulkSendMailModal } from "./useBulkSendMailModal";
import "./send-bulk-mail-modal.css";

export interface BulkSendMailModalProps extends Partial<ModalProps> {
  variables: any,
  ids: string[];
  onSubmit(data);
}

export const BulkSendMailModal: FC<BulkSendMailModalProps> = React.memo(function BulkSendMailModal(props) {
  const { variables, ids, onSubmit, ...modalProps } = props;
  const {
    onBulkSubmit,
    query,
    to,
    from,
    handleSelect,
    getTemplate,
    selected,
    templateFilters,
    addresses,
    mailBoxes
  } = useBulkSendMailModal({ ...props });
  const [subject, setSubject] = useState<string>();
  const { $settings } = useSource();
  const emailSignature = useMemo(() => {
    if ($settings?.emailSignature && $settings?.emailSignature[ 0 ]?.signature) {
      return $settings?.emailSignature[ 0 ]?.signature;
    }
  }, [$settings]);
  const { $viewer } = useSource();

  return (
    <Modal {...modalProps} size={"lg"}>
      <Form
        onSubmit={onBulkSubmit}
        initialValues={{ from }}
        layout={"inline"}
      >
        <Modal.Header>
          <Typography weights={"medium"} variant={"base16"}>Create bulk email</Typography>
        </Modal.Header>
        <BulkModalBody>
          <Sidebar width={310}>
            <BulkRecipientList
              query={query}
              selected={selected}
              onSelect={handleSelect}
            />
          </Sidebar>
          <Container>
            <Header className={'send-bulk-mail-modal-header'}>
              <Form.Select
                name="from"
                label="From:"
                errorMessage={false}
                properties={{
                  size: "sm",
                  searchable:false,
                  style: { width: 251 }
                }}
                data={addresses}
              />
              <FormField<PointerPickerProps>
                component={PointerPicker}
                name="template"
                label="Template:"
                required
                errorMessage={false}
                properties={{
                  size: "sm",
                  cleanable: true,
                  style: { width: 237 },
                  labelKey: "objectName",
                  valueKey: "id",
                  fields: ["content", "subject"],
                  filters: templateFilters,
                  targetClass: "EmailTemplate"
                }}
              />
            </Header>
            <Field name={"template"} subscription={{ value: true }}>
              {({ input: { value: templateId } }) => {
                const template = !!templateId && getTemplate(templateId);
                return (
                  <>
                    {!!template &&
                    <Header as={"div"}>
                      <Stack spacing={8} alignItems={"center"}>
                        <Typography weights={"regular"} color={"secondary"}
                                    variant={"base14"}>Subject:</Typography>
                        <Typography variant={"base14"}
                                    weights={"medium"}>{subject || "(No subject)"}</Typography>
                      </Stack>
                    </Header>
                    }
                    <Content>
                      {!!template ?
                        <Field name={"from"} subscription={{ value: true, initial: true }}>
                          {({ input: { value: from, onChange } }) => {
                            const fromEmail = mailBoxes.find(m => m.address === from);
                            return <EmailRenderer
                              onRender={({ subject }) => {
                                setSubject(subject);
                              }}
                              templateId={template.objectId}
                              leadId={selected.objectId}
                              fromId={!fromEmail ? (selected.assignedTo?.objectId) : $viewer.objectId}
                              toEmails={to ? [to] : []}
                            />;
                          }}
                        </Field> :
                        <EmptyState
                          icon={"description"}
                          title={"Template not selected"}
                          subtitle={"Please select the template"}
                        />
                      }
                      {!!template && !!emailSignature &&
                      <Field name={"from"} subscription={{ value: true, initial: true }}>
                        {({ input: { value: from, onChange } }) => {
                          const fromEmail = mailBoxes.find(m => m.address === from);
                          const signatureSettings = $settings?.emailSignature?.[ 0 ];
                          let enabled = false;
                          if (!fromEmail) {
                            enabled = signatureSettings?.enabledRelcu;
                          } else {
                            const domainType = fromEmail?.domain.provider.type;
                            if (domainType === "none" || fromEmail?.domain.valid) {
                              enabled = signatureSettings?.enabledRelcu;
                            } else {
                              enabled = signatureSettings?.enabledMicrosoftGmail;
                            }
                          }
                          return enabled ? <EmailRenderer
                            template={emailSignature}
                            fromId={!fromEmail ? (selected.assignedTo?.objectId) : $viewer.objectId}
                          /> : null;
                        }}
                      </Field>}
                    </Content>
                  </>
                );
              }}
            </Field>
            <FormSpy
              subscription={{ errors: true, touched: true }}>
              {
                ({ errors, touched }) => {
                  if (touched.template && errors?.template) {
                     return <div className={'send-bulk-mail-modal-warning'}>
                      <SectionMessage type="warning">Content is missing. Please choose template to send the
                        message.</SectionMessage>
                    </div>
                  }
                  return null;
                }
              }
            </FormSpy>
          </Container>
        </BulkModalBody>
        <Modal.Footer>
          <Button type={"button"} size={"xs"} onClick={props.onClose} color={"blue"} appearance="text">CANCEL</Button>
          <Button type={"submit"} size={"xs"} appearance="primary">SEND</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

interface BulkSendMailModalAlertProps extends BoxComponentProps {
  handleClick: () => void;
}

export const BulkSendMailModalAlert: FC<BulkSendMailModalAlertProps> = function (props) {
  const { handleClick } = props;
  const style = {
    color: "white",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 500
  };
  return (
    <Box>
      Emails in progress, you can follow the process on <a onClick={handleClick} style={style}>Email</a> tab.
    </Box>
  );
};
