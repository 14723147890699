import React                                    from "react";
import { FC }                                   from "react";
import { SetStateAction }                       from "react";
import { Dispatch }                             from "react";
import { useState }                             from "react";
import { useMemo }                              from "react";
import { dividedDataByFixedDate }               from "../../utils/helpers";
import { GetUnreadNotifications_notifications } from "../__types__/GetUnreadNotifications";
import { useUnreadNotifications }               from "../useUnreadNotifications";

export const UnreadNotificationContext = React.createContext<{
  notifications: GetUnreadNotifications_notifications,
  count: number,
  unSeenCount: number,
  filters: { value: string, label: string }[],
  setFilters: Dispatch<SetStateAction<object[]>>,
  id: string,
  lastNotificationSeenAt: string
}>(null);

export const UnreadNotificationProvider: FC<any> = React.memo(function UnreadNotificationProvider(props) {
  const { lastNotificationSeenAt, id } = props;
  const notifications = useUnreadNotifications();
  const [filters, setFilters] = useState([]);
  const dividedData = useMemo(() => dividedDataByFixedDate(notifications?.edges || [], null, lastNotificationSeenAt), [lastNotificationSeenAt, notifications]);

  return <UnreadNotificationContext.Provider
    {...props}
    value={{
      id,
      filters,
      setFilters,
      notifications,
      lastNotificationSeenAt,
      // unSeenCount: newNotesCount,
      count: notifications.edges.length,
    }}/>;
});
