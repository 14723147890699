import React                              from "react";
import { useMemo }                        from "react";
import { useState }                       from "react";
import { gql }                            from "@apollo/client";
import { useMutation }                    from "@apollo/client";
import { useAlert }                       from "@relcu/ui";
import { useSource }                      from "@relcu/ui";
import { useNavigate }                    from "@relcu/react-router";
import { getPrimaryBorrower }             from "../../../utils/helpers";
import { useMessageTemplates }            from "../../Layout/View/MessageView/useMessageTemplates";
import { useViewerPhoneLines }            from "../../useViewerPhoneLines";
import { SendBulkPhoneMessageVariables }  from "./__types__/SendBulkPhoneMessage";
import { SendBulkPhoneMessage }           from "./__types__/SendBulkPhoneMessage";
import { BulkSendPhoneMessageModalAlert } from "./BulkSendPhoneMessageModal";
import { BulkSendPhoneMessageModalProps } from "./BulkSendPhoneMessageModal";

export function useBulkSendPhoneMessageModal(props: BulkSendPhoneMessageModalProps) {
  const { onClose, variables, ids } = props;
  const { info, error } = useAlert();
  const navigate = useNavigate();
  const { $viewer } = useSource();
  const allowBehalfOfSend = $viewer.role !== "loan_officer";
  const [selected, setSelected] = useState(null);
  const isAllSelected = ids.some(item => item === "*");
  const query = {
    where: isAllSelected ? variables.where : { id: { in: ids } },
    order: variables.order
  };
  const primaryBorrower = getPrimaryBorrower(selected);
  const { bulkFromNumbers: fromNumbers } = useViewerPhoneLines();
  const numbers = useMemo(() => {
    if (allowBehalfOfSend) {
      return [{ label: "On behalf of assignees", value: "" }, ...fromNumbers];
    }
    return fromNumbers;
  }, [fromNumbers, allowBehalfOfSend]);
  const from = numbers[ 0 ]?.value || "";
  const [sendBulkEmail] = useMutation<SendBulkPhoneMessage, SendBulkPhoneMessageVariables>(SEND_BULK_PHONE_MESSAGE);
  // const to = useMemo(() => (primaryBorrower?.contact && getDefaultPhoneNumberForSMS(primaryBorrower?.contact?.phones || [], "Contact")?.number), [primaryBorrower]);

  const { templates } = useMessageTemplates();
  const messageTemplates = React.useMemo(() => templates.map(t => {
    return { label: t.node.title, value: t.node.objectId };
  }).sort((a, b) => a.label.localeCompare(b.label)), [templates]);
  const handleSelect = (data) => {
    setSelected(data);
  };
  const getTemplate = objectId => {
    return templates.find(t => t.node.objectId === objectId)?.node;
  };
  let receiver = useMemo(() => {
    const result = [];
    (selected?.members || []).map((member) => {
      member.contact?.phones?.map((phone) => {
        if (!result.find((n) => n.number == phone.number)) {
          result.push({
            firstName: member.contact.firstName,
            lastName: member.contact.lastName,
            number: phone.number
          });
        }
      });
    });
    return result;
  }, [selected]);
  // const handleTemplateChange = useCallback((form: FormApi<any>) => {
  //   const values = form.getState().values;
  //   if (values.template) {
  //     const template = templates.find(({ node }) => node.objectId == values.template);
  //     form.change("content", smsTemplate(template.node.text, to, $viewer, receiver, selected));
  //   }
  // }, [to, templates, receiver, selected]);

  async function onBulkSubmit(data) {
    if (!data.template) {
      return { template: "Template is required." };
    }
    const variables: any = {
      from: data.from,
      where: query.where,
      templateId: data.template
    };

    // if (data.template) {
    //   variables.templateId = data.template;
    // } else {
    //   variables.content = data.content;
    // }
    try {
      const result = await sendBulkEmail({ variables });
      if (result?.data.sendBulkPhoneMessage?.objectId) {
        info(BulkSendPhoneMessageModalAlert({
          handleClick: () => {
            navigate(`/user/${$viewer.objectId}/sms/${result?.data.sendBulkPhoneMessage?.objectId}`);
          }
        }), { width: "auto" });
        onClose(null);
      } else {
        return result;
      }
    } catch (e) {
      console.error(e);
      error(e?.message ?? "Something went wrong");
    }
  }

  return {
    from,
    numbers,
    query,
    onBulkSubmit,
    handleSelect,
    getTemplate,
    messageTemplates,
    primaryBorrower,
    // handleTemplateChange,
    selected,
    receiver
  };
}

const SEND_BULK_PHONE_MESSAGE = gql`
  mutation SendBulkPhoneMessage( $from: String, $where: LeadWhereInput, $content: String, $templateId: String ){
    sendBulkPhoneMessage(
      from: $from,
      where: $where,
      content: $content
      templateId: $templateId
    ){
      id
      objectId
    }
  }
`;


