import { gql }                           from "@apollo/client";
import { useEffect }                     from "react";
import { useMemo }                       from "react";
import { useParams }                     from "@relcu/react-router";
import { useLocation }                   from "@relcu/react-router";
import { useNavigate }                   from "@relcu/react-router";
import { useSearchParams }               from "@relcu/react-router";
import { useQuery }                      from "@apollo/client";
import { useMutation }                   from "@apollo/client";
import { FieldProps }                    from "@relcu/ui";
import { List }                          from "@relcu/ui";
import { toFirstLower }                  from "../../../../utils/helpers";
import { toNodeId }                      from "../../../../utils/helpers";
import { MarkMessagesAsReadVariables }   from "../../../__types__/MarkMessagesAsRead";
import { MarkMessagesAsRead }            from "../../../__types__/MarkMessagesAsRead";
import { MARK_MESSAGES_AS_READ }         from "../../../operations.graphql";
import { GetMessageListVariables }       from "./__types__/GetMessageList";
import { GetMessageList }                from "./__types__/GetMessageList";
import { SubscribeMessageListVariables } from "./__types__/SubscribeMessageList";
import { SubscribeMessageList }          from "./__types__/SubscribeMessageList";
import { useUnreadNotifications }        from "../../../useUnreadNotifications";

export interface UseSmsListOptions {
  limit?: number;
  party?: string;
  page?: number;
  unread?: boolean;
}
export function useMessageList({ limit = List.defaultProps.count, party, page, unread }: UseSmsListOptions = {}) {
  const [searchParams] = useSearchParams();
  const notification = useUnreadNotifications();
  const { edges = [] } = notification || {};
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = +page || +searchParams.get("page") || 1;
  const skip = limit * (currentPage - 1);
  const { objectId } = useParams();
  party = party ? party : objectId;
  const [markAsRead] = useMutation<MarkMessagesAsRead, MarkMessagesAsReadVariables>(MARK_MESSAGES_AS_READ);

  const query = useQuery<GetMessageList, GetMessageListVariables>(GET_MESSAGE_LIST, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      party,
      skip,
      limit,
      unread
    }
  });
  const objects = useMemo(() => {
    const { data: { customMessages: { nodes = [] } = {} } = {} } = query;
    return nodes.map(node => {
      return {
        ...node,
        direction: node.direction === "incoming" ? "call_received" : "call_made"
      }
    })
  }, [query.data]);
  const unreadSmsNotifications = edges.filter(({ node }) => node.type === "sms");
  const notifiableItems = useMemo(() => {
    return objects.filter(d => unreadSmsNotifications.find(({ node }) => node.data.sender === d.scopeId))
  }, [objects, unreadSmsNotifications]);

  const api = {
    limit,
    skip,
    currentPage,
    objects,
    notifiableItems,
    get loading() {
      return query.loading;
    },
    get count() {
      const { data: { customMessages: { count = 0 } = {} } = {} } = query;
      return count;
    },
    getColumnOptions(row, column: FieldProps) {
      if (column.component === "ListItemText" || column.component === "ListItemMultiText") {
        return {
          ...column,
          component: row.unreadConv ? "ListItemTitle" : column.component
        };
      }
      return column;
    },
    onRowClick(obj) {
      api.navigate(obj);
    },
    navigate(obj) {
      if (obj.scopeId) {
        navigate(`/lead/${obj.scopeId}/sms`);
      } else {
        navigate(`/${toFirstLower(obj.className)}/${obj.participantId}/sms`);
      }
    },
    markAsRead(id: string) {
      return markAsRead({
        variables: {
          ids: [id]
        }
      });
    },
    onPage(page) {
      searchParams.set("page", page);
      navigate(`/${location.pathname}?${searchParams.toString()}`);
    },
    subscribe() {
      const link = toNodeId({ className: "User", objectId: party });
      return query.subscribeToMore<SubscribeMessageList, SubscribeMessageListVariables>({
        document: SUBSCRIBE_MESSAGE_LIST,
        variables: {
          link,
          unread
        },
        updateQuery(prev, { subscriptionData: { data: { phoneMessages: { node, event } } } }) {
          query.refetch().catch(console.error);
          return prev;
        }
      });
    }
  };

  useEffect(api.subscribe, [party, unread]);

  return api;
}

export const GET_MESSAGE_LIST = gql`
  query GetMessageList($party:ID!,$skip:Int,$limit:Int,$unread:Boolean) {
    customMessages(
      party:$party
      skip:$skip
      limit:$limit
      unread:$unread
    ) {
      count
      nodes{
        className
        content
        createdAt
        direction
        objectIcon
        objectId
        objectName
        unreadConv
        participantId
        scopeId
      }
    }
  }
`;

export const SUBSCRIBE_MESSAGE_LIST = gql`
  subscription SubscribeMessageList($link:ID!,$unread: Boolean) {
    phoneMessages(where: {
      participants: {have: {party: {have: {link:$link }}}},
      unread: {equalTo: $unread }
    }){
      event
      node {
        id
        objectId
        objectIcon
        objectName
        direction
        content
        createdAt
        unread
        scope {
          __typename
          objectId
        }
        participants{
          type
          party {
            __typename
            ... on User {
              id
              objectId
              objectIcon
              objectName
            }
            ... on Contact {
              id
              objectId
              objectIcon
              objectName
            }
          }
        }
      }
    }
  }
`;
