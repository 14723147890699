import { TypePolicy } from "@apollo/client";
import { getField }   from "../../utils/schemaUtils";

export const Mortech: TypePolicy = {
  fields: {
    view: {
      read(e, { cache }) {
        if (e) {
          return e;
        }
        const { defaultValue } = getField("Lead", "mortech.view");
        return e ?? defaultValue;
      }
    },
    source: {
      read(e, { cache }) {
        if (e) {
          return e;
        }
        const { defaultValue } = getField("Lead", "mortech.source");
        return e ?? defaultValue;
      }
    },
    lockInDays: {
      read(e) {
        return e ?? "45";
      }
    },
    product: {
      read(e) {
        return e ?? "4";
      }
    }
  }
};
