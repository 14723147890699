import React                     from "react";
import { FC }                    from "react";
import { useClassNames }         from "@relcu/rc";
import { IconType }              from "@relcu/ui";
import { Box }                   from "@relcu/ui";
import { BoxItemComponentProps } from "@relcu/ui";
import { classNames }            from "@relcu/ui";
import { CommonClasses }         from "@relcu/ui";
import { FontIcon }              from "@relcu/ui";

export interface DialButtonProps extends BoxItemComponentProps {
  state: string;
  tooltip?: string;
  disabled?: boolean;
  active?: boolean;
  actualState: string;
  icon: IconType;
}

export const BarToggleButton: FC<DialButtonProps> = React.memo(function BarToggleButton(props) {
  const { state, actualState, icon, active, disabled, onClick, className, children, ...p } = props;
  const { merge, withClassPrefix } = useClassNames("icon-wrapper", "rc");
  const classes = merge(className, withClassPrefix({
    "active": state == actualState,
    "disabled": disabled
  }));

  return (
    <Box className={classes} {...p}>
      <FontIcon type={icon}
                className={classNames(
                  CommonClasses.ClickableIcon,
                  { [ CommonClasses.PrimaryIcon ]: active }
                )}
                onClick={onClick}/>
      {children}
    </Box>
  );
});
