import { gql }                        from "@apollo/client";
import { useQuery }                   from "@apollo/client";
import { TagProps }                   from "@relcu/ui";
import { JsonFormContext }            from "@relcu/ui";
import { useContext }                 from "react";
import { useCallback }                from "react";
import { useMemo }                    from "react";
import { useState }                   from "react";
import { getSelectionSet }            from "../../utils/graphQlUtils";
import { useJqlMutation }             from "../Layout/Jql";
import { GetCollectionTagsVariables } from "./__types__/GetCollectionTags";
import { GetCollectionTags }          from "./__types__/GetCollectionTags";

const GET_COLLECTION_TAGS = gql`
  query GetCollectionTags($className: String!, $search: String!){
    tags(className: $className, search: $search)
  }
`;

export function useTag({ className, tags, id, onChange }: Partial<TagProps>) {
  const [searchText, onType] = useState(String());
  const context = useContext(JsonFormContext);

  if (context.className) {
    className = context.className;
  }

  const {
    data: { tags: searchTags = [] } = {}
  } = useQuery<GetCollectionTags, GetCollectionTagsVariables>(GET_COLLECTION_TAGS, {
    fetchPolicy: "network-only",
    variables: {
      className,
      search: searchText || ""
    }
  });
  const options = useMemo(() => searchTags.filter(t => !tags.includes(t) && t.includes(searchText)), [searchText, searchTags, tags]);

  const [update] = useJqlMutation({
    operation: `update${className}`,
    fields: [
      {
        [ className.toLowerCase() ]: getSelectionSet(["tags"])
      }
    ],
    variables: {
      input: {
        type: `Update${className}Input!`
      }
    }
  }, {
    operationName: `Update${className}Tags`
  });
  const change = useCallback((tags: string[]) => {
    return update({
      variables: {
        input: {
          id: id,
          fields: {
            tags
          }
        }
      }
    });
  }, [id]);
  const handleType = useCallback((value) => {
    if (value == null || value.length <= 25) {
      onType(value || "");
    }
  }, [onType]);
  const handleChange = useCallback((tags) => change(tags), [tags]);

  const handleCreateNew = useCallback(() => {
    const existTag = tags.find(t => t == searchText);
    const newTag = searchText?.trim();
    if (newTag && !existTag) {
      onChange([...tags, newTag]);
    }
  }, [onChange, searchText, tags]);

  return {
    handleCreateNew,
    handleChange,
    handleType,
    searchText,
    options
  };

}
