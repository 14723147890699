import { useRef }                   from "react";
import { useMemo }                  from "react";
import { useMutation }              from "@apollo/client";
import { useSource }                from "@relcu/ui";
import { MailSendInput }            from "../types/graphql-global-types";
import { getPrimaryBorrower }       from "../utils/helpers";
import { applySignature }           from "../utils/mailUtils";
import { prepareMessageText }       from "../utils/mailUtils";
import { CreateDraftMailVariables } from "./__types__/CreateDraftMail";
import { CreateDraftMail }          from "./__types__/CreateDraftMail";
import { SendMailVariables }        from "./__types__/SendMail";
import { SendMail }                 from "./__types__/SendMail";
import { EmailRendererRef }         from "./EmailVisualizer";
import { CREATE_DRAFT_MAIL }        from "./operations.graphql";
import { SEND_MAIL }                from "./operations.graphql";
import { useMailboxes }             from "./useMailboxes";

export function useMailApi(node?: { __typename: string, objectId?: string, [ k: string ]: any }) {
  const contentRendererRef = useRef<EmailRendererRef>();
  const signatureRendererRef = useRef<EmailRendererRef>();
  const { $viewer: user, $settings } = useSource();
  const { mailBoxes: mBoxes } = useMailboxes();
  const [createDraft, createDraftMailData] = useMutation<CreateDraftMail, CreateDraftMailVariables>(CREATE_DRAFT_MAIL);
  const [send] = useMutation<SendMail, SendMailVariables>(SEND_MAIL);
  const mailBoxes = useMemo(() => mBoxes.map(({ node }) => ({ ...node, disabled: node.disabled || node.domain.disabled })).sort((a, b) => {
    if (a?.assignedTo?.objectId == user.objectId || a?.assignedTo) {
      return -1;
    }
    return 1;
  }), [mBoxes, user.objectId]);
  const enabledMailBoxes = mailBoxes.filter(m => !m.disabled && !m.domain.disabled);
  const enabledForBulk = enabledMailBoxes.filter(m => m.domain.valid && !m.common);

  const nodeEmail = useMemo(()=>{
    if (node?.__typename == "Lead") {
      const primaryBorrower = getPrimaryBorrower(node);
      const email = primaryBorrower?.contact?.emails?.filter((email) => !email.optOut) || [];
      const primaryEmail = email.find(e=> e.isPrimary)
      if(primaryEmail || email.length){
        return primaryEmail ? primaryEmail.address : email[0]?.address;
      } else {
        const coBorrowers = node.members.filter(m=> m.type === "co_borrower")
        if(coBorrowers.length){
          let coBorrowersEmails = []
          coBorrowers.forEach(({contact})=>{
            const enabledEmails = contact.emails.filter((email) => !email.optOut)
            coBorrowersEmails = [...coBorrowersEmails, ...enabledEmails]
          })
          return coBorrowersEmails[0]?.address
        }
      }
    } else if (node?.__typename == "Contact") {
      const email = node.emails.filter((email) => !email.optOut);
      const primaryEmail = email.find(e=> e.isPrimary)
      return primaryEmail ? primaryEmail.address : email[0]?.address;
    }
  },[node])
  const defaultMailbox = enabledMailBoxes.at(0);
  const defaultBulkMailbox = enabledForBulk.at(0);
  const handleMailSubmit = async ({ template, content, ...values }) => {
    if (!values.from) {
      return { from: `From is required.` };
    }

    if (!values.subject) {
      return { subject: `Subject is required.` };
    }
    if (values.to.length == 0) {
      return { to: `To is required.` };
    }

    let html = content;
    let text: string;
    if (template && contentRendererRef.current) {
      html = contentRendererRef.current.html;
      text = contentRendererRef.current.text;
    }
    if (signatureRendererRef.current) {
      html = applySignature(html, signatureRendererRef.current.html);
    }
    return await send({
      variables: {
        input: {
          ...values,
          from: values.from.address,
          html: html,
          text: text || prepareMessageText(html),
          scope: node?.__typename == "Lead" ? node.objectId : null
        }
      }
    });
  };
  const createDraftMail = async (input: MailSendInput) => {
    return createDraft({
      variables: {
        input: {
          subject: "New mail subject",
          scope: node.__typename == "Lead" ? node.objectId : null,
          ...input
        }
      }
    });
  };
  return {
    contentRendererRef,
    signatureRendererRef,
    handleSubmit: handleMailSubmit,
    mailBoxes,
    createDraftMailData,
    createDraftMail,
    defaultMailbox,
    defaultBulkMailbox,
    nodeEmail
  };
}
