import React                   from "react";
import { FC }                  from "react";
import { useLayoutEffect }     from "react";
import { useState }            from "react";
import { Suspense }            from "react";
import { FormSpy }             from "@relcu/form";
import { Field }               from "@relcu/form";
import { Form }                from "@relcu/form";
import { MultiSelectVariants } from "@relcu/ui";
import { useSource }           from "@relcu/ui";
import { FieldContainer }      from "@relcu/ui";
import { StepRow }             from "@relcu/ui";
import { MultiChoiceField }    from "@relcu/ui";
import { StepSection }         from "@relcu/ui";
import { ButtonVariants }      from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { ModalFooter }         from "@relcu/ui";
import { ModalProps }          from "@relcu/ui";
import { ModalBody }           from "@relcu/ui";
import { Modal }               from "@relcu/ui";
import { ChoiceField }         from "@relcu/ui";
import { SelectVariants }      from "@relcu/ui";
import { defaultMutators }     from "@relcu/ui";
import { StepContainer }       from "@relcu/ui";
import { CircularLoader }      from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { AlertColors }         from "@relcu/ui";
import { Alert }               from "@relcu/ui";
import { EmailRenderer }       from "../../EmailVisualizer";
import { PointerField }        from "../../Layout/Field/PointerField";
import { useQuickSendModal }   from "./useQuickSendModal";
import "./quick-send-mail-modal.css";

const DraftEdit = React.lazy(() => import("../../WysiwygArea/DraftEdit"));

export interface QuickSendMailModalProps extends Partial<ModalProps> {
  data: any;
}

export const QuickSendMailModal: FC<QuickSendMailModalProps> = React.memo(function QuickSendMailModal(props) {
  const { onClose, data } = props;
  const { $viewer } = useSource();
  const { primaryBorrower, templateFilters, emails, emailSignature, onSubmit, mailBoxes, defaultEmail, signatureRendererRef, contentRendererRef, signatureSettings } = useQuickSendModal(props);
  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    setShow(true);
  }, []);//to fix portal or editor bug, editor dropdowns not working after modal second time opened
  return (
    <Modal
      onClose={onClose}
      variant={"middle"}
      open={true}
      disableBackdropClick={true}
      closeIcon={true}
      title={`Send email to ${primaryBorrower.contact.objectName}`}
      className={"send-mail-modal"}
    >
      <Form
        keepDirtyOnReinitialize
        mutators={defaultMutators}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.to) {
            return errors;
          }
          return errors;
        }}
        initialValues={{
          from: mailBoxes.filter(m => !m.disabled && !m.domain.disabled)[ 0 ] ?? "",
          to: defaultEmail ? [defaultEmail.address] : []
        }}>
        {({ handleSubmit, form }) =>
          <>
            <ModalBody container direction={"column"} flex={1}
                       style={{ paddingTop: "var(--layout-box-gap-x-s)", paddingBottom: "var(--layout-box-gap-x-s)" }}>
              <StepSection>
                <StepContainer direction={"row"}>
                  <StepRow style={{overflow: "hidden"}} flex={1}>
                    <FieldContainer label={"Template:"} alignItems={"center"} space={"10%"} style={{overflow: "hidden"}} flex={1}>
                      <PointerField
                        variant={SelectVariants.Ghost}
                        flex={1}
                        keepErrorSpace={false}
                        placeholder="Select template"
                        thumbnail={false}
                        fields={["content", "subject"]}
                        name="template"
                        filters={templateFilters}
                        targetClass="EmailTemplate"
                      />
                    </FieldContainer>
                  </StepRow>
                </StepContainer>
                <StepContainer
                  background={"white"}
                  style={{ paddingTop: "var(--layout-box-gap-x-x-s)", paddingBottom: "var(--layout-box-gap-x-x-s)" }}>
                  <StepRow>
                    <FieldContainer label={"From:"} alignItems={"center"} space={"10%"} flex={1}>
                      <ChoiceField
                        flex={1}
                        name="from"
                        height={40}
                        mode="button"
                        variant={SelectVariants.Ghost}
                        optionLabel={"address"}
                        optionKey={"address"}
                        placeholder={"Select send email"}
                        options={mailBoxes}
                      />
                    </FieldContainer>
                  </StepRow>
                  <StepRow>
                    <FieldContainer label={"To:"} alignItems={"center"} space={"10%"} flex={1}>
                      <MultiChoiceField
                        flex={1}
                        name="to"
                        optionLabel={"address"}
                        optionKey={"address"}
                        variant={MultiSelectVariants.Ghost}
                        placeholder={"Select recipient’s emails"}
                        options={emails}
                      />
                    </FieldContainer>
                  </StepRow>
                  <StepRow>
                    <FieldContainer label={"CC:"} alignItems={"center"} space={"10%"} flex={1}>
                      <MultiChoiceField
                        flex={1}
                        name="cc"
                        optionLabel={"address"}
                        optionKey={"address"}
                        variant={MultiSelectVariants.Ghost}
                        placeholder={"Select CC recipient’s emails"}
                        options={emails}
                      />
                    </FieldContainer>
                  </StepRow>
                </StepContainer>
                <StepContainer background={"white"} className={"mail-modal__container"} gap={"XS"}
                               style={{ overflowY: "auto" }}>
                  <Field name={"subject"}>
                    {({ input: { value, onChange } }) => (
                      <input className={"mail-modal__input"} placeholder={"Type Subject"} value={value}
                             onChange={(e) => onChange(e.target.value)}/>
                    )}
                  </Field>
                  <Field name={"content"}>
                    {({ input: { value, onChange } }) => (
                      <Field name="template" subscription={{ value: true }}>
                        {({ input }) => (
                          !input.value ? (
                            show &&
                            <Suspense fallback={<CircularLoader justify={"center"}/>}>
                              <DraftEdit input={{ value, onChange }} editorStyle={{ minHeight: 120 }}/>
                            </Suspense>
                          ) : (
                            <>
                              <Box flexBasis={12}/>
                              <Field name="to" subscription={{ value: true }}>
                                {({ input: { value: to } }) => (
                                  <EmailRenderer ref={contentRendererRef}
                                                 onRender={({ subject }) => {
                                                   form.change("subject", subject);
                                                 }}
                                                 templateId={input.value.objectId}
                                                 fromId={$viewer.objectId}
                                                 leadId={data.objectId}
                                                 toEmails={to}
                                  />)}
                              </Field>
                            </>
                          )
                        )}
                      </Field>
                    )}
                  </Field>
                  {emailSignature && <Field name={"from"}>
                    {({ input: { value: fromEmail, onChange } }) => {
                      let enabled = false;
                      const domainType = fromEmail?.domain.provider.type;
                      if (domainType !== "none" && fromEmail?.auth) {
                        enabled = signatureSettings?.enabledMicrosoftGmail;
                      } else {
                        enabled = signatureSettings?.enabledRelcu;
                      }
                      return enabled ? <EmailRenderer
                        ref={signatureRendererRef}
                        template={emailSignature}
                        fromId={$viewer.objectId}
                      /> : null;
                    }}
                  </Field>}
                </StepContainer>
                <StepContainer>
                  <FormSpy
                    subscription={{ submitErrors: true, errors: true, touched: true, dirtyFieldsSinceLastSubmit: true }}>
                    {
                      ({ submitErrors, errors, touched, dirtyFieldsSinceLastSubmit }) => {
                        if (submitErrors?.subject && !dirtyFieldsSinceLastSubmit?.subject) {
                          return <Alert variant={AlertColors.Error} stretch>
                            "Subject" is missing. Please add to send the email.
                          </Alert>;
                        } else if ((submitErrors?.to || errors?.to) && !dirtyFieldsSinceLastSubmit?.to) {
                          return <Alert variant={AlertColors.Error} stretch>
                            {errors?.to?.trim() || "Please choose at least one recipient to send the email."}
                          </Alert>;
                        }
                        return null;
                      }
                    }
                  </FormSpy>
                </StepContainer>
              </StepSection>
            </ModalBody>
            <ModalFooter>
              <Button variant={ButtonVariants.Ghost} onClick={onClose}>CANCEL</Button>
              <Button onClick={handleSubmit}>SEND</Button>
            </ModalFooter>
          </>
        }
      </Form>
    </Modal>
  );
});
