import { Link }                                         from "@relcu/react-router";
import { CommonClasses }                                from "@relcu/ui";
import React                                            from "react";
import { TimelineItem }                                 from "@relcu/ui";
import { TypographyColor }                              from "@relcu/ui";
import { Typography }                                   from "@relcu/ui";
import { TimelineItemSubject }                          from "@relcu/ui";
import { TimelineItemIconColors }                       from "@relcu/ui";
import { TimelineItemIcon }                             from "@relcu/ui";
import { AssignmentActivity as AssignmentActivityType } from "../../../../__types__/AssignmentActivity";

export interface AssignmentActivityProps extends AssignmentActivityType {
  createdAt: string;
}

export const AssignmentActivity = React.memo<AssignmentActivityProps>(function AssignmentActivity(props) {
  const { assignedBy, assignedTo, assignmentSubject, assignedFrom, assignmentEvent, createdAt } = props;
  const initiatorName = assignedBy ? assignedBy.objectName : "Relcu";
  const to = assignedTo ? assignedTo.objectName : "Unknown";
  const from = assignedFrom ? assignedFrom.objectName : "Unknown";

  return <TimelineItem
    avatar={<TimelineItemIcon variant={TimelineItemIconColors.Default} icon={"group"}/>}
    primary={<TimelineItemSubject>
      {assignedBy?.objectId && !assignedBy?.objectId.startsWith("int:") ?
        <Link to={`/user/${assignedBy.objectId}/lead`}>
          <Typography className={CommonClasses.ClickableIcon}>{initiatorName}</Typography>
        </Link>
        :
        <Typography>{initiatorName}</Typography>
      }
      <Typography
        color={TypographyColor.Secondary}>{assignmentEvent === "assigned" ? "assigned" : assignmentEvent == "unassigned" ? "unassigned" : "reassigned"}</Typography>
      {
        assignmentSubject?.objectId ?
          <Link to={`/lead/${assignmentSubject.objectId}`}>
            <Typography className={CommonClasses.ClickableIcon}>{assignmentSubject.objectName || "Unknown"}</Typography>
          </Link>
          :
          <Typography>{assignmentSubject.objectName || "Unknown"}</Typography>
      }
      <Typography color={TypographyColor.Secondary}>{assignmentEvent === "assigned" ? "to" : "from"}</Typography>
      {
        assignmentEvent === "assigned" ?
          assignedTo?.objectId ?
            <Link to={`/user/${assignedTo.objectId}/lead`}>
              <Typography className={CommonClasses.ClickableIcon}>{to}</Typography>
            </Link>
            :
            <Typography>{to}</Typography>
          :
          assignedFrom?.objectId ?
            <Link to={`/user/${assignedFrom.objectId}/lead`}>
              <Typography className={CommonClasses.ClickableIcon}>{from}</Typography>
            </Link>
            :
            <Typography>{from}</Typography>
      }
      {
        assignmentEvent === "reassigned" && <>
          <Typography color={TypographyColor.Secondary}>to</Typography>
          {assignedTo?.objectId ?
            <Link to={`/user/${assignedTo.objectId}/lead`}>
              <Typography className={CommonClasses.ClickableIcon}>{to}</Typography>
            </Link>
            :
            <Typography>{to}</Typography>
          }
        </>
      }
    </TimelineItemSubject>}
    creationDate={createdAt}
  />;
});