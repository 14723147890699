import { useQuery }                          from "@apollo/client";
import { gql }                               from "@apollo/client";
import { useField }                          from "@relcu/final-form";
import { useForm }                           from "@relcu/final-form";
import { Field }                             from "@relcu/final-form";
import { FieldCell }                         from "@relcu/rc";
import { useLazyCondition }                  from "@relcu/ui";
import { useCondition }                      from "@relcu/ui";
import { CircularLoader }                    from "@relcu/ui";
import { useContext }                        from "react";
import { useMemo }                           from "react";
import { useEffect }                         from "react";
import React                                 from "react";
import { FC }                                from "react";
import { LoanEstimateOffer }                 from "../../../../../../graph/__types__/LoanEstimateOffer";
import { DOCUMENT }                          from "../../../../../../graph/operations.graphql";
import { NODE_FRAGMENT }                     from "../../../../../../graph/operations.graphql";
import { Proposal }                          from "../../Proposal";
import { WHEN_IS_STREAMLINE_WO_APPRAISAL }   from "../offer.conditions";
import { GetAppraisalFeesByCounty }          from "./__types__/GetAppraisalFeesByCounty";
import { GetAppraisalFeesByCountyVariables } from "./__types__/GetAppraisalFeesByCounty";
import { GetEnabledAppraisals }              from "./__types__/GetEnabledAppraisals";

const TypeMap = {
  "single_family_detached": "singleFamily",
  "pud": "pud",
  "townhome": "townhome",
  "detached_condo": "condo",
  "2_unit": "multiFamily",
  "3_unit": "multiFamily",
  "4_unit": "multiFamily",
  "warrantable_condo_lt_5_stories": "condo",
  "warrantable_condo_5_to_8_stories": "condo",
  "warrantable_condo_gt_8_stories": "condo",
  "non_warrantable_condo_lt_5_stories": "condo",
  "non_warrantable_condo_5_to_8_stories": "condo",
  "non_warrantable_condo_gt_8_stories": "condo",
  "condotel_condo_lt_5_stories": "condo",
  "condotel_condo_5_to_8_stories": "condo",
  "condotel_condo_gt_8_stories": "condo",
  "mobile": "mobile",
  "manufactured": "manufactured",
  "co_op": "coOp"
};
export const AppraisalFeeCell: FC = React.memo(function AppraisalFeeCell() {
    const loanEstimate = useContext(Proposal.Context);
    const form = useForm<LoanEstimateOffer>();
    const { input: { value: waive } } = useField("waive");
    const { data: appraisalData, loading } = useQuery<GetEnabledAppraisals>(GET_ENABLED_APPRAISALS, {
      skip: !waive
    });
    const appraisal = useMemo(() => {
      const usableAppraisalNode = appraisalData?.appraisals?.edges[ 0 ]?.node;
      let apprType = usableAppraisalNode?.defaultType;
      if (usableAppraisalNode) {
        for (let file of usableAppraisalNode.uploadedFiles) {
          if (file.type == form.getState().values.productType) {
            apprType = form.getState().values.productType;
          }
        }
      }
      return {
        apprType,
        usableAppraisalNode
      };
    }, [appraisalData]);
    const appraisalName = useMemo(() => {
      let name = "";
      const productType = form.getState().values.productType;
      const conforming = form.getState().values.conforming;
      switch (productType) {
        case "conventional":
          return conforming ? "conventional" : "nonConf";
        case "fha":
          return "fha";
        case "va":
          return "va";
        default:
          return "nonConf";
      }
      //todo implement jumbo

      // let name = "";
      // const selectedProduct = $object.mortech.productName;
      // let productName = selectedProduct?.toLowerCase() || "";
      // let hasJumbo = productName.includes("jumbo");
      // let hasNonConf = productName.includes("non conf") || productName.includes("non conv");
      // let hasConf = productName.includes("conf") || productName.includes("conv") || productName.includes("home");
      // if (hasJumbo) {
      //   name = "jumbo";
      // } else if ((!hasNonConf && hasConf) || productName.includes("fhlmc") || productName.includes("fhma")) {
      //   name = "conventional";
      // } else if (productName.includes("fha")) {
      //   name = "fha";
      // } else if (productName.includes("va")) {
      //   name = "va";
      // } else {
      //   name = "nonConf";
      // }
      // return name;
    }, [form]);
    const usableAppraisalNode = appraisalData?.appraisals?.edges[ 0 ]?.node;
    let apprType = usableAppraisalNode?.defaultType;
    if (usableAppraisalNode) {
      for (let file of usableAppraisalNode.uploadedFiles) {
        if (file.type == appraisalName) {
          apprType = appraisalName;
        }
      }
    }
    const { data: appraisalFeesData, loading: loadingAppraisal } = useQuery<GetAppraisalFeesByCounty, GetAppraisalFeesByCountyVariables>(GET_APPRAISAL_FEES_BY_COUNTY, {
      skip: !appraisal.usableAppraisalNode,
      variables: {
        where: {
          AND: [
            {
              appraisal: {
                have: {
                  objectId: {
                    equalTo: appraisal.usableAppraisalNode?.objectId
                  }
                }
              }
            },
            {
              state: {
                equalTo: loanEstimate.propertyState
              }
            },
            {
              OR: [
                {
                  county: {
                    equalTo: loanEstimate.propertyCounty
                  }
                },
                {
                  county: {
                    equalTo: "*"
                  }
                }
              ]
            },
            {
              loanType: {
                equalTo: appraisal.apprType
              }
            }
          ]
        }
      }
    });
    const appraisalFeeValue = useMemo(() => {
      const propertyType = form.getState().values.propertyType;
      const appraisalFees = appraisalFeesData?.appraisalFees?.edges?.map(({ node }) => node);
      if (!appraisalFees?.length) {
        return 0;
      } else {
        if (appraisalFees?.length == 1) {
          return appraisalFees[ 0 ][ TypeMap[ propertyType ] ] || 0;
        } else {
          const countyFee = appraisalFees?.find(fee => fee.county != "*");
          if (countyFee && (!!countyFee[ TypeMap[ propertyType ] ] || countyFee[ TypeMap[ propertyType ] ] == 0)) {
            return countyFee[ TypeMap[ propertyType ] ];
          } else {
            return appraisalFees?.find(fee => fee.county == "*")[ TypeMap[ propertyType ] ] || 0;
          }
        }
      }
    }, [appraisalFeesData]);

    const showLoader = useMemo(() => {
      return loading || loadingAppraisal;
    }, [loading, loadingAppraisal]);

    useEffect(() => {
      form.change("appraisalFee", appraisalFeeValue);
    }, [appraisalFeeValue]);

    return <>
      <FieldCell.Checkbox name={"waive"}>
        <Field subscription={{ value: true, initial: true }} name={"appraisalFee"}>
          {({ input }) => showLoader ? <CircularLoader/> : (input.value)}
        </Field>
      </FieldCell.Checkbox>
    </>;
  }
);
export const GET_ENABLED_APPRAISALS = gql`
  query GetEnabledAppraisals
  {
    appraisals(where
    :
    {
      enabled:{
        equalTo: true
      }
    }
      ,
      order: [createdAt_DESC]
    )
    {
      count
      edges
      {
        node
        {
          enabled
          uploadedFiles
          {
            type
          }
          defaultType
          objectId
          objectName
          createdAt
        }
      }
    }
  }
`;
export const GET_APPRAISAL_FEES_BY_COUNTY = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  query GetAppraisalFeesByCounty($where:AppraisalFeeWhereInput!) {
    appraisalFees(where: $where,order: [county_DESC]) {
      edges {
        node {
          ...Node
          ...Document
          coOp
          condo
          manufactured
          multiFamily
          pud
          singleFamily
          mobile
          townhome
          county
          fipsCode        }
      }
    }
  }
`;
