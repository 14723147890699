import { useMemo }                          from "react";
import { useEffect }                        from "react";
import { useState }                         from "react";
import { useCallback }                      from "react";
import { useMutation }                      from "@apollo/client";
import { gql }                              from "@apollo/client";
import { useQuery }                         from "@apollo/client";
import { ConfirmDialog }                    from "@relcu/ui";
import { useAlert }                         from "@relcu/ui";
import { useModal }                         from "@relcu/ui";
import { applySmoothDrag }                  from "../../../../utils/helpers";
import { DistributionStatsModal }           from "../../../Modal/DistributionStatsModal";
import { DistributionQueueDialog }          from "./DistributionQueueDialog";
import { CreateDistributionQueueVariables } from "./__types__/CreateDistributionQueue";
import { CreateDistributionQueue }          from "./__types__/CreateDistributionQueue";
import { DeleteDistributionQueueVariables } from "./__types__/DeleteDistributionQueue";
import { DeleteDistributionQueue }          from "./__types__/DeleteDistributionQueue";
import { GetDistributionQueueWithStats }    from "./__types__/GetDistributionQueueWithStats";
import { UpdateDistributionQueueVariables } from "./__types__/UpdateDistributionQueue";
import { UpdateDistributionQueue }          from "./__types__/UpdateDistributionQueue";

export function useDistributionQueue(options) {
  const { success, error } = useAlert();
  const [updateQueue] = useMutation<UpdateDistributionQueue, UpdateDistributionQueueVariables>(UPDATE_DISTRIBUTION_QUEUE);
  const [deleteQueue] = useMutation<DeleteDistributionQueue, DeleteDistributionQueueVariables>(DELETE_DISTRIBUTION_QUEUE);
  const [createQueue] = useMutation<CreateDistributionQueue, CreateDistributionQueueVariables>(CREATE_DISTRIBUTION_QUEUE);
  const { data: { distributionQueuesWithStats = [] } = {}, refetch, loading } = useQuery<GetDistributionQueueWithStats>(GET_QUEUES_WITH_STATS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    pollInterval: 5000
  });
  const [stats, setStats] = useState(() => sortStats(distributionQueuesWithStats ?? []));
  const pull = useMemo(() => stats.filter(s => s.strategy != "round_robin"), [stats]);
  const push = useMemo(() => stats.filter(s => s.strategy == "round_robin"), [stats]);
  useEffect(() => {
    setStats(sortStats(distributionQueuesWithStats ?? []));
  }, [distributionQueuesWithStats]);
  const onRefresh = () => {
    refetch();
  };
  const [modal, statsModalContext] = useModal(DistributionStatsModal);
  const [queueModal, queueModalContext] = useModal(DistributionQueueDialog);
  const [confirm, confirmContext] = useModal(ConfirmDialog);
  // const handleMoveRow = useCallback((dragIndex, hoverIndex) => {
  //   setStats(prev => {
  //     const p = [...prev];
  //     p.splice(hoverIndex, 0, p.splice(dragIndex, 1)[ 0 ]);
  //     return p;
  //   });
  // }, []);



  const handleDrop = useCallback(async (list, { removedIndex, addedIndex, payload }: {
    removedIndex: number | null;
    addedIndex: number | null;
    payload?: any;
    element?: HTMLElement;
  }, strategy: "pull" | "round_robin") => {
    if (removedIndex == null && addedIndex == null) {
      return;
    }
    const result = applySmoothDrag(list, { removedIndex, addedIndex, payload });

    setStats((prev) => {
      const filtered = prev.filter(p => p.strategy != strategy);
      if (strategy == "round_robin") {
        return [...result, ...filtered];
      }

      return [...filtered, ...result];

    });

    const updatedStats = result.map((s, i) => {
      return updateQueue(
        {
          variables: {
            input: {
              id: s.id,
              fields: {
                priority: strategy == "round_robin" ? i + 1 : 100 + i + 1
              }
            }
          }
        }
      );
    });
    await Promise.allSettled(updatedStats);
    refetch();
  }, [stats]);
  const handleDelete = useCallback(async (queueId) => {
    const { destroy } = confirm({
      title: "Confirm",
      subTitle: "You are deleting a distribution queue.",
      content: "Once you delete the queue, it will not be possible to restore it.",
      onConfirm: () => {
        try {
          deleteQueue({
            variables: { id: queueId },
            update: () => {
              refetch();
            }
          });
        } catch (e) {
          console.error(e);
          error(e.message.toString());
        }
        destroy();
      }
    });
  }, []);
  const handleStatsCLick = useCallback((queueId, queueName, strategy, distributeOfflineUsers) => modal({ queue: queueId, rule: queueName, strategy: strategy, distributeOfflineUsers: distributeOfflineUsers }), []);
  const handleQueueCLick = useCallback((edit, queueId?) => {
    const data = stats.find(({ id }) => id == queueId);
    const { destroy } = queueModal({
      edit,
      data: data,
      dialogProps: options.properties,
      onConfirm: (data) => {
        try {
          if (queueId) {
            updateQueue({
              variables: {
                input: {
                  id: queueId,
                  fields: data
                }
              },
              update: () => {
                refetch();
              }
            });
            success("Queue updated successfully.");
          } else {
            const list = (data.strategy == "round_robin" ? push : pull);
            createQueue({
              variables: {
                input: {
                  fields: {
                    ...data,
                    priority: (list?.at(-1)?.priority || -1) + 1
                  }
                }
              },
              update: () => {
                refetch();
              }
            });
            success("Queue created successfully.");
          }
          destroy();
        } catch (e) {
          console.error(e);
          error(e.message.toString());

        }
      }
    });
  }, [stats]);
  function sortStats(stats) {
    return [...stats].sort((a, b) => {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    });
  }

  return {
    get data() {
      return stats;
    },
    pull,
    push,
    loading,
    handleDelete,
    confirmContext,
    handleStatsCLick,
    statsModalContext,
    handleQueueCLick,
    queueModalContext,
    handleDrop,
    onRefresh
  };
}

export const DISTRIBUTION_QUEUE_FRAGMENT = gql`
  fragment DistributionQueue on DistributionQueue{
    objectId
    id
    objectName
    createdAt
    disabled
    notify
    notifyMobile
    autoDial
    draft
    skipTimezoneCheck
    priority
    distributeOfflineUsers
    limits {
      daily
      weekend
      weekly
      filter
      monthly
    }
    view {
      name
      color
      icon
      showDailyCap
      showLeadCount
      showMonthlyCap
      showWeeklyCap
      showCoolDownTime
    }
    schedule {
      SUN
      MON
      THU
      WED
      TUE
      FRI
      SAT
    }
    rules {
      disabled
      leads
      limits {
        daily
        weekend
        weekly
        filter
        monthly
      }
      users
      leadsFilter
      usersFilter
      name
      slug
      sort
    }
  }
`;

export const GET_QUEUES_WITH_STATS = gql`
  query GetDistributionQueueWithStats {
    distributionQueuesWithStats {
      leadsAvailable
      eligibleUsers
      objectId
      id
      objectName
      createdAt
      disabled
      notify
      notifyMobile
      autoDial
      distributeOfflineUsers
      draft
      skipTimezoneCheck
      priority
      strategy
      limits {
        cdp
        daily
        weekend
        weekly
        filter
        monthly
      }
      view {
        phoneLeadSound {
          sound
          repeat
        }
        webLeadSound {
          sound
          repeat
        }
        name
        color
        icon
        showDailyCap
        showLeadCount
        showMonthlyCap
        showWeeklyCap
        showCoolDownTime
      }
      schedule {
        SUN
        MON
        THU
        WED
        TUE
        FRI
        SAT
      }
      rules {
        disabled
        leads
        limits {
          daily
          weekend
          weekly
          filter
          monthly
        }
        users
        leadsFilter
        usersFilter
        name
        slug
        sort
      }
    }
  }
`;

export const CREATE_DISTRIBUTION_QUEUE = gql`
  mutation CreateDistributionQueue($input: CreateDistributionQueueInput!)  {
    createDistributionQueue(input: $input) {
      distributionQueue {
        ...DistributionQueue
      }
    }
  }
  ${DISTRIBUTION_QUEUE_FRAGMENT}
`;

export const UPDATE_DISTRIBUTION_QUEUE = gql`
  mutation UpdateDistributionQueue($input: UpdateDistributionQueueInput!)  {
    updateDistributionQueue(input: $input) {
      distributionQueue {
        ...DistributionQueue
      }
    }
  }
  ${DISTRIBUTION_QUEUE_FRAGMENT}
`;

export const DELETE_DISTRIBUTION_QUEUE = gql`
  mutation DeleteDistributionQueue($id: ID!)  {
    updateDistributionQueue(input: {id: $id,fields: {deleted:true}}) {
      distributionQueue {
        id
      }
    }
  }
`;



