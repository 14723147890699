import { useMutation }                from "@apollo/client";
import { gql }                        from "@apollo/client";
import { useAlert }                   from "@relcu/ui";
import { confirmModal }               from "@relcu/ui";
import { useEffect }                  from "react";
import { useState }                   from "react";
import { useLazyQuery }               from "@apollo/client";
import { useThrottle }                from "@relcu/ui";
import { useMounted }                 from "@relcu/ui";
import { MergeRecordToLeadVariables } from "./__types__/MergeRecordToLead";
import { MergeRecordToLead }          from "./__types__/MergeRecordToLead";
import { SearchDirectMailVariables }  from "./__types__/SearchDirectMail";
import { SearchDirectMail }           from "./__types__/SearchDirectMail";

export const SEARCH_DIRECT_MAIL = gql`
  query SearchDirectMail(
    $matchesRegex:String,
    $first:Int,
    $skip: Int
  ) {
    directMails(
      first: $first,
      skip: $skip,
      where: {
        AND: [
          {
            OR: [
              {
                firstName: {matchesRegex: $matchesRegex,options: "i"}
              },
              {
                lastName: {matchesRegex: $matchesRegex,options: "i"}
              },
              {
                objectName: {matchesRegex: $matchesRegex,options: "i"}
              },
              {
                address: {matchesRegex: $matchesRegex,options: "i"}
              },
              {
                resNum: {matchesRegex: $matchesRegex,options: "i"}
              }
            ]
          },
          {
            merged: {
              notEqualTo: true
            }
          }
        ]
      }
    ) {
      count
      edges {
        node {
          objectId
          objectName
          leadProvider
          resNum
          address
          createdAt
        }
      }
    }
  }
`;

export const MERGE_RECORD = gql`
  mutation MergeRecordToLead($leadId: ID!,$resNum: String!) {
    mergeRecordToLead(leadId: $leadId,resNum: $resNum)
  }
`;

export function useRecordSearch(props) {
  const [value, setValue] = useState("");
  let [query, setQuery] = useThrottle(value, 1000);
  const { success, error } = useAlert();
  const [mergeRecordToLead] = useMutation<MergeRecordToLead, MergeRecordToLeadVariables>(MERGE_RECORD);
  const limit = 15;
  const text = query ? query.trim() : null;
  const matchesRegex = `.*${text}.*`;
  const [load, { loading, data, called, refetch }] = useLazyQuery<SearchDirectMail, SearchDirectMailVariables>(SEARCH_DIRECT_MAIL, {
    variables: {
      skip: 0,
      first: limit,
      matchesRegex
    },
    fetchPolicy: "no-cache"
  });
  useEffect(() => setQuery(value), [value]);
  useMounted(load, [query]);
  const search = {
    loading,
    value,
    setValue,
    data: data?.directMails?.edges.map(({ node }) => node),
    count: data?.directMails?.count,
    async onRowClick(item) {
      await confirmModal({
        title: "Merge record with lead",
        content: `Are you sure you want to merge a direct mail record with ${props.objectName}?`,
        label: "Merge"
      });
      try {
        const { data: { mergeRecordToLead: isSuccess } = {} = {} } = await mergeRecordToLead({ variables: { leadId: props.leadId, resNum: item.resNum } });
        if (isSuccess) {
          success("Record was successfully merged.");
        } else {
          throw Error("Something went wrong");
        }
        props.onClose();
      } catch (e) {
        error(e.message);
        console.error(e);
      }
    },
    onPage(page: number) {
      return refetch({
        skip: (page - 1) * limit
      });
    },
    get isEmpty() {
      return !called || (!loading && (!Object.keys(data?.directMails).length || !data?.directMails?.count));
    }
  };

  return search;
}
