import React                            from "react";
import { FC }                           from "react";
import { useContext }                   from "react";
import { useEffect }                    from "react";
import { useCallback }                  from "react";
import { useMemo }                      from "react";
import { useFormState }                 from "@relcu/form";
import { getIn }                        from "@relcu/form";
import { useForm }                      from "@relcu/form";
import { useFieldArray }                from "@relcu/form";
import { useNavigate }                  from "@relcu/react-router";
import { useReactiveVar }               from "@apollo/client";
import { confirmModal }                 from "@relcu/ui";
import { ButtonVariants }               from "@relcu/ui";
import { Button }                       from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { ButtonSizes }                  from "@relcu/ui";
import { ButtonColors }                 from "@relcu/ui";
import { JsonSectionProps }             from "@relcu/ui";
import { getDefaultPhoneNumberForSMS }  from "../../../../utils/helpers";
import { getDefaultEmailAddress }       from "../../../../utils/helpers";
import { getDefaultPhoneNumberForCall } from "../../../../utils/helpers";
import { usePermissions }               from "../../../AccessControl";
import { useMarkConferenceRead }        from "../../../useMarkConferenceRead";
import { usePhone }                     from "../../../usePhone";
import { useViewerPhoneLines }          from "../../../useViewerPhoneLines";
import { LeadMembersContext }           from "../../Form/LeadForm";
import { LeadMemberForm }               from "./LeadMemberForm";
import { LeadMemberRow }                from "./LeadMemberRow";
import { LeadMemberSectionClasses }     from "./LeadMemberSectionClasses";

export const LeadMemberReadSection: FC = React.memo(function LeadMemberReadSection(props: JsonSectionProps) {
  const { editableMemberIndexVar } = useContext(LeadMembersContext);
  const editableMemberIndex = useReactiveVar(editableMemberIndexVar);
  const { fields } = useFieldArray("members", { subscription: { value: true } });
  const { $object, $viewer, $settings } = useSource();
  const { markAsRead } = useMarkConferenceRead({ id: $object.id, __typename: $object.__typename, userObjectId: $viewer.objectId });
  const { fromNumbers } = useViewerPhoneLines();
  const form = useForm();
  const navigate = useNavigate();
  const phone = usePhone();
  const { canUpdate } = usePermissions($object);
  const newMembers = useMemo(() => {
    const borrowers = [];
    fields.forEach(((name, index) => {
      const member = getIn(form.getState().values, name);
      borrowers.push({ name, member, index });
    }));
    borrowers.sort((next, prev) => {
      if (next.member.type == "borrower") {
        return -1;
      } else if (prev.member.type == "co_borrower" && !next.member?.contact?.id) {
        return 1;
      } else if (next.member.type == "co_borrower" && prev.member.type != "borrower") {
        return -1;
      }

      return 0;
    });
    return {
      borrowers
    };
  }, [fields, form]);

  function reset() {
    const { initial } = form.getFieldState("members");
    form.change("members", initial || []);
  }
  const onCall = useCallback((contact) => {
    const { number } = getDefaultPhoneNumberForCall(contact.phones, "Contact");
    phone.call({
      contactId: contact.objectId,
      contactName: contact.objectName,
      leadId: $object.objectId,
      leadName: $object.objectName,
      to: number,
      from: fromNumbers[ 0 ].value,
      markAsRead
    });
  }, [fromNumbers, phone, $object, markAsRead]);
  const onSms = useCallback((contact) => {
    const defaultPhoneNumber = getDefaultPhoneNumberForSMS(contact.phones, "Contact");
    navigate(`/lead/${$object.objectId}/sms`, { state: { to: { value: defaultPhoneNumber?.number, smsOptOut: defaultPhoneNumber?.smsOptOut } } });
  }, [navigate, $object]);
  const onEmail = useCallback((contact) => {
    const defaultEmailAddress = getDefaultEmailAddress(contact.emails);
    // ($settings.emailEnabled && $viewer.identities?.find((c)=>c?.provider=="microsoft")?.scope?.includes("Mail.Send")) ?
    //   navigate(`/lead/${$object.objectId}/emails`)
    //   : window.location.href = `mailto:${defaultEmailAddress?.address}`;
    window.location.href = `mailto:${defaultEmailAddress?.address}`;
  }, [$object]);
  const onEdit = useCallback((index) => {
    reset();
    editableMemberIndexVar(index);
  }, [form]);
  const onRemove = async (index) => {
    try {
      await confirmModal({
        title: "Remove loan contact",
        subTitle: `You are removing loan contact.`,
        content: `You can restore loan contacts by adding from "Loan contacts" section.`,
        label: "REMOVE"
      });
      reset();
      editableMemberIndexVar(null);
      form.setConfig("keepDirtyOnReinitialize", true);
      fields.remove(index);
      setTimeout(form.submit);
      form.setConfig("keepDirtyOnReinitialize", false);
    } catch (e) {
      console.error(e);
    }

  };
  const onClear = useCallback(() => {
    editableMemberIndexVar(null);
    reset();
  }, [fields]);
  const formState = useFormState({ subscription: { dirtyFieldsSinceLastSubmit: true, submitSucceeded: true, submitFailed: true } });
  useEffect(() => {
    if (formState.submitSucceeded && !formState.submitFailed) {
      setTimeout(() => {
        reset();
        editableMemberIndexVar(null);
      });
    }
  }, [formState.submitFailed, formState.submitSucceeded]);
  return <>
    {
      newMembers.borrowers.map(({ name, index, member }) => (index == editableMemberIndex) ?
        <Box container direction={"column"} gap={"XXS"} className={LeadMemberSectionClasses.Form}
             justify={"space-between"} key={index}>
          <Box container alignItems={"center"} className={LeadMemberSectionClasses.FormHeader}>
            Add contact to loan
            <Box container gap={"XXS"} alignItems={"center"}
                 className={LeadMemberSectionClasses.FormActions}>
              <Button icon={"save"} type="submit" variant={ButtonVariants.Ghost} color={ButtonColors.White}
                      onlyIcon size={ButtonSizes.Medium}/>
              <Button icon={"clear"} type="button" variant={ButtonVariants.Ghost} color={ButtonColors.White}
                      onClick={onClear} onlyIcon size={ButtonSizes.Medium}/>
            </Box>
          </Box>
          <Box container>
            <LeadMemberForm name={name}/>
          </Box>
        </Box>
        :
        <LeadMemberRow
          key={`${member.type}_${member?.contact?.id}_${index}`}
          canUpdate={canUpdate}
          onCall={onCall}
          onEmail={onEmail}
          onSms={onSms}
          fields={fields}
          index={index}
          name={name}
          onEdit={onEdit}
          onRemove={onRemove}/>
      )
    }
    {
      editableMemberIndex === null && canUpdate && <Button
        type="button" icon={"add"} alignSelf={"end"}
        onClick={() => {
          fields.push({});
          const { value } = form.getFieldState("members");
          editableMemberIndexVar(value.length - 1);
        }}
        variant={ButtonVariants.Ghost}>
        Add Contact
      </Button>
    }
  </>;
});
