import React                        from "react";
import { FC }                       from "react";
import { useCallback }              from "react";
import { useState }                 from "react";
import { useQuery }                 from "@apollo/client";
import { ButtonVariants }           from "@relcu/ui";
import { ButtonColors }             from "@relcu/ui";
import { Alignment }                from "@relcu/ui";
import { classNames }               from "@relcu/ui";
import { BoxComponentProps }        from "@relcu/ui";
import { Link }                     from "@relcu/ui";
import { Popper }                   from "@relcu/ui";
import { Tooltip }                  from "@relcu/ui";
import { SearchableMenu }           from "@relcu/ui";
import { Box }                      from "@relcu/ui";
import { FontIcon }                 from "@relcu/ui";
import { Button }                   from "@relcu/ui";
import { ButtonSizes }              from "@relcu/ui";
import { confirmModal }             from "@relcu/ui";
import { FIND_LEAD_BY_OBJECT_NAME } from "../LeadConnectionRC";
import { LeadConnectionClasses }    from "./LeadConnectionClasses";
import "./lead-connection.css";

export interface LeadConnectionProps extends BoxComponentProps {
  leadId?: any
  lead?: any,
  type?: string
  onClick(item)
  onRemove()
}

export const LeadConnection: FC<LeadConnectionProps> = React.memo((props) => {
  const { onClick, leadId, lead, className, justify, onRemove, type, ...p } = props;
  const [searchBounding, setSearchBounding] = useState(null);
  const [q, setQ] = useState(String());
  const handleOpen = useCallback((e) => {
    setSearchBounding(searchBounding ? null : e.currentTarget.getBoundingClientRect());
  }, [setSearchBounding, searchBounding]);
  const {
    data: { leads: { edges = [] } = {} } = {}
  } = useQuery(FIND_LEAD_BY_OBJECT_NAME, {
    fetchPolicy: "network-only",
    variables: {
      objectName: q
    }
  });
  const handleClose = useCallback(() => {
    setSearchBounding(null);
    setQ("");
  }, [setSearchBounding, searchBounding]);

  const handleSelect = useCallback((item) => {
    onClick(item);
    handleClose();
  }, [handleClose]);

  const removeLink = async () => {
    try {
      await confirmModal({
        title: "Remove lead relation",
        subTitle: `You are removing lead relation.`,
        content: `You can restore lead relation by clicking on "Connect to" button.`,
        label: "REMOVE"
      });
      await onRemove();
    } catch (e) {
      console.error(e);
    }
  };

  return <>
    {
      type === "bulk" ?
        <Box container gap={"XXXS"}>
          <Button icon={"link"} size={ButtonSizes.Large} variant={ButtonVariants.Ghost} onClick={(e) => handleOpen(e)}>
            Relate to
          </Button>
          <Button icon={"delete"} size={ButtonSizes.Large} variant={ButtonVariants.Ghost} onClick={removeLink}>
            Remove relation
          </Button>
        </Box>
        :
        <Box {...p} justify={justify} container gap={"XXXS"} alignItems={"center"}
             className={classNames(className, LeadConnectionClasses.LeadConnection)}>
          {
            lead ?
              <>
                <Link to={`/lead/${lead.objectId}/details`} justify={justify} container alignItems={"center"}
                      className={LeadConnectionClasses.LeadConnectionLink}>
                  <FontIcon type={"rc_lead"}/>
                  <span>{lead?.objectName}</span>
                </Link>
                <Tooltip title={"Change relation"} alignment={Alignment.Bottom}>
                  <Button onlyIcon size={ButtonSizes.Small} className={LeadConnectionClasses.LeadConnectionEdit}
                          icon={"link"} color={ButtonColors.White}
                          onClick={(e) => handleOpen(e)}/>
                </Tooltip>
                <Tooltip title={"Remove"} alignment={Alignment.Bottom}>
                  <Button onlyIcon size={ButtonSizes.Small} className={LeadConnectionClasses.LeadConnectionEdit}
                          icon={"delete"} color={ButtonColors.White}
                          onClick={removeLink}/>
                </Tooltip>
              </>
              :
              <Box container gap={"XXXS"} alignItems={"center"}
                   onClick={(e) => handleOpen(e)}>
                <FontIcon type={"link"}/>
                Connect to
              </Box>
          }
        </Box>
    }
    {
      <Popper alignment={Alignment.BottomLeft} open={!!searchBounding} onClickAway={handleClose}
              anchorBounding={searchBounding}>
        <SearchableMenu
          optionLabel={"objectName"}
          optionKey={"id"}
          options={edges.map(e => {
            return ({ id: e.node.objectId, objectName: e.node.objectName });
          })}
          onSelect={handleSelect}
          selected={lead && { id: lead.objectId }}
          searchText={q}
          loading={false}
          onType={(t) => {
            return t ? setQ(t) : setQ("");
          }}
          showCreateButton={false}
        />
      </Popper>
    }
  </>;
});
