import { useMemo }                      from "react";
import { useCallback }                  from "react";
import { useState }                     from "react";
import { makeVar }                      from "@apollo/client";
import { FormApi }                      from "@relcu/form";
import { useConstant }                  from "@relcu/ui";
import { omit }                         from "@relcu/ui";
import { IBaseField }                   from "../../../../../types/ISchemas";
import { uuid }                         from "../../../../../utils/helpers";
import { getField }                     from "../../../../../utils/schemaUtils";
import { steps }                        from "./DistributionQueueDialog";
import { DistributionQueueDialogProps } from "./DistributionQueueDialog";
import { sortSchedule }                 from "./utils";
import { defaultValidator }             from "@relcu/ui";
import { convertLimits }                from "./utils";
import { parseLimits }                  from "./utils";
import { parseSchedule }                from "./utils";
import { parseSort }                    from "./utils";
import { convertSort }                  from "./utils";
import { convertSchedule }              from "./utils";

export const DistributionDialogErrorMessages = {
  leadsFilter: "Lead filtering is incomplete. Make sure to complete all mandatory steps to proceed.",
  usersFilter: "Lead-to-user mapping is incomplete. Make sure to complete all mandatory steps to proceed.",
  sort: "Lead sorting and distribution sequence is incomplete. Make sure to complete all mandatory steps to proceed.",
  "view.name": "Queue name is required."
};

export function useDistributionDialog(props: DistributionQueueDialogProps) {
  const { data, onConfirm, edit } = props;
  const [step, setStep] = useState(0);
  const schemas = useMemo(getSchemas, [props]);
  const notCompleteRulesVar = useConstant(() => makeVar({}));
  const notCompleteAppearanceVar = useConstant(() => makeVar<{ [ key: string ]: any }>({}));
  const handleSubmit = useCallback(async (data) => {
    try {
      const isValid = validateData(data);
      if (!isValid) {
        navigateToInvalidStep();
        return;
      }
      const result = covertFromData(data);
      result.draft = false;
      onConfirm(result);
    } catch (e) {
      console.error(e);
    }
  }, [step]);
  const handleDraftSave = useCallback(async (form: FormApi<any>) => {
    try {
      const data = form.getState().values;
      const result = covertFromData(data);
      result.notify = false;
      result.notifyMobile = false
      result.disabled = true;
      result.draft = true;
      onConfirm(result);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const changeStep = useCallback((e, form: FormApi<any>) => {
    e.stopPropagation();
    e.preventDefault();
    const values = form.getState().values;
    if (!values.rules || values.rules.length == 0) {
      return;
    }

    const isValid = validateData(values);
    if (!isValid) {
      return;
    }
    setStep((prevStep) => {
      if (prevStep < steps.length - 1) {
        return prevStep + 1;
      }

      return prevStep;
    });
  }, [steps, step]);

  function validateData(data) {
    let isValid = true;
    if ((!edit && step == 0) || edit) {
      const rulesValid = validateRules(data.rules);
      if (!rulesValid) {
        isValid = false;
      }
    }

    if ((!edit && step == 2) || edit) {
      const appearanceValid = validateAppearance(data.view);
      if (!appearanceValid) {
        isValid = false;
      }
    }

    return isValid;
  }
  function validateRules(rules) {
    let isRulesValid = true;
    rules.map((rule, index) => {
      if (!rule.leadsFilter) {
        addError(`${index}`, "leadsFilter", DistributionDialogErrorMessages[ "leadsFilter" ]);
        isRulesValid = false;
      } else {
        const valid = !Object.values(defaultValidator(rule.leadsFilter)).filter(rv => (typeof rv !== "boolean") || rv === false).length;
        if (!valid) {
          addError(`${index}`, "leadsFilter", DistributionDialogErrorMessages[ "leadsFilter" ]);
          isRulesValid = false;
        } else {
          removeError(`${index}`, "leadsFilter");
        }
      }

      if (rule.usersFilter) {
        const validUserFilters = !Object.values(defaultValidator(rule.usersFilter)).filter(rv => (typeof rv !== "boolean") || rv === false).length;
        if (!validUserFilters) {
          addError(`${index}`, "usersFilter", DistributionDialogErrorMessages[ "usersFilter" ]);
          isRulesValid = false;
        } else {
          removeError(`${index}`, "usersFilter");
        }
      } else {
        removeError(`${index}`, "usersFilter");
      }

      if (rule?.sort?.length == 0) {
        removeError(`${index}`, "sort");
      } else {
        for (let i = 0; i < rule?.sort?.length; i++) {
          const sorts = rule.sort[ i ];
          if (!sorts?.order || !sorts?.prioritizeBy) {
            addError(`${index}`, "sort", DistributionDialogErrorMessages[ "sort" ]);
            isRulesValid = false;
            break;
          } else {
            removeError(`${index}`, "sort");
          }
        }
      }
    });
    return isRulesValid;
  }
  function validateAppearance(view) {
    let isAppearanceValid = true;
    const notCompleteAppearance = { ...notCompleteAppearanceVar() };
    if (!view.name?.trim()) {
      notCompleteAppearance.name = DistributionDialogErrorMessages[ "view.name" ];
      isAppearanceValid = false;
    } else {
      delete notCompleteAppearance.name;
    }
    notCompleteAppearanceVar(notCompleteAppearance);
    return isAppearanceValid;
  }
  function removeError(index: string, errorKey: string) {
    let notCompleteRules = notCompleteRulesVar();
    const errors = {};
    Object.keys(notCompleteRules).forEach(key => {
      const e = notCompleteRules[ key ];
      if (key === index) {
        const r = { ...e };
        delete r[ errorKey ];

        if (!Object.keys(r).length) {
          delete errors[ key ];
        } else {
          errors[ index ] = r;
        }
      } else {
        errors[ key ] = e;
      }
    });
    notCompleteRulesVar(errors);
  }
  function addError(index: string, key: string, message: string) {
    const notCompleteRules = { ...notCompleteRulesVar() };
    if (notCompleteRules[ index ]) {
      notCompleteRules[ index ] = {
        ...notCompleteRules[ index ],
        [ key ]: message
      };
    } else {
      notCompleteRules[ index ] = {
        [ key ]: message
      };
    }
    notCompleteRulesVar(notCompleteRules);
  }
  function covertFromData(data) {
    const convertedSchedule = convertSchedule(data.schedule ?? []);
    return {
      draft: data.draft,
      notify: data.notify,
      notifyMobile: data.notifyMobile,
      autoDial: data.autoDial,
      disabled: !data.disabled,
      strategy: data.strategy,
      distributeOfflineUsers: data.distributeOfflineUsers,
      skipTimezoneCheck: data?.skipTimezoneCheck,
      view: {
        ...omit(data?.view, ["__typename"]),
        webLeadSound: omit(data?.view.webLeadSound, ["__typename"]),
        phoneLeadSound: omit(data?.view.phoneLeadSound, ["__typename"]),
        name: data?.view?.name?.trim()
      },
      schedule: convertedSchedule ? omit(convertedSchedule, ["__typename"]) : convertedSchedule,
      limits: convertLimits(data.limits),
      rules: data.rules.map(rule => {
        const r = {
          ...rule,
          sort: rule.sort.length ? convertSort(rule.sort) : null,
          limits: convertLimits(rule.limits)
        };
        if (!edit || !r.slug) {
          r.slug = uuid();
        }
        return r;
      })
    };
  }
  function navigateToInvalidStep() {
    let invalidStepIndex;
    const notCompleteRules = notCompleteRulesVar();
    const notCompleteAppearance = notCompleteAppearanceVar();
    if (Object.keys(notCompleteRules).length) {
      invalidStepIndex = 0;
    } else if (Object.keys(notCompleteAppearance).length) {
      invalidStepIndex = 2;
    }

    if (invalidStepIndex != null) {
      setStep(prev => {
        if (prev != invalidStepIndex) {
          return invalidStepIndex;
        }
        return prev;
      });
    }
    //todo in case of adding error in other steps add new reactiveVars and check them too
  }

  function getSchemas() {
    return props.dialogProps.leadFilters.reduce((schema, column) => {
      schema[ column.name ] = getField("Lead", column?.schemaName ?? column.name);//todo fix this shit
      schema[ column?.schemaName ?? column.name ] = getField("Lead", column?.schemaName ?? column.name);//todo fix this shit
      return schema;
    }, {});
  }

  function clearRulesError(index, errorName) {
    const notCompleteRules = notCompleteRulesVar();
    const errors = { ...notCompleteRules };
    delete errors?.[ index ]?.[ errorName ];
    notCompleteRulesVar(errors ?? {});
  }

  const initialValues = useMemo(() => {
    return {
      draft: data?.draft,
      notify: data?.notify,
      notifyMobile: data?.notifyMobile,
      autoDial: data?.autoDial,
      disabled: !data?.disabled,
      distributeOfflineUsers: data?.distributeOfflineUsers,
      skipTimezoneCheck: data?.skipTimezoneCheck,
      strategy: data?.strategy || "pull",
      view: {
        ...data?.view,
        name: data?.view?.name || data?.objectName,
        icon: data?.view.icon || "switch_account",
        color: data?.view.color || "primary",
        webLeadSound: data?.view.webLeadSound || { "repeat": 0, "sound": "lead-increment" },
        phoneLeadSound: data?.view.phoneLeadSound || { "repeat": 0, "sound": "call-increment" }
      },
      limits: parseLimits(data?.limits ?? [{ filter: ["*"] }]),
      rules: data?.rules.map(rule => {
        return {
          ...omit(rule, ["__typename"]),
          limits: parseLimits(rule.limits ?? [{ filter: ["*"] }]),
          sort: parseSort(rule.sort ?? []),
          leadsFilter: rule.leadsFilter ?? {},
          usersFilter: rule.usersFilter
        };
      }) ?? [
        {
          name: "Rule 1",
          leadsFilter: {
            "combinator": "and",
            "rules": [{
              "field": "assignedTo.objectId",
              "id": "r-default-1",
              "operator": "is null",
              "valueSource": "value",
              "value": ""
            },
              {
                "field": "createdAt",
                "id": "r-default-2",
                "operator": ">",
                "valueSource": "value",
                "value": { _now: "-10minutes" }
              }
            ]
          },
          limits: parseLimits([{ filter: ["*"] }]),
          sort: []
        }
      ],
      schedule: sortSchedule(parseSchedule(data?.schedule ? omit(data?.schedule, ["__typename"]) : null, "00:00-12:00"))
    };
  }, [data]);

  return {
    getFieldOptions(className, fieldName) {
      return getField(className, fieldName).options;
    },
    getFieldSchema<T = IBaseField>(field: string): T {
      return schemas[ field ];
    },
    step,
    setStep,
    changeStep,
    handleSubmit,
    handleDraftSave,
    initialValues,
    notCompleteRulesVar,
    notCompleteAppearanceVar,
    clearRulesError,
    convertLimits
  };
}
