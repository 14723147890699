import { ListRow }             from "@relcu/ui";
import { ListRowColors }       from "@relcu/ui";
import React                   from "react";
import { FC }                  from "react";
import { JsonSectionProps }    from "@relcu/ui";
import { ButtonColors, Radio } from "@relcu/ui";
import { Button }              from "@relcu/ui";
import { Box }                 from "@relcu/ui";
import { useMounted }          from "@relcu/ui";
import { EmptyList }           from "@relcu/ui";
import { VoiceTemplate }       from "@relcu/ui";
import { VoiceAction }         from "@relcu/ui";
import { Section }             from "@relcu/ui";
import { useVoiceMailSection } from "../VoiceMailSection";

export const VoiceOutOfWorkSection: FC<JsonSectionProps> = React.memo(function VoiceTemplateSection(props) {
  const { title, view, editable } = props;
  const api = useVoiceMailSection(title, "voicemail_outOfWork_template");
  useMounted(async () => {
    if (!api.isSelected && api.data.length === 1) {
      try {
        await api.setDefault(api.data[ 0 ].node.id);
      } catch (e) {
        console.error(e);
      }
    }

  }, [api.isSelected, api.data]);
  return <Section view={view} title={title} editable={editable} container direction={"column"} gap={"XS"} flexGrow={1}>
    <Box container direction={"column"} gap={"XS"} flexGrow={1}>
      {
        api.voiceMails.length == 0 && !api.active
          ?
          <Box container direction="column" gap={"S"}>
            <EmptyList icon={"rc_voicemail_call"}
                       content={`Record your voicemail template by clicking on "Start recording" below`}
                       title={`You do not have voicemail templates`}
                       flexGrow={1}/>
            <Button
              alignSelf="center"
              disabled={api.busy}
              onClick={api.toggleRecording}
              icon={!!api.active ? "stop" : "record_voice_over"}
              color={ButtonColors.Primary}
            >
              START RECORDING
            </Button>
          </Box>
          :
          <>
            <VoiceAction busy={api.busy} active={api.active} onClick={api.toggleRecording}/>
            {api.voiceMails.map((recording, index) => (
              recording.uploaded ?
                <Box container key={recording.id + index} gap="XXS">
                {
                  api.editId != recording.id &&
                  <Radio
                    name={"default_outOfWork"}
                    onChange={() => api.onChangeDefault(recording.id)}
                    checked={recording.id == api.defaultVoicemail?.id}/>
                }
                <VoiceTemplate
                  flex={1}
                  key={recording.id}
                  onEdit={() => api.setEditId(recording.id)}
                  editing={api.editId == recording.id}
                  voiceTemplate={recording}
                  onCancel={api.onCancel}
                  onDelete={api.deleteRecording}
                  onSave={api.handleSave}
                />
              </Box> :
                recording?.uploadStatus != 'failed' &&
                <ListRow
                  key={recording.id + index}
                  gap={"XXXL"}
                  color={ListRowColors.Grey}
                  outlined={false}
                  style={{marginLeft: 33}}
                >
                  <p
                    style={{ color: "var(--color-gray-extra-dark)", fontSize: "var(--typography-body-font-size)" }}>{recording.name} recording
                    is being processed. </p>
                </ListRow>
            ))}
          </>
      }
    </Box>
  </Section>;
});

export default VoiceOutOfWorkSection;
