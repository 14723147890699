import { CircularLoader }  from "@relcu/ui";
import { classNames }      from "@relcu/ui";
import { IconType }        from "@relcu/ui";
import { Box }             from "@relcu/ui";
import { List }            from "@relcu/ui";
import { ListViewClasses } from "@relcu/ui";
import { JsonViewProps }   from "@relcu/ui";
import React               from "react";
import { FC }              from "react";
import { EmptyList }       from "@relcu/ui";
import { useMessageList }  from "./useMessageList";

export const SmsListView: FC<JsonViewProps & { icon?: IconType, title?: string }> = React.memo(function SmsListView(props) {
  const messages = useMessageList();
  return (
    <Box
      container
      justify={(messages.loading || messages.objects.length == 0) ? "center" : null}
      className={classNames(ListViewClasses.ListView, {
        [ ListViewClasses.ListEmptyView ]: messages.loading || messages.objects.length == 0
      })}>
      {
        messages.loading ?
          <CircularLoader/>
          :
          (messages.objects.length == 0 ?
            <EmptyList icon={props.icon} content={`When you have ${props.title.toLowerCase()}, you'll see them here.`}
                       title={`No ${props.title} found`}
                       alignSelf={"center"}/>
            :
            <List
              notifiableItems={messages.notifiableItems}
              matchingKey={'objectId'}
              emptyIcon={props.icon}
              headers={props.headers}
              onRowClick={messages.onRowClick}
              count={messages.limit}
              currentPage={messages.currentPage}
              fields={props.fields}
              objects={messages.objects}
              total={messages.count}
              onPage={messages.onPage}
              getColumnOptions={messages.getColumnOptions}
              flex={1}
            />)
      }
    </Box>
  );
});
export default SmsListView;
