import { HTMLAttributes }             from "react";
import React, { useContext, useMemo } from "react";
import Typography                     from "../Typography";
import { useClassNames }              from "../utils";
import { useConstant }                from "./useConstant";
import { TabClasses }                 from "./TabClasses";
import { Elements }                   from "./TabContainer";
import { TabsState }                  from "./TabContainer";

export interface TabItemProps extends HTMLAttributes<HTMLDivElement> {
  active: boolean;
}

const defaultTabItemProps: TabItemProps = {
  active: false
};

TabItem.defaultProps = defaultTabItemProps;
export function TabItem(props: TabItemProps) {
  const { className, children, active, ...p } = props;
  const state = useTabState();
  const { merge, withClassPrefix } = useClassNames(TabClasses.TabItem, "rc");
  const classes = merge(className, withClassPrefix({ "active": state.isActive }));

  if (typeof children === "function") {
    return React.cloneElement(children(active), {
      onClick: state.onClick
    });
  }

  return <div
    className={classes}
    {...p}
    onClick={(event) => {
      p.onClick?.(event);
      state.onClick();
    }}>
    {children}
  </div>;
}

export function useTabState() {
  const [activeIndex, setActive] = useContext(TabsState);
  const elements = useContext(Elements);

  const tabIndex = useConstant(() => {
    const currentIndex = elements.tabs;
    elements.tabs += 1;

    return currentIndex;
  });

  const onClick = useConstant(() => () => setActive(tabIndex));

  const state = useMemo(
    () => ({
      isActive: activeIndex === tabIndex,
      onClick
    }),
    [activeIndex, onClick, tabIndex]
  );

  return state;
}
