import { useForm }                      from "@relcu/form";
import { useField }                     from "@relcu/form";
import { FormSpy }                      from "@relcu/form";
import { Field }                        from "@relcu/form";
import { SectionMessage }               from "@relcu/rc";
import { EmptyState }                   from "@relcu/rc";
import { Typography }                   from "@relcu/rc";
import { Form }                         from "@relcu/rc";
import { useSource }                    from "@relcu/ui";
import { BoxComponentProps }            from "@relcu/ui";
import { Box }                          from "@relcu/ui";
import React, { useMemo }               from "react";
import { FC }                           from "react";
import { Button }                       from "@relcu/rc";
import { Modal }                        from "@relcu/rc";
import { ModalProps }                   from "@relcu/rc";
import { Sidebar }                      from "@relcu/rc";
import { Container }                    from "@relcu/rc";
import { Content }                      from "@relcu/rc";
import { Header }                       from "@relcu/rc";
import { smsTemplate }                  from "../../../utils/helpers";
import { BulkModalBody }                from "../../Bulk/BulkModalBody";
import { BulkRecipientList }            from "../../Bulk/BulkRecipientList";
import { useBulkSendPhoneMessageModal } from "./useBulkSendPhoneMessageModal";
import "./send-bulk-message-modal.css";

export interface BulkSendPhoneMessageModalProps extends Partial<ModalProps> {
  variables: any,
  ids: string[];
  onSubmit(data);
}

export const BulkSendPhoneMessageModal: FC<BulkSendPhoneMessageModalProps> = React.memo(function BulkSendPhoneMessageModal(props) {
  const { variables, ids, onSubmit, ...modalProps } = props;
  const {
    onBulkSubmit,
    query,
    from,
    numbers,
    handleSelect,
    selected,
    messageTemplates,
    receiver,
    primaryBorrower,
    getTemplate
  } = useBulkSendPhoneMessageModal({ ...props });
  const { $viewer } = useSource();

  return (
    <Modal {...modalProps} size={"lg"}>
      <Form
        onSubmit={onBulkSubmit}
        initialValues={{ from }}
        layout={"inline"}
      >
        <Modal.Header>
          <Typography weights={"medium"} variant={"base16"}>Create bulk SMS</Typography>
        </Modal.Header>
        <BulkModalBody>
          <Sidebar width={310}>
            <BulkRecipientList
              query={query}
              type={"sms"}
              selected={selected}
              onSelect={handleSelect}
            />
          </Sidebar>
          <Container>
            <Header className={"send-bulk-sms-modal-header"}>
              <Form.Select
                name="from"
                label="From:"
                errorMessage={false}
                properties={{
                  size: "sm",
                  style: { width: 251 }
                }}
                data={numbers}
              />
              <Form.Select
                name="template"
                label="Template:"
                required
                errorMessage={false}
                properties={{
                  size: "sm",
                  style: { width: 237 },
                  cleanable: true
                }}
                data={messageTemplates}
              />
            </Header>
            <Field name={"template"} subscription={{ value: true }}>
              {({ input: { value: templateId } }) => {
                const template = !!templateId && getTemplate(templateId);
                const from = useField("from").input.value;
                return (
                  <>
                    <Content>
                      {
                        !!template ?
                          <Typography lineHeight={"lh20"}
                                      variant={"base14"}>
                            {smsTemplate(template.text, primaryBorrower.contact, (from == "" ? selected.assignedTo : $viewer), receiver, selected)}
                          </Typography> :
                          <EmptyState
                            icon={"description"}
                            title={"Template not selected"}
                            subtitle={"Please select the template"}
                          />
                      }
                    </Content>
                  </>
                );
              }}
            </Field>
            <FormSpy
              subscription={{ errors: true, touched: true }}>
              {
                ({ errors, touched }) => {
                  if (touched.template && errors?.template) {
                    return <div className={"send-bulk-message-modal-warning"}>
                      <SectionMessage type="warning">Content is missing. Please choose template to send the
                        message.</SectionMessage>
                    </div>;
                  }
                  return null;
                }
              }
            </FormSpy>
          </Container>
        </BulkModalBody>
        <Modal.Footer>
          <Button type={"button"} size={"xs"} onClick={props.onClose} color={"blue"} appearance="text">CANCEL</Button>
          <Button type={"submit"} size={"xs"} appearance="primary">SEND</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

interface BulkSendPhoneMessageModalAlertProps extends BoxComponentProps {
  handleClick: () => void;
}

export const BulkSendPhoneMessageModalAlert: FC<BulkSendPhoneMessageModalAlertProps> = function (props) {
  const { handleClick } = props;
  const style = {
    color: "white",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 500
  };
  return (
    <Box>
      SMSs in progress, you can follow the process on <a onClick={handleClick} style={style}>SMS</a> tab.
    </Box>
  );
};
