import { deepClone }                    from "@relcu/ui";
import { useRef }                       from "react";
import { useEffect }                    from "react";
import { useState }                     from "react";
import { useCallback }                  from "react";
import { useMemo }                      from "react";
import { shallowEqualObjects }          from "@relcu/ui";
import { omit }                         from "@relcu/ui";
import { deepPick }                     from "@relcu/ui";
import { useMutation }                  from "@apollo/client";
import { useQuery }                     from "@apollo/client";
import { gql }                          from "@apollo/client";
import { useNavigate }                  from "@relcu/react-router";
import { useSearchParams }              from "@relcu/react-router";
import { useAlert }                     from "@relcu/ui";
import { useSource }                    from "@relcu/ui";
import { IColumn }                      from "@relcu/ui";
import { useModal }                     from "@relcu/ui";
import { useLazyCondition }             from "@relcu/ui";
import { JsonViewProps }                from "@relcu/ui";
import { confirmModal }                 from "@relcu/ui";
import { transformNameToLabel }         from "../../../../utils/helpers";
import { compileVars }                  from "../../../../utils/helpers";
import { pluralize }                    from "../../../../utils/pluralize";
import { getField }                     from "../../../../utils/schemaUtils";
import { toGqlQuery }                   from "../../../../utils/toGqlQuery";
import { usePermissions }               from "../../../AccessControl";
import { PageViewDialog }               from "../../Dialog/PageViewDialog/PageViewDialog";
import { useJqlQuery }                  from "../../Jql";
import { SchemaDialog }                 from "../../Dialog/SchemaDialog";
import { CreateTableViewVariables }     from "./__types__/CreateTableView";
import { CreateTableView }              from "./__types__/CreateTableView";
import { DeleteTableViewVariables }     from "./__types__/DeleteTableView";
import { DeleteTableView }              from "./__types__/DeleteTableView";
import { GetTableViewJqlVariables }     from "./__types__/GetTableViewJql";
import { GetTableViewJql }              from "./__types__/GetTableViewJql";
import { GetTableViewsVariables }       from "./__types__/GetTableViews";
import { GetTableViews }                from "./__types__/GetTableViews";
import { SubscribeTableViewsVariables } from "./__types__/SubscribeTableViews";
import { SubscribeTableViews }          from "./__types__/SubscribeTableViews";
import { UpdateTableViewVariables }     from "./__types__/UpdateTableView";
import { UpdateTableView }              from "./__types__/UpdateTableView";

const dialogTitles = {
  PhoneMessageTemplate: "SMS template",
  PmiPartner: "PMI partner"
};

const actionsSteps = {
  "create": 0,
  "edit": 0,
  "share": 3
};
export function useTableView(props: JsonViewProps) {
  const [tableColumns, setTableColumns] = useState([]);
  const [viewOpen, setViewOpen] = useState(!!props.group);
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = useRef(null);
  const source = useSource();
  const { $viewer } = source;
  const { success, error } = useAlert();
  const navigate = useNavigate();
  const { canCreate } = usePermissions({ __typename: props.classId });
  const [searchParams, setSearchParams] = useSearchParams();
  const evaluate = useLazyCondition();
  const [modal, modalContext] = useModal(SchemaDialog);
  const [pageViewModal, pageViewModalContext] = useModal(PageViewDialog);
  const defaultRules = useMemo(() => {
    return compileVars(props.filters.rules || [], source);
  }, [props.filters.rules]);
  const setSelectedViewId = (view) => {
    setSelectedRows([]);
    if (view) {
      setSearchParams({ view });
    } else {
      navigate("");
    }
  };
  const count = 30;
  const currentPage = +searchParams.get("page") || 1;
  const currentView = searchParams.get("view");
  const selectedViewId = currentView || null;
  const order = searchParams.get("order") || props.order || "createdAt_DESC";
  const offset = count;
  const operation = props.jql.query.find.operation;
  const { data: { tableViews = { edges: [] } } = {}, refetch: reFetchTableViews, subscribeToMore } = useQuery<GetTableViews, GetTableViewsVariables>(GET_TABLE_VIEWS, {
    variables: { where: { group: { equalTo: props.group } } }
  });
  const selectedView = useMemo(() => tableViews.edges.find(v => v.node.objectId == selectedViewId)?.node, [tableViews, selectedViewId]);
  const [createTableView] = useMutation<CreateTableView, CreateTableViewVariables>(CREATE_TABLE_VIEWS);
  const [updateTableView] = useMutation<UpdateTableView, UpdateTableViewVariables>(UPDATE_TABLE_VIEWS);
  const [deleteTableview] = useMutation<DeleteTableView, DeleteTableViewVariables>(DELETE_TABLE_VIEWS);
  const where = useMemo(() => {
    let filters;
    if (defaultRules) {
      filters = {
        id: "defaultRuleWrapper",
        combinator: "and",
        rules: defaultRules
      };
    }
    if (selectedView?.filter) {
      filters.rules = [
        ...filters.rules,
        selectedView.filter
      ];
    }
    if (filters) {
      return JSON.parse(toGqlQuery(filters, props.classId, getField));
    } else {
      return {};
    }
  }, [selectedView?.filter, defaultRules]);
  const variables = useMemo(() => {
    const variables = props.variables || {
      where: where,
      first: offset,
      skip: offset * (currentPage - 1),
      order: [order]
    };

    if (!order || (!order.includes("objectId_ASC") && !order.includes("objectId_DESC"))) {
      variables.order.push("objectId_ASC");
    }
    return variables;
  }, [props.variables, currentPage, order, offset, where]);

  const findQuery = useMemo(() => {
    props.jql.query.find.fields.find(e => e.edges)?.edges?.find(e => e.node)?.node?.unshift("notifications @client");
    return props.jql.query.find;
  }, [props.jql.query.find.fields, selectedView]);
  const fields = useMemo(() => {
    return props.fields.map((f) => {
      return {
        name: f.name,
        field: f.field,
        fields: f.fields
      };
    });
  }, [props.fields]);

  const actions = useMemo(() => {
    return props.actions?.map(a => {
      return {
        type: a.type,
        field: a.field
      };
    });
  }, [props.actions]);
  const { data: { getTableViewJql: { __typename, variables: queryVariables, fields: queryFields, operation: queryOperation } = {} } = {}, refetch: refetchJql, loading: loadingJql } = useQuery<GetTableViewJql, GetTableViewJqlVariables>(GET_TABLE_VIEW_JQL, {
    variables: {
      id: selectedView?.objectId,
      actions,
      fields,
      className: props.classId
    },
    skip: !selectedView
  });

  const clientQueryFields = useMemo(() => {
    if (queryFields) {
      const clientQueryFields = deepClone(queryFields);
      clientQueryFields?.find(e => e.edges)?.edges?.find(e => e.node)?.node.unshift("notifications @client");
      return clientQueryFields;
    }
    return queryFields;
  }, [queryFields]);

  const { data, loading, refetch } = useJqlQuery(selectedView ? {
    operation: queryOperation,
    fields: clientQueryFields,
    variables: omit(queryVariables, ["__typename"])
  } : findQuery, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    operationName: `${props.classId}TableViewQuery`,
    skip: selectedView && (queryOperation == undefined || queryFields == undefined || queryVariables == undefined),
    variables
  });

  useEffect(() => {
    const currentView = localStorage.getItem(props.group);
    setSelectedViewId(currentView);
  }, [props.group]);
  useEffect(() => {
    if (currentView) {
      localStorage.setItem(props.group, currentView);
    } else {
      localStorage.removeItem(props.group);
    }
  }, [currentView, props.group]);

  useEffect(() => {
    return subscribeToMore<SubscribeTableViews, SubscribeTableViewsVariables>({
      document: SUBSCRIBE_TABLE_VIEWS,
      variables: {
        where: { group: { equalTo: props.group } }
      },
      updateQuery(prev, { subscriptionData: { data: { tableViews: { node, event } } } }) {
        reFetchTableViews().catch(console.error);
        return prev;
      }
    });
  }, [props.group]);
  useEffect(() => {
    if (selectedView?.fields) {
      setTableColumns(selectedView.fields.map((sColumn) => {
        const aColumn = api.availableColumns.find(a => a.key == sColumn.key);
        return {
          ...aColumn, fixed: sColumn.fixed, visible: true
        };
      }));
    } else {
      setTableColumns(api.defaultColumns);
    }
  }, [selectedView]);

  const api = {
    queryVariables: variables,
    tableRef,
    order,
    name: dialogTitles[ props.classId ] || transformNameToLabel(props.classId),
    title: props.title,
    count,
    offset,
    currentPage,
    selectedRows,
    setSelectedRows,
    className: props.className,
    blank: props.blank,
    get data() {
      const nodes = data?.[ operation ]?.[ "edges" ] ?? [];
      return nodes.map(({ node }) => {
        return node;
      });
    },
    get pageCount() {
      return Math.ceil((api.total || api.data.length) / count);
    },
    get total() {
      return (
        data &&
        data[ operation ] &&
        data[ operation ][ "count" ]
      ) || 0;
    },
    get draft() {
      if (!selectedView || !tableViews.edges.length || !tableColumns.length) {
        return false;
      }
      const view = tableViews.edges.find(v => v.node.objectId == selectedView.objectId);
      if (!view || view.node.createdBy.objectId !== $viewer.objectId) {
        return false;
      }
      const tFields = deepPick(tableColumns, ["key", "fixed"]);
      const sFields = deepPick(view.node.fields, ["key", "fixed"]);
      return sFields.some((sField, index) => {
        const tField = (tFields[ index ]);
        return Object.entries(sField).some(([sF, sV]) => {
          const tV = tField?.[ sF ] ?? null;
          const notEqual = !shallowEqualObjects(sV, tV);
          return notEqual;
        });
      });
    },
    onSelectView(view) {
      setSelectedViewId(view?.objectId || null);
      // currentPage > 1 && api.onPage(1);
    },
    async onDeleteView(viewId) {
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete View?`,
        content: `All information related with this View will be lost permanently`,
        label: "DELETE"
      });
      await deleteTableview({
        variables: {
          input: { id: viewId }
        },
        update: () => {
          reFetchTableViews();
        }
      });

      if (selectedView && selectedView.id == viewId) {
        setSelectedViewId(null);
      }
      success("Page view deleted successfully.");
    },
    onColumnsChange(columns) {
      setTableColumns(columns);
      //todo fix this fucking naughty sheet
      //Resize observer API does not allow trigger change
      // https://relcu.atlassian.net/browse/RC-2324
      //@ts-ignore
      const currentHeight = Number(tableRef.current.root.style.height.replace(/\D/g, ""));
      //@ts-ignore
      tableRef.current.root.style.height = `${currentHeight - 1}px`;
      setTimeout(() => {
        //@ts-ignore
        tableRef.current.root.style.height = `${currentHeight}px`;
      }, 100);
    },
    onSort(sortField, type) {
      searchParams.set("order", `${sortField}_${type.toUpperCase()}`);
      setSelectedRows([]);
      navigate(`/${location.pathname}?${searchParams.toString()}`);
    },
    navigateDefault() {
      if (selectedViewId) {
        setSearchParams({ page: "1", view: selectedViewId });
      } else {
        setSearchParams({ page: "1" });
      }
      setSelectedRows([]);
    },
    onPage(page) {
      searchParams.set("page", page.toString());
      setSelectedRows([]);
      navigate(`/${location.pathname}?${searchParams.toString()}`);
    },
    onCreate(obj) {
      modal({
        action: "create",
        className: props.classId,
        title: dialogTitles[ obj.__typename ]
      });
    },
    get headers() {
      return props.headers?.filter((field) => {
        if (field.conditions) {
          const { apply } = evaluate({ conditions: field.conditions });
          return apply;
        }

        return true;
      });
    },
    get availableColumns(): IColumn[] {
      return props.fields.filter((field) => {
        if (field.conditions) {
          const { apply } = evaluate({ conditions: field.conditions });
          return apply;
        }
        return true;
      }).map(({ label, name, ...other }, index) => ({
        title: label,
        key: name,
        resizable: !other.flexGrow,
        ...other
      } as IColumn));
    },
    get defaultColumns(): IColumn[] {
      return api.availableColumns.filter(c => c.visible);
    },
    get createAction() {
      const canCreateAccess = "creatable" in props ? props.creatable : props.editable;
      if (canCreateAccess) {
        const apply = typeof canCreateAccess === "object" ? evaluate({ conditions: props.creatable }).apply : canCreateAccess;
        if (canCreate && apply) {
          return {
            onClick(e, data) {
              e.stopPropagation();
              api.onCreate({ __typename: props.classId });
            }
          };
        }
      }
      return;
    }
  };
  const handleViewCLick = async ({ label, value }) => {
    const initialStep = actionsSteps[ value ];
    // const group = props.group.split("-")[ 0 ];
    if (initialStep != undefined) {
      const { destroy } = pageViewModal({
        share: !$viewer.dataTableView || $viewer.dataTableView.share,
        edit: value != "create",
        initialStep: initialStep,
        schemaName: props.classId,
        permission: props.permissions,
        fields: props.filters.fields,
        data: {
          availableFields: [...props.fields],
          selectedView: value != "create" ? { ...selectedView } : null
        },
        onConfirm: async (data) => {
          try {
            const { id, columns, ...viewData } = data;
            if (id) {
              const view = await updateTableView({
                variables: {
                  input: {
                    id: id,
                    fields: { ...viewData, fields: columns } as any
                  }
                },
                update: async () => {
                  await reFetchTableViews();
                  await refetchJql();
                  await refetch();
                  setSelectedViewId(view.data.updateTableView.tableView.objectId);
                  api.navigateDefault();
                  // currentPage > 1 && api.onPage(1);
                }
              });
              success("Page view updated successfully.");
            } else {
              const { columns, ...viewData } = data;
              const view = await createTableView({
                variables: { input: { fields: { ...viewData, fields: columns, group: props.group, createdBy: { link: $viewer.id } } } },
                update: async () => {
                  await reFetchTableViews();
                  setSelectedViewId(view.data.createTableView.tableView.objectId);
                }
              });
              success("Page view created successfully.");
            }
            destroy();
          } catch (e) {
            console.error(e);
            error(e.message.toString());
          }
          searchParams.delete("order");
          setSearchParams(searchParams);
          // navigate(`/${location.pathname}?${searchParams.toString()}`);
        }
        // dialogProps: options.properties,
      });
    } else {
      if (value == "delete") {
        await api.onDeleteView(selectedView.id);
      }
      if (value == "save") {
        try {
          const fields = deepPick(tableColumns, ["key", "fixed", "properties"]);
          await updateTableView({
            variables: {
              input: {
                id: selectedView.id,
                fields: { fields } as any
              }
            },
            update: (cache, result) => {
              reFetchTableViews();
              setSelectedViewId(result.data.updateTableView.tableView.objectId);
              api.navigateDefault();
              // currentPage > 1 && api.onPage(1);
            }
          });
          success("Changes saved successfully.");
        } catch (e) {
          console.error(e);
          error(e.message.toString());
        }

      }
      if (value == "clone") {
        try {
          const fields = deepPick(tableColumns, ["key", "fixed", "properties"]);
          const view = await createTableView({
            variables: { input: { fields: { name: `Copy of ${selectedView.name}`, filter: selectedView.filter, fields, group: props.group, createdBy: { link: $viewer.id } } } },
            update: async () => {
              await reFetchTableViews();
              setSelectedViewId(view.data.createTableView.tableView.objectId);
            }
          });
          success("Page view cloned successfully.");
        } catch (e) {
          console.error(e);
          error(e.message.toString());
        }
      }
    }
  };

  const views = useMemo(() => {
    return {
      "MY VIEWS": tableViews.edges.filter(({ node }) => node.createdBy.objectId == $viewer.objectId).map(({ node }) => {
        return {
          onSelect() {
            return api.onSelectView(node);
          },
          onDelete() {
            return api.onDeleteView(node.id);
          },
          title: node.name,
          selected: node.id === selectedView?.id
        };
      }),
      "SHARED WITH ME": tableViews.edges.filter(({ node }) => node.createdBy.objectId !== $viewer.objectId).map(({ node }) => {
        return {
          onSelect() {
            return api.onSelectView(node);
          },
          title: node.name,
          selected: node.id === selectedView?.id
        };
      })
    };
  }, [tableViews, $viewer, selectedView]);
  const defaultView = useMemo(() => {
    return {
      selected: !selectedView,
      title: `All ${pluralize(props.classId)}`,
      onSelect: () => api.onSelectView(null)
    };
  }, [selectedView, props.classId]);

  return {
    ...api,
    views,
    defaultView,
    viewOpen,
    setViewOpen,
    pageViewModalContext,
    modalContext,
    handleViewCLick,
    searchParams,
    refetch,
    loading: loading || loadingJql,
    selectedView,
    data: useMemo(() => api.data, [data, operation]),
    draft: useMemo(() => api.draft, [selectedView, tableColumns, tableViews]),
    total: useMemo(() => api.total, [data, operation]),
    headers: useMemo(() => api.headers, [props.headers, evaluate]),
    columns: tableColumns
  };
}

const TABLE_VIEW_FRAGMENT = gql`
  fragment TableViewFragment on TableView{
    id
    objectId
    name
    filter
    fields {
      key
      fixed
      properties
    }
    createdBy {
      objectId
    }
    rPerm
  }
`;

export const SUBSCRIBE_TABLE_VIEWS = gql`
  subscription SubscribeTableViews($where:TableViewSubscriptionWhereInput!){
    tableViews(where: $where){
      event
      node {
        ...TableViewFragment
      }
    }
  }
  ${TABLE_VIEW_FRAGMENT}
`;

export const GET_TABLE_VIEWS = gql`
  query GetTableViews($where:TableViewWhereInput!){
    tableViews(where: $where){
      edges {
        node {
          ...TableViewFragment
        }
      }
    }

  }
  ${TABLE_VIEW_FRAGMENT}
`;
export const CREATE_TABLE_VIEWS = gql`
  mutation CreateTableView($input:CreateTableViewInput!){
    createTableView(input: $input){
      tableView {
        ...TableViewFragment
      }
    }

  }
  ${TABLE_VIEW_FRAGMENT}
`;
export const UPDATE_TABLE_VIEWS = gql`
  mutation UpdateTableView($input:UpdateTableViewInput!){
    updateTableView(input:$input){
      tableView {
        ...TableViewFragment
      }
    }

  }
  ${TABLE_VIEW_FRAGMENT}
`;
export const DELETE_TABLE_VIEWS = gql`
  mutation DeleteTableView($input:DeleteTableViewInput!){
    deleteTableView(input: $input){
      tableView {
        ...TableViewFragment
      }
    }

  }
  ${TABLE_VIEW_FRAGMENT}
`;

const GET_TABLE_VIEW_JQL = gql`
  query GetTableViewJql($id:ID!, $fields: [TableViewSelectedFields], $actions: [TableViewActions], $className: String,) {
    getTableViewJql(id: $id, fields: $fields, actions: $actions, className: $className) {
      fields
      operation
      variables {
        where
        order
        skip
        search
        after
        first
        last
        before
      }
    }
  }
`;
