import { getField }       from "../utils/schemaUtils";
import { usePermissions } from "./AccessControl";
import { useMailboxes }   from "./useMailboxes";

export function useMailAccess(node) {
  const { mailBoxes } = useMailboxes();
  const { canUpdate } = usePermissions(node);
  const { states } = getField("Lead", "leadStatus");
  return !!mailBoxes.length && canUpdate && (node.__typename != "Lead" || !states[ node.leadStatus.status ]?.not_contact);
}
