import { TypePolicy } from "@apollo/client";
import { schemaVar }  from "../../reactiveVars";
import { getFields }  from "../../utils/schemaUtils";

export const User: TypePolicy = {
  fields: {
    status: {
      read(existing, { readField, cache }) {
        const online = readField("online");
        if (!online) {
          return "offline";
        } else {
          return existing;
        }
      }
    },
    history: {
      merge(existing, incoming) {
        return incoming;
      }
    },
    bookmarks: {
      merge(existing, incoming) {
        return incoming;
      }
    },
    identities: {
      merge(existing, incoming) {
        return incoming;
      }
    },
    dataTableView: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar();
          const defaultValue = {};
          getFields(schemas, "DataTablePermission").forEach(f => defaultValue[ f.name ] = f.defaultValue);
          storage.defaultValue = defaultValue;
        }
        return existing || storage.defaultValue;
      }
    },
    notificationControls: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar();
          const defaultValue = {};
          getFields(schemas, "NotificationControls").forEach(f => defaultValue[ f.name ] = f.defaultValue);
          storage.defaultValue = defaultValue;
        }
        return existing || storage.defaultValue;
      }
    },
    bulkPermissions: {
      read(existing, { storage, cache }) {
        if (!storage.defaultValue) {
          const schemas = schemaVar();
          const defaultValue = {};
          getFields(schemas, "BulkMessagingPermission").forEach(f => defaultValue[ f.name ] = f.defaultValue);
          storage.defaultValue = defaultValue;
        }
        return existing || storage.defaultValue;
      }
    }
  }
};
