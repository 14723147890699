import { useMemo }              from "react";
import React                    from "react";
import { useCallback }          from "react";
import { InputState }           from "../../Input/Input";
import { Select }               from "../../Input/Select";
import { MenuItem }             from "../../Menu";
import { useConsumeField }      from "../BaseField";
import { TreeChoiceFieldProps } from "./TreeChoiceField";

export const TreeChoiceEditField = React.memo(function PickListEditField(props) {
  const { input, meta: { touched, error, submitError } } = useConsumeField<TreeChoiceFieldProps>();
  const options = input.options;

  const sortedOptions = useMemo(() => {
    return options;
  }, [options]);

  const getParentsCount = (item, items) => {
    if (!item.parent) {
      return 0;
    }
    let count = 1;
    for (const opt of items) {
      if (item.parent == opt.value) {
        count += getParentsCount(opt, items);
      }
    }
    return count;
  };

  const getChildrenCount = (item, items) => {
    let count = 0;
    for (const opt of items) {
      if (item.value == opt.parent) {
        count += getParentsCount(opt, items);
      }
    }
    return count;
  };
  const hasError = (touched && !!error) || !!submitError;
  const getOptionSelected = (selectedItem, value) => {
    if (!value) {
      return false;
    }

    return (selectedItem?.value || selectedItem) == value;
  };
  const Option = useCallback((item) => {
    let parentsCount = getParentsCount(item, sortedOptions);
    // let childrenCount = getChildrenCount(item, sortedOptions);
    // let props = {
    //   thumbnail:<FontIcon type={childrenCount>0?'circle':'trip_origin'} color={'#727E92'}/>
    // };
    return <MenuItem style={{ paddingLeft: (16 + parentsCount * 8) }} disabled={item.disabled}>{item.label}</MenuItem>;
  }, [sortedOptions]);

  return (
    <Select
      state={(hasError && InputState.Error) || undefined}
      message={hasError ? (error || submitError) : undefined}
      {...input}
      options={sortedOptions}
      height={45}
      onChange={(item) => input.onChange(item?.value || item)}
      getOptionSelected={useCallback(getOptionSelected, [input.value, input.options])}
      getOptionValue={useCallback((item) => item?.value || item, [input.value, input.options])}
      getOptionLabel={useCallback((item) => item?.label || item, [input.value, input.options])}
      getOptionDisabled={useCallback((item) => item?.disabled, [input.value, input.options])}
      renderOption={Option}
    />
  );
})
export default TreeChoiceEditField;
