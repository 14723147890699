import { Tooltip }                     from "@relcu/ui";
import { ButtonSizes }                 from "@relcu/ui";
import { Button }                      from "@relcu/ui";
import { Alignment }                   from "@relcu/ui";
import { IActionCellProps }            from "@relcu/ui";
import { useMemo }                     from "react";
import React                           from "react";
import { FC }                          from "react";
import { getPrimaryBorrower }          from "../../../../../utils/helpers";
import { getDefaultPhoneNumberForSMS } from "../../../../../utils/helpers";
import { useViewerPhoneLines }         from "../../../../useViewerPhoneLines";

export const SmsAction: FC<IActionCellProps> = React.memo(function SmsAction(props) {
  const { rowData, tooltip, path, bulkPermissions, onClick, ...p } = props;
  const primary = getPrimaryBorrower(rowData);
  const { fromNumbers } = useViewerPhoneLines();
  let number = getDefaultPhoneNumberForSMS(primary?.contact?.phones ?? [], "Contact");
  const disabled = (rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length) || !fromNumbers.length || !number || number?.smsOptOut || !primary?.contact?.phones?.length;
  return (
    <Tooltip title={tooltip} alignment={Alignment.Bottom}>
      <Button
        onlyIcon
        size={ButtonSizes.Small}
        onClick={() => onClick(rowData)}
        disabled={disabled}
        {...p}/>
    </Tooltip>
  );
});
