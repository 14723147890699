import React                            from "react";
import { FC }                           from "react";
import { useMemo }                      from "react";
import { useCallback }                  from "react";
import { ButtonColors }                 from "@relcu/ui";
import { Tooltip }                      from "@relcu/ui";
import { ButtonSizes }                  from "@relcu/ui";
import { Button }                       from "@relcu/ui";
import { Alignment }                    from "@relcu/ui";
import { IActionCellProps }             from "@relcu/ui";
import { getDefaultPhoneNumberForCall } from "../../../../../utils/helpers";
import { getBorrowerName }              from "../../../../../utils/helpers";
import { getPrimaryBorrower }           from "../../../../../utils/helpers";
import { getField }                     from "../../../../../utils/schemaUtils";
import { usePermissions }               from "../../../../AccessControl";
import { usePhone }                     from "../../../../usePhone";
import { useViewerPhoneLines }          from "../../../../useViewerPhoneLines";

export const CallAction: FC<IActionCellProps> = React.memo(function CallAction(props) {
  const { rowData, tooltip, bulkPermissions, singlePermissions, ...p } = props;
  const { states } = getField("Lead", "leadStatus");
  const phone = usePhone();
  const { fromNumbers } = useViewerPhoneLines();
  const { canUpdate } = usePermissions(rowData);
  let primaryBorrower = getPrimaryBorrower(rowData);
  let number = getDefaultPhoneNumberForCall(primaryBorrower?.contact?.phones, "Contact");
  const isInCall = useMemo(() => {
    return phone?.hybridCalls?.initialDisplayCall.leadId == rowData.objectId;
  }, [phone?.hybridCalls?.initialDisplayCall, rowData.objectId]);
  const disabled = states[ rowData?.leadStatus.status ]?.not_contact || !canUpdate || (!!phone.active && !isInCall) || (rowData?.duplicateOf && !!Object.keys(rowData.duplicateOf).length) || !fromNumbers.length || !number || number?.callOptOut || !primaryBorrower?.contact?.phones?.length;
  const onCall = useCallback((e, rowData) => {
    e.stopPropagation();
    if (isInCall) {
      if (phone.direction == "incoming") {
        phone.active.reject();
      } else {
        phone.active.drop();
      }
    } else if (!phone.active && !disabled) {
      let primaryBorrower = getPrimaryBorrower(rowData);
      let borrowerName = getBorrowerName(primaryBorrower);
      let numbers = getDefaultPhoneNumberForCall(primaryBorrower?.contact?.phones, "Contact");
      if (numbers && !numbers.callOptOut) {
        phone.call({
          from: fromNumbers[ 0 ].value,
          to: numbers.number,
          leadId: rowData.objectId,
          leadName: rowData.objectName,
          contactId: primaryBorrower.contact.objectId,
          contactName: borrowerName
        });
        // navigate(`/lead/${rowData.objectId}`);
      }
    }

  }, [phone, fromNumbers, isInCall, disabled]);
  return <Tooltip title={tooltip} alignment={Alignment.Bottom}>
    <Button
      style={{ pointerEvents: "all" }}
      icon={isInCall ? "call_end" : "call"}
      color={isInCall ? ButtonColors.Error : ButtonColors.Success}
      onlyIcon
      size={ButtonSizes.Small}
      disabled={disabled}
      onClick={(e)=>onCall(e,rowData)}/>
  </Tooltip>;
});
