import { useContext }                          from "react";
import React                                   from "react";
import { useMemo }                             from "react";
import { useCallback }                         from "react";
import { useMutation }                         from "@apollo/client";
import { gql }                                 from "@apollo/client";
import { useQuery }                            from "@apollo/client";
import { useNavigate }                         from "@relcu/react-router";
import { useConstant }                         from "@relcu/ui";
import { useModal }                            from "@relcu/ui";
import { useLazyCondition }                    from "@relcu/ui";
import { AppBarProps }                         from "@relcu/ui";
import { permissionUtils }                     from "@relcu/ui";
import { ClientContext }                       from "../../Client";
import { USER_NOTIFICATION_CONTROLS_FRAGMENT } from "../../graph/operations.graphql";
import { GET_SETTINGS }                        from "../../graph/operations.graphql";
import { layoutVar }                           from "../../reactiveVars";
import { schemaVar }                           from "../../reactiveVars";
import { usePolicy }                           from "../AccessControl";
import { SchemaDialog }                        from "../Layout/Dialog/SchemaDialog";
import { SearchModal }                         from "../Modal/SearchModal";
import { UpdateViewerStatusVariables }         from "../SnackBar/__types__/UpdateViewerStatus";
import { UpdateViewerStatus }                  from "../SnackBar/__types__/UpdateViewerStatus";
import { useStat }                             from "../Stat";
import { useReactiveStorage }                  from "../useReactiveStorage";
import { SideBarUser }                         from "./__types__/SideBarUser";

const USER = gql`
  query SideBarUser {
    viewer {
      user {
        id
        objectId
        objectName
        objectIcon
        firstName
        lastName
        role
        status
        ...UserNotificationControls
      }
    }
  }
  ${USER_NOTIFICATION_CONTROLS_FRAGMENT}
`;
export const UPDATE_VIEWER_STATUS = gql`
  mutation UpdateViewerStatus($id:ID!,$status:String){
    updateUser(input: {id: $id,fields: {status: $status}}){
      user {
        id
        status
      }
    }
  }
`;

// const items:AppBarProps['items'] = [
//   { type: "logo", src: props.logoSrc },
//
//   { action: "search", title: "Search", icon: "search" },
//
//   {
//     type: "menu", title: "Add", icon: "add",
//     items: [
//       { type: "item", action: "add.user", title: "User", icon: "person", active: false },
//       { type: "item", action: "add.lead", title: "Lead", icon: "rc_lead", active: false },
//       { type: "item", action: "add.contact", title: "Contact", icon: "rc_contact", active: false }
//     ]
//   },
//   { type: "divider" },
//   ...pages,
//   { type: "divider" },
//   ...navs,
//   { action: "reminders", title: "Reminders", icon: "access_alarm", active: false, label: props.stats.remindersCount },
//   { action: "calls", title: "Calls", icon: "phone", active: false, label: props.stats.missedCallsCount },
//   { action: "sms", title: "SMS", icon: "forum", active: false, label: props.stats.unreadMessagesCount },
//   { action: "emails", title: "Email", icon: "email", active: false, label: props.stats.unreadEmailsCount },
//   { type: "splitter" },
//   { action: "pinned", title: "Pinned Items", icon: "rc_pin", active: props.pinedActive },
//   { action: "settings", title: "Settings", icon: "settings" },
//   {
//     type: "user",
//     items: [
//       { type: "item", action: "profile", title: "Profile", icon: "person", active: false },
//       { type: "item", action: "preference", title: "Preferences", icon: "tune", active: false }
//     ]
//   }
//
// ]
interface AppSideBarState extends AppBarProps {
  contextSchemaHolder: React.ReactElement;
  contextSearchHolder: React.ReactElement;
}
export function useSideBar(): AppSideBarState {
  const { logOut } = useContext(ClientContext);
  const schema = schemaVar();
  const layout = layoutVar();
  const policy = usePolicy();
  const navigate = useNavigate();
  const [schemaModal, contextSchemaHolder] = useModal(SchemaDialog);
  const [searchModal, contextSearchHolder] = useModal(SearchModal);
  const layouts = useConstant(() => Object.values(layout));
  const schemas = useConstant(() => Object.values(schema));
  const [updateStatus] = useMutation<UpdateViewerStatus, UpdateViewerStatusVariables>(UPDATE_VIEWER_STATUS);
  const { data: { viewer: { user } } } = useQuery<SideBarUser>(USER, {
    fetchPolicy: "cache-only"
  });
  const { notificationControls, role } = user;
  const { data: { settings: $settings } } = useQuery(GET_SETTINGS, { fetchPolicy: "cache-only" });
  const logoSrc = `${window.__CONFIG__.assets}/${window.__CONFIG__.logo || "logo/relcu.svg"}`;

  const createNav = useConstant(() => {
    const collections = schemas.filter(schema => !schema.isSystem && schema.kind === "collection" && ((role == "loan_officer" && schema.className != "Contact") || role != "loan_officer"));
    const items = collections.filter(c => policy.canCreate({ __typename: c.className })).map(c => {
      return { type: "item", action: "create", title: c.className, params: [c.className], icon: ICONS[ c.className ] };
    });
    if (items.length) {
      return [{
        type: "menu", title: "Add", icon: "add",
        items
      }];
    }
    return [];
  });
  const stats = useStat();
  const [navbarActive, setNavbarActive] = useReactiveStorage("@navbar");
  const toggleNavBar = useCallback(() => setNavbarActive(a => !a), []);

  let navs = useNavLinks(layouts, user, $settings, stats, notificationControls);
  let pages = usePageLinks(layouts, user, $settings, stats);
  const items = useMemo<AppBarProps["items"]>(() => {
    return [
      { type: "logo", action: "navigate", params: ["/"], src: logoSrc },
      { type: "item", action: "search", title: "Search", icon: "search" },
      ...createNav,
      { type: "divider" },
      ...navs,
      { type: "splitter" },
      ...pages,
      { action: "pinned", title: "Pinned Items", icon: "rc_pin", active: navbarActive },
      {
        type: "user",
        items: [
          { type: "item", action: "navigate", params: [`/user/${user.objectId}/details`], title: "Profile", icon: "person" },
          { type: "item", action: "navigate", params: [`/user/${user.objectId}/preference`], title: "Preferences", icon: "tune" }
        ]
      }
    ];
  }, [user, navs, navbarActive, stats]);

  const onAction = useCallback((action: string, ...params) => {
    //navigate(`/user/${user.objectId}`);
    switch (action) {
      case "search": {
        searchModal({});
        break;
      }
      case "logout": {
        return logOut();
      }
      case "navigate": {
        return navigate(params[ 0 ]);
      }
      case "status": {
        return updateStatus({
          variables: {
            id: user.id,
            status: params[ 0 ]
          }
        });
      }
      case "pinned": {
        return toggleNavBar();
      }
      case "create": {
        return schemaModal({ action: "create", className: params[ 0 ] });
      }
      default: {
        console.info("UNHANDLED ACTION", action, params);
      }
    }
  }, [user, items]);

  return {
    user,
    items,
    onAction,
    contextSchemaHolder,
    contextSearchHolder
  };
}
function useNavLinks(layouts, user, settings, stats, notificationControls) {
  const navigationPages = useConstant(() => layouts.filter(layout => layout.route == "/user/:objectId/*"));
  const evaluate = useLazyCondition({
    source: { $object: user, $viewer: user, $settings: settings }
  });
  let navs = [];
  for (let page of navigationPages) {
    for (let tab of page.tabs) {
      if (tab.navigation) {
        if (tab.conditions) {
          let label = notificationControls[ NotificationControlsMap[ tab.path ] ] ? stats[ COUNTERS[ tab.path ] ] : 0;
          if (tab.path == "emails" && notificationControls[ "bulkEmail" ] && stats[ COUNTERS[ "bulkEmail" ] ]) {
            label += stats[ COUNTERS[ "bulkEmail" ] ];
          }
          if (tab.path == "sms" && notificationControls[ "bulkSMS" ] && stats[ COUNTERS[ "bulkSMS" ] ]) {
            label += stats[ COUNTERS[ "bulkSMS" ] ];
          }
          const { apply } = evaluate({ conditions: tab.conditions });
          let path = page.route.replace(":objectId", user.objectId).replace("*", tab.path);
          if (apply) {
            navs.push({
              type: "item",
              action: "navigate",
              title: tab.title,
              icon: tab.icon,
              label,
              params: [path]
            });
          }
        }
      }
    }
  }
  return navs;
}

function usePageLinks(layouts, user, settings, stats) {
  const navigationPages = useConstant(() => layouts.filter(layout => layout.page && layout.primary));
  let navs = [];
  for (let page of navigationPages) {
    if (permissionUtils.hasAccess(Object(user), page.permissions, "read")) {
      navs.push({
        type: "item",
        action: "navigate",
        params: [page.route.replace("*", "")],
        title: page.objectName,
        icon: page.objectIcon
      });
    }
  }
  return navs;
}

const NotificationControlsMap = {
  calls: "missedCalls",
  sms: "sms",
  emails: "emails",
  pipeline: "loanComments",
  reminders: "tasksReminders"
};

const COUNTERS = {
  calls: "missedCallsCount",
  sms: "unreadMessagesCount",
  emails: "unreadEmailsCount",
  pipeline: "unreadPipelinesCount",
  reminders: "remindersCount",
  bulkEmail: "bulkEmailsCount",
  bulkSMS: "bulkSMSCount"
};
// TODO: Make this dynamic
const ICONS = {
  User: "person",
  Lead: "rc_lead",
  Contact: "rc_contact",
  Task: "add_task",
  LeadSource: "input"
};
