import { FC }                from "react";
import React                 from "react";
import { useBoxProps }       from "../..";
import { BoxComponentProps } from "../..";
import { Box }               from "../..";
import { classNames }        from "../..";
import { WidgetClasses }     from "./WidgetClasses";

export const Widget: FC<WidgetProps> = React.memo(function Widget(props) {
  const { className, title, ...p } = props;
  return (
    <Box container direction={"column"} gap={"XXS"} flex={1}
         className={classNames(WidgetClasses.Widget, className)} {...useBoxProps(p)}>
      {!!title && (
        <Box container alignSelf={"start"} className={WidgetClasses.WidgetHeader}>
          {title}
        </Box>
      )}
      {props.children}
    </Box>
  );
});

export interface WidgetProps extends BoxComponentProps {
  title?: string
}
