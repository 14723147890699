import { ReactElement }   from "react";
import { useMemo }        from "react";
import React              from "react";
import { FC }             from "react";
import { FieldProps }     from "../../../../..";
import { ListHeader }     from "../../../../..";
import { ActionsProps }   from "../../../../..";
import { CircularLoader } from "../../../../..";
import { List }           from "../../../../List";
import { Button }         from "../../../../Button";
import { EmptyList }      from "../../../../EmptyList";
import { IconType }       from "../../../../Icon";
import { Box }            from "../../../../Box";
import { classNames }     from "../../../../..";

export interface ListViewProps<T = object> {
  name: string
  title?: string
  loading: boolean
  count: number
  currentPage: number
  total: number
  offset: number
  onRowClick: (data: T) => void
  onPage: (page: number) => void
  fields: FieldProps[]
  data: Array<T>
  icon?: IconType,
  createAction?: ActionsProps
  actions?: ActionsProps[] | ((data: any) => ActionsProps[])
  filter?: ReactElement
  headers?: ListHeader[]
  getColumnValue?: (data: T, column: FieldProps) => any
  blank?: ListViewBlank
  description?: string
}
export interface ListViewBlank {
  title?: string;
  icon?: IconType;
  content?: string
  action?: string
}

export const ListView: FC<ListViewProps> = React.memo(function ListView(props) {
  return (
    <>
      {props.filter}
      <Box
        container
        direction={"column"}
        gap={"XS"}
        justify={(props.loading || props.data.length == 0) ? "center" : null}
        className={classNames(ListViewClasses.ListView, {
          [ ListViewClasses.ListEmptyView ]: props.loading || props.data.length == 0
        })} flexGrow={1}>
        {
          props.loading ?
            <CircularLoader alignContent={"center"} flexGrow={1} justify={"center"}/>
            :
            (props.data.length == 0 ?
              <Box container direction={"column"} gap={"XS"}>
                <EmptyList icon={props.blank?.icon || "search_off"}
                           content={props.createAction ? (props.blank?.content || `Click on the “Create” button below to create ${props.name}`) : ""}
                           title={props.blank?.title || `No ${props.name} found`}
                           alignSelf={"center"} flexGrow={1} justify={"center"}/>
                {
                  props.createAction &&
                  <Button alignSelf={"center"}
                          icon={"add"}
                          {...props.createAction as any}>
                    {props.blank?.action || "Create"}
                  </Button>
                }
              </Box>
              :
              <List
                title={props.title}
                headers={props.headers}
                createAction={props.createAction}
                actions={props.actions}
                emptyIcon={props.icon}
                onRowClick={props.onRowClick}
                count={props.offset}
                currentPage={props.currentPage}
                fields={props.fields}
                objects={props.data}
                total={props.total}
                onPage={props.onPage}
                flex={1}
                description={props.description}
                getColumnValue={props.getColumnValue}
              />)
        }
      </Box>
    </>
  );
});


export enum ListViewClasses {
  ListView = "list-view",
  ListEmptyView = "list-view--empty",
}

export default ListView;
