import React, {FC, useEffect, useState, useRef} from 'react';
import {gql, useQuery} from '@apollo/client';
import {JsonSectionProps, TimelineItemBody, useSource} from "@relcu/ui";
import {Section} from "@relcu/ui";
import infoIcon from '../../../../assets/info.svg';
import {GetEngagementAnalytics, GetEngagementAnalyticsVariables} from "./__types__/GetEngagementAnalytics";
import "./engagement-analytics-view.css";

interface InfoPopupProps {
    isVisible: boolean;
    toggleVisibility: (e: any) => void;
}
type ColorLegendItem = {
    range: string;
    color: string;
};
const COLOR_LEGEND: ColorLegendItem[] = [
    {range: '7 - 10', color: '#7ba841'},
    {range: '4 - 7', color: '#87CEFA'},
    {range: '0 - 4', color: '#f5b900'},
];
const DESCRIPTION = {
    Call: "the average rating of all calls associated with a lead and considering the duration between an outbound call and the subsequent inbound response",
    Email: "the response time between outbound and inbound emails, the sentiment of the inbound emails, and any negative indicators such as opt-outs or being marked as SPAM",
    Response: "the time elapsed between the last outbound communication and the most recent inbound response from the lead",
    TimeInStatus: "the duration a lead remains in a particular status; shorter times suggest efficient lead conversion, while longer times indicate potential waiting periods",
    Status: "the current status of the lead, taking into account how promptly they progress through stages",
    SMS: "the response time between outbound and inbound SMS, the sentiment of the received SMS, and any indications of opt-out or SPAM marking",
    Engagement: "and combining the Call, Lead Status, Time in Status, Responsiveness, Email, and SMS scores to provide a comprehensive measure of the lead's overall engagement and responsiveness"
};
const ENGAGEMENT_ANALYTICS_QUERY = gql`
    query GetEngagementAnalytics($lead: ID!) {
        engagementAnalytics(where: {
            lead:{have:{id:{equalTo:$lead}}}
        }) {
            edges {
                node {
                    id
                    callScore
                    emailScore
                    responseScore
                    timeInStatusScore
                    statusScore
                    smsScore
                    totalScore
                    smsSummary
                    callSummary
                    emailSummary
                    totalSummary
                }
            }
        }
    }
`;
const ScoreType: FC<{ type: string }> = ({type}) => (
    <div className="type-container">
        <div className="score-header">{type} Score</div>
        <div className="score-description">
            This score is calculated by evaluating {DESCRIPTION[type]}.
        </div>
    </div>
);
const ScoreGuide: FC = () => (
    <div className="score-guide-outer">
        <div className="score-guide-inner">Score Guide</div>
        <div className="color-legend-wrapper">
            {COLOR_LEGEND.map((item, index) => (
                <div key={index} className="color-item">
                    <span className="color-dot" style={{ background: item.color }}></span>
                    {item.range}
                </div>
            ))}
        </div>
    </div>
);
const InfoPopup: FC<InfoPopupProps> = ({isVisible, toggleVisibility}) => {
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleVisibility(event);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [toggleVisibility]);

    if (!isVisible) return null;

    return (
        <div ref={ref} className="info-popup-container">
            <div className="info-popup-header">Score Metrics</div>
            {["Call", "SMS", "Email", "Status", "TimeInStatus", "Response", "Engagement"].map(type => (
                <ScoreType key={type} type={type}/>
            ))}
            <ScoreGuide/>
        </div>
    );
};
const CapitalizeWords = (inputString: string): string => {
    return inputString
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
const GradientForScore = (score: number): string[] => {
    switch (true) {
        case score <= 4:
            return ['#f5b900', '#f5b900', '#f9edcc'];
        case score <= 7:
            return ['#52B9D1', '#52B9D1', '#def0f8'];
        default:
            return ['#7ba841', '#7ba841', '#e4edd8'];
    }
};
export const EngagementAnalyticsSection = React.memo<JsonSectionProps>(function EngagementAnalyticsSection(props) {
    const {$object} = useSource();
    const {$viewer} = useSource();
    const allowedRoles = ["admin", "sales_manager", "team_manager"];
    const lead = $object.objectId;
    const {title, view, editable, ...rest} = props;
    const {data} = useQuery<GetEngagementAnalytics, GetEngagementAnalyticsVariables>(ENGAGEMENT_ANALYTICS_QUERY, {
        variables: {lead}
    });
    const getNodeData = (key: string) => data?.engagementAnalytics.edges[0]?.node?.[key];
    const engagement_score = getNodeData('totalScore');

    const individualScores = {
        call_score: getNodeData('callScore'),
        SMS_score: getNodeData('smsScore'),
        email_score: getNodeData('emailScore'),
        status_score: getNodeData('statusScore'),
        time_in_status_score: getNodeData('timeInStatusScore'),
        response_score: getNodeData('responseScore')
    };

    const summaries = {
        Total_Summary: getNodeData('totalSummary'),
        Call_Summary: getNodeData('callSummary'),
        SMS_Summary: getNodeData('smsSummary'),
        Email_Summary: getNodeData('emailSummary')
    };

    const formattedSummaries = Object.entries(summaries).reduce((acc, [key, value]) => {
        const formattedKey = key.replace(/_/g, ' ')
        acc[formattedKey] = value;
        return acc;
    }, {});

    const [isInfoPopupVisible, setInfoPopupVisibility] = useState(false);
    const toggleInfoPopup = (e: React.MouseEvent) => {
        e.preventDefault();
        setInfoPopupVisibility(prev => !prev);
    };
    const handleButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    const colors = GradientForScore(engagement_score);
    const [masterProgress, setMasterProgress] = useState(0);
    const strokeDasharray = `${(masterProgress / 10) * 100} 100`;
    const [progress, setProgress] = useState({
        call_score: 0,
        email_score: 0,
        response_score: 0,
        time_in_status_score: 0,
        status_score: 0,
        SMS_score: 0
    });
    const incrementProgress = (current: number, target: number) => {
        let newValue = current + 0.1;
        if (newValue > target) newValue = target;
        if (newValue > 10) newValue = 10;
        return newValue;
    }
    const useIncrementalProgressEffect = (engagement_score: any, progress: any, individualScores: any) => {
        useEffect(() => {
            const interval = setInterval(() => {
                let newMasterProgress = incrementProgress(masterProgress, engagement_score);
                let newProgress = {...progress};
                let update = newMasterProgress !== masterProgress;
                for (const key in individualScores) {
                    const newScore = incrementProgress(progress[key], individualScores[key]);
                    if (newScore !== progress[key]) {
                        newProgress[key] = newScore;
                        update = true;
                    }
                }
                if (update) {
                    setMasterProgress(newMasterProgress);
                    setProgress(newProgress);
                } else {
                    clearInterval(interval);
                }
            }, 20);
            return () => clearInterval(interval);
        }, [engagement_score, masterProgress, progress]);
    }
    const ButtonComponent = ({label, isSelected, onHover, onLeave, setSelectedSummary}) => {
        const formattedLabel = label.replace(/_/g, ' ')
        const isButtonSelected = label === isSelected;
        return (
            <button
                key={label}
                onClick={() => setSelectedSummary(label)}
                style={{
                    color: isButtonSelected ? '#6c7adb' : 'black',
                    border: isButtonSelected ? '5px solid white' : '5px solid transparent',
                    backgroundColor: isButtonSelected ? 'white' : '#f8f9fb',
                    boxShadow: isButtonSelected ? '0px 2px 5px rgba(0,0,0,0.2)' : 'none'
                }}
                className="dynamic-button"
                onMouseOver={onHover}
                onMouseOut={onLeave}>
                {formattedLabel}
            </button>
        );
    };
    const [hoveredButton, setHoveredButton] = useState(null);
    const [selectedSummary, setSelectedSummary] = useState(Object.keys(formattedSummaries)[0]);

    useIncrementalProgressEffect(engagement_score, progress, individualScores);

    if (engagement_score == null) {
        return null
    }
    const EngagementScoreCircle = ({ engagement_score, colors, strokeDasharray }) => (
        <svg width="80" height="80" viewBox="0 0 36 36">
            <defs>
                <linearGradient id={`gradient-${engagement_score}`} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{stopColor: colors[0], stopOpacity: 1}}/>
                    <stop offset="100%" style={{stopColor: colors[1], stopOpacity: 1}}/>
                </linearGradient>
            </defs>
            <circle
                stroke={colors[2]}
                fill="none"
                strokeWidth="4"
                strokeLinecap="round"
                cx="18"
                cy="18"
                r="16"
            />
            <circle
                stroke={`url(#gradient-${engagement_score})`}
                fill="none"
                strokeWidth="4"
                strokeDasharray={strokeDasharray}
                strokeLinecap="round"
                cx="18"
                cy="18"
                r="16"
            />
        </svg>
    );

    return (
        $viewer.role && allowedRoles.includes($viewer.role) && (
            <Section className={"engagement-section"} view={view} title={title} editable={editable}>
                <div className="main-container">
                    <div>
                        <div className="header-container">
                            <h2 className="title">
                                Engagement Analytics
                            </h2>
                            <button onClick={toggleInfoPopup} className="info-button">
                                <img src={infoIcon} alt="Info" width="16" height="16"/>
                            </button>
                            <InfoPopup isVisible={isInfoPopupVisible} toggleVisibility={toggleInfoPopup}/>
                        </div>
                        <div className="engagement-score-outer">
                            <div className="score-container">
                                <div className="engagement-score-box">
                                    <div className="engagement-score-internalBox">
                                        <EngagementScoreCircle
                                            engagement_score={engagement_score}
                                            colors={colors}
                                            strokeDasharray={strokeDasharray}
                                        />
                                        <div className="circle">
                                            {masterProgress.toFixed(1)}
                                        </div>
                                    </div>
                                    <div className="score-box">
                                        Engagement Score
                                    </div>
                                </div>
                                <div className="individual-score-container">
                                    {Object.entries(individualScores).map(([key, score]) => {
                                        const displayKey = key.split('_').map(CapitalizeWords).join(' ');
                                        const strokeDasharray = `${(progress[key] / 10) * 100} 100`;
                                        const colors = GradientForScore(progress[key]);
                                        return (
                                            <div key={key} className="color-box">
                                                <svg width="60" height="60" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id={`gradient-${progress[key]}`} x1="0%" y1="0%"
                                                                        x2="100%" y2="0%">
                                                            <stop offset="0%"
                                                                  style={{stopColor: colors[0], stopOpacity: 1}}/>
                                                            <stop offset="100%"
                                                                  style={{stopColor: colors[1], stopOpacity: 1}}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <circle
                                                        stroke={colors[2]}
                                                        fill="none"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        cx="18"
                                                        cy="18"
                                                        r="16"
                                                    />
                                                    <circle
                                                        stroke={`url(#gradient-${progress[key]})`}
                                                        fill="none"
                                                        strokeWidth="4"
                                                        strokeDasharray={strokeDasharray}
                                                        strokeLinecap="round"
                                                        cx="18"
                                                        cy="18"
                                                        r="16"
                                                    />
                                                    <text
                                                        x="18"
                                                        y="21"
                                                        fontFamily="Arial"
                                                        fontSize="10px"
                                                        fill="black"
                                                        textAnchor="middle">
                                                        {progress[key].toFixed(1)}
                                                    </text>
                                                </svg>
                                                <span className="circle-box">{displayKey}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={handleButtonClick} className="summary-container">
                        <div className="button-group">
                            {Object.keys(formattedSummaries).map(key => (
                                <ButtonComponent
                                    label={key}
                                    isSelected={selectedSummary}
                                    onHover={() => setHoveredButton(key)}
                                    onLeave={() => setHoveredButton(null)}
                                    setSelectedSummary={setSelectedSummary}
                                />
                            ))}
                        </div>
                        <div className="summary-box">
                            {formattedSummaries[selectedSummary]}
                        </div>
                    </div>
                </div>
            </Section>
        )
    );
})