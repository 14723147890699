import React                               from "react";
import { useContext }                      from "react";
import { useCallback }                     from "react";
import { useEffect }                       from "react";
import { useParams }                       from "@relcu/react-router";
import { useMutation }                     from "@apollo/client";
import { useQuery }                        from "@apollo/client";
import { gql }                             from "@apollo/client";
import { Alignment }                       from "@relcu/ui";
import { Tooltip }                         from "@relcu/ui";
import { Typography }                      from "@relcu/ui";
import { useSource }                       from "@relcu/ui";
import { MailMessage }                     from "../../../../../graph/__types__/MailMessage";
import { MAIL_MESSAGE }                    from "../../../../../graph/operations.graphql";
import { NODE_FRAGMENT }                   from "../../../../../graph/operations.graphql";
import { DOCUMENT }                        from "../../../../../graph/operations.graphql";
import { MailMessageWhereInput }           from "../../../../../types/graphql-global-types";
import { MailMessages }                    from "./__types__/MailMessages";
import { MailMessagesVariables }           from "./__types__/MailMessages";
import { MarkMailMessageAsReadVariables }  from "./__types__/MarkMailMessageAsRead";
import { MarkMailMessageAsRead }           from "./__types__/MarkMailMessageAsRead";
import { UpdateMailConversationVariables } from "./__types__/UpdateMailConversation";
import { UpdateMailConversation }          from "./__types__/UpdateMailConversation";
import { ContentContext }                  from "./Content";

export function useThread() {
  const params = useParams();
  const messageId = params[ "nearBy" ];
  const { conversation: mail } = useContext(ContentContext);
  const { $object: { __typename }, $viewer: { id: viewerId, objectId: viewerObjectId } } = useSource();
  const [markAsRead] = useMutation<MarkMailMessageAsRead, MarkMailMessageAsReadVariables>(MARKS_MAIL_MESSAGE_AS_READ);
  const [update] = useMutation<UpdateMailConversation, UpdateMailConversationVariables>(UPDATE_MAIL_CONVERSATION);
  let where: MailMessageWhereInput = {
    //@ts-ignore
    deleted: { notEqualTo: true },
    conversation: {
      have: {
        id: { equalTo: mail?.id }
      }
    },
    "OR": [
      {
        "parties": {
          "have": {
            "AND": [
              {
                "party": {
                  "have": {
                    "link": viewerId
                  }
                },
                "metadata": {
                  "isDraft": {
                    "equalTo": true
                  }
                }
              }
            ]
          }
        }
      },
      {
        "parties": {
          "haveNot": {
            "metadata": {
              "isDraft": {
                "equalTo": true
              }
            }
          }
        }
      }
    ]
  };
  if (__typename === "Contact") {
    where.parties = {
      have: {
        party: {
          have: {
            link: viewerId
          }
        }
      }
    };
  }
  const {
    data: { conversationMessages: { edges = [] } = {} } = {},
    loading,
    subscribeToMore,
    refetch
  } = useQuery<MailMessages, MailMessagesVariables>(MAIL_MESSAGES, {
    skip: !mail?.objectId,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { where, conversationId: mail?.objectId }
  });
  const handleClick = useCallback((item) => {
    api.onConnect(mail?.id, item.id);
  }, [mail?.id]);

  const api = {
    defaultSelectedMessage: messageId,
    viewerObjectId,
    handleClick,
    loading,
    async onMarkAsRead(objectId) {
      await markAsRead({
        variables: {
          objectId
        }
      });
    },
    onConnect(id, leadId) {
      update({
        variables: {
          id,
          fields: {
            scope: {
              link: leadId
            }
          }
        }
      }).catch((e) => console.error(e));
    },
    onRemove(id) {
      update({
        variables: {
          id,
          fields: {
            scope: {
              link: null
            }
          }
        }
      }).catch((e) => console.error(e));
    },
    objects: edges.map(({ node }) => node as MailMessage).sort((a, b) => (a.date < b.date) ? 1 : ((a.date > b.date) ? -1 : 0)),
    get bulkOwner() {
      return mail?.parent?.lastSender.party.objectId == viewerObjectId;
    },
    get participants() {
      const participants = [];
      mail?.parties?.forEach((part, index) => {
        if (!part.party) {
          participants.push(<Typography key={part.address + index}>{part.address}</Typography>);
        } else {
          participants.push(<Tooltip key={part.address + index} title={part.address}
                                     alignment={Alignment.Top}><Typography>{part.party.objectName}</Typography></Tooltip>);
        }

        if (index != mail.parties.length - 1) {
          participants.push(<Typography key={index}>,&nbsp;</Typography>);
        }
      });

      return participants;
    },
    subscribe() {
      return subscribeToMore({
        document: SUBSCRIBE_MAIL_MESSAGES,
        variables: {
          conversationId: mail?.id
        },
        updateQuery(prev, event) {
          refetch().catch(console.error);
          return prev;
        }
      });
    },
    mail
  };

  useEffect(api.subscribe, [mail?.id]);

  return api;
}

export const MAIL_MESSAGES = gql`
  query MailMessages($where:MailMessageWhereInput!,$conversationId:String!) {
    conversationMessages(where: $where,conversationId: $conversationId) {
      edges {
        node {
          ...MailMessage
        }
      }
    }
  }
  ${MAIL_MESSAGE}
`;

export const SUBSCRIBE_MAIL_MESSAGES = gql`
  subscription SubscribeMailMessages($conversationId: ID!) {
    mailMessages(where: {
      deleted: {notEqualTo: true},
      conversation: {have: {link: $conversationId}}
    }, events: [ENTER,LEAVE,CREATE,DELETE]){
      event
      node {
        ...MailMessage
      }
    }
  }
  ${MAIL_MESSAGE}
`;

export const UPDATE_MAIL_CONVERSATION = gql`
  mutation UpdateMailConversation($id:ID!, $fields:UpdateMailConversationFieldsInput!){
    updateMailConversation(input: {
      id: $id,
      fields: $fields
    }) {
      mailConversation {
        id
        scope{
          ...Node
          objectId
          objectName
          objectIcon
          createdAt
        }
      }
    }
  }
  ${NODE_FRAGMENT}
`;

export const MARKS_MAIL_MESSAGE_AS_READ = gql`
  mutation MarkMailMessageAsRead($objectId:String!) {
    markMailMessageAsRead(objectId: $objectId)
  }
`;
