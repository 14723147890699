import React                                               from "react";
import { useState }                                        from "react";
import { useMemo }                                         from "react";
import { Fields }                                          from "@relcu/gql-query-builder";
import { ButtonVariants }                                  from "@relcu/ui";
import { ButtonSizes }                                     from "@relcu/ui";
import { Box }                                             from "@relcu/ui";
import { Button }                                          from "@relcu/ui";
import { PointerListFieldProps as IPointerListFieldProps } from "@relcu/ui";
import { PointerListField as BasePointerListField }        from "@relcu/ui";
import { getSelectionSet }                                 from "../../../../utils/graphQlUtils";
import { toFirstLower }                                    from "../../../../utils/helpers";
import { pluralize }                                       from "../../../../utils/pluralize";
import { useJqlQuery }                                     from "../../Jql";
import { usePointerFilters }                               from "../PointerField";

export interface PointerListFieldProps extends IPointerListFieldProps {
  fields?: Fields;
}

export const PointerListField = React.memo<Omit<PointerListFieldProps, "searchText" | "options">>(function PointerListField({ fields, ...props }) {
  const selectionSet = useMemo(() => getSelectionSet(fields), [fields]);
  const filters = usePointerFilters(props.filters);
  const [q, setQ] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const { targetClass, ...otherProps } = props;
  const targetClasses = Array.isArray(targetClass) ? targetClass : [targetClass];
  const query = useMemo(() => {
    let query: any;
    let value = {
      objectName: {
        matchesRegex: q ? `^${(q || "").trim()}` : "",
        options: "i"
      },
      ...filters
    };
    if (selectedIds.length) {
      value[ "objectId" ] = {
        in: selectedIds
      };
    }

    if (targetClasses.length > 1) {
      query = targetClasses.map(n => {
        const operation = pluralize(toFirstLower(n));
        return {
          operation,
          variables: {
            after: "",
            first: 5,
            [ `${operation}Where` ]: {
              type: `${n}WhereInput`,
              name: "where",
              value
            }
          },
          fields: [
            {
              pageInfo: [
                "endCursor",
                "startCursor",
                "hasNextPage",
                "hasPreviousPage"
              ]
            },
            {
              edges: [
                {
                  node: selectionSet
                }
              ]
            }
          ]
        };
      });
    } else {
      query = {
        operation: pluralize(toFirstLower(targetClasses[ 0 ])),
        variables: {
          after: "",
          first: 10,
          where: {
            type: `${targetClasses[ 0 ]}WhereInput`,
            value
          }
        },
        fields: [
          {
            pageInfo: [
              "endCursor",
              "startCursor",
              "hasNextPage",
              "hasPreviousPage"
            ]
          },
          {
            edges: [
              {
                node: selectionSet
              }
            ]
          }
        ]
      };
    }
    return query;
  }, [q, selectedIds, targetClasses]);

  const { data = {}, loading, fetchMore } = useJqlQuery(query, {
    skip: props.view === "read"
  });

  const options = useMemo(() => {
      if (targetClasses.length > 1) {
        const items = [];
        Object.keys(data).forEach(t => {
          const label = t == "leadSources" ? "CAMPAIGN" : t.toUpperCase();
          const options = data[ t ].edges.map(({ node }) => node);
          if (options.length) {
            items.push({
              label,
              options: data[ t ].edges.map(({ node }) => node)
            });
          }
        });
        return items;
      }
      const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
      if (data[ operation ]) {
        return (
          Object(data[ operation ])[ "edges" ] || []
        ).map(({ node }) => node);
      }
      return [];
    },
    [data, targetClasses]
  );
  const pageInfo = useMemo(() => {
    const operation = pluralize(toFirstLower(targetClasses[ 0 ]));
    return Object(data[ operation ])[ "pageInfo" ];
  }, [data, targetClasses]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        where: query.variables.where.value,
        first: query.variables.first,
        after: pageInfo.endCursor
      },
      updateQuery(prev, { fetchMoreResult }) {
        const className = pluralize(toFirstLower(targetClasses[ 0 ]));
        return {
          [ className ]: {
            ...prev[ className ],
            pageInfo: fetchMoreResult[ className ].pageInfo,
            edges: [...prev[ className ].edges, ...fetchMoreResult[ className ].edges]
          }
        };
      }
    });
  };

  return (
    <BasePointerListField
      {...otherProps}
      onLoadMoreHandler={
        pageInfo?.hasNextPage &&
        <Box container justify={"center"} style={{ position: "sticky", bottom: 0, background: "white" }}>
          <Button size={ButtonSizes.Small} variant={ButtonVariants.Ghost} onClick={handleLoadMore}>Load more</Button>
        </Box>
      }
      onSelectedFilter={(selected) => {
        setSelectedIds((selected as any).map(s => s.objectId));
      }}
      options={options}
      searchText={q}
      onType={setQ}
      loading={loading}
      thumbnail={true}
    />
  );
});
