import { FormSummaryField }  from "@relcu/ui";
import { TopLevelCondition } from "@relcu/ui";

export const WHEN_CASH_AMOUNT: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "cash_out_refinance" }] };
export const WHEN_RATE_TERM: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "rate_term_refinance" }] };
export const WHEN_RATE_TERM_OR_CASH_AMOUNT: TopLevelCondition = { or: [{ path: "loanPurpose", operator: "equals", value: "rate_term_refinance" }, { path: "loanPurpose", operator: "equals", value: "cash_out_refinance" }] };
export const WHEN_IS_PURCHASE: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "purchase" }] };
export const WHEN_IS_REFINANCE: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "contains", value: "refinance" }] };
export const WHEN_HAS_SECONDARY_FINANCING: TopLevelCondition = { and: [{ path: "secondaryFinancing", operator: "neqEquals", value: "none" }] };
export const WHEN_REFINANCE_OR_SUBORDINATE_FINANCING: TopLevelCondition = { or: [{ path: "loanPurpose", operator: "contains", value: "refinance" },{ path: "secondaryFinancing", operator: "equals", value: "subordinate_financing" }] };
export const WHEN_IS_VA: TopLevelCondition = { and: [{ path: "productType", operator: "equals", value: "va" }] };
export const WHEN_IS_FHA: TopLevelCondition = { and: [{ path: "productType", operator: "equals", value: "fha" }] };
export const WHEN_IS_VA_FHA_REFINANCE: TopLevelCondition = { and: [{ path: "productType", operator: "includes", value: ["va", "fha"] }, { path: "loanPurpose", operator: "equals", value: "rate_term_refinance" }] };
export const WHEN_IS_STREAMLINE_VA_FHA: TopLevelCondition = { and: [{ path: "productType", operator: "includes", value: ["va", "fha"] }, { path: "isStreamLine", operator: "equals", value: true }] };
export const WHEN_IS_FIRST_TIME_HOME_BUYER: TopLevelCondition = { and: [{ path: "firstTimeHomeBuyer", operator: "equals", value: true }] };
export const WHEN_AMI_WAIVER: TopLevelCondition = { and: [{ path: "amiWaiverEligibility", operator: "equals", value: true }] };
export const WHEN_IS_EXPANDED_EXISTS: TopLevelCondition = {
  or: [
    {
      and:
        [
          { path: "pricingEngine", operator: "equals", value: "manual" },
          { path: "objectId", operator: "isNotEmpty" }
        ]
    },
    {
      and:
        [
          { path: "pricingEngine", operator: "neqEquals", value: "manual" },
          { path: "rate", operator: "isNotEmpty" }
        ]
    }
  ]
};
// export const WHEN_ONE_HAVE_OBJECT_ID: TopLevelCondition = { and: [{ path: "$object", operator: "some", value: "objectId" }] };
export const WHEN_IS_MANUAL_PRICING: TopLevelCondition = { and: [{ path: "pricingEngine", operator: "equals", value: "manual" }] };
export const WHEN_IS_OB_PRICING: TopLevelCondition = { and: [{ path: "pricingEngine", operator: "equals", value: "optimalblue" }] };
export const WHEN_IS_MORTECH_PRICING: TopLevelCondition = { and: [{ path: "pricingEngine", operator: "equals", value: "mortech" }] };
export const WHEN_FEE_IS_ADDED: TopLevelCondition = { and: [{ path: "fee", operator: "includes", value: "$fees" }] };
export const WHEN_IS_PURCHASE_FEE_IS_ADDED: TopLevelCondition = { and: [...WHEN_IS_PURCHASE.and, { path: "fee", operator: "includes", value: "$fees" }] };
export const WHEN_IS_FHA_PURCHASE_PRIMARY_RESIDENCE: TopLevelCondition = {
  and: [
    { path: "productType", operator: "equals", value: "fha" },
    { path: "loanPurpose", operator: "equals", value: "purchase" },
    { path: "propertyOccupancy", operator: "equals", value: "owner_occupied" }
  ]
};
export const WHEN_LOAN_TERM_FIXED: TopLevelCondition = { and: [{ path: "amortizationType", operator: "equals", value: "fixed" }] };
export const WHEN_LOAN_TERM_ARM: TopLevelCondition = { and: [{ path: "amortizationType", operator: "equals", value: "arm" }] };

//Monthly payment
export const WHEN_MORTGAGE_INSURANCE_GREATER_THEN_0: TopLevelCondition = { and: [{ path: "monthlyPremium", operator: "greater", value: 0 }, ...WHEN_IS_FHA.and] };

//Prepaid costs
export const WHEN_IS_STREAMLINE_WO_CREDIT: TopLevelCondition = {
  or: [
    { path: "isStreamLine", operator: "neqEquals", value: true },
    { and: [{ path: "isStreamLine", operator: "equals", value: true }, { path: "withCredit", operator: "equals", value: true }] }
  ]
};
export const WHEN_LOCK_IN_FEE_INCLUDED: TopLevelCondition = { and: [{ path: "addedFees", operator: "contains", value: "lockInFee" }] };

//Other
export const WHEN_IS_STREAMLINE_WO_APPRAISAL: TopLevelCondition = {
  or: [
    { path: "isStreamLine", operator: "neqEquals", value: true },
    {
      and: [
        { path: "isStreamLine", operator: "equals", value: true },
        { path: "withAppraisal", operator: "equals", value: true }
      ]
    }
  ]
};

//Title fees
export const WHEN_TITLE_COMPANY_IS_EDITABLE: TopLevelCondition = { or: [{ path: "titleCompany", operator: "equals", value: "custom" }, { path: "isTitleFeeEditable", operator: "equals", value: true }] };
export const WHEN_TITLE_INSURANCE_IS_NOT_EMPTY: TopLevelCondition = { or: [{ path: "titleInsurance", operator: "neqEquals", value: null }, { path: "titleInsurance", operator: "neqEquals", value: undefined }] };
export const WHEN_RECORDING_CHARGES_IS_NOT_EMPTY: TopLevelCondition = { or: [{ path: "recordingCharges", operator: "neqEquals", value: null }, { path: "recordingCharges", operator: "neqEquals", value: undefined }] };
export const WHEN_OWNERS_TITLE_IS_NOT_EMPTY: TopLevelCondition = { or: [{ path: "ownersTitle", operator: "neqEquals", value: null }, { path: "ownersTitle", operator: "neqEquals", value: undefined }] };
export const WHEN_TRANSFER_TAX_IS_NOT_EMPTY: TopLevelCondition = { or: [{ path: "transferTax", operator: "neqEquals", value: null }, { path: "transferTax", operator: "neqEquals", value: undefined }] };
export const WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY: TopLevelCondition = { or: [{ path: "settlementFee", operator: "neqEquals", value: null }, { path: "settlementFee", operator: "neqEquals", value: undefined }] };
export const WHEN_LENDERS_TITLE_IS_NOT_EMPTY: TopLevelCondition = { or: [{ path: "lendersTitle", operator: "neqEquals", value: undefined }] };

//Closing costs conditions
export const WHEN_MIP: TopLevelCondition = { and: [{ path: "productType", operator: "equals", value: "fha" }, { path: "financeMip", operator: "isEmpty" }] };
export const WHEN_FF: TopLevelCondition = { and: [{ path: "productType", operator: "equals", value: "va" }, { path: "financeFf", operator: "isEmpty" }] };
export const WHEN_IN_PESTINSPECTION_FEE_STATES: TopLevelCondition = { and: [{ path: "$state", operator: "includes", value: "$pestInspectionFeeStates" }] };
export const WHEN_IS_PESTINSPECTION_FEE: TopLevelCondition = { and: [...WHEN_IN_PESTINSPECTION_FEE_STATES.and, { path: "isStreamLine", operator: "neqEquals", value: true }, { path: "productType", operator: "equals", value: "va" }] };
export const WHEN_IN_DOC_REVIEW_FEE_STATES: TopLevelCondition = { and: [{ path: "$state", operator: "includes", value: "$docReviewFeeStates" }] };
export const WHEN_IN_ATTORNEY_FEE_STATES: TopLevelCondition = { and: [{ path: "loanPurpose", operator: "equals", value: "purchase" }, { path: "$state", operator: "includes", value: "$attorneyFeeStates" }] };
export const WHEN_IN_SURVEY_FEE_STATES: TopLevelCondition = { and: [{ path: "$state", operator: "includes", value: "$surveyFeeStates" }] };
export const WHEN_SECONDARY_FINANCING_NOT_NONE: TopLevelCondition = { and: [{ path: "secondaryFinancing", operator: "neqEquals", value: "none" }] };
export const WHEN_DISCOUNT_POINT: TopLevelCondition = { and: [{ path: "price", operator: "neqEquals", value: null }, { path: "price", operator: "lower", value: 100 }] };
export const WHEN_LENDER_CREDITS: TopLevelCondition = { and: [{ path: "price", operator: "greaterEqual", value: 100 }] };
export const WHEN_PROPERTY_TYPE_CONDO: TopLevelCondition = { and: [{ path: "propertyType", operator: "includes", value: ["condo"] }] };
export const WHEN_CONDO_CERTIFICATE_FEE_INCLUDED: TopLevelCondition = { and: [{ path: "addedFees", operator: "contains", value: "condoCertificateFee" }, ...WHEN_PROPERTY_TYPE_CONDO.and] };
export const WHEN_EARNEST_MONEY_PAID_INCLUDED: TopLevelCondition = { and: [{ path: "addedFees", operator: "contains", value: "earnestMoneyPaid" }] };
export const WHEN_SELLER_CONCESSION_INCLUDED: TopLevelCondition = { and: [{ path: "addedFees", operator: "contains", value: "sellerConcession" }] };
export const WHEN_PMI_IS_NOT_MONTHLY: TopLevelCondition = {
  or: [
    {
      and: [
        { path: "pmiEligible", operator: "equals", value: true },
        { path: "pmiType", operator: "isNotEmpty" },
        { path: "pmiType", operator: "neqEquals", value: "monthly" }
      ]
    },
    {
      and: [
        { path: "pmiEligible", operator: "equals", value: true },
        { path: "$pmiProducts", operator: "none", value: "monthly" },
        { path: "pmiType", operator: "isEmpty" }
      ]
    }
  ]
};
export const WHEN_PMI_IS_MONTHLY: TopLevelCondition = {
  and: [
    { path: "pmiEligible", operator: "equals", value: true },
    {
      or: [
        {
          and: [
            { path: "$pmiProducts", operator: "some", value: "monthly" },
            { path: "pmiType", operator: "isEmpty" }
          ]
        },
        { path: "pmiType", operator: "equals", value: "monthly" }
      ]
    }
  ]
};

export const WHEN_RATES_EXISTS: TopLevelCondition = {
  and: [
    { path: "rate", operator: "neqEquals", value: null },
    { path: "rate", operator: "neqEquals", value: undefined },
    { path: "apr", operator: "neqEquals", value: null },
    { path: "apr", operator: "neqEquals", value: undefined },
    { path: "price", operator: "neqEquals", value: null },
    { path: "price", operator: "neqEquals", value: undefined }
  ]
};

export const WHEN_TITLE_FEES_EXISTS: TopLevelCondition = {
  and: [
    { and: [{ path: "titleCompany", operator: "neqEquals", value: null }, { path: "titleCompany", operator: "neqEquals", value: undefined }] },
    { and: [{ path: "titleInsurance", operator: "neqEquals", value: null }, { path: "titleInsurance", operator: "neqEquals", value: undefined }] },
    { and: [{ path: "recordingCharges", operator: "neqEquals", value: null }, { path: "recordingCharges", operator: "neqEquals", value: undefined }] },
    { and: [{ path: "ownersTitle", operator: "neqEquals", value: null }, { path: "ownersTitle", operator: "neqEquals", value: undefined }] },
    { and: [{ path: "transferTax", operator: "neqEquals", value: null }, { path: "transferTax", operator: "neqEquals", value: undefined }] },
    { and: [{ path: "settlementFee", operator: "neqEquals", value: null }, { path: "settlementFee", operator: "neqEquals", value: undefined }] }
  ]
};

//summaries
export const MONTHLY_PAYMENT_SUM_FIELDS: FormSummaryField[] = [
  { name: "taxes" },
  { name: "insurance" },
  { name: "pmi", conditions: WHEN_PMI_IS_MONTHLY },
  { name: "monthlyPremium", conditions: WHEN_IS_FHA },
  { name: "pi" }
];

export const OTHER_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "lenderCredits", conditions: WHEN_LENDER_CREDITS },
  { name: "appraisalFee", conditions: WHEN_IS_STREAMLINE_WO_APPRAISAL },
  { name: "discountPoints", conditions: WHEN_DISCOUNT_POINT },
  { name: "floodCertificationFee" },
  { name: "adminFee" },
  { name: "condoCertificateFee", conditions: WHEN_CONDO_CERTIFICATE_FEE_INCLUDED },
  { name: "mip", conditions: WHEN_MIP },
  { name: "ff", conditions: WHEN_FF },
  { name: "surveyFee", conditions: WHEN_IN_SURVEY_FEE_STATES },
  { name: "pestInspectionFee", conditions: WHEN_IS_PESTINSPECTION_FEE },
  { name: "docReviewFee", conditions: WHEN_IN_DOC_REVIEW_FEE_STATES },
  { name: "attorneyFee", conditions: WHEN_IN_ATTORNEY_FEE_STATES },
  { name: "earnestMoneyPaid", conditions: WHEN_EARNEST_MONEY_PAID_INCLUDED },
  { name: "sellerConcession", conditions: WHEN_SELLER_CONCESSION_INCLUDED },
  { name: "subordinationFee", conditions: WHEN_SECONDARY_FINANCING_NOT_NONE }
];

export const TITLE_FEE_SUM_FIELDS: FormSummaryField[] = [
  { name: "titleInsurance", conditions: WHEN_TITLE_INSURANCE_IS_NOT_EMPTY },
  { name: "recordingCharges", conditions: WHEN_RECORDING_CHARGES_IS_NOT_EMPTY },
  { name: "ownersTitle", conditions: WHEN_OWNERS_TITLE_IS_NOT_EMPTY },
  { name: "transferTax", conditions: WHEN_TRANSFER_TAX_IS_NOT_EMPTY },
  { name: "settlementFee", conditions: WHEN_SETTLEMENT_FEE_IS_NOT_EMPTY },
  { name: "lendersTitle", conditions: WHEN_LENDERS_TITLE_IS_NOT_EMPTY }
];

export const CREDIT_REPORT_SUM_FIELDS: FormSummaryField[] = [
  { name: "creditReportFee", conditions: WHEN_IS_STREAMLINE_WO_CREDIT },
  { name: "loanSafeFee" },
  { name: "mersFee" },
  { name: "verificationFee" },
  { name: "electronicDocumentDelivery" },
  { name: "transcriptFee" }
];

export const PREPAID_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "propertyInsuranceYearly", conditions: WHEN_IS_PURCHASE },
  { name: "propertyInsurancePrepaidTotal" },
  { name: "propertyTaxTotal" },
  { name: "prepaidInterestTotal" },
  { name: "lockInFee", conditions: WHEN_LOCK_IN_FEE_INCLUDED }
];
export const CLOSING_COSTS_SUM_FIELDS: FormSummaryField[] = [
  { name: "otherCostTotal" },
  { name: "creditReportTotal" },
  { name: "prepaidCostTotal" },
  { name: "titleFeeTotal" },
  { name: "pmi", conditions: WHEN_PMI_IS_NOT_MONTHLY }
];

export const REFINANCE_SUM_FIELDS: FormSummaryField[] = [
  { name: "currentMortgageBalance" },
  { name: "loanAmount" },
  { name: "totalLoanAmount" },
  { name: "totalClosingCosts" }
];

export const PURCHASE_SUM_FIELDS: FormSummaryField[] = [
  { name: "purchaseValue" },
  { name: "loanAmount" },
  { name: "totalLoanAmount" },
  { name: "totalClosingCosts" }
];

