import { useMemo }         from "react";
import React               from "react";
import { FC }              from "react";
import { useRef }          from "react";
import { useEffect }       from "react";
import { useCallback }     from "react";
import { ReactText }       from "react";
import { ReactElement }    from "react";
import { useState }        from "react";
import { createPortal }    from "react-dom";
import { useConstant }     from "../..";
import { AlertColors }     from "./Alert";
import { AlertProps }      from "./Alert";
import { AlertTransition } from "./AlertTransition";
import { AlertContainer }  from "./AlertContainer";
import { AlertPosition }   from "./AlertPosition";

export interface AlertOptions extends Omit<AlertProps, "variant"> {
  variant?: AlertColors
  position?: AlertPosition,
  autoCloseTimeout?: number,
  autoClose?: boolean
}

export interface AlertObject {
  id: string,
  message: string | ReactText | ReactElement,
  position: AlertPosition,
  variant: AlertColors,
  options: Omit<AlertProps, "variant">
  autoCloseTimeout?: number,
  autoClose?: boolean,
  remove: () => void,
  ref: React.RefObject<any>
}

export interface AlertContextValue {
  info: WithoutVariant
  error: WithoutVariant
  success: WithoutVariant
  push:WithoutVariant
  warning: WithoutVariant
  closeAlert: (id: string) => void
}

export type AlertMessage = string | ReactText | ReactElement;
export type WithoutVariant = (message: AlertMessage, options?: Omit<AlertOptions, "variant">) => AlertObject;
export type AlertStackOptions = { limit?: number }
class AlertStack {
  #handlers = new Set<(alerts: AlertObject[]) => void>();
  #alerts = [];
  #limit: number;
  constructor(options: AlertStackOptions) {
    this.#limit = options.limit;
  }
  subscribe(handler) {
    this.#handlers.add(handler);
    return () => {
      this.unsubscribe(handler);
    };
  }
  unsubscribe(handler) {
    this.#handlers.delete(handler);
  }
  dispatch(alerts: AlertObject[]) {
    for (let handler of this.#handlers.values()) {
      handler(alerts);
    }
  }
  close(id) {
    const alert = this.#alerts.find(alert => alert.id === id);
    if (alert?.ref?.current) {
      alert.ref.current.remove();
    }
  }
  remove(id) {
    const alerts = this.#alerts.filter((alert) => alert.id !== id);
    this.dispatch(alerts);
    this.#alerts = alerts;
  }
  push(message, options?) {
    const {
      position = AlertPosition.BOTTOM_CENTER,
      variant = AlertColors.Primary,
      autoClose = true,
      autoCloseTimeout = 3000,
      ...rest
    } = Object(options) as AlertOptions;
    let alert: AlertObject = this.#alerts.find(alert => alert.message === message);

    if (alert) {
      return alert;
    }
    if (this.#limit && this.#limit <= this.#alerts.length) {
      this.close(this.#alerts[ 0 ].id);
    }
    const id = Math.random().toString(36).substr(2, 9);
    alert = {
      id,
      message,
      position,
      variant,
      autoCloseTimeout,
      autoClose,
      ref: React.createRef(),
      options: {
        ...rest,
        onClose: () => {
          this.close(id);
          if (rest.onClose) {
            rest.onClose();
          }
        }
      },
      remove: () => {
        this.remove(id);
      }
    };
    this.#alerts = this.#alerts.concat(alert);
    this.dispatch(this.#alerts.slice());
    return alert;
  }
}

export const AlertContext = React.createContext<AlertContextValue>(null);

export const AlertProvider: FC<AlertStackOptions> = React.memo((props) => {
  const stack = useConstant(() => new AlertStack(props));

  const closeAlert = useCallback((id: string) => {
    stack.close(id);
  }, []);
  const info: WithoutVariant = useCallback((message, options?) => {
    return stack.push(message, {
      ...options,
      variant: AlertColors.Primary
    });
  }, []);
  const push: WithoutVariant = useCallback((message, options?) => {
    return stack.push(message, {
      ...options,
      variant: AlertColors.Default
    });
  }, []);
  const error: WithoutVariant = useCallback((message, options?) => {
    return stack.push(message, {
      ...options,
      variant: AlertColors.Error
    });
  }, []);
  const success: WithoutVariant = useCallback((message, options?) => {
    return stack.push(message, {
      ...options,
      variant: AlertColors.Success
    });
  }, []);
  const warning: WithoutVariant = useCallback((message, options?) => {
    return stack.push(message, {
      ...options,
      variant: AlertColors.Warning
    });
  }, []);

  const context = useMemo(() => ({
    info,
    push,
    error,
    success,
    warning,
    closeAlert
  }), [stack]);

  return (
    <AlertContext.Provider value={context}>
      {props.children}
      <AlertPortal stack={stack}/>
    </AlertContext.Provider>
  );
});

export const AlertPortal = React.memo<{ stack: AlertStack }>(({ stack }) => {
  const [alerts, setAlerts] = useState<AlertObject[]>([]);
  const root = useRef(null);
  const groupBy = useCallback((array, fn) =>
    array.reduce((result, item) => {
      const key = fn(item);
      if (!result[ key ]) {
        result[ key ] = [];
      }
      result[ key ].push(item);
      return result;
    }, {}), []);
  useEffect(() => {
    root.current = document.createElement("div");
    root.current.id = "__alerts__";
    document.body.appendChild(root.current);
    return () => {
      if (root.current) {
        document.body.removeChild(root.current);
      }
    };
  }, []);

  useEffect(() => stack.subscribe((alerts) => {
    setAlerts(alerts);
  }), [stack]);

  const groupedByPosition = groupBy(alerts, alert => alert.position);

  return (
    <>
      {root.current &&
      createPortal(
        <React.Fragment>
          {Object.keys(groupedByPosition).map((position: AlertPosition) => (
            <AlertContainer key={position} position={position}>
              {groupedByPosition[ position ].map(alert => (
                <AlertTransition ref={alert.ref} key={alert.id} {...alert}  />
              ))}
            </AlertContainer>
          ))}
        </React.Fragment>,
        root.current
      )
      }
    </>
  );
});
