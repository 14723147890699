import { useMutation }                  from "@apollo/client";
import { useNavigate }                  from "@relcu/react-router";
import { addMinutes, toFirstLower }     from "@relcu/ui";
import { useModal }                     from "@relcu/ui";
import { deviceVar }                    from "../reactiveVars";
import { toNodeId }                     from "../utils/helpers";
import { getDefaultPhoneNumberForCall } from "../utils/helpers";
import { MarkCallsAsReadVariables }     from "./__types__/MarkCallsAsRead";
import { MarkCallsAsRead }              from "./__types__/MarkCallsAsRead";
import { SchemaDialog }                 from "./Layout/Dialog/SchemaDialog";
import { MARK_CALLS_AS_READ }           from "./operations.graphql";

type ITask = { subject?: { __typename: string, objectId: string } };
export function useTask() {
  const navigate = useNavigate();
  const [markAsRead] = useMutation<MarkCallsAsRead, MarkCallsAsReadVariables>(MARK_CALLS_AS_READ);
  const [schemaModal, contextSchemaHolder] = useModal(SchemaDialog);
  return {
    contextSchemaHolder,
    async onCallBack({ e, task, fromNumbers }) {
      e.stopPropagation();
      if (fromNumbers.length == 0) {
        return;
      }
      if (!task.subject) {
        return;
      }
      let primaryBorrower = task.subject.members.find((member) => {
        return member.isPrimary;
      });
      if (primaryBorrower.contact.phones.length == 0) {
        return;
      }
      const defaultNumber = getDefaultPhoneNumberForCall(primaryBorrower.contact.phones, "Contact");
      const callable = !defaultNumber?.callOptOut && defaultNumber.number;
      const { call } = deviceVar();
      if (callable) {
        await call({
          from: fromNumbers[ 0 ].value,
          to: callable,
          leadId: task.subject.objectId,
          contactName: primaryBorrower.contact.objectName,
          contactId: primaryBorrower.contact.objectId,
          markAsRead: async () => {
            await markAsRead({
              variables: {
                scope: toNodeId(task.subject)
              }
            });
          }
        });
        navigate(`/${toFirstLower(task.subject.__typename)}/${task.subject.objectId}/`);
      }
    },
    onEdit(e, task: ITask) {
      e.stopPropagation();
      schemaModal({
        className: "Task",
        action: "save",
        record: {
          ...task
        }
      });
    },
    onSendSms(e, task: ITask) {
      e.stopPropagation();
      if (!task.subject) {
        return;
      }
      return navigate(`/${toFirstLower(task.subject.__typename)}/${task.subject.objectId}/sms`);
    },
    onNavigate(task: ITask) {
      if (!task.subject) {
        return;
      }
      return navigate(`/${toFirstLower(task.subject.__typename)}/${task.subject.objectId}`);
    },
    async onSnooze({ task, number, update }) {
      const currentTime = new Date();
      const dueDate = (currentTime < new Date(task.dueDate)) ? addMinutes(new Date(task.dueDate), Number(number)) : addMinutes(currentTime, Number(number));
      await update({
        variables: {
          id: task.id,
          dueDate
        }
      });
    }
  };
}
