import React            from "react";
import { useEffect }    from "react";
import { useCallback }  from "react";
import { useState }     from "react";
import { useNavigate }  from "@relcu/react-router";
import { useMounted }   from "@relcu/ui";
import { Typography }   from "@relcu/rc";
import { Icon }         from "@relcu/rc";
import { IconButton }   from "@relcu/rc";
import { Stack }        from "@relcu/rc";
import { DialPad }      from "@relcu/ui";
import { DeviceCall }   from "../../../../Tw";
import { HybridCalls }  from "../../../../types/hybridCall";
import { usePhone }     from "../../../usePhone";
import { CallReply }    from "../../CallActions/CallReply";
import { useCallReply } from "../../CallActions/CallReply/useCallReply";
import { SmsReply }     from "../../CallActions/SmsReply";

export const TwilioCallActions = React.memo(function TwilioCallActions(props) {
  const { active = {} as DeviceCall, status, direction, hybridCalls } = usePhone();
  const { initialDisplayCall, myCall } = Object(hybridCalls) as HybridCalls;
  const navigate = useNavigate();
  const [openDialPad, setOpenDialPad] = useState(false);
  const [openVoiceMessages, setOpenVoiceMessages] = useState(false);
  const reply = useCallReply(hybridCalls?.myCall?.callSid, () => setOpenVoiceMessages(false));
  const [digits, setDigits] = useState("");

  const accept = useCallback(() => {
    active.accept();
    if (initialDisplayCall.leadId) {
      navigate(`/lead/${initialDisplayCall.leadId}/details`);
    } else {
      navigate(`/contact/${initialDisplayCall.objectId}/calls`);
    }
  }, [initialDisplayCall, active]);

  useEffect(() => {
    if (status === "pending") {
      setOpenDialPad(false);
    }
  }, [active]);

  const onDigit = useCallback((number) => {
    setDigits(prev => prev += number);
  }, [active]);

  const handleToggleVoiceMessageClick = () => {
    setOpenVoiceMessages(prev => !prev);
    setOpenDialPad(prev => prev && false);
  };

  const handleToggleDialPadClick = () => {
    setOpenDialPad(prev => !prev);
    setOpenVoiceMessages(prev => prev && false);
  };

  useMounted(() => {
    active.sendDigits(digits.slice(-1));
  }, [digits]);

  return <Stack
    direction={"column"}
    alignItems={"center"}
    justifyContent={"center"}
    childrenRenderMode={"clone"}
    style={{ width: "100%" }}>
    <Stack
      justifyContent={"center"}
      spacing={16}
      alignItems={"center"}
      childrenRenderMode={"clone"}>
      {
        status === "open" &&
        <>
          <IconButton
            size={"sm"}
            icon={<Icon type={"call_end"}/>}
            color={"red"}
            onClick={() => active.drop()}/>
          <IconButton
            size={"sm"}
            icon={<Icon type={myCall?.muted ? "mic_off" : "mic"}/>}
            onClick={() => active.mute(!myCall?.muted)}
          />
          {
            direction === "outgoing" && !!reply?.templates?.length &&
            <IconButton
              size={"sm"}
              icon={<Icon type={"rc_voicemail_call"}/>}
              onClick={handleToggleVoiceMessageClick}
            />
          }
          {
            direction === "outgoing" &&
            <IconButton size={"sm"} icon={<Icon type={"dialpad"}/>} onClick={handleToggleDialPadClick}/>
          }
        </>
      }
      {
        status !== "open" && direction === "incoming" &&
        <>
          <IconButton
            size={"sm"}
            icon={<Icon type={"call"}/>}
            color={"green"}
            onClick={accept}/>
          <IconButton
            size={"sm"}
            icon={<Icon type={"forum"}/>}
            onClick={handleToggleVoiceMessageClick}/>
          <IconButton
            size={"sm"}
            icon={<Icon type={"call_end"}/>}
            color={"red"}
            onClick={() => active.reject()}/>
        </>
      }
      {
        status !== "open" && direction === "outgoing" &&
        <IconButton
          size={"sm"}
          icon={<Icon type={"call_end"}/>}
          color={"red"}
          onClick={() => active.drop()}/>
      }
    </Stack>
    {
      openDialPad && !openVoiceMessages &&
      <>
        <Stack.Item basis={"24px"}/>
        <Stack justifyContent={"center"} alignItems={"center"} style={{ height: 44 }}>
          {
            digits &&
            <Typography variant={"large"}>{digits}</Typography>
          }
        </Stack>
        <DialPad onDigit={onDigit} onClose={() => setOpenDialPad(false)}/>
      </>
    }
    {
      direction === "outgoing" && openVoiceMessages && !openDialPad &&
      <>
        <Stack.Item basis={"32px"}/>
        <CallReply templates={reply.templates} send={reply.send}/>
      </>
    }
    {
      status !== "open" && direction === "incoming" && openVoiceMessages && !openDialPad &&
      <>
        <Stack.Item basis={"32px"}/>
        <SmsReply onClick={() => setOpenVoiceMessages(false)}/>
      </>
    }
  </Stack>;
});
