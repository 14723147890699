import { DurationLikeObject } from "luxon";
import { DateTime }           from "luxon";
import { getIn }              from "@relcu/form";
import { setIn }              from "@relcu/form";
import { MessageStatuses }    from "../constants/Statuses";
import { Condition }          from "./Condition";
import Handlebars             from "handlebars/dist/handlebars";
import RelativeTimeFormatUnit = Intl.RelativeTimeFormatUnit;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const stringToHslColor = (str, s = 40, l = 80, op = 1) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%, " + op + ")";
};

export function pad(num: number) {
  return num.toString().length > 1 ? num : "0" + num;
}

export const getParticipantName = (displayName) => {
  let midName = "";
  let fullName = "";

  let name = displayName.split(" ").slice(0, 2);
  if (name.length > 1) {
    midName = (name[ 0 ]).charAt(0) + (name[ 1 ]).charAt(0);
    fullName = `${name[ 0 ]} ${name[ 1 ]}`;
  } else {
    midName = (displayName.charAt(0) + displayName.charAt(1)).toUpperCase();
    fullName = `${displayName}`;
  }

  return {
    midName,
    fullName
  };
};
export function compileVars(expression: object | string, source: object, root: string = null) {
  if (typeof expression === "string" && expression.startsWith("$")) {
    const path = Condition.getPath(expression, root);
    return getIn(source, path);
  }
  if (typeof expression !== "object") {
    return expression;
  }
  if (Array.isArray(expression)) {
    return expression.map(obj => compileVars(obj, source, root));
  }
  return Object.fromEntries(
    Object.entries(expression).map(([k, v]) => {
      if ((isObject(v) || Array.isArray(v)) || typeof v === "string") {
        return [k, compileVars(v, source, root)];
      }
      return [k, v];
    })
  );
}
export function isObject(value) {
  return Object.prototype.toString.call(value) === "[object Object]";
}
export const concatText = (text, length = 2) => {
  let textToReturn = text;
  if (text.length > 2) {
    let textSplit: string[] = text.split(" ");
    if (textSplit.length > 1) {
      textToReturn = textSplit[ 0 ].substr(0, 1) + (length == 2 ? textSplit[ 1 ].substr(0, 1) : "");
    } else {
      textToReturn = textSplit[ 0 ].substr(0, length);
    }
  }
  return textToReturn;
};
export const getRandom = (array) => {
  return array[ Math.floor(Math.random() * array.length) ];
};
export const validURL = (str) => {
  let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(str);
};
export const formatNumber = (value, precision) => {
  return Number(value).toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision });
};
export const deepClone = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[ i ] = deepClone(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[ key ] = deepClone(obj[ key ]);
      return newObj;
    }, {});
  }
};
export function ownerDocument(node) {
  return (node && node.ownerDocument) || document;
}
export function setRef(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
export function checkType(item, label) {
  if (typeof item === "object") {
    return item[ label ] ? item[ label ].toString() : "";
  }
  return item;
}
export function makeLocalDate(timeZone: { rawOffset: number, dstOffset: number }) {
  const offset = timeZone.rawOffset + (timeZone.dstOffset ?? 0);
  const d = new Date();
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  return new Date(utc + (1000 * offset));
}
export const simpleDebounce = (func, delay) => {
  let timer;

  function cancel() {
    clearTimeout(timer);
  }

  function debounced() {
    cancel();
    timer = setTimeout(() => {
      func();
    }, delay);
  }

  debounced.cancel = cancel;
  return debounced;
};
export enum Gaps {
  XXXL = 64,
  XXL = 56,
  XL = 48,
  L = 40,
  M = 32,
  S = 24,
  XS = 16,
  XXS = 8,
  XXXS = 4,
  XXXXS = 1,
}
export function timeTo(dateTime: DateTime): string {
  const units: Array<keyof DurationLikeObject> = [
    "year",
    "month",
    "week",
    "day",
    "hour",
    "minute",
    "second"
  ];
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";
  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto"
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit as RelativeTimeFormatUnit);
};

export function isOverdue(dueDate) {
  return DateTime.fromISO(dueDate).diffNow().get("milliseconds") <= 0;
}

export const pick = (obj: object, props: string[], complex = false) => {
  if (complex) {
    return props.reduce(function (result, prop) {
      const value = getIn(obj, prop);
      if (value !== null && typeof value !== "undefined") {
        result = setIn(result, prop, value);
      }
      return result;
    }, {});
  }
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => props.includes(key))
  );
};
export const omit = (obj: object, props: string[]) => {
  if (typeof obj !== "object") {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => !props.includes(key))
  );
};
export const groupBy = <T extends object>(xs: T[], complexKey: string): Record<string, T[]> => {
  return xs.reduce((rv, x) => {
    const cur = getIn(x, complexKey);
    (rv[ cur ] = rv[ cur ] || []).push(x);
    return rv;
  }, {});
};
export function toFirstUpper(string: string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}
export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let amMatch = cleaned.match(/^(374|)?(\d{2})(\d{3})(\d{3})$/);
  if (match) {
    let intlCode = (match[ 1 ] ? "+1" : "");
    return ["(", match[ 2 ], ") ", match[ 3 ], "-", match[ 4 ]].join("");
  } else if (amMatch) {
    let intlCode = (amMatch[ 1 ] ? "+374" : "");
    return ["(", amMatch[ 2 ], ") ", amMatch[ 3 ], "-", amMatch[ 4 ]].join("");
  }
  return null;
}
export function parsePhoneNumber(phoneNumber) {
  return String(phoneNumber).replace(/[() .-]/g, "");
}
export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function transformNameToLabel(name: string) {
  name = name.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  name = name.trim();
  name = name[ 0 ].toUpperCase() + name.substring(1);
  return name;
}
export const deepPick = (obj: object, props: string[]) => {
  if (typeof obj !== "object") {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(obj => deepPick(obj, props));
  }
  let state = {};
  props.forEach(complexKey => {
    const value = getIn(obj, complexKey);
    state = setIn(state, complexKey, value) || state;
  });
  return state;
};
export const guidGenerator = (): string => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};
export function base64urlEncode(str) {
  return base64urlEscape(btoa(str));
}
export function base64urlDecode(str) {
  return atob(base64urlUnescape(str));
}

export function base64urlUnescape(str) {
  /* str += new Array(5 - str.length % 4).join("=");*/
  return str.replace(/\-/g, "+").replace(/_/g, "/");
}

export function base64urlEscape(str) {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export const humanize = (value) => {
  const camelMatch = /([A-Z])/g;
  const underscoreMatch = /_/g;

  const camelCaseToSpaces = value.replace(camelMatch, " $1");
  const underscoresToSpaces = camelCaseToSpaces.replace(underscoreMatch, " ");
  const caseCorrected =
    underscoresToSpaces.charAt(0).toUpperCase() +
    underscoresToSpaces.slice(1).toLowerCase();

  return caseCorrected;
};

export function toFirstLower(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
export const format = (value, precision) => {
  return Number((!value ? 0 : value)).toLocaleString("en-US", { minimumFractionDigits: precision, maximumFractionDigits: precision });
};

export const handlebars = (tpl, object) => {
  return Handlebars.compile(tpl)(object);
};

export function shallowEqualObjects(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    const key = aKeys[ i ];

    if (objA[ key ] !== objB[ key ] || !Object.prototype.hasOwnProperty.call(objB, key)) {
      return false;
    }
  }

  return true;
}

export function getCallContact(call) {
  return call.calls.find(c => c.party?.__typename == "Contact")?.party;
}

export const getDuration = (startDate: Date, endDate: Date) => {
  const duration = { h: 0, m: 0, s: 0 };
  if (startDate) {
    let end = new Date(endDate || new Date());
    let start = new Date(startDate);

    let diffMs = (end.getTime() - start.getTime());
    duration.h = Math.floor((diffMs % 86400000) / 3600000);
    duration.m = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    duration.s = Math.round(Math.abs(diffMs / (1000) % 60));
  }
  return duration;
};

export function getElementPositionRelativeToParent(elementBounding: DOMRect, parentBounding: DOMRect) {
  const relativePos: any = {};
  relativePos.top = elementBounding.top - parentBounding.top;
  relativePos.right = elementBounding.right - parentBounding.right;
  relativePos.bottom = elementBounding.bottom - parentBounding.bottom;
  relativePos.left = elementBounding.left - parentBounding.left;

  return relativePos;
}

export function emailTemplate(path, user) {
  const replaceObject = {
    ...user,
    userID: user.objectId,
    firstName: user.firstName,
    lastName: user.lastName,
    userNmls: user.nmlsId,
    reviewUrl: user.reviewUrl,
    applyUrl: user.applyUrl,
    username: user.username,
    positionName: user.positionName,
    profilePicture: user.objectIcon,
    phone: formatPhoneNumber(user.phoneLines?.edges[ 0 ]?.node.number)
  };
  try {
    return Handlebars.compile(path)(replaceObject);
  } catch (ex) {
    return `<pre>${ex.message}</pre>`;
  }
}

export function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) {
    return arr;
  }
  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[ 0 ];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}

export function getMessageStatus(status) {
  return MessageStatuses.find(m => m.value == status)?.label ?? "";
}
