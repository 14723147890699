import { useSearchParams } from "@relcu/react-router";
import { useMounted }      from "@relcu/ui";
import { useState }        from "react";
import { useEffect }       from "react";
import { useMemo }         from "react";
import { gql }             from "@apollo/client";
import { useSource }       from "@relcu/ui";
import { toFirstUpper }    from "../../../../utils/helpers";
import { toNodeId }        from "../../../../utils/helpers";
import { toFirstLower }    from "../../../../utils/helpers";
import { pluralize }       from "../../../../utils/pluralize";
import { useJqlQuery }     from "../../Jql";

export function useMilestoneView(props) {
  const { jql } = props;
  const { $object } = useSource();
  const [searchParams] = useSearchParams();
  const selectedLoanId = searchParams.get("lid");

  const [loanId, setLoanId] = useState(selectedLoanId || $object?.loan?.objectId);
  const operation = useMemo(() => pluralize(toFirstLower("Loan")), []);
  const query = useMemo(() => ({
    operation: "loans",
    operationName: "GetLoans",
    variables: {
      where: {
        type: "LoanWhereInput",
        name: "where"
      },
      order: {
        type: "[LoanOrder!]",
        value: ["updatedAt_DESC"]
      }
    },
    fields: [{ edges: [{ node: ["objectId", ...jql.query.get.fields] }] }]
  }), [operation, jql.query.get.fields]);

  const { data: { loans } = {}, subscribeToMore, refetch } = useJqlQuery(query, {
    operationName: "GetLoans",
    variables: {
      where: { lead: { have: { objectId: { equalTo: $object.objectId } } } }
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first"
  });

  const loan = useMemo(() => {
    let loanEdge = (loans?.edges || []).find((l) => (l.node.objectId == loanId));
    return loanEdge?.node || loans?.edges?.[ 0 ]?.node;
  }, [loans, loanId]);
  const loanOptions = useMemo(() => ((loans?.edges || []).map((l) => ({ label: l.node.objectName, value: l.node.objectId }))), [loans]);

  useEffect(() => {
    return subscribeToMore({
      document: SUBSCRIBE_LOAN,
      variables: {
        leadId: toNodeId({ objectId: $object.objectId, className: "Lead" })
      },
      updateQuery: (prev) => {
        refetch();
        return prev;
      }
    });
  }, []);

  useMounted(() => {
    setLoanId(selectedLoanId);
  }, [selectedLoanId]);

  return {
    loan,
    loans,
    setLoanId,
    loanOptions
  };
}

const SUBSCRIBE_LOAN = gql`
  subscription SubscribeLoan($leadId:ID!) {
    loans(where: {lead: {have: {link: $leadId}}}) {
      event
      node {
        id
      }
    }
  }
`;
