import React                     from "react";
import { FC }                    from "react";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "./editor.css";

export interface EditorProps {
  value: string;
  onChange(content: string);
}

export const Editor: FC<EditorProps> = React.memo(function Editor(props) {
  const { value, onChange } = props;
  return <SunEditor
    placeholder={"Type a message..."}
    setContents={value}
    onChange={onChange}
    height={"100%"}
    setOptions={{
      showPathLabel: false,
      toolbarContainer: "#custom_toolbar",
      buttonList: [
        ["bold", "underline", "italic", "fontSize", "fontColor", "hiliteColor", "outdent", "indent", "align", "list", "link"]
      ]
    }}>
  </SunEditor>;
});
